import { useState } from 'react';

import FsLightbox from 'fslightbox-react';

import { IPrivateGallery } from './IPrivateGallery';
import * as S from './styles';

const totalVisibleImages = 3;

function PrivateGallery({ images }: IPrivateGallery) {
  const [toggler, setToggler] = useState(false);
  const [initialSlide, setInitialSlide] = useState(1);

  const showImages = images.slice(0, totalVisibleImages);

  const calcRemainingImages = (): number | undefined => {
    const remainingImage = images.length - totalVisibleImages;

    return remainingImage > 0 ? remainingImage : undefined;
  };

  return (
    <>
      <S.GridContainer remaining_images={calcRemainingImages()}>
        {showImages.map((image, index) => (
          <S.GalleryItem
            key={`private-gallery-item-${index}`}
            onClick={() => {
              setToggler(!toggler);
              setInitialSlide(index + 1);
            }}
          >
            <img src={image} alt={`Thumb number ${index}`} />
          </S.GalleryItem>
        ))}
      </S.GridContainer>
      <FsLightbox
        toggler={toggler}
        sources={[...images]}
        slide={initialSlide}
      />
    </>
  );
}

export { PrivateGallery };
