import { FiCheckSquare } from 'react-icons/fi';

import { Title } from 'components/Atoms/Title';

import * as S from './style';

export default function Installment() {
  return (
    <S.InstallmentContainer>
      <main>
        <Title color="white">
          Parcelamos a sua Entrada ou as Taxas de Documentação em até 18X no
          Cartão de Crédito
        </Title>

        <div className="fgts-message">
          <FiCheckSquare size={22} />
          <p>Use o seu FGTS para pagamento de parte da Entrada ou Total.</p>
        </div>
      </main>
    </S.InstallmentContainer>
  );
}
