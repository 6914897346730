import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 2rem;

  h3 {
    font-weight: 700;
    font-size: 1.4rem;
    margin-bottom: 5rem;
  }

  @media (max-width: 884px) {
    padding: 0 1.25rem;
  }
`;

export const CardContainer = styled.div`
  @media (max-width: 520px) {
    margin-left: 100px !important;
  }
`;
