import styled from 'styled-components';

export type BaseInputProps<T> = {
  value?: T;
  setValue?: (value: T) => void;
  error?: string | number;
  placeholder?: string;
  className?: string;
};

const Container = styled.div`
  input {
    padding: 11px 15px;
    border-radius: 6px;
    border: 1px solid var(--grey-200);
    width: 100%;

    &:active {
      outline: 2px solid var(--blue-200);
    }
  }

  margin-bottom: 10px;
`;

export default function BaseInput<T = string>({
  value,
  setValue,
  placeholder,
  error,
  className,
}: BaseInputProps<T>) {
  return (
    <Container className={className}>
      <input
        placeholder={placeholder}
        value={value as any}
        onChange={(e: any) => setValue && setValue(e.target.value)}
      />
      {error && <small style={{ color: 'red' }}>{error}</small>}
    </Container>
  );
}
