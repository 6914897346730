const routesNames = {
  adDetails: '/detalhes-anuncio',
  termsOfUsage: '/termos-uso',
  privacyPolicy: '/politica-privacidade',
  addUser: '/adicionar-usuario',
  dashboard: '/dashboard',
  detail: '/imovel/:slug/:id',
  list: '/lista-de-imoveis',
  login: '/login',
  myProfile: '/perfil',
  realty: '/todos-os-imoveis',
  register: '/cadastro-imovel',
  editProperty: '/editar-imovel/:id',
  root: '/',
  search: '/busca',
  signUp: '/cadastro-usuario',
  clients: '/clientes',
  users: '/usuarios',
  user: '/editar-usuario/:id',
  userDetails: '/detalhe-usuario/:id',
  favorites: '/favoritos',
  changePassword: '/alterar-senha',
  catalogs: '/catalogos',
  catalogsCreate: '/catalogo',
  catalogsEdit: '/catalogo/:id',
  catalogsView: '/catalogo/:slug/:id',
  Owners: '/proprietarios',
  OwnerCreate: '/proprietario',
  OwnerEdit: '/proprietario/:id',
  tags: '/tarjas',
  tagsCreate: '/tarja',
  tagsEdit: '/tarjas/:id',
  communication: '/comunicados',
  communicationCreate: '/comunicado',
  communicationEdit: '/comunicado/:id',
  changeResetPassword: '/reset-senha/:hash',
  notFound: '*',
};

export { routesNames };
