import { Box, Typography, Input, useMediaQuery } from '@mui/material';

import { IVerticalInputContainerProps } from './IVerticalInputContainer';

function VerticalInputContainer({
  title,
  subtitle,
  component,
  ...rest
}: IVerticalInputContainerProps) {
  const matches = useMediaQuery('(max-width: 884px)');

  return (
    <Box
      display="flex"
      flexDirection={matches ? 'column' : 'row'}
      alignItems={matches ? 'flex-start' : 'center'}
      columnGap="15%"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        minWidth="180px"
        alignItems="flex-start"
        marginBottom={matches ? '12px' : '0'}
      >
        <Typography
          fontSize="18px"
          fontWeight="600"
          color="var(--primary-neutral-900)"
        >
          {title}
        </Typography>

        {subtitle && (
          <Typography
            fontSize="14px"
            fontWeight="400"
            color="var(--primary-neutral-650)"
          >
            {subtitle}
          </Typography>
        )}
      </Box>

      {component ? (
        <>{component}</>
      ) : (
        <Box width={matches ? '100%' : '500px'}>
          <Input {...rest} fullWidth />
        </Box>
      )}
    </Box>
  );
}

export { VerticalInputContainer };
