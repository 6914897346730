import styled, { css } from 'styled-components';

import { IPageDefaultWrapperProps } from './IPageDefaultWrapper';

type IContainerProps = Pick<IPageDefaultWrapperProps, 'verticalPad'>;

export const Container = styled.div<IContainerProps>`
  ${props =>
    props.verticalPad &&
    css`
      padding: 1.75rem 0 0;
    `}

  @media (min-width: 840px) and (max-width: 1200px) {
    padding: 0 1.4rem;
  }
`;
