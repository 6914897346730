import { useState, useEffect } from 'react';
import { BsCameraVideo, BsCardImage } from 'react-icons/bs';
import { FiMapPin } from 'react-icons/fi';

import MuiModal from '@mui/material/Modal';
import { Property } from '@types';
import { Logo } from 'components/Atoms';
import CloseButton from 'components/Atoms/CloseButton';
import LightButton from 'components/Atoms/LightButton';
import Modal, { useModal } from 'components/Atoms/modal/Modal';
import { PropertyPriceTicket } from 'pages/AnnouncementDetail/components/PropertyPriceTicket';

import {
  ImageDisplay,
  ImageFooter,
  ImageSubMessage,
  ImageSwitchBtnLeft,
  ImageSwitchBtnRight,
  useImageDisplay,
} from './Images';
import {
  LocationDisplay,
  LocationFooter,
  LocationSwitchBtnLeft,
  LocationSwitchBtnRight,
  useLocationDisplay,
} from './Location';
import * as S from './style';
import {
  useVideoDisplay,
  VideoDisplay,
  VideoFooter,
  VideoSubMessage,
  VideoSwitchBtnLeft,
  VideoSwitchBtnRight,
} from './Videos';

export { useModal as useModal } from 'components/Atoms/modal/Modal';

export type PropertyContentModalProps = {
  property?: Property;
  isOpen: boolean;
  close: () => void;
  mode: 'images' | 'videos' | 'location' | 'streetView' | '360';
  index?: number;
};

export default function PropertyContentModal({
  close,
  isOpen,
  mode,
  property,
  index = 0,
}: PropertyContentModalProps) {
  const imageController = useImageDisplay({ property, index });
  const videoController = useVideoDisplay({ property });
  const locationController = useLocationDisplay({ property });
  const [currentMode, setCurrentMode] = useState(mode);
  const mobilePriceTicketModal = useModal();
  const [disableEscKey, setDisableEscKey] = useState(false);

  useEffect(() => {
    if (property && currentMode !== mode) {
      setCurrentMode(mode);
    }
  }, [mode]);

  if (!property) return <></>;

  return (
    <MuiModal
      disableEscapeKeyDown={disableEscKey}
      open={isOpen}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <S.Background>
          <div className="mobile-header mobile-only">
            <Logo />

            <CloseButton onClick={close} />
          </div>
          {currentMode === 'images' && (
            <ImageSwitchBtnLeft {...imageController.props} />
          )}

          {currentMode === 'videos' && (
            <VideoSwitchBtnLeft {...videoController.props} />
          )}
          {currentMode === 'location' && (
            <LocationSwitchBtnLeft {...imageController.props} />
          )}
          <S.Container>
            <S.Header>
              <Logo className="desktop-only" />
              <div className="actions-container">
                <LightButton
                  responsive={currentMode !== 'images'}
                  active={currentMode === 'images'}
                  className="light-button"
                  icon={<BsCardImage />}
                  text="Fotos"
                  onClick={() => setCurrentMode('images')}
                />
                {!!property?.videos?.length && (
                  <LightButton
                    className="light-button"
                    responsive={currentMode !== 'videos'}
                    active={currentMode === 'videos'}
                    icon={<BsCameraVideo />}
                    text="Vídeos"
                    onClick={() => setCurrentMode('videos')}
                  />
                )}
                <LightButton
                  responsive={currentMode !== 'location'}
                  active={currentMode === 'location'}
                  className="light-button"
                  icon={<FiMapPin />}
                  text="Localização"
                  onClick={() => setCurrentMode('location')}
                />
                {/* <LightButton
                  responsive={currentMode !== 'streetView'}
                  active={currentMode === 'streetView'}
                  className="light-button"
                  icon={<VscPerson />}
                  text="Street View"
                  onClick={() => setCurrentMode('streetView')}
                />
                <LightButton
                  responsive={currentMode !== '360'}
                  active={currentMode === '360'}
                  className="light-button"
                  icon={<GrRotateRight />}
                  text="Ver  360"
                  onClick={() => setCurrentMode('360')}
                /> */}

                <div className="btn-clone-c desktop-only">
                  <CloseButton onClick={close} />
                </div>
              </div>
            </S.Header>
            <S.Main>
              <main>
                {currentMode === 'images' && (
                  <ImageDisplay
                    {...imageController.props}
                    onCarouselClose={() => setDisableEscKey(false)}
                    onCarouselOpen={() => setDisableEscKey(true)}
                  />
                )}

                {currentMode === 'videos' && (
                  <VideoDisplay {...videoController.props} />
                )}

                {currentMode === 'location' && (
                  <LocationDisplay {...locationController.props} />
                )}
              </main>

              <aside className="price-ticket">
                <PropertyPriceTicket property={property} variant="price-down" />
              </aside>
            </S.Main>

            {currentMode === 'images' && (
              <ImageSubMessage {...imageController.props} />
            )}

            {currentMode === 'videos' && (
              <VideoSubMessage {...videoController.props} />
            )}

            {currentMode === 'images' && (
              <ImageFooter {...imageController.props} />
            )}

            {currentMode === 'videos' && (
              <VideoFooter {...videoController.props} />
            )}

            {currentMode === 'location' && (
              <LocationFooter {...locationController.props} />
            )}
          </S.Container>
          {currentMode === 'images' && (
            <ImageSwitchBtnRight {...imageController.props} />
          )}

          {currentMode === 'videos' && (
            <VideoSwitchBtnRight {...videoController.props} />
          )}

          {currentMode === 'location' && (
            <LocationSwitchBtnRight {...imageController.props} />
          )}
          <button
            className="mobile-only liked-btn"
            onClick={() => mobilePriceTicketModal.open()}
          >
            Gostou? Entre em contato
          </button>

          <Modal {...mobilePriceTicketModal.props}>
            <PropertyPriceTicket
              property={property}
              variant="price-down-full-no-border"
            />
          </Modal>
        </S.Background>
      </div>
    </MuiModal>
  );
}
