import { lazy, Suspense } from 'react';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';

import { routesNames } from 'common/utils/routesNames';
import * as Pages from 'pages';

import { PrivateRoute, PublicRoute } from './Routes';

const RegisterEditPage = lazy(
  /* webpackChunkName: "RegisterEditPage" */ () =>
    import('../pages/RegisterEdit'),
);
const RegisterPage = lazy(
  /* webpackChunkName: "RegisterPage" */ () => import('../pages/Register'),
);
const EditUserPage = lazy(
  /* webpackChunkName: "EditUserPage" */ () => import('../pages/EditUser'),
);
const TermsOfUsagePage = lazy(
  /* webpackChunkName: "TermsOfUsagePage" */ () =>
    import('../pages/TermsOfUsage'),
);
const PrivacyPolicyPage = lazy(
  /* webpackChunkName: "PrivacyPolicyPage" */ () =>
    import('../pages/PrivacyPolicy'),
);
const AddUserPage = lazy(
  /* webpackChunkName: "AddUserPage" */ () => import('../pages/AddUser'),
);
const DashboardPage = lazy(
  /* webpackChunkName: "DashboardPage" */ () => import('../pages/Dashboard'),
);
const OwnerFormPage = lazy(
  /* webpackChunkName: "OwnerFormPage" */ () => import('../pages/Owner/Form'),
);
const UserDetailsPage = lazy(
  /* webpackChunkName: "UserDetailsPage" */ () =>
    import('../pages/UserDatails'),
);
const AdDetailsPage = lazy(
  /* webpackChunkName: "AdDetailsPage" */ () => import('../pages/AdDetails'),
);
const AnnouncementDetailPage = lazy(
  /* webpackChunkName: "AnnouncementDetailPage" */
  () => import('../pages/AnnouncementDetail'),
);

const SearchPage = lazy(
  /* webpackChunkName: "SearchPage" */
  () => import('../pages/Search'),
);

const ChangeResetPasswordPage = lazy(
  /* webpackChunkName: "ChangeResetPasswordPage" */
  () => import('../pages/ChangeResetPassword'),
);

const ClientsPage = lazy(
  /* webpackChunkName: "ClientsPage" */
  () => import('../pages/Clients'),
);

const ProfilePage = lazy(
  /* webpackChunkName: "ProfilePage" */
  () => import('../pages/Profile'),
);

const RealtyPage = lazy(
  /* webpackChunkName: "RealtyPage" */
  () => import('../pages/Realty'),
);

const ListPage = lazy(
  /* webpackChunkName: "ListPage" */
  () => import('../pages/List'),
);
const UsersPage = lazy(
  /* webpackChunkName: "UsersPage" */
  () => import('../pages/Users'),
);
const FavoritesPage = lazy(
  /* webpackChunkName: "FavoritesPage" */
  () => import('../pages/Favorites'),
);
const ChangePasswordPage = lazy(
  /* webpackChunkName: "ChangePasswordPage" */
  () => import('../pages/ChangePassword'),
);
const CatalogPage = lazy(
  /* webpackChunkName: "CatalogPage" */
  () => import('../pages/Catalog'),
);
const CatalogFormPage = lazy(
  /* webpackChunkName: "CatalogFormPage" */
  () => import('../pages/Catalog/Form'),
);
const CatalogViewPage = lazy(
  /* webpackChunkName: "CatalogViewPage" */
  () => import('../pages/Catalog/View'),
);
const NotFoundPage = lazy(
  /* webpackChunkName: "NotFoundPage" */
  () => import('../pages/NotFound'),
);
const OwnersPage = lazy(
  /* webpackChunkName: "OwnersPage" */
  () => import('../pages/Owner'),
);
const TagsPage = lazy(
  /* webpackChunkName: "TagsPage" */
  () => import('../pages/Tags'),
);
const TagsFormPage = lazy(
  /* webpackChunkName: "TagsFormPage" */
  () => import('../pages/Tags/Form'),
);

const CommunicationPage = lazy(
  /* webpackChunkName: "CommunicationPage" */
  () => import('../pages/Communication'),
);
const CommunicationFormPage = lazy(
  /* webpackChunkName: "CommunicationFormPage" */
  () => import('../pages/Communication/Form'),
);

interface Routes {
  path: string;
  isPrivate: boolean;
  element: JSX.Element;
}

const routesPaths: Routes[] = [
  { path: routesNames.root, isPrivate: false, element: <Pages.LandingPage /> },
  {
    path: routesNames.detail,
    isPrivate: false,
    element: (
      <Suspense fallback={<></>}>
        <AnnouncementDetailPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.termsOfUsage,
    isPrivate: false,
    element: (
      <Suspense fallback={<></>}>
        <TermsOfUsagePage />
      </Suspense>
    ),
  },
  {
    path: routesNames.privacyPolicy,
    isPrivate: false,
    element: (
      <Suspense fallback={<></>}>
        <PrivacyPolicyPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.search,
    isPrivate: false,
    element: (
      <Suspense fallback={<></>}>
        <SearchPage />
      </Suspense>
    ),
  },
  // { path: routesNames.login, isPrivate: false, element: <Pages.SignIn /> },
  {
    path: routesNames.clients,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <ClientsPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.changeResetPassword,
    isPrivate: false,
    element: (
      <Suspense fallback={<></>}>
        <ChangeResetPasswordPage />
      </Suspense>
    ),
  },
  // { path: routesNames.signUp, isPrivate: false, element: <Pages.SignUp /> },
  {
    path: routesNames.dashboard,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <DashboardPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.myProfile,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <ProfilePage />
      </Suspense>
    ),
  },
  {
    path: routesNames.realty,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <RealtyPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.adDetails,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <AdDetailsPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.register,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <RegisterPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.editProperty,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <RegisterEditPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.addUser,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <AddUserPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.list,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <ListPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.users,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <UsersPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.user,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <EditUserPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.userDetails,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <UserDetailsPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.favorites,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <FavoritesPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.changePassword,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <ChangePasswordPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.catalogs,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <CatalogPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.catalogsCreate,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <CatalogFormPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.catalogsEdit,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <CatalogFormPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.catalogsView,
    isPrivate: false,
    element: (
      <Suspense fallback={<></>}>
        <CatalogViewPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.Owners,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <OwnersPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.OwnerCreate,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <OwnerFormPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.OwnerEdit,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <OwnerFormPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.tags,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <TagsPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.tagsCreate,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <TagsFormPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.tagsEdit,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <TagsFormPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.communication,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <CommunicationPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.communicationCreate,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <CommunicationFormPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.communicationEdit,
    isPrivate: true,
    element: (
      <Suspense fallback={<></>}>
        <CommunicationFormPage />
      </Suspense>
    ),
  },
  {
    path: routesNames.notFound,
    isPrivate: false,
    element: (
      <Suspense fallback={<></>}>
        <NotFoundPage />
      </Suspense>
    ),
  },
];

export function Routes() {
  return (
    <ReactRouterDomRoutes>
      {routesPaths.map(({ path, isPrivate, element }) =>
        isPrivate ? (
          <Route
            key={path}
            path={path}
            element={<PrivateRoute>{element}</PrivateRoute>}
          />
        ) : (
          <Route
            key={path}
            path={path}
            element={<PublicRoute>{element}</PublicRoute>}
          />
        ),
      )}
    </ReactRouterDomRoutes>
  );
}
