import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { IButtonProps } from './IButton';

type IContainerProps = Pick<
  IButtonProps,
  'color' | 'bolder' | 'full_width' | 'high_border' | 'no_border'
>;

export const Container = styled.button<IContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  column-gap: 0.75rem;

  font-size: 1rem;

  padding: 0.5rem 1.25rem;
  border-radius: 0.5rem;
  border: none;

  transition: all 0.25s ease-in-out;

  ${props =>
    props.color === 'blue' &&
    css`
      background: var(--blue-100);
      color: var(--white);
      border: 2px solid var(--blue-100);

      &:hover {
        border-color: ${darken(0.075, '#1DAEFF')};
        background: ${darken(0.075, '#1DAEFF')};
      }
    `};

  ${props =>
    props.color === 'high_blue' &&
    css`
      background: var(--blue-500);
      color: var(--white);
      border: 2px solid var(--blue-500);

      &:hover {
        border-color: ${darken(0.075, '#0C4A88')};
        background: ${darken(0.075, '#0C4A88')};
      }
    `};

  ${props =>
    props.color === 'alpha-blue' &&
    css`
      background: var(--alpha-blue-15);
      color: var(--blue-100);
      border: 2px solid var(--blue-100);

      &:hover {
        border-color: ${darken(0.075, '#1DAEFF')};
        background: ${darken(0.075, '#1DAEFF')};
        color: var(--white);
      }
    `};

  ${props =>
    props.color == 'alpha-red' &&
    css`
      background: ${darken(0.075, '#e01a1a')};
      border: 2px solid var(--primary-neutral-300);
      color: var(--primary-neutral-100);

      &:hover {
        background: ${darken(0.075, '#c71b1b')};
      }
    `};

  ${props =>
    props.color === 'green' &&
    css`
      background: var(--green-400);
      color: var(--white);
      border: 2px solid var(--green-400);

      &:hover {
        border-color: ${darken(0.075, '#2EA340')};
        background: ${darken(0.075, '#2EA340')};
      }
    `};

  ${props =>
    props.color === 'white' &&
    css`
      background: var(--white);
      border: 2px solid var(--primary-neutral-300);
      color: var(--primary-neutral-600);

      &:hover {
        border-color: ${darken(0.075, '#DFE8F6')};
        color: ${darken(0.075, '#606D93')};
      }
    `};

  ${props =>
    props.color === 'light' &&
    css`
      background: var(--blue-10);
    `};

  ${props =>
    props.color === 'transparent' &&
    css`
      background: transparent;
    `};

  ${props =>
    props.bolder &&
    css`
      font-weight: 700;
      font-size: 1rem;
    `};

  ${props =>
    props.full_width &&
    css`
      width: 100%;
    `};

  ${props =>
    props.high_border &&
    css`
      border-color: var(--blue-400);
      color: var(--blue-400);
      font-weight: 600;

      &:hover {
        border-color: ${darken(0.075, '#0073B5')};
        color: ${darken(0.075, '#0073B5')};
      }
    `}

  ${props =>
    props.no_border &&
    css`
      border: none;
    `}
`;
