import styled from 'styled-components';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 18px;

  height: 180px;
  width: 100%;

  div:nth-child(2),
  div:nth-child(3),
  div:nth-child(4) {
    grid-row-start: 1;
    grid-row-end: 3;
  }

  div:nth-child(5),
  div:nth-child(6),
  div:nth-child(7) {
    grid-row-start: 3;
    grid-row-end: 5;
  }

  div:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 5;
  }

  div:nth-child(2) {
    grid-column-start: 5;
    grid-column-end: 7;
  }

  div:nth-child(3) {
    grid-column-start: 7;
    grid-column-end: 9;
  }

  div:nth-child(4) {
    grid-column-start: 9;
    grid-column-end: 11;
  }

  div:nth-child(5) {
    grid-column-start: 5;
    grid-column-end: 7;
  }

  div:nth-child(6) {
    grid-column-start: 7;
    grid-column-end: 9;
  }

  div:nth-child(7) {
    grid-column-start: 9;
    grid-column-end: 11;
  }

  @media (max-width: 884px) {
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(12, 1fr);
    gap: 8px;

    height: 400px;

    div:nth-child(2),
    div:nth-child(3),
    div:nth-child(4) {
      grid-row-start: 5;
      grid-row-end: 7;
    }

    div:nth-child(5),
    div:nth-child(6),
    div:nth-child(7) {
      grid-row-start: 7;
      grid-row-end: 9;
    }

    div:nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 13;
      grid-row-start: 1;
      grid-row-end: 5;
    }

    div:nth-child(2) {
      grid-column-start: 1;
      grid-column-end: 5;
    }

    div:nth-child(3) {
      grid-column-start: 5;
      grid-column-end: 9;
    }

    div:nth-child(4) {
      grid-column-start: 9;
      grid-column-end: 13;
    }

    div:nth-child(5) {
      grid-column-start: 1;
      grid-column-end: 5;
    }

    div:nth-child(6) {
      grid-column-start: 5;
      grid-column-end: 9;
    }

    div:nth-child(7) {
      grid-column-start: 9;
      grid-column-end: 13;
    }
  }
`;
