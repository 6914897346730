const escKeyCode = 27;

function controlHideDiv(ref: any, callback: () => void) {
  function handleClickOutside(event: any) {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  }

  function handlePressEsc(event: any) {
    if (event.keyCode === escKeyCode) {
      callback();
    }
  }

  document.addEventListener('mousedown', handleClickOutside);
  document.addEventListener('keydown', handlePressEsc);

  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
    document.removeEventListener('keydown', handlePressEsc);
  };
}

export { controlHideDiv };
