import arrow_left from 'common/assets/svgs/arrow_left.svg';
import arrow_right from 'common/assets/svgs/arrow_right.svg';
import styled, { css } from 'styled-components';

import { IHouseCardCarouselProps } from './IHouseCardCarousel';

type IContainerProps = Pick<
  IHouseCardCarouselProps,
  'noArrowLeft' | 'defaultArrowPositionStyle'
>;

export const Container = styled.div<IContainerProps>`
  .slick-arrow {
    background: rgba(0, 0, 0, 0.1);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    box-shadow: 4px 8px 32px rgba(0, 0, 0, 0.1);
  }

  .slick-prev,
  .slick-next {
    top: ${props => (props.defaultArrowPositionStyle ? '' : '4rem')} !important;
    z-index: 1;
  }

  .slick-prev {
    left: ${props => (props.defaultArrowPositionStyle ? '' : '0px')} !important;

    &::before {
      content: url(${arrow_left});
      position: absolute;
      top: 29%;
      left: 29%;
    }
  }

  .slick-next {
    right: ${props =>
      props.defaultArrowPositionStyle ? '' : '0px'} !important;

    &::before {
      content: url(${arrow_right});
      position: absolute;
      top: 29%;
      left: 29%;
    }
  }

  .slick-disabled {
    cursor: not-allowed;
  }

  .slick-dots {
    bottom: -2.25rem;
  }

  .slick-dots li button:before {
    font-size: 0.55rem;
    color: var(--blue-500);
  }

  .slick-dots li.slick-active button:before {
    color: var(--blue-500);
  }

  ${props =>
    props.noArrowLeft &&
    css`
      .slick-prev {
        display: none !important;
      }
    `}

  @media (max-width: 884px) {
    .slick-list {
      padding: 0 !important;
    }

    .slick-arrow {
      box-shadow: 4px 16px 32px rgba(0, 0, 0, 0.1);
    }

    .slick-prev {
      right: ${props =>
        props.defaultArrowPositionStyle ? '' : '0px'} !important;
    }

    .slick-next {
      right: ${props =>
        props.defaultArrowPositionStyle ? '' : '0px'} !important;
    }
  }

  @media (max-width: 480px) {
    .slick-prev {
      right: ${props =>
        props.defaultArrowPositionStyle ? '' : '0px'} !important;
    }

    .slick-next {
      right: ${props =>
        props.defaultArrowPositionStyle ? '' : '0'} !important;
    }
  }
`;
