import styled from 'styled-components';

export const IconWrapper = styled.div`
  height: 100%;
  width: 20%;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  border: 2px solid var(--primary-neutral-300);

  svg,
  path {
    width: 20px;
    height: 20px;
  }
`;
