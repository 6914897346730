import house from 'common/assets/images/house_mock.png';
import { HouseCard } from 'components/Molecules';
import { IHouseCardProps } from 'components/Molecules/HouseCard/IHouseCard';
import { Carousel } from 'components/Organisms';
import { v4 as uuid } from 'uuid';

import * as S from './styles';

const housesMock: IHouseCardProps[] = [
  {
    id: 12,
    name: 'Casa 1',
    value: 300000,
    infos: {
      bedrooms: 2,
      bathrooms: 2,
      houseArea: 97,
      totalArea: 150,
      metragem: 'm²',
      slug: '',
      code: '',
    },
    location: {
      latitude: -16.7307346,
      longitude: -49.3458032,
      name: 'Moinho dos Ventos - Goiânia, GO',
    },
    image: house,
  },
  {
    id: 12,
    name: 'Casa 2',
    value: 300000,
    infos: {
      bedrooms: 2,
      bathrooms: 2,
      houseArea: 97,
      totalArea: 150,
      metragem: 'm²',
      slug: '',
      code: '',
    },
    location: {
      latitude: -16.7307346,
      longitude: -49.3458032,
      name: 'Moinho dos Ventos - Goiânia, GO',
    },
    image: house,
  },
  {
    id: 12,
    name: 'Casa 3',
    value: 300000,
    infos: {
      bedrooms: 2,
      bathrooms: 2,
      houseArea: 97,
      totalArea: 150,
      metragem: 'm²',
      slug: '',
      code: '',
    },
    location: {
      latitude: -16.7307346,
      longitude: -49.3458032,
      name: 'Moinho dos Ventos - Goiânia, GO',
    },
    image: house,
  },
  {
    id: 12,
    name: 'Casa 4',
    value: 300000,
    infos: {
      bedrooms: 2,
      bathrooms: 2,
      houseArea: 97,
      totalArea: 150,
      metragem: 'm²',
      slug: '',
      code: '',
    },
    location: {
      latitude: -16.7307346,
      longitude: -49.3458032,
      name: 'Moinho dos Ventos - Goiânia, GO',
    },
    image: house,
  },
  {
    id: 12,
    name: 'Casa 5',
    value: 300000,
    infos: {
      bedrooms: 2,
      bathrooms: 2,
      houseArea: 97,
      totalArea: 150,
      metragem: 'm²',
      slug: '',
      code: '',
    },
    location: {
      latitude: -16.7307346,
      longitude: -49.3458032,
      name: 'Moinho dos Ventos - Goiânia, GO',
    },
    image: house,
  },
  {
    id: 12,
    name: 'Casa 6',
    value: 300000,
    infos: {
      bedrooms: 2,
      bathrooms: 2,
      houseArea: 97,
      totalArea: 150,
      metragem: 'm²',
      slug: '',
      code: '',
    },
    location: {
      latitude: -16.7307346,
      longitude: -49.3458032,
      name: 'Moinho dos Ventos - Goiânia, GO',
    },
    image: house,
  },
  {
    id: 12,
    name: 'Casa 7',
    value: 300000,
    infos: {
      bedrooms: 2,
      bathrooms: 2,
      houseArea: 97,
      totalArea: 15,
      metragem: 'm²',
      slug: '',
      code: '',
    },
    location: {
      latitude: -16.7307346,
      longitude: -49.3458032,
      name: 'Moinho dos Ventos - Goiânia, GO',
    },
    image: house,
  },
];

function PrivateHighlightCarousel() {
  return (
    <S.Container>
      <Carousel
        defaultCenterMode={false}
        noArrowLeft
        defaultArrowPositionStyle
        items={housesMock.map(house => (
          <HouseCard {...house} key={uuid()} />
        ))}
      />
    </S.Container>
  );
}

export { PrivateHighlightCarousel };
