import { useEffect, useState } from 'react';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { HouseCard } from 'components/Molecules';
import { Carousel, PropertiesMap } from 'components/Organisms';
import api from 'services/api';
import { v4 as uuid } from 'uuid';

import { useAuth } from '../../../hooks/auth';
import * as S from './styles';

function ProperyCarousel(props: any) {
  // Temporario pois não havia documentação do back-end
  const [carouselProperties, setCarouselProperties] = useState<any[]>([]);
  const { userData } = useAuth();
  const [catalogos, setCatalogos] = useState<any[]>([]);
  const [displayMode, setDisplayMode] = useState('LIST');

  useEffect(() => {
    api.get(`/properties/latlong/${props.idProperty}`).then(req => {
      if (req.data.imoveis) {
        setCarouselProperties(req.data.imoveis);
      }
    });
  }, []);

  useEffect(() => {
    if (userData.id) {
      api.get(`/catalogo?user_register=true`).then(({ data }) => {
        const resp = data.data;
        if (resp) {
          const list = resp.map(function (dt: { id: any; titulo: any }) {
            return { value: dt.id, label: dt.titulo };
          });
          setCatalogos(list);
        }
      });
    }
  }, [userData]);

  return (
    <>
      {carouselProperties.length > 0 && (
        <S.Container>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '30px',
            }}
          >
            <h3 style={{ margin: 0 }}>Imóveis próximos</h3>

            <ToggleButtonGroup
              value={displayMode}
              exclusive
              onChange={(_, v) =>
                setDisplayMode &&
                setDisplayMode(displayMode === 'MAP' ? 'LIST' : 'MAP')
              }
              color="primary"
              style={{ height: '40px' }}
            >
              <ToggleButton
                value="MAP"
                aria-label="Ver como mapa"
                name="toggle-map-btn"
                style={{ width: '100%' }}
              >
                Mapa
              </ToggleButton>
              <ToggleButton
                value="LIST"
                aria-label="Ver como lista"
                name="toggle-list-btn"
                style={{ width: '100%' }}
              >
                Lista
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          {displayMode === 'LIST' ? (
            <Carousel
              noDots
              classItemCaroucel={'itemCarousel'}
              slidesToShow={
                carouselProperties.length > 4 ? 4 : carouselProperties.length
              }
              customSettings={{
                dots: false,
                autoplay: false,
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 4,
                arrows: true,
                responsive: [
                  {
                    breakpoint: 1600,
                    settings: {
                      slidesToShow: 4,
                      slidesToScroll: 4,
                    },
                  },
                  {
                    breakpoint: 1200,
                    settings: {
                      slidesToShow: 2.2,
                      slidesToScroll: 2,
                    },
                  },
                  {
                    breakpoint: 520,
                    settings: {
                      dots: true,
                      // arrows: false,
                      slidesToShow: 1.3,
                      slidesToScroll: 1,
                    },
                  },
                ],
              }}
              items={carouselProperties.map(data => (
                <S.CardContainer key={uuid()}>
                  <HouseCard
                    tarja={data.tarja}
                    id={data.id}
                    name={data.name}
                    value={
                      data?.promotional_amount
                        ? data.promotional_amount
                        : data.amount
                    }
                    favoriteId={
                      data?.favorites_data?.length
                        ? data.favorites_data[0].id
                        : undefined
                    }
                    infos={{
                      bathrooms: data.bathrooms,
                      bedrooms: data.bedrooms,
                      houseArea: data.contruct_area,
                      totalArea: data.land_area,
                      metragem: data.type_footage,
                      slug: data.slug,
                      code: data.code,
                    }}
                    location={{
                      latitude: Number(data.latitude),
                      longitude: Number(data.longitude),
                      name: `${data.district} - ${data.city?.description}`,
                    }}
                    withLoggedActions={!!userData.id}
                    images={data.images.map(
                      (img: any) => /*img.h_115 ||*/ img.image,
                    )}
                    property={data}
                    catalogos={catalogos}
                  />
                </S.CardContainer>
              ))}
            />
          ) : (
            <div
              style={{
                width: '100%',
                height: '1000px',
                maxHeight: 'calc(100vh - 200px)',
              }}
            >
              <PropertiesMap
                markers={carouselProperties.map(data => ({
                  lat: data.latitude,
                  lng: data.longitude,
                  alt: data.name,
                  property_id: data.id,
                  property_name: data.name,
                  property_price: data?.promotional_amount
                    ? data.promotional_amount
                    : data.amount,
                  property_bathrooms: data.bathrooms,
                  property_bedrooms: data.bedrooms,
                  property_houseArea: data.contruct_area,
                  property_totalArea: data.land_area,
                  property_metragem: data.type_footage,
                  property_slug: data.slug,
                  favoriteId: data?.favorites_data?.length
                    ? data.favorites_data[0].id
                    : undefined,
                  code: data.code,
                  images: data.images,
                  // isSelected?: boolean,
                }))}
                initialPosition={{
                  lat: props.property.latitude,
                  lng: props.property.longitude,
                }}
              />
            </div>
          )}
        </S.Container>
      )}
    </>
  );
}

export { ProperyCarousel };
