/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useMediaQuery } from '@mui/material';
import { Property, PropertyImage } from '@types';
import ContentButton from 'components/Atoms/ContentButton';
import { Carousel } from 'components/Organisms';

import * as S from './style';

export type ImagesPreviewProps = {
  property?: Property;
  contentActions?: {
    onOpenImages: (options?: { image?: PropertyImage; index?: number }) => void;
    onOpenVideos: () => void;
  };
};

export default function ImagesPreview({
  property,
  contentActions,
}: ImagesPreviewProps) {
  const isMobile = useMediaQuery('(max-width: 884px)');

  return (
    <>
      <S.ImagesPreviewContainer>
        <div className="first-image-container">
          {property?.images?.length ? (
            <img
              onClick={() =>
                contentActions?.onOpenImages({
                  index: 0,
                  image: property.images![0],
                })
              }
              className="first-image"
              src={property.images[0].image}
              alt="primeira-imagem"
            />
          ) : (
            <div className="first-image" />
          )}
        </div>

        {!!property?.images?.length && property?.images?.length > 4 ? (
          <div className="other-image-container">
            {property?.images.slice(1, 5).map((image, index) => (
              <img
                onClick={() =>
                  contentActions?.onOpenImages({
                    image,
                    index: isMobile ? index : index + 1,
                  })
                }
                key={image.image}
                className="other-image"
                src={image.image}
                alt="segunda-imagem"
              />
            ))}

            <div className="actions-container">
              <ContentButton
                invertColors
                onClick={() => contentActions?.onOpenImages()}
              >
                Ver Fotos ({property.images.length})
              </ContentButton>
              {!!property?.videos?.length && (
                <ContentButton
                  invertColors
                  onClick={contentActions?.onOpenVideos}
                >
                  Ver Vídeos ({property?.videos?.length})
                </ContentButton>
              )}
            </div>
          </div>
        ) : (
          <div className="other-image-container">
            <div className="other-image" />
            <div className="other-image" />
            <div className="other-image" />
            <div className="other-image" />
          </div>
        )}
      </S.ImagesPreviewContainer>

      <S.ImagesPreviewContainerMobile>
        {!property ? (
          <div className="shimmer"></div>
        ) : (
          <Carousel
            noDots
            noArrowLeft
            noArrowRight
            customSettings={{
              slidesToShow: 1.05,
            }}
            items={
              property.images?.map((image, index) => (
                <div
                  key={image.id}
                  style={{
                    paddingRight: '1px',
                    paddingLeft: '1px',
                    height: '300px',
                  }}
                >
                  <img
                    onClick={() =>
                      contentActions?.onOpenImages({
                        image,
                        index: isMobile ? index : index + 1,
                      })
                    }
                    alt={image.description}
                    src={image.image}
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
              )) ?? []
            }
          />
        )}
      </S.ImagesPreviewContainerMobile>
    </>
  );
}
