import { IRealtyDetailsOptionProps } from './IRealtyDetailsOption';
import * as S from './styles';

function RealtyDetailsOption({
  icon,
  label,
  subLabel,
  width,
  selected,
  userCanEnterNumber,
  onChangeNumber,
  ...props
}: IRealtyDetailsOptionProps) {
  return (
    <S.Container width={width} type="button" selected={selected} {...props}>
      {icon}
      {userCanEnterNumber ? (
        <input
          type="number"
          // @ts-ignore
          onChange={e => onChangeNumber(e.target.value)}
        />
      ) : (
        <span className="principal-label">{label}</span>
      )}
      {subLabel && <span className="sub-label">{subLabel}</span>}
    </S.Container>
  );
}

export { RealtyDetailsOption };
