import whatsapp from 'common/assets/images/whatsapp.png';
import { darken } from 'polished';
import styled from 'styled-components';

export const Link = styled.a<{ name?: string }>``;

export const TalkWithUsButton = styled.button`
  display: flex;
  align-items: center;
  column-gap: 0.6rem;

  padding: 0.75rem;
  background: var(--white);
  border: none;
  border-radius: 6px;

  transition: all 0.25s ease-in-out;

  &:hover {
    background: ${darken(0.015, '#fff')};
  }

  > div {
    width: 25px;
    height: 25px;
    background-image: url(${whatsapp});
    background-repeat: no-repeat;
    background-position: center;
  }

  a {
    color: var(--black-400);
    font-size: 0.85rem;
    font-weight: 500;
    text-decoration: none;
  }

  @media (max-width: 884px) {
    margin: 1rem auto 0;
    padding: 0.5rem 2.25rem;
    box-shadow: 0 4px 5px -3px rgba(0, 0, 0, 0.25);
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 26.875rem;
  padding: 1.5rem 1.75rem;

  border-radius: 6px;
  border: 1px solid var(--blue-100);
  background: var(--alpha-blue-15);

  > div {
    width: 85%;
  }

  p {
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 1.15rem;
    color: var(--blue-100);
  }

  div span {
    font-size: 1rem;
    color: var(--grey-400);
  }

  @media (max-width: 884px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;

    p {
      margin-bottom: 0.25rem;
    }

    div span {
      font-weight: 500;
    }

    > div {
      width: 100%;
    }
  }
`;
