import { useNavigate } from 'react-router-dom';

import { routesNames } from 'common/utils/routesNames';

import { ILogoProps } from './ILogo';
import * as S from './styles';

function Logo({ small, className }: ILogoProps) {
  const navigate = useNavigate();

  return (
    <S.Container
      className={`${className} logo-prado`}
      small={small}
      onClick={() => navigate(routesNames.root)}
      role="button"
      aria-label="Página inicial"
      name="logo"
    />
  );
}

export { Logo };
