import styled from 'styled-components';

export const InstallmentContainer = styled.div`
  border-radius: 10px;
  background-image: url('/finan.jpg');
  background-position: right calc(-15px);
  background-repeat: no-repeat;
  background-size: 70%;

  > main {
    border-radius: 10px;
    padding: 20px;
    background: transparent linear-gradient(267deg, #0073b500 0%, #0073b5 70%)
      0% 0% no-repeat padding-box;

    @media (min-width: 1024px) {
      padding: 50px 44px;
    }
  }

  .fgts-message {
    display: flex;
    align-items: center;
    gap: 15px;
    color: white;
    font-size: 1rem;
    margin-top: 20px;
  }
`;
