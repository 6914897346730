import { LoginSlider } from 'components/Organisms';
import { ICommonProps } from 'models/ICommonProps';

import * as S from './styles';

function LoginTemplate({ children }: ICommonProps) {
  return (
    <S.Container>
      <S.SlideContainer>
        <LoginSlider />
      </S.SlideContainer>

      <S.MainContainer>{children}</S.MainContainer>
    </S.Container>
  );
}

export { LoginTemplate };
