import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';
import { useAuth } from 'hooks/auth';

import { IMenuButtonProps } from './IMenuButton';
function MenuButton({
  label,
  icon,
  isLogout,
  path,
  fillPathColor,
}: IMenuButtonProps): JSX.Element {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const enabled = pathname === path;

  const getClickFunction = useCallback(() => {
    if (path === 'logout') return logout();

    return navigate(path);
  }, [path, logout]);

  return (
    <Button
      type="button"
      sx={{
        display: 'grid',
        gridTemplateColumns: '25% 1fr',
        textAlign: 'left',
        width: '100%',
        padding: '10px',
        borderRadius: '8px',
        background: enabled ? '#0073B515' : 'transparent',
        fontSize: '14px',
        fontWeight: '600',
        color: isLogout
          ? 'var(--blue-500)'
          : `${enabled ? '#0073B5' : '#848FAC'}`,
        textTransform: 'capitalize',
        svg: {
          width: '24px',
          height: '24px',
          fill: enabled ? '#0073B5' : '#848FAC',
          path: {
            fill: fillPathColor
              ? enabled
                ? '#0073B5 !important'
                : '#848FAC !important'
              : null,
          },
        },
      }}
      onClick={getClickFunction}
    >
      {icon}
      {label}
    </Button>
  );
}

export { MenuButton };
