import { useEffect, useState } from 'react';

import {
  Autocomplete,
  Box,
  FormControl,
  Paper,
  TextField,
} from '@mui/material';
import { IDropdownOption } from 'models/IDropdownOption';
import { apiSearch } from 'services/api';

import * as S from './styles';

interface IAsyncAutoCompleteProps {
  icon?: JSX.Element;
  width?: string;
  defaultValue?: any;
  // options: IDropdownOption[];
  onSelectOption: (option: IDropdownOption) => void;
  fetcher: (q?: string | undefined) => Promise<
    | false
    | {
        label: string;
        value: string;
      }[]
  >;
}

function AsyncAutoComplete({
  fetcher,
  defaultValue,
  onSelectOption,
}: IAsyncAutoCompleteProps) {
  const [q, setQ] = useState('');
  const [options, setOption] = useState<any>([]);

  async function fetch() {
    const response = await fetcher(q);
    if (!response) return;
    setOption(response);
  }

  useEffect(() => {
    if (q.length < 2) {
      return;
    }

    fetch();
  }, [q]);

  return (
    <S.Container>
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        maxWidth="100%"
        height="100%"
        maxHeight="38px"
        columnGap="10px"
        fontFamily={'Urbanist'}
      >
        <FormControl
          size="small"
          sx={{ width: '100%', fontFamily: 'Urbanist' }}
        >
          <Autocomplete
            options={options}
            disableClearable
            noOptionsText="Informe ao menos 2 caracteres"
            defaultValue={defaultValue}
            onChange={(e: any, v: any) => {
              onSelectOption(v);
            }}
            sx={{
              "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
                {
                  backgroundColor: '#4396e6',
                },
              "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'].Mui-focused":
                {
                  backgroundColor: '#3878b4',
                },
            }}
            PaperComponent={({ children }) => {
              return <Paper>{children}</Paper>;
            }}
            getOptionLabel={option => option.label}
            // renderOption={(_, option) => option.label}
            renderInput={params => (
              <TextField
                {...params}
                onChange={({ target }: any) => setQ(target.value)}
              />
            )}
          />
        </FormControl>
      </Box>
    </S.Container>
  );
}

export default AsyncAutoComplete;
