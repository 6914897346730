import styled, { css } from 'styled-components';

interface IStepProps {
  enabled?: boolean;
}

export const StepSlider = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const Step = styled.div<IStepProps>`
  width: 10px;
  height: 10px;

  border-radius: 50%;
  background: var(--brand-200);

  transition: 0.75s all;

  ${props =>
    props.enabled &&
    css`
      width: 48px;
      border-radius: 4px;
      background: var(--blue-400);
    `}
`;
