/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

interface IGridContainerProps {
  remaining_images?: number;
}

export const GridContainer = styled.div<IGridContainerProps>`
  display: grid;
  min-width: 100%;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);

  gap: 24px;

  div:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  div:nth-child(2) {
    grid-column-start: 4;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  div:nth-child(3) {
    grid-column-start: 4;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 3;

    ${props => props.remaining_images !== undefined && css`
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;

        width: 100%;
        height: 100%;

        border-radius: 8px;
        background: rgba(0, 0, 0, .5);
        z-index: 1;
      }
      
      &::after {
        content: '+ ${props.remaining_images}';
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;

        color: #fff;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.4;
        z-index: 2;
      }
    `}
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, 1fr);

    gap: 12px;

    div:nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 7;
      grid-row-start: 1;
      grid-row-end: 4;
    }

    div:nth-child(2) {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 4;
      grid-row-end: 6;
    }

    div:nth-child(3) {
      grid-column-start: 4;
      grid-column-end: 7;
      grid-row-start: 4;
      grid-row-end: 6;
    }
  }
`;

export const GalleryItem = styled.div`
  position: relative;

  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
`;
