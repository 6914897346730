import styled from 'styled-components';

export type ButtonParams = {
  responsive?: boolean;
  active?: boolean;
};

export const Button = styled.button<ButtonParams>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 6px;
  padding: 8px 12px;
  border: none;
  background-color: ${({ active }) =>
    active ? 'var(--blue-400)' : 'var(--grey-50)'};
  transition: 0.2s;
  flex: 1;

  &:hover {
    background-color: ${({ active }) =>
      active ? 'var(--blue-400)' : ' var(--grey-200)'};
  }

  &:active {
    background-color: ${({ active }) =>
      active ? 'var(--blue-400)' : 'var(--grey-50)'};
  }

  .icon-container {
    color: ${({ active }) => (active ? 'white' : 'var(--blue-400)')};
    padding-top: 3px;
  }

  > span {
    display: ${({ responsive }) => (responsive ? 'none' : 'flex')};
    color: ${({ active }) => (active ? 'white' : 'auto')};

    @media (min-width: 1024px) {
      display: block;
    }
  }
`;
