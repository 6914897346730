import logo from 'common/assets/images/logo.png';
import styled from 'styled-components';

import { ILogoProps } from './ILogo';
import { env } from '../../../configs';

export const Container = styled.div<ILogoProps>`
  width: ${props => (props.small ? '7.875rem' : '11.375rem')};
  height: ${props => (props.small ? '2.375rem' : '3.375rem')};

  background-image: url(${env.appEnv === 'homolog'
    ? 'https://workingtech.com.br/wp-content/uploads/2022/03/workingtech_logo_567x100.png'
    : logo});
  background-repeat: no-repeat;
  background-size: contain;

  cursor: pointer;

  @media (max-width: 884px) {
    width: 8.25rem;
    height: 2.2rem;
  }
`;
