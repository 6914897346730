import { useEffect, useState, ChangeEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Pagination,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { USER_TYPE } from '@types';
import { cleanObj } from 'common/utils/cleanObj';
import { routesNames } from 'common/utils/routesNames';
import { Button, DefaultPageTitle } from 'components/Atoms';
import { Filter, HouseCard, SearchFilter } from 'components/Molecules';
import { useAuth } from 'hooks/auth';
import { useSearch } from 'hooks/search';
import api from 'services/api';

import * as S from './styles';

function List() {
  const [pagesTotal, setPagesTotal] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [properties, setProperties] = useState<any[]>([]);
  const [catalogos, setCatalogos] = useState<any[]>([]);
  const [trigger, setTrigger] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { userData, logoutLocal, is } = useAuth();
  const {
    filter,
    changeCitiesMode,
    keys,
    addressQ,
    proprietaryQ,
    temporarySelectedDistrictsToString,
  } = useSearch();

  useEffect(() => {
    changeCitiesMode('default');
  }, []);

  useEffect(() => {
    if (userData.id) {
      api
        .get(`/catalogo`)
        .then(({ data }) => {
          const resp = data.data;
          if (resp) {
            const list = resp.map(function (dt: { id: any; titulo: any }) {
              return { value: dt.id, label: dt.titulo };
            });
            setCatalogos(list);
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            logoutLocal();
          }
        });
    }
  }, [userData]);

  useEffect(() => {
    setLoading(true);
    const params = {
      city_id: filter?.city?.value,
      price_min: filter?.min_value,
      price_max: filter?.max_value,
      type_property: filter?.type === 'Galpão' ? 'Galpões' : filter?.type,
      bedrooms: filter?.bedrooms,
      district: temporarySelectedDistrictsToString(),
      code: filter?.code,
      evaluation: filter?.evaluation,
      disabled: filter?.disabled,
      tarja_id: filter?.tarja_id,
      furnished_property: filter?.furnished_property,
      gated_community: filter?.gated_community,
      address: filter?.address,
      proprietary: filter?.proprietary,
      corretor: filter?.corretor,
      phone_proprietary: filter?.phone_proprietary,
      contruct_area: filter?.contruct_area,
      land_area: filter?.land_area,
      preco_max_mq: filter?.preco_max_mq,
      preco_min_mq: filter?.preco_min_mq,
      bathrooms: filter?.bathrooms,
      garage: filter?.garage,
      suites: filter?.suites,
      user_capture: true,
      street: addressQ?.value,
      proprietario: proprietaryQ?.value,
    };

    api
      .get(
        `/propertiesAuth?page=${currentPage}&limit=12&coluna=id&order=desc`,
        {
          params: { ...cleanObj(params) },
        },
      )
      .then(req => {
        const { data } = req;
        setPagesTotal(data.imoveis.last_page);
        setProperties([...data.imoveis.data]);
      })
      .catch(error => {
        if (error.response.status === 401) {
          logoutLocal();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userData, trigger, currentPage, filter]);

  const handleChangePage = (_: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const getComunicatorId = useCallback((property: any): number | undefined => {
    if (property.user_indisponivel !== null) return property.user_indisponivel;
    else if (property.user_locador !== null) return property.user_locador;
    else if (property.user_vendedor !== null) return property.user_vendedor;

    return undefined;
  }, []);

  return (
    <Box
      width="100%"
      height="fit-content"
      padding="24px 26px"
      border="1px solid var(--primary-neutral-300)"
      borderRadius="16px"
      sx={{ background: 'var(--white)' }}
    >
      <S.HeaderContainer>
        <DefaultPageTitle>Imóveis Cadastrados</DefaultPageTitle>
        <div className="actions-container">
          <Button
            color="alpha-blue"
            onClick={() => navigate(routesNames.favorites)}
          >
            Imóveis favoritos
          </Button>
          {(is(USER_TYPE.gestor) || is(USER_TYPE.secretaria)) && (
            <Button
              color="high_blue"
              onClick={() => navigate(routesNames.register)}
            >
              Novo imóvel
            </Button>
          )}
        </div>
      </S.HeaderContainer>
      <br />
      <S.HeaderContainer>
        <div></div>
        <div className="filters-container">
          <Filter key={keys.general} />
        </div>
      </S.HeaderContainer>
      <Box marginTop="24px">
        <SearchFilter key={keys.general} />
      </Box>

      {properties.length === 0 ? (
        <Box marginTop="24px" borderTop="1px solid var(--alpha-blue-15)">
          <Typography
            fontWeight="600"
            fontSize="22px"
            textAlign="center"
            paddingTop="22px"
            color="var(--primary-neutral-900)"
          >
            Sem imóveis para o filtro aplicado
          </Typography>
        </Box>
      ) : (
        <>
          {loading ? (
            <Box marginTop="24px" borderTop="1px solid var(--alpha-blue-15)">
              <Typography
                fontWeight="600"
                fontSize="22px"
                textAlign="center"
                paddingTop="22px"
                color="var(--primary-neutral-900)"
              >
                Carregando
              </Typography>
            </Box>
          ) : (
            <>
              <S.CardsContainer>
                {properties.map(data => (
                  <HouseCard
                    tarja={data.tarja}
                    key={data.id}
                    id={data.id}
                    name={data.name}
                    value={
                      data?.promotional_amount
                        ? data.promotional_amount
                        : data.amount
                    }
                    disabled={data.disabled}
                    favoriteId={
                      data?.favorites_data?.length
                        ? data.favorites_data[0].id
                        : undefined
                    }
                    infos={{
                      bathrooms: data.bathrooms,
                      bedrooms: data.bedrooms,
                      houseArea: data.contruct_area,
                      totalArea: data.land_area,
                      metragem: data.type_footage,
                      slug: data.slug ?? '',
                      code: data.code ?? '',
                    }}
                    location={{
                      latitude: Number(data.latitude),
                      longitude: Number(data.longitude),
                      name: `${data.district} - ${data.city?.description}`,
                    }}
                    images={data.images.map(
                      (img: any) => /*img.h_115 ||*/ img.image,
                    )}
                    status={data.status || ''}
                    withLoggedActions
                    onChangeStatus={() => setTrigger(!trigger)}
                    comunicator_id={getComunicatorId(data)}
                    property={data}
                    catalogos={catalogos}
                  />
                ))}
              </S.CardsContainer>
              {!!pagesTotal && pagesTotal > 1 && (
                <Box display="flex" justifyContent="center" marginTop="24px">
                  <Stack spacing={2}>
                    <Pagination
                      count={pagesTotal}
                      page={currentPage}
                      onChange={handleChangePage}
                      variant="outlined"
                      color="primary"
                    />
                  </Stack>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
}

export { List };
export default List;
