import styled from 'styled-components';

export const CardsContainer = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: min-content;
  align-items: center;
  gap: 12px;
  justify-content: center;

  & > div {
    height: 100%;
  }

  @media (min-width: 670px) {
    grid-template-columns: min-content min-content;
  }

  @media (min-width: 885px) {
    grid-template-columns: min-content min-content;
  }

  @media (min-width: 1175px) {
    grid-template-columns: min-content min-content min-content;
  }

  @media (min-width: 1500px) {
    grid-template-columns: min-content min-content min-content min-content;
  }

  @media (min-width: 2000px) {
    grid-template-columns: min-content min-content min-content min-content min-content;
  }
`;
