import { ImSpinner } from 'react-icons/im';

import { IButtonProps } from './IButton';
import * as S from './styles';

function Button({
  color,
  children,
  icon,
  bolder,
  full_width,
  high_border,
  no_border,
  loading,
  name,
  ...rest
}: IButtonProps) {
  return (
    <S.Container
      {...rest}
      color={color}
      bolder={bolder}
      full_width={full_width}
      high_border={high_border}
      no_border={no_border}
      name={name}
    >
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // height: '245px',
          }}
        >
          <ImSpinner
            style={{
              width: '100%',
              margin: 'auto',
              display: 'block',
            }}
            className="loading-icon"
          />
        </div>
      ) : (
        <>
          {icon}
          {children}
        </>
      )}
    </S.Container>
  );
}

export { Button };
