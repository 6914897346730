export const STATUS_CODE = {
  UNAUTHORIZED: 401,
} as const;

export const USER_TYPE = {
  cliente: 1,
  secretaria: 2,
  corretor: 3,
  gestor: 4,
} as const;

export type PropertyImage = {
  id: number;
  property_id: number;
  image: string;
  description?: string;
  order: number;
  created_at: string; // ex: '2023-04-17T22:52:13.000000Z'
  updated_at: string; // ex: '2023-04-17T22:52:13.000000Z'
  img_min: boolean;
};

export type Property = {
  id: number;
  code: string;
  description: string;
  type_property: 'Lote';
  name: string;
  amount: number;
  promotional_amount?: number;
  comissao: number;
  contruct_area?: number;
  keys: string;
  address?: string;
  district?: string;
  city_id: number;
  branch_agency?: string;
  broker_info: string;
  evaluation: boolean;
  phone_proprietary: string;
  proprietary: string;
  lat_cliente: number;
  long_cliente: number;
  url: string;
  created_at: string; // '2023-04-17T22:52:11.000000Z'
  updated_at: string; // '2023-04-17T22:52:11.000000Z'
  key_number?: string;
  amount_evaluation?: number;
  latitude: number;
  longitude: number;
  videos?: {
    id: number;
    property_id: number;
    src: string;
    created_at: string;
    updated_at: string;
  }[];
  city?: {
    id: number;
    state_id: number;
    description: string;
    iso: number;
    iso_ddd: number;
    status: number;
    slug: string;
    population: number;
    lat: string;
    long: string;
    income_per_capita: string;
    state: {
      id: number;
      description: string;
      uf: string;
      iso: number;
      slug: string;
      population: number;
      region_id: number;
    };
  };
  /** Logged only */
  favoriteData?: {
    id: number;
    user_id: number;
    property_id: number;
  };
  usuarioDeCaptura?: {
    id: number;
    name: string;
    email: string;
    email_verified_at?: string;
    address: string;
    phone: string;
    photo: string;
    created_at: string;
    updated_at: string;
    type_id: number;
    city_id: number;
    status: true;
    occupation: null;
    deleted_at: null;
    cpf: string;
    date_birth: string;
    creci: string;
    bank: string;
    agency: string;
    account: string;
    operation: string;
    pix_key: string;
    qualification: string;
    branch_agency: string;
    department: string;
    instagram: string;
    facebook: string;
    waiting_creci: false;
    not_have_creci: false;
    cep: null;
    address_number: null;
    neighborhood: null;
    type_phone: null;
    limit_access: null;
    current_access_limit: null;
    access: null;
    city: {
      id: number;
      state_id: number;
      description: string;
      iso: number;
      iso_ddd: number;
      status: number;
      slug: string;
      population: number;
      lat: string;
      long: string;
      income_per_capita: string;
      state: {
        id: number;
        description: string;
        uf: string;
        iso: number;
        slug: string;
        population: number;
        region_id: number;
      };
    };
    type: {
      id: number;
      description: string;
      created_at: null;
      updated_at: null;
    };
  };
  images?: PropertyImage[];
  criativos?: {
    id: number;
    property_id: number;
    image: string;
    description?: string;
    order: number;
    created_at: string; // ex: '2023-04-17T22:52:13.000000Z'
    updated_at: string; // ex: '2023-04-17T22:52:13.000000Z'
    img_min: boolean;
  }[];
  user_register?: {
    id: number;
    name: string;
    type_id: number;
    city: null;
    type: {
      id: number;
      description: string;
      created_at?: string;
      updated_at?: string;
    };
  };
  type_footage: 'm²' | 'hectares';
  land_area: number;
  bedrooms: number;
  bathrooms: number;
  suites: number;
  hall: number;
  garage: number;
  living_room: number;
  service_area: number;
  // status: 'disponivel';
  // slug: 'lote-a-venda-jardim-ipe-aparecida-de-goiania-goias';
  // deleted_at: null;
  // user_capture: 5;
  // user_vendedor: null;
  // user_locador: null;
  // district_id: null;
  // user_indisponivel: null;
  // accept_financing: false;
  // cash_payment_only: true;
  // settled: false;
  // installments: false;
  // Agio: false;
  // gated_community: false;
  // kitchen: false;
  // pantry: false;
  // closet: false;
  // desk: false;
  // lighting_project: false;
  // winter_garden: false;
  // special_paintings: false;
  // custom_cabinets: false;
  // furnished_property: false;
  // artesian_well: false;
  // solar_energy: false;
  // balcony: false;
  // elevator: false;
  // Incoming_outgoing_cargo: false;
  // parking: false;
  // ramp: false;
  // refectory: false;
  // deposit: false;
  // mezzanine: false;
  // reception: false;
  // gourmet_area: false;
  // hot_tub: false;
  // party_room: false;
  // games_room: false;
  // gym: false;
  // sauna: false;
  // fitness_space: false;
  // toy_library: false;
  // football_field: false;
  // tennis_court: false;
  // sand_court: false;
  // sports_court: false;
  // movie_theater: false;
  // pilates_space: false;
  // bike_rack: false;
  // laundry: false;
  // playground: false;
  // coexistence_square: false;
  // massage_space: false;
  // spa: false;
  // social_hall: false;
  // meeting_room: false;
  // coworking: false;
  // lava_jato: false;
  // electric_fence: false;
  // electronic_gate: false;
  // alarm: false;
  // eletronic_lock: false;
  // '24_hour_concierge': false;
  // '24_hour_monitoring': false;
  // surrounded: false;
  // front_footage: null;
  // background_footage: null;
  // left_side_footage: null;
  // right_side_footage: null;
  // full_walled: false;
  // walled_the_back: false;
  // walled_in_front: false;
  // right_side_wall: false;
  // drained_to_the_bottom: false;
  // drained_forward: false;
  // plan: true;
  // water: false;
  // energy: false;
  // sewage: false;
  // planned_neighborhood: false;
  // permanent_preservation_area: false;
  // allotted: false;
  // brook: false;
  // river: false;
  // dam: false;
  // lake: false;
  // head_office: false;
  // caretakers_house: false;
  // machinery_shed: false;
  // storage_shed: false;
  // machinery: false;
  // fruit_trees: false;
  // vegetable_garden: null;
  // corral: false;
  // sty: false;
  // stalls: false;
  // water_source: false;
  // formed_pasture: false;
  // livestock: false;
  // planting_area: false;
  // cattle_raising: false;
  // pisciculture: false;
  // agriculture: false;
  // silviculture: false;
  // deposit_rural: false;
  // orchard: false;
  // woods: false;
  // vegetation: false;
  // virgin_forest: false;
  // purchase_sale_contract: false;
  // lottery_contract: false;
  // right_of_ownership: false;
  // recorded: false;
  // registered: false;
  // use_soil: false;
  // architectural_project: false;
  // hydraulic_design: false;
  // electrical_project: false;
  // structural_design: false;
  // basic_sanitation_AVTO: false;
  // electricity_AVTO: false;
  // inventory: false;
  // financing_ready_documents: false;
  // pigeonhole: false;
  // automation: false;
  // disabled_access: false;
  // pet_place: false;
  // concertina: false;
  // cameras: false;
  // left_side_wall: false;
  // hen_house: false;
  // pool: false;
  // grill: false;
  // tv_room: false;
  // dining_room: false;
  // island_bench: false;
  // plaster_finishes: false;
  // club_structure: false;
  // ofuro: false;
  // mart: false;
  // green_areas: false;
  // presence_sensors: false;
  // project_approved: false;
  // accept_exchange: true;
  // payment_in_installments: false;
  // portion_part_entrance: true;
  // cep: '74964-694';
  // street: 'Rua JI 44, Quadra  240, Lote 23';
  // number: null;
  // uf: 'GO';
  // type_phone_owner: 'pt-BR';
  // documents_to_regularize: false;
  // informacoes_internas: null;
  // double_right_foot: false;
  // toilet: false;
  // copa: false;
  // roof: false;
  // yard: false;
  // wine_house: false;
  // fireplace: false;
  // hiking_trail: false;
  // bike_lane: false;
  // kiosk: false;
  // intercom: false;
  // requires_transferring_funding: false;
  // disabled: false;
  // user_include: 1;
  // proprietario_id: null;
  // meta_description: null;
  // uniq_key: 'ruaji44quadra240lote23jardimip53369lote3605562986189587';
  // tarja_id: null;
  // atualization: false;
  // featured_property: false;

  // imageMin: {
  //   id: 10579;
  //   property_id: 791;
  //   image: 'https://storage.googleapis.com/pradoimoveis/images/properies/791/MIN_CIBVsACP6leeduk4gODCHdC0CzBVWJ91na87DyTT.jpg';
  //   description: null;
  //   order: 0;
  //   created_at: '2023-04-17T22:52:13.000000Z';
  //   updated_at: '2023-04-17T22:52:13.000000Z';
  //   img_min: true;
  // };
  //
  // usuarioDeVenda: null;
  // usuarioDeLocacao: null;
  // usuarioInformouIndisponibilidade: null;
  //
  // user_includes: {
  //   id: 1;
  //   name: 'Gestor';
  //   type_id: 4;
  //   city: null;
  //   type: {
  //     id: 4;
  //     description: 'Gestor';
  //     created_at: null;
  //     updated_at: null;
  //   };
  // };
  // tarja: null;
};
