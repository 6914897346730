import api from 'services/api';

import { Response } from './BaseService';

export default class C2SService {
  async createLead(body: {
    name: string;
    phone: string;
    email: string;
    body?: string;
  }): Promise<
    Response<{
      success: true;
      lead_id: string;
      received_by: {
        id: string;
        name: string;
        email: string;
        phone: string;
      };
      company: string;
      info: {
        ramal: string;
        source: string;
        name: string;
        phone: string;
        email: string;
        city: string;
        neighbourhood: string;
        prop_ref: string;
        type_negotiation: string;
        brand: string;
        model: string;
        description: string;
        price: string;
        url: string;
        body: string;
      };
    }>
  > {
    try {
      const data = await api.post('/c2s/leads', body);
      return {
        isError: false,
        data: data.data.data,
        code: data.status ?? 200,
      };
    } catch (err) {
      return {
        isError: true,
        data: (err as any)?.response?.data,
        code: (err as any)?.response?.status ?? 500,
      };
    }
  }
}
