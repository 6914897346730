/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';

import { ICity } from 'models/ICity';
import { IDropdownOption } from 'models/IDropdownOption';
import api from 'services/api';

interface IFilter {
  city?: IDropdownOption;
  district?: string;
  type?: string;
  bedrooms?: number;
  min_value?: number;
  max_value?: number;
  code?: string;
  evaluation?: boolean;
  furnished_property?: boolean;
  gated_community?: boolean;
  address?: string;
  proprietary?: string;
  phone_proprietary?: string;
  corretor?: number;
  tarja_id?: number;
  disabled?: string;
  contruct_area?: number | string;
  land_area?: number | string;
  preco_max_mq?: number | string;
  preco_min_mq?: number | string;
  bathrooms?: number | string;
  suites?: number | string;
  garage?: number | string;
}

type IUpdateFilter = Omit<IFilter, 'city'>;

interface ISearchContextData {
  keys: { cidade: number; bairro: number; general: number };
  allKey: number;
  setAllKey: React.Dispatch<number>;
  setKeys: (keys: { cidade: number; bairro: number; general: number }) => void;
  filter?: IFilter;
  cities: IDropdownOption[];
  districts: IDropdownOption[];
  selectedCity?: IDropdownOption;
  resetFilters: () => void;
  setSelectedCity: (value: IDropdownOption) => void;
  selectedDistrict?: string;
  setSelectedDistrict: (value: string) => void;
  setSelectedDistrictSeveral?: any;
  selectedDistrictSeveral?: any;
  updateFilter(data: IUpdateFilter): void;
  changeCitiesMode(value: 'auth' | 'default'): void;
  addressQ?: { label: string; value: string };
  setAddressQ: React.Dispatch<null | { label: string; value: string }>;
  proprietaryQ?: { label: string; value: string };
  setProprietaryQ: React.Dispatch<null | { label: string; value: string }>;
  temporarySelectedDistricts: { label: string; value: string }[];
  setTemporarySelectedDistricts: React.Dispatch<
    { label: string; value: string }[]
  >;
  temporarySelectedDistrictsToString: () => string;
}

const SearchContext = createContext<ISearchContextData>(
  {} as ISearchContextData,
);

const rawQueryParams = new URLSearchParams(window.location.search);
const queryParams: IFilter = {
  bedrooms: rawQueryParams.get('quartos')
    ? Number(rawQueryParams.get('quartos'))
    : undefined,
  contruct_area: rawQueryParams.get('metragem-imovel')
    ? Number(rawQueryParams.get('metragem-imovel'))
    : undefined,
  bathrooms: rawQueryParams.get('banheiros')
    ? Number(rawQueryParams.get('banheiros'))
    : undefined,
  suites: rawQueryParams.get('suites')
    ? Number(rawQueryParams.get('suites'))
    : undefined,
  garage: rawQueryParams.get('garagem')
    ? Number(rawQueryParams.get('garagem'))
    : undefined,
  land_area: rawQueryParams.get('metragem-terreno')
    ? Number(rawQueryParams.get('metragem-terreno'))
    : undefined,
  preco_max_mq: rawQueryParams.get('preco-maximo-m')
    ? Number(rawQueryParams.get('preco-maximo-m'))
    : undefined,
  preco_min_mq: rawQueryParams.get('preco-min-m')
    ? Number(rawQueryParams.get('preco-min-m'))
    : undefined,
  type: rawQueryParams.get('tipo') ?? undefined,
  district: rawQueryParams.get('bairro') ?? undefined,
  min_value: rawQueryParams.get('preco-minimo')
    ? Number(rawQueryParams.get('preco-minimo'))
    : undefined,
  max_value: rawQueryParams.get('preco-maximo')
    ? Number(rawQueryParams.get('preco-maximo'))
    : undefined,
  code: rawQueryParams.get('codigo') ?? undefined,
  evaluation: rawQueryParams.get('apenas-avaliados') == 'true',
  furnished_property: rawQueryParams.get('apenas-mobiliados') == 'true',
  gated_community: rawQueryParams.get('condominio-fechado') == 'true',
  corretor: rawQueryParams.get('corretor')
    ? Number(rawQueryParams.get('corretor'))
    : undefined,
  tarja_id: rawQueryParams.get('tarja')
    ? Number(rawQueryParams.get('tarja'))
    : undefined,
  city: rawQueryParams.get('cidade')
    ? {
        label: rawQueryParams.get('cidade')?.split('..')[1]!,
        value: rawQueryParams.get('cidade')?.split('..')[0]!,
      }
    : undefined,
  // address?: string;
  // proprietary?: string;
  // phone_proprietary?: string;
  // disabled?: string;
};

const SearchProvider: React.FC = ({ children }) => {
  const [allKey, setAllKey] = useState<any>(1);
  const [addressQ, setAddressQ] = useState<any>(
    rawQueryParams.get('logradouro')
      ? {
          label: rawQueryParams.get('logradouro'),
          value: rawQueryParams.get('logradouro'),
        }
      : null,
  );
  const [proprietaryQ, setProprietaryQ] = useState<any>(
    rawQueryParams.get('proprietario')
      ? {
          label: rawQueryParams.get('proprietario')?.split('..')[1]!,
          value: rawQueryParams.get('proprietario')?.split('..')[0]!,
        }
      : undefined,
  );
  const [keys, setKeys] = useState({
    general: 1,
    cidade: 1,
    bairro: 1,
  });
  const [selectedCity, setSelectedCity] = useState<IDropdownOption>();
  const [selectedDistrict, setSelectedDistrict] = useState<string>();
  const [selectedDistrictSeveral, setSelectedDistrictSeveral] = useState<any>();
  const [cities, setCities] = useState<IDropdownOption[]>([]);
  const [districts, setDistricts] = useState<IDropdownOption[]>([]);
  const [filter, setFilter] = useState<undefined | IFilter>(queryParams);
  const [citiesMode, setCitiesMode] = useState<'auth' | 'default'>('default');

  useEffect(() => {
    const storageData = localStorage.getItem('@user:prado_imoveis');

    if (citiesMode === 'auth' && storageData !== null) {
      api.get('/cities?properties_cities=true').then(req => {
        const { cities } = req.data;
        setCities([
          ...cities.map((city: ICity) => {
            return {
              label: city.state.uf + ' - ' + city.description,
              value: city.id,
            };
          }),
        ]);
      });
    } else if (citiesMode === 'default') {
      api.get('/cities?properties_cities=true').then(req => {
        const { cities } = req.data;
        setCities([
          ...cities.map((city: ICity) => {
            return {
              label: city.state.uf + ' - ' + city.description,
              value: city.id,
            };
          }),
        ]);
      });
    }
  }, [citiesMode]);

  useEffect(() => {
    if (!selectedCity?.value) return;
    api.get(`/bairros/${selectedCity.value}`).then(req => {
      const { bairros } = req.data;

      const dropdownValues = bairros.map((bairro: any) => {
        return { label: bairro.district, value: bairro.district };
      });
      dropdownValues.unshift({ label: 'Selecione vários', value: 'varios' });
      if (bairros.length === 0) setSelectedDistrict(undefined);

      setDistricts(dropdownValues);
    });
  }, [selectedCity]);

  useEffect(() => {
    const keysMap = {
      bedrooms: 'quartos',
      bathrooms: 'banheiros',
      suites: 'suites',
      garage: 'garagem',
      type: 'tipo',
      min_value: 'preco-minimo',
      max_value: 'preco-maximo',
      code: 'codigo',
      evaluation: 'apenas-avaliados',
      furnished_property: 'apenas-mobiliados',
      gated_community: 'condominio-fechado',
      corretor: 'corretor',
      tarja_id: 'tarja',
      district: 'bairro',
      logradouro: 'logradouro',
      proprietario: 'proprietario',
      city: 'cidade',
      contruct_area: 'metragem-imovel',
      land_area: 'metragem-terreno',
      preco_max_mq: 'preco-maximo-m',
      preco_min_mq: 'preco-minimo-m',
    };

    const qP = new URLSearchParams(window.location.search);

    Object.keys(keysMap).forEach(key => {
      qP.delete((keysMap as any)[key]);
    });

    history.replaceState(null, '', '?' + qP.toString());

    if (!filter) return;

    Object.keys(filter).forEach(key => {
      if (!(filter as any)[key]) return;

      if (key === 'city') {
        qP.set(
          'cidade',
          `${(filter as any)[key].value}..${(filter as any)[key].label}`,
        );
        return;
      }

      qP.set((keysMap as any)[key], (filter as any)[key]);
    });

    if (addressQ) {
      qP.set('logradouro', addressQ.value);
    }

    if (proprietaryQ) {
      qP.set('proprietario', `${proprietaryQ.value}..${proprietaryQ.label}`);
    }

    if (temporarySelectedDistricts.length) {
      qP.set('bairro', temporarySelectedDistrictsToString());
    }

    history.replaceState(null, '', '?' + qP.toString());
  }, [filter]);

  const updateFilter = useCallback(
    (data: any) => {
      setFilter({ ...data, city: selectedCity });
    },
    [selectedCity],
  );

  const resetFilters = () => {
    setFilter(undefined);
    setSelectedCity(undefined);
    setAddressQ(null);
    setProprietaryQ(null);
    setTemporarySelectedDistricts([]);
  };

  const changeCitiesMode = useCallback((value: 'auth' | 'default') => {
    setCitiesMode(value);
  }, []);

  // New
  const [temporarySelectedDistricts, setTemporarySelectedDistricts] = useState<
    { label: string; value: string }[]
  >([]);

  function temporarySelectedDistrictsToString() {
    return temporarySelectedDistricts.map(ts => ts.label).join('+');
  }

  useEffect(() => {
    if (!filter?.district?.length) {
      setTemporarySelectedDistricts([]);
    } else {
      setTemporarySelectedDistricts(
        filter.district.split(',').map(item => ({
          value: item.trim(),
          label: item.trim(),
        })),
      );
    }
  }, [filter?.district]);

  return (
    <SearchContext.Provider
      value={{
        keys,
        setKeys,
        filter,
        selectedCity,
        setSelectedCity,
        districts,
        selectedDistrict,
        setSelectedDistrict,
        setSelectedDistrictSeveral,
        selectedDistrictSeveral,
        resetFilters,
        cities,
        updateFilter,
        changeCitiesMode,
        addressQ,
        setAddressQ,
        proprietaryQ,
        setProprietaryQ,
        allKey,
        setAllKey,
        temporarySelectedDistricts,
        setTemporarySelectedDistricts,
        temporarySelectedDistrictsToString,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

function useSearch(): ISearchContextData {
  const context = useContext(SearchContext);

  if (!context) throw new Error('useSearch must be used with SearchProvider');

  return context;
}

export { SearchProvider, useSearch };
