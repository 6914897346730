import styled from 'styled-components';

interface IGalleryItem {
  image_source: string;
}

export const GridGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);

  gap: 0.15rem;

  height: 50vh;
  width: 100%;

  div:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 9;
  }

  div:nth-child(2) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 5;
  }

  div:nth-child(3) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 5;
  }

  div:nth-child(4) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 5;
    grid-row-end: 9;
  }

  div:nth-child(5) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 5;
    grid-row-end: 9;
  }

  @media (max-width: 884px) {
    height: 30vh;
  }

  @media (max-width: 480px) {
    height: 20vh;
  }
`;

export const GalleryItem = styled.div<IGalleryItem>`
  cursor: pointer;

  background-image: url(${props => props.image_source});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
