function cleanObj(obj: any, avoidFields: any[] = []): any {
  const newObj = Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => {
      if (avoidFields.includes(key as any)) return true;
      if (value !== null && value !== undefined && value !== '') return value;
    }),
  );

  return newObj;
}

export { cleanObj };
