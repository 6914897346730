import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import api from 'services/api';

import { Button, DefaultPageTitle } from '../../components/Atoms';
import { DataTable } from '../../components/Atoms/DataTable';
import { useAuth } from '../../hooks/auth';
function Owners() {
  const navigate = useNavigate();
  const [list, setList] = useState();
  const [pagesTotal, setPagesTotal] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { logoutLocal } = useAuth();
  useEffect(() => {
    api
      .get(`/proprietario?page=${currentPage}`)
      .then(({ data }) => {
        const response = data.data;
        setPagesTotal(response.last_page);
        setCurrentPage(response.current_page);
        setList(response.data);
      })
      .catch(error => {
        if (error.response.status === 401) {
          logoutLocal();
        }
      });
  }, [currentPage]);

  const columns = [
    {
      title: 'Id',
      field: 'id',
    },
    {
      title: 'Nome',
      field: 'nome',
    },
    {
      title: 'E-mail',
      field: 'email',
    },
    {
      title: 'Profissão',
      field: 'profissao',
    },
  ];

  const handleChangePage = (_: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  const edit = (data: any) => {
    navigate(`/proprietario/${data.id}`);
  };

  return (
    <Box
      width="100%"
      height="fit-content"
      padding="24px 26px"
      border="1px solid var(--primary-neutral-300)"
      borderRadius="16px"
      sx={{ background: 'var(--white)' }}
    >
      <Box
        display="flex"
        gap="20px"
        flexWrap="wrap"
        marginTop="20px"
        justifyContent="space-between"
      >
        <div>
          <DefaultPageTitle>Proprietários</DefaultPageTitle>
        </div>
        <div
          style={{
            justifyContent: 'flex-end',
            width: '46%',
            display: 'flex',
          }}
        >
          <Button
            color="high_blue"
            onClick={() => navigate(`/proprietario`)}
            type="button"
          >
            Novo proprietário
          </Button>
        </div>
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        marginTop="20px"
        justifyContent="center"
        alignItems={'center'}
        width={'100%'}
        flexDirection={'column'}
      >
        {list && (
          <DataTable
            width={'100%'}
            columns={columns}
            data={list}
            validIdUserRegister={true}
            onEdit={edit}
            pagesTotal={pagesTotal}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
          />
        )}
      </Box>
    </Box>
  );
}

export { Owners };
export default Owners;
