import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  padding-left: 24px;
  user-select: none;

  cursor: pointer;

  > input {
    position: absolute;
    opacity: 0;

    cursor: pointer;
  }

  > .checkmark {
    position: absolute;
    left: 0;

    height: 16px;
    width: 16px;

    background-color: var(--white);
    border: 1px solid var(--primary-neutral-900);
    border-radius: 50%;
  }

  > .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  > input:checked ~ .checkmark:after {
    display: block;
  }

  > .checkmark:after {
    top: 3px;
    left: 3px;

    width: 8px;
    height: 8px;

    border-radius: 50%;
    background: var(--primary-neutral-900);
  }
`;
