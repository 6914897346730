import { useState } from 'react';

export interface UseRootFormParams<T = any> {
  initialData: Partial<T>;
}

export default function useRootForm<T = any>(props?: UseRootFormParams<T>) {
  const [isLoading, setIsLoading] = useState(false);

  const [form, setForm] = useState(props?.initialData ?? ({} as Partial<T>));
  const [formErrors, setFormErrors] = useState<{ [key in keyof T]?: string }>(
    {},
  );

  function clearFormErrors() {
    setFormErrors({});
  }

  function setField(fieldName: keyof T, value: any) {
    setForm({ ...form, [fieldName]: value });
  }

  function registerField(fieldName: keyof T) {
    return {
      value: form[fieldName] as any,
      setValue: (val: any) => setField(fieldName, val) as any,
      error: formErrors ? formErrors[fieldName] ?? undefined : undefined,
    };
  }

  return {
    form,
    isLoading,
    setIsLoading,
    register: registerField,
    registerField,
    setForm,
    setField,
    formErrors,
    setFormErrors,
    clearFormErrors,
  };
}
