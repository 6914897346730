import styled from 'styled-components';

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  place-items: center;

  @media (min-width: 500px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const Container = styled.div`
  width: 100%;

  @media (max-width: 884px) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
`;

export const TopWrapper = styled.div`
  display: grid;
  row-gap: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 840px) and (max-width: 1200px) {
    padding-top: 34px;
  }

  > div:first-child {
    display: grid;
    gap: 10px;

    @media (min-width: 900px) {
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      width: 100%;
    }
  }
`;

export const MapContainer = styled.div<{ height: string }>`
  position: absolute;
  left: 0;

  width: 100%;
  height: ${props => props.height};
  min-height: 400px;
`;

export const CarouselContainer = styled.div`
  z-index: 9;

  width: 100%;

  @media (max-width: 884px) {
    bottom: 2.25rem;

    .slick-list {
      padding: 0 10px !important;
    }
  }

  @media (max-width: 480px) {
    .slick-next {
      right: 0.5rem;
    }
  }
`;
