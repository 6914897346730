import { lazy } from 'react';

import { ILatLng } from 'components/Organisms/Map/IMap';

const Map = lazy(() => import('components/Organisms/Map'));

export interface MapScreenProps {
  zoom: number;
  markedProperty?: number;
  latlngs: any;
  mapCenter: any;
  catalogos?: any;
}

export default function MapScreen(props: MapScreenProps) {
  return (
    <Map
      // fullMarkerPopup={false}
      zoom={props.zoom}
      currentPropertyId={props.markedProperty}
      markers={props.latlngs}
      centerPosition={props.mapCenter}
      markedProperty={props.markedProperty}
      catalogos={props.catalogos}
      onClickMarker={(value: ILatLng) => {
        // handleGetIndex(value.property_id);
      }}
    />
  );
}
