import { AiOutlineEye } from 'react-icons/ai';
import { CgTrash } from 'react-icons/cg';
import { HiOutlinePencilAlt } from 'react-icons/hi';

import { Box, Stack, Pagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useAuth } from '../../../hooks/auth';
import useInputMask from '../../../hooks/useInputMask';
import { IDataTable } from './IDataTable';
function DataTable({
  columns,
  data,
  width,
  onView,
  onEdit,
  onDelete,
  pagesTotal,
  currentPage,
  handleChangePage,
  validIdUserRegister,
}: IDataTable) {
  const { userData } = useAuth();
  const { convertDate } = useInputMask();
  return (
    <>
      <TableContainer style={{ width: width }} component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {columns &&
                columns.map((c: any, index: number) => {
                  return <TableCell key={index}>{c.title}</TableCell>;
                })}
              {(onView || onEdit || onDelete) && (
                <TableCell align="right">Opções</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((record: any, index: number) => {
                return (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {columns &&
                      columns.map((c: any, index: number) => {
                        if (c.render) {
                          return (
                            <TableCell key={index} scope="row">
                              {c.render(record)}
                            </TableCell>
                          );
                        }

                        const ret = c.field.split(':');
                        let fildOne = null;
                        let fildTwo = null;
                        let dataConsultaArray = record[c.field];
                        if (ret.length > 1) {
                          fildOne = ret[0];
                          fildTwo = ret[1];
                          dataConsultaArray = record[fildOne][fildTwo];
                        }

                        if (c.type === 'date') {
                          return (
                            <TableCell key={index} scope="row">
                              {convertDate(dataConsultaArray.split('T')[0])}
                            </TableCell>
                          );
                        }
                        if (c.type === 'dateHouer') {
                          return (
                            <TableCell key={index} scope="row">
                              {convertDate(dataConsultaArray.split('T')[0]) +
                                ' as ' +
                                dataConsultaArray.split('T')[1].substring(0, 5)}
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell key={index} scope="row">
                            {c.type === 'html' ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: record[c.field],
                                }}
                              />
                            ) : (
                              record[c.field]
                            )}
                          </TableCell>
                        );
                      })}

                    {(onView || onEdit || onDelete) && (
                      <TableCell key={index} align="right" scope="row">
                        {onEdit && (
                          <span className={'button-action-table'}>
                            <HiOutlinePencilAlt
                              size="22px"
                              onClick={() => onEdit(record)}
                            />
                          </span>
                        )}

                        {onView && (
                          <span className="button-action-table">
                            <AiOutlineEye
                              size="22px"
                              onClick={() => onView(record)}
                            />
                          </span>
                        )}

                        {onDelete && (
                          <span className={'button-action-table'}>
                            <CgTrash
                              size="22px"
                              onClick={() => onDelete(record)}
                            />
                          </span>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {pagesTotal && (
        <Box display="flex" justifyContent="center" marginTop="24px">
          <Stack spacing={2}>
            <Pagination
              count={pagesTotal}
              page={currentPage}
              onChange={handleChangePage}
              variant="outlined"
              color="primary"
            />
          </Stack>
        </Box>
      )}
    </>
  );
}

export { DataTable };
