import { darken } from 'polished';
import styled from 'styled-components';

interface IContainerProps {
  preview?: string;
  profilePage?: boolean;
}

export const Container = styled.div<IContainerProps>`
  position: relative;
  height: ${props => (props.profilePage ? '150px' : '100%')};
  width: ${props => (props.profilePage ? '150px' : '100%')};

  background: var(--white);
  background-image: url(${props => props.preview});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  border: 1px solid var(--primary-neutral-300);
  border-radius: ${props => (props.profilePage ? '50%' : '12px')};

  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    border-color: ${darken(0.2, '#DFE8F6')};
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    cursor: pointer;

    input {
      display: none;
    }

    svg {
      transition: all 0.5s;
    }

    &:hover {
      svg {
        transform: scale(1.1);
      }
    }
  }
`;

export const RemoveButton = styled.button`
  position: absolute;
  left: 50%;
  top: 50%;

  width: 40px;
  height: 40px;

  margin-left: -20px;
  margin-top: -20px;

  background: none;
  border: none;

  svg {
    transition: all 0.5s;
  }

  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
`;
