import styled from 'styled-components';

export const Container = styled.div`
  > #fab {
    width: 60px;
    position: fixed;
    left: 24px;
    bottom: 24px;
    border: none;
    background-color: transparent;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    z-index: 9;
    text-decoration: none;

    &:hover {
      width: 220px;
    }

    > div {
      max-width: 220px;
      min-width: 220px;
      width: 220px;
      border-radius: 10px;
      background-color: #24d366;
      overflow: hidden;
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 0;
      padding-right: 10px;
      font-size: 1rem;
      color: white;

      > div {
        background-color: #24d366;
        height: 100%;
        width: 60px;
        border-radius: 10px;
        overflow: hidden;

        > svg {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          float: left;
        }
      }
    }
  }

  .property-price-ticket {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;

    > div {
      display: flex;
      flex-direction: column;
      gap: 10px;
      /* position: fixed; */
      /* z-index: 999999999999999999999999999999; */
      background: white;
      padding-bottom: 10px;
      border-radius: 5px;
    }

    .outside {
      display: grid;
      gap: 10px;
      padding: 0 20px;
    }
  }
`;

export const Header = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

export const HeaderButtonsContainer = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
  }
`;

export const HeaderButtonsContainerMobile = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 20px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const Body = styled.div`
  padding: 20px;

  .actions-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .location-container {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--blue-400);

    > span {
      color: var(--grey-400);
    }

    > .ver-no-mapa {
      text-decoration: none;
      color: var(--blue-400);
      margin-left: 10px;
      border: none;
      background-color: transparent;
    }
  }

  > main {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 54px;

    & > main {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
`;

export const FavoriteButton = styled.div`
  width: 100%;
  border-radius: 6px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--grey-50);
  letter-spacing: 0.7px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background: yellow;
  }
`;

export const FinanBtn = styled.button`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: 2px solid var(--blue-100);
  background-color: white;
  transition: 0.2s;

  div {
    background-color: var(--blue-100);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 20px;
    transition: 0.2s;
  }

  div:first-of-type {
    padding: 8px 20px;
    background-color: transparent;
    color: var(--blue-100);
    font-weight: bold;
    font-size: 1.1rem;
  }

  &:hover {
    border: 2px solid var(--blue-300);

    div {
      background: var(--blue-300);
    }

    div:first-of-type {
      background-color: transparent;
      color: var(--blue-300);
    }
  }
`;

export const WppQuestions = styled.button`
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
  background-color: var(--green-400);
  color: white;
  width: 100%;
  font-size: 1rem;
  letter-spacing: 0.7px;
  transition: 0.2s;

  &:hover {
    background: var(--green-500);
  }
`;
