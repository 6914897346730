export default {
  title: 'Relação de documentos para análise de crédito',
  docs: [
    'Documentos Pessoais:',
    '• RG e CPF ou CNH (todos os compradores).',
    '• Certidão de nascimento, casamento, averbação de divórcio ou atestado de óbito.',
    '• Comprovante de endereço (Atualizado últimos 60 dias).',
    ' ',
    'Assalariados:',
    '• Carteira de trabalho (todos compradores).',
    '• 2 últimos contra cheques (todos compradores).',
    ' ',
    'Autônomos:',
    '• IRPF - Declaração de imposto de renda.',
    '• 6 últimos meses de extratos bancários (conta corrente ou poupança).',
    '• 3 últimos boletos pagos de cartão de crédito ou despesas em geral.',
    '• 3 últimos extratos da máquina de cartão (pagseguro, mercadopago, sumup, picpay, infinite pay e etc...).',
    ' ',
    'Empresários:',
    '• Pró-labore (para empresários, exceto: MEI - micro empreendedor individual).',
  ],
};
