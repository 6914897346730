import React from 'react';
import ReactDOM from 'react-dom';

import Bootstrap from 'Bootstrap';

import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Bootstrap />
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
