import { BiCheckShield } from 'react-icons/bi';
import { FiCheckSquare } from 'react-icons/fi';
import { RiLockLine } from 'react-icons/ri';

import { Property } from '@types';
import IconPrecoLupa from 'common/assets/images/icon_preco_lupa.png';
import { Title } from 'components/Atoms/Title';
import { Sharer } from 'helpers';

import * as S from './style';

export type CreditBox = {
  property?: Property;
};

export default function CreditBox({ property }: CreditBox) {
  return (
    <S.CreditBox>
      <header style={{ textAlign: 'start' }}>
        <Title color="green-400">
          Somos Correspondentes Bancários Credenciados
        </Title>
      </header>

      <main>
        <h5>O que consideramos para análise de crédito</h5>
        <ul>
          <li>
            <FiCheckSquare size={22} />
            <span>Documentação completa, legível e atualizada</span>
          </li>
          <li>
            <FiCheckSquare size={22} />
            <span>Apresentar renda de assalariado ou autônomo</span>
          </li>
          <li>
            <FiCheckSquare size={22} />
            <span>Não possuir restrições no nome dos compradores</span>
          </li>
        </ul>
      </main>

      <main>
        <button
          // href={`https://wa.me/+${constants.defaultWppNumber}?text=${encodeURI(
          // 'Olá, gostaria de fazer uma análise.',
          // )}`}
          onClick={() =>
            Sharer.whatsApp({
              property,
              toPrado: true,
              message: url =>
                `Olá, gostaria de fazer uma análise para o imóvel: ${url}`,
            })
          }
          aria-label="Fazer análise"
        >
          <span>Clique aqui e faça sua análise de crédito grátis</span>
          <img alt="preco-lupa" src={IconPrecoLupa} height={31} />
        </button>
        {/* <a
          href={`https://wa.me/+${constants.defaultWppNumber}?text=${encodeURI(
            'Olá, gostaria de fazer uma análise.',
          )}`}
          target="_blank"
          rel="noreferrer"
          aria-label="Fazer análise"
        >
          <span>Clique aqui e faça sua análise de crédito grátis</span>
          <FaRegHandshake size={48} />
        </a> */}
        <ul>
          <li>
            <BiCheckShield size={24} />
            <span>Sem nenhum custo totalmente seguro.</span>
          </li>
          <li>
            <RiLockLine size={24} />
            <span>Nós não compartilhamos seus dados.</span>
          </li>
        </ul>
      </main>
    </S.CreditBox>
  );
}
