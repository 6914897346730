/* eslint-disable prettier/prettier */
import { useCallback, useState, MouseEvent } from 'react';
import {useNavigate} from "react-router-dom";

import { Box, Button, Popover } from '@mui/material';
import no_user from 'common/assets/images/no_user.png';
import { ReactComponent as ChangePassword } from 'common/assets/svgs/change-password-icon.svg';
import { ReactComponent as Logout } from 'common/assets/svgs/logout.svg';
import { routesNames } from 'common/utils/routesNames';
import { useAuth } from 'hooks/auth';

import * as S from './styles';

function MyProfileActs() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { userData } = useAuth();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'opt-popover' : undefined;

  const renderOption = useCallback(
    (label: string, icon: JSX.Element, onClick?: () => void, name?: string): JSX.Element => {
      return (
        <Button
          sx={{
            width: '100%',
            columnGap: '16px',
            justifyContent: 'flex-start',
            fontSize: '14px',
            fontWeight: '500',
            textTransform: 'capitalize',
            color: 'var(--primary-neutral-900)',
          }}
          type="button"
          onClick={onClick}
          aria-label={label}
          name={name}
        >
          {icon}
          {label}
        </Button>
      );
    },
    [],
  );

  return (
    <>
      <Button
        id={id}
        sx={{
          transition: 'all .25s ease-in-out',
          backgroundImage: `url(${userData.photo || no_user})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          cursor: 'pointer',
          ':hover': {
            boxShadow: '0 0 5px rgba(0,0,0,.125)',
          },
          width: '42px',
          height: '42px',
          minWidth: '42px',
          borderRadius: '50%',
        }}
        onClick={handleClick}
        aria-label="Ações do usuário"
        name="option-btn"
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          rowGap="4px"
          right="0"
          top="48px"
          borderRadius="12px"
          padding="12px"
          boxShadow="0px 12px 30px rgba(70, 87, 147, 0.2)"
          sx={{ background: 'var(--white)' }}
        >
          {renderOption('Meu Perfil', <S.ProfileImage />, () => navigate(routesNames.dashboard), 'meu-perfil-btn')}
          {renderOption('Senha', <ChangePassword />, () => navigate('/alterar-senha'), 'change-password-btn')}
          {renderOption('Sair', <Logout />, logout, 'logout-btn')}
        </Box>
      </Popover>
    </>
  );
}

export { MyProfileActs };
