import { Box, useMediaQuery } from '@mui/material';
import house from 'common/assets/images/house_mock.png';
import { DefaultPageTitle, Button } from 'components/Atoms';
import { HouseCard } from 'components/Molecules';
import { IHouseCardProps } from 'components/Molecules/HouseCard/IHouseCard';

import * as S from './styles';

const housesMock: IHouseCardProps[] = [
  {
    id: 1,
    name: 'Casa 1',
    value: 300000,
    infos: {
      bedrooms: 2,
      bathrooms: 2,
      houseArea: 97,
      totalArea: 150,
      metragem: 'm²',
      slug: '',
      code: '',
    },
    location: {
      latitude: -16.7307346,
      longitude: -49.3458032,
      name: 'Moinho dos Ventos - Goiânia, GO',
    },
    image: house,
    isSelectable: true,
  },
  {
    id: 1,
    name: 'Casa 2',
    value: 300000,
    infos: {
      bedrooms: 2,
      bathrooms: 2,
      houseArea: 97,
      totalArea: 150,
      metragem: 'm²',
      slug: '',
      code: '',
    },
    location: {
      latitude: -16.7307346,
      longitude: -49.3458032,
      name: 'Moinho dos Ventos - Goiânia, GO',
    },
    image: house,
    isSelectable: true,
  },
  {
    id: 1,
    name: 'Casa 3',
    value: 300000,
    infos: {
      bedrooms: 2,
      bathrooms: 2,
      houseArea: 97,
      totalArea: 150,
      metragem: 'm²',
      slug: '',
      code: '',
    },
    location: {
      latitude: -16.7307346,
      longitude: -49.3458032,
      name: 'Moinho dos Ventos - Goiânia, GO',
    },
    image: house,
    isSelectable: true,
  },
  {
    id: 1,
    name: 'Casa 4',
    value: 300000,
    infos: {
      bedrooms: 2,
      bathrooms: 2,
      houseArea: 97,
      totalArea: 150,
      metragem: 'm²',
      slug: '',
      code: '',
    },
    location: {
      latitude: -16.7307346,
      longitude: -49.3458032,
      name: 'Moinho dos Ventos - Goiânia, GO',
    },
    image: house,
    isSelectable: true,
  },
  {
    id: 1,
    name: 'Casa 5',
    value: 300000,
    infos: {
      bedrooms: 2,
      bathrooms: 2,
      houseArea: 97,
      totalArea: 150,
      metragem: 'm²',
      slug: '',
      code: '',
    },
    location: {
      latitude: -16.7307346,
      longitude: -49.3458032,
      name: 'Moinho dos Ventos - Goiânia, GO',
    },
    image: house,
    isSelectable: true,
  },
  {
    id: 1,
    name: 'Casa 6',
    value: 300000,
    infos: {
      bedrooms: 2,
      bathrooms: 2,
      houseArea: 97,
      totalArea: 150,
      metragem: 'm²',
      slug: '',
      code: '',
    },
    location: {
      latitude: -16.7307346,
      longitude: -49.3458032,
      name: 'Moinho dos Ventos - Goiânia, GO',
    },
    image: house,
    isSelectable: true,
  },
  {
    id: 1,
    name: 'Casa 7',
    value: 300000,
    infos: {
      bedrooms: 2,
      bathrooms: 2,
      houseArea: 97,
      totalArea: 150,
      metragem: 'm²',
      slug: '',
      code: '',
    },
    location: {
      latitude: -16.7307346,
      longitude: -49.3458032,
      name: 'Moinho dos Ventos - Goiânia, GO',
    },
    image: house,
    isSelectable: true,
  },
  {
    id: 1,
    name: 'Casa 7',
    value: 300000,
    infos: {
      bedrooms: 2,
      bathrooms: 2,
      houseArea: 97,
      totalArea: 150,
      metragem: 'm²',
      slug: '',
      code: '',
    },
    location: {
      latitude: -16.7307346,
      longitude: -49.3458032,
      name: 'Moinho dos Ventos - Goiânia, GO',
    },
    image: house,
    isSelectable: true,
  },
  {
    id: 1,
    name: 'Casa 7',
    value: 300000,
    infos: {
      bedrooms: 2,
      bathrooms: 2,
      houseArea: 97,
      totalArea: 150,
      metragem: 'm²',
      slug: '',
      code: '',
    },
    location: {
      latitude: -16.7307346,
      longitude: -49.3458032,
      name: 'Moinho dos Ventos - Goiânia, GO',
    },
    image: house,
    isSelectable: true,
  },
  {
    id: 1,
    name: 'Casa 7',
    value: 300000,
    infos: {
      bedrooms: 2,
      bathrooms: 2,
      houseArea: 97,
      totalArea: 150,
      metragem: 'm²',
      slug: '',
      code: '',
    },
    location: {
      latitude: -16.7307346,
      longitude: -49.3458032,
      name: 'Moinho dos Ventos - Goiânia, GO',
    },
    image: house,
    isSelectable: true,
  },
];

function Realty() {
  const matches = useMediaQuery('(max-width: 884px)');

  return (
    <>
      <Box
        display="flex"
        flexDirection={matches ? 'column' : 'row'}
        alignItems={matches ? 'flex-start' : 'flex-end'}
        rowGap="10px"
        justifyContent="space-between"
      >
        <DefaultPageTitle
          color="var(--primary-neutral-900)"
          align={matches ? 'center' : 'left'}
        >
          Todos os imóveis
        </DefaultPageTitle>

        <Button color="high_blue">Add Imóvel</Button>
      </Box>

      <Box
        display="flex"
        flexDirection={matches ? 'column' : 'row'}
        rowGap={matches ? '18px' : 'initial'}
        justifyContent="space-between"
        marginTop="36px"
        borderRadius="10px"
        sx={{ background: '#fff', padding: '24px 34px' }}
      >
        {/* <Box
          display="grid"
          // gridTemplateColumns={matches ? 'repeat(3, 26vw)' : 'repeat(3, 240px)'}
          gridTemplateColumns={matches ? '1fr' : 'repeat(3, 240px)'}
          rowGap={matches ? '18px' : 'initial'}
          columnGap={matches ? 'initial' : '2.5vw'}
          justifyContent="space-between"
        >
          <Box height="40px">
            <Dropdown icon={<Buildings />} title="Tipo de Imóvel" />
          </Box>

          <Box height="40px">
            <Dropdown icon={<Calendar />} title="Data de criação" />
          </Box>

          <Box height="40px">
            <Dropdown icon={<LocationBold />} title="Localização" />
          </Box>
        </Box> */}

        <Box width={matches ? '100%' : '180px'}>
          <Button
            color="high_blue"
            full_width
            aria-label="Pesquisar"
            name="search-btn"
          >
            Pesquisar
          </Button>
        </Box>
      </Box>

      <Box
        padding="26px 24px"
        marginTop="26px"
        sx={{ background: 'var(--white)' }}
      >
        <Box
          display="grid"
          gridTemplateColumns={matches ? '1fr' : 'repeat(2, 180px)'}
          gridTemplateRows={matches ? 'repeat(2, 40px)' : '40px'}
          justifyContent="flex-end"
          columnGap={matches ? 'initial' : '20px'}
          rowGap={matches ? '10px' : 'initial'}
        >
          {/* <Dropdown title="Mais recentes" />
          <Dropdown title="Ações" /> */}
        </Box>

        <S.Wrapper>
          {housesMock.map((el, index) => (
            <HouseCard {...el} key={`house-${index}`} />
          ))}
        </S.Wrapper>
      </Box>
    </>
  );
}

export { Realty };
export default Realty;
