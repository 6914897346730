import { useState } from 'react';

import { Property } from '@types';
import { defaultRealtorName } from 'common/constants/defaultRealtorName';
import Modal, { ModalProps } from 'components/Atoms/modal/Modal';

import { InfosToBroker } from '../InfosToBroker';

export type InfoToBrokerModalProps = {
  property?: Property;
} & Omit<ModalProps, 'children'>;

export default function InfoToBrokerModal({
  property,
  ...rest
}: InfoToBrokerModalProps) {
  const [mensageWppCaptador, setMensageWppCaptador] = useState('');
  const [mensageWppProprietario, setMensageWppProprietario] = useState('');

  return (
    <Modal {...rest} title="Informações ao Corretor">
      {property && (
        <InfosToBroker
          mensageWppCaptador={mensageWppCaptador}
          mensageWppProprietario={mensageWppProprietario}
          address={`${property.address}, ${property.district} - ${property.city?.description}`}
          keys_local={property.keys}
          broker_info={property.broker_info}
          captador={property.usuarioDeCaptura?.name || defaultRealtorName}
          link_enterprise={
            property.usuarioDeCaptura === null ? '' : property.url
          }
          avaliation_value={property.amount_evaluation!}
          captador_phone={property.usuarioDeCaptura?.phone || '62982902290'}
          commission={property.comissao}
          owner={property.proprietary}
          owner_phone={property.phone_proprietary}
          property_id={property.id}
          evaluated={!!property.evaluation}
          amount_evaluation={property.amount_evaluation!}
          lat={property.latitude}
          lng={property.longitude}
          name={property.name}
          key_number={property.key_number}
          branch_agency={property.branch_agency}
          user_include={property.user_register}
          data_inclusao={property.created_at}
          data_edit={property.updated_at}
        />
      )}
    </Modal>
  );
}
