import { useCallback, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { ImageInput } from 'components/Atoms';
import Compressor from 'compressorjs';

import { IImagesInputProps } from './IImagesInput';
import naturalSorter from './sort';
import * as S from './styles';

function ImagesInput({ onAddNewImage }: IImagesInputProps) {
  const [images, setImages] = useState<any[]>([]);

  useEffect(() => {
    onAddNewImage(images.filter(item => item !== '-1'));
  }, [images]);
  //codigo do matheus
  const handleAddImage = useCallback(
    (index_input: number, value?: File[]) => {
      if (!value) {
        setImages(images.map((item, i) => (i == index_input ? '-1' : item)));
        return;
      }
      //? Extrai os nomes dos arquivos
      let filesNames: string[] = [];
      for (const foto of value) {
        filesNames.push(foto.name);
      }
      filesNames = filesNames.sort();

      const aux = [...images];
      let index = index_input;
      let i = 0;
      value.forEach(file => {
        new Compressor(file, {
          quality: 0.9, // 0.6 can also be used, but its not recommended to go below.
          success: compressedResult => {
            // compressedResult has the compressed file.
            // Use the compressed file to upload the images to your server.
            //setCompressedFile(res)
            if (aux[index] !== undefined) {
              aux[index] = compressedResult;
            } else {
              aux.push(compressedResult);
            }

            if (i === value.length - 1) {
              // const orderedFiles: File[] = [];

              // for (const fileName of filesNames) {
              //   for (const file of aux) {
              //     if (file.name === fileName) orderedFiles.push(file);
              //   }
              // }
              handleImagesSave(aux);
            }

            index++;
            i++;
          },
        });
      });
    },
    [images],
  );

  function handleImagesSave(aux: File[]): void {
    const files = [...aux];

    let filesNames = [] as string[];
    for (const foto of files) {
      filesNames.push(foto.name);
    }

    filesNames = filesNames.sort(naturalSorter);
    console.clear();
    console.log('filesNames:', filesNames);

    const orderedFiles: File[] = [];
    for (const fileName of filesNames) {
      for (const file of files) {
        if (file.name === fileName) orderedFiles.push(file);
      }
    }

    console.log(orderedFiles);

    setImages(orderedFiles);
  }

  return (
    <Box>
      <S.GridContainer>
        <ImageInput
          disableAutoPreview
          multiple
          onRemoveImage={() => handleAddImage(0)}
          onAddMultiple={file => handleAddImage(0, file)}
          previewFile={images[0]}
        />
        <ImageInput
          disableAutoPreview
          multiple
          onRemoveImage={() => handleAddImage(1)}
          onAddMultiple={file => handleAddImage(1, file)}
          previewFile={images[1]}
        />
        <ImageInput
          disableAutoPreview
          multiple
          onRemoveImage={() => handleAddImage(2)}
          onAddMultiple={file => handleAddImage(2, file)}
          previewFile={images[2]}
        />
        <ImageInput
          disableAutoPreview
          multiple
          onRemoveImage={() => handleAddImage(3)}
          onAddMultiple={file => handleAddImage(3, file)}
          previewFile={images[3]}
        />
        <ImageInput
          disableAutoPreview
          multiple
          onRemoveImage={() => handleAddImage(4)}
          onAddMultiple={file => handleAddImage(4, file)}
          previewFile={images[4]}
        />
        <ImageInput
          disableAutoPreview
          multiple
          onRemoveImage={() => handleAddImage(5)}
          onAddMultiple={file => handleAddImage(5, file)}
          previewFile={images[5]}
        />

        {images.length > 6 && (
          <ImageInput
            disableAutoPreview
            multiple
            onAddMultiple={file => handleAddImage(6, file)}
            previewFile={images[6]}
          />
        )}

        {images.length <= 6 && (
          <ImageInput
            multiple
            onAddMultiple={files => setImages([...images, ...files])}
            addMoreType
          />
        )}
      </S.GridContainer>

      {images.length > 6 && (
        <div
          style={{
            gap: '18px',
            marginTop: '18px',
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {images.slice(7).map((image, index) => (
            <div
              key={index + 7}
              style={{
                height: '80px',
                width: '105px',
              }}
            >
              <ImageInput
                disableAutoPreview
                multiple
                onRemoveImage={() => handleAddImage(index + 7)}
                onAddMultiple={file => handleAddImage(index + 7, file)}
                previewFile={image}
              />
            </div>
          ))}

          <div
            style={{
              height: '80px',
              width: '105px',
            }}
          >
            <ImageInput
              multiple
              onAddMultiple={files => setImages([...images, ...files])}
              addMoreType
            />
          </div>
        </div>
      )}
      <Typography
        fontSize="12px"
        fontWeight="400"
        color="var(--grey-300)"
        textAlign="right"
        marginTop="5px"
      >
        Max 30mb
      </Typography>
    </Box>
  );
}

export { ImagesInput };
