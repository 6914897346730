import { Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { Box, Pagination, Stack } from '@mui/material';
import { ReactComponent as Expand } from 'common/assets/svgs/expand.svg';
import { ReactComponent as FilterAdd } from 'common/assets/svgs/filter-add.svg';
import { PageDefaultWrapper, DefaultPageTitle } from 'components/Atoms';
import { Button } from 'components/Atoms';
import CloseButton from 'components/Atoms/CloseButton';
import { Filter, HouseCard } from 'components/Molecules';
import { SearchFilter } from 'components/Molecules';
import { ILatLng } from 'components/Organisms/Map/IMap';
import { useSearch } from 'hooks/search';
import useWindowDimensions from 'hooks/useWindowDimensions';
import api, { getPublicProperties } from 'services/api';

import { LastAddedCarousel } from '../../components/Organisms/LastAddedCarousel';
import { useAuth } from '../../hooks/auth';
import useInputMask from '../../hooks/useInputMask';
import MapScreen from './components/MapScreen';
import { ModalMapSearch } from './components/ModalMapSearch';
import * as S from './styles';

const LIST_PER_PAGE = 12;
// @ts-ignore
let CURRENT_PAGE: string | null = 1;

function Search() {
  const [filtersKey, setFiltersKey] = useState(1);
  const [displayMode, setDisplayMode] = useState<'MAP' | 'LIST'>('MAP');
  const { width } = useWindowDimensions();
  const [mapCenter, setMapCenter] = useState<ILatLng>();
  const [properties, setProperties] = useState<any>([]);
  const [propertiesList, setPropertiesList] = useState<any>([]);
  const [latlngs, setLatlngs] = useState<ILatLng[]>([]);
  const [markedProperty, setMarkedProperty] = useState<number | undefined>();
  const [zoom, setZoom] = useState(5);
  const {
    filter,
    changeCitiesMode,
    temporarySelectedDistrictsToString,
    addressQ,
    proprietaryQ,
  } = useSearch();
  const [open, setOpen] = useState(false);
  const [fullScreenFiltersOpen, setFullScreenFiltersOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userData, logoutLocal } = useAuth();
  const [catalogos, setCatalogos] = useState<any[]>([]);
  const [lastReqData, setLastReqData] = useState<any>({});

  useEffect(() => {
    if (localStorage.getItem('display')) {
      const storageDisplay = localStorage.getItem('display');
      // @ts-ignore
      setDisplayMode(storageDisplay);
    }
    setMarkedProperty(undefined);
    changeCitiesMode('default');
  }, []);

  useEffect(() => {
    if (userData.id) {
      api
        .get(`/catalogo?user_register=true`)
        .then(({ data }) => {
          const resp = data.data;
          if (resp) {
            const list = resp.map(function (dt: { id: any; titulo: any }) {
              return { value: dt.id, label: dt.titulo };
            });
            setCatalogos(list);
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            logoutLocal();
          }
        });
    }
  }, [userData]);

  useEffect(() => {
    setFiltersKey(filtersKey + 1);
    CURRENT_PAGE = localStorage.getItem('page');
  }, [open]);

  async function loadData(
    mapMode?: boolean,
    pagination?: { page?: any; limit?: number },
  ) {
    if (pagination?.page) {
      // @ts-ignore
      localStorage.setItem('page', pagination?.page.toString());
      CURRENT_PAGE = pagination?.page;
    }
    setIsLoading(true);
    const dataReq = {
      city_id: filter?.city?.value,
      tarja_id: filter?.tarja_id,
      price_min: filter ? removeMoneyMask(filter?.min_value) : '',
      price_max: filter ? removeMoneyMask(filter?.max_value) : '',
      type_property: filter?.type === 'Galpão' ? 'Galpões' : filter?.type,
      bedrooms: filter?.bedrooms,
      district: temporarySelectedDistrictsToString(), //filter?.district,
      code: filter?.code,
      evaluation: filter?.evaluation,
      furnished_property: filter?.furnished_property,
      gated_community: filter?.gated_community,
      mapMode: mapMode ? mapMode : false,
      address: filter?.address,
      proprietary: filter?.proprietary,
      corretor: filter?.corretor,
      phone_proprietary: filter?.phone_proprietary,
      contruct_area: filter?.contruct_area,
      land_area: filter?.land_area,
      preco_max_mq: filter?.preco_max_mq,
      preco_min_mq: filter?.preco_min_mq,
      bathrooms: filter?.bathrooms,
      garage: filter?.garage,
      suites: filter?.suites,
      user_capture: true,
      listMode: !mapMode ? true : false,
      street: addressQ?.value,
      proprietario: proprietaryQ?.value,
    };
    setLastReqData(dataReq);
    const imoveis = await getPublicProperties(dataReq, pagination);
    if (pagination?.limit) {
      setPropertiesList(imoveis);
    } else {
      setProperties(imoveis);
    }
    setIsLoading(false);
  }

  const { removeMoneyMask } = useInputMask();

  useEffect(() => {
    CURRENT_PAGE = localStorage.getItem('page');
    if (displayMode === 'MAP') {
      loadData(true);
    } else {
      loadData(false, { page: CURRENT_PAGE, limit: LIST_PER_PAGE });
    }
  }, [filter, displayMode]);

  useEffect(() => {
    if (properties?.data?.length > 0) {
      const formatedPosition: ILatLng[] = properties.data.map((el: any) => {
        return {
          lat: Number(
            el?.lat_cliente !== null ? el?.lat_cliente : el?.latitude,
          ),
          lng: Number(
            el?.long_cliente !== null ? el?.long_cliente : el?.longitude,
          ),
          property_id: el?.id,
          property_name: el?.name,
          property_price: el?.amount,
          property_slug: el.slug,
          property_totalArea: el?.land_area,
          property_metragem: el?.type_footage,
          code: el?.code,
        };
      });
      setLatlngs([...formatedPosition]);
      setMapCenter({ ...formatedPosition[0] });
    }
  }, [properties]);

  const setDataDisplayMode = (data: any) => {
    localStorage.setItem('page', '1');
    CURRENT_PAGE = '1';
    localStorage.setItem('display', data);
    setDisplayMode(data);
  };

  const mapScream = (
    <>
      <S.MapContainer
        height={open ? '100%' : width > 700 ? 'calc(100vh - 120px)' : '500px'}
      >
        {!open && (
          <Button
            title="Expandir mapa"
            onClick={() => setOpen(!open)}
            style={{
              width: 'max-content',
              position: 'absolute',
              top: 10,
              right: 10,
              zIndex: 1000,
            }}
            color="white"
            type="button"
            aria-label="Expandir mapa"
            name="map-expand-btn"
          >
            <span>Expandir mapa</span>
            <Expand style={{ width: '10px', height: '10px' }} />
          </Button>
        )}
        {open && fullScreenFiltersOpen ? (
          <div
            style={{
              position: 'absolute',
              top: width > 700 ? '20px' : '50px',
              left: width > 700 ? '100px' : '10px',
              right: width > 700 ? '100px' : '10px',
              zIndex: 1000,
            }}
          >
            <header
              style={{
                display: 'flex',
                justifyContent: 'end',
                marginBottom: '5px',
              }}
            >
              <div
                style={{
                  background: 'white',
                  borderRadius: '5px',
                  padding: '4px 4px 0 4px',
                  boxShadow: '5px 5px 5px rgba(0,0,0,0.1)',
                }}
              >
                <CloseButton onClick={() => setFullScreenFiltersOpen(false)} />
              </div>
            </header>

            <div
              style={{
                background: 'white',
                boxShadow: '5px 5px 5px rgba(0,0,0,0.1)',
                borderRadius: '10px',
                padding: '20px 20px 10px 20px',
              }}
            >
              <Header
                properties={displayMode === 'MAP' ? properties : propertiesList}
                filter={filter}
                displayMode={displayMode}
                lastReqData={lastReqData}
                setDisplayMode={setDataDisplayMode}
                isLoading={isLoading}
                hideFields={['displayMode', 'filterStatus']}
              />
            </div>
          </div>
        ) : (
          open &&
          !fullScreenFiltersOpen && (
            <button
              onClick={() => setFullScreenFiltersOpen(true)}
              style={{
                position: 'absolute',
                zIndex: 1000,
                top: '50px',
                right: '15px',
                border: 'none',
                boxShadow: '5px 5px 5px rgba(0,0,0,0.1)',
                // border: '1px solid #1DAEFF',
                borderRadius: '0.25rem',
                padding: '0.5rem 0.7rem',
              }}
            >
              <FilterAdd />
            </button>
          )
        )}
        {latlngs.length > 0 && mapCenter && (
          <>
            <Suspense fallback={<h1>...</h1>}>
              <MapScreen
                zoom={zoom}
                markedProperty={markedProperty}
                latlngs={latlngs}
                mapCenter={mapCenter}
                catalogos={catalogos}
              />
            </Suspense>
          </>
        )}
      </S.MapContainer>
      {!open && (
        <div
          style={{
            height: width > 700 ? 'calc(100vh - 120px)' : '500px',
            minHeight: '400px',
          }}
        ></div>
      )}
    </>
  );

  return (
    <>
      <Helmet>
        <title>Prado imóveis</title>
        <meta name="description" content={`Busca imóveis`} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <PageDefaultWrapper verticalPad>
        <S.Container>
          <Header
            key={filtersKey}
            properties={displayMode === 'MAP' ? properties : propertiesList}
            filter={filter}
            lastReqData={lastReqData}
            displayMode={displayMode}
            setDisplayMode={setDataDisplayMode}
            isLoading={isLoading}
          />

          {((!properties?.data?.length && displayMode === 'MAP') ||
            (!propertiesList?.data?.length && displayMode === 'LIST')) &&
          !isLoading ? (
            <></>
          ) : displayMode === 'LIST' && isLoading ? (
            <S.ListContainer>
              <div
                className="shimmer"
                style={{
                  height: '300px',
                  width: '280px',
                }}
              ></div>
              <div
                className="shimmer"
                style={{
                  height: '300px',
                  width: '280px',
                }}
              ></div>
              <div
                className="shimmer"
                style={{
                  height: '300px',
                  width: '280px',
                }}
              ></div>
              <div
                className="shimmer"
                style={{
                  height: '300px',
                  width: '280px',
                }}
              ></div>
              <div
                className="shimmer"
                style={{
                  height: '300px',
                  width: '280px',
                }}
              ></div>
              <div
                className="shimmer"
                style={{
                  height: '300px',
                  width: '280px',
                }}
              ></div>
              <div
                className="shimmer"
                style={{
                  height: '300px',
                  width: '280px',
                }}
              ></div>
              <div
                className="shimmer"
                style={{
                  height: '300px',
                  width: '280px',
                }}
              ></div>
            </S.ListContainer>
          ) : displayMode === 'LIST' ? (
            <div>
              <S.ListContainer>
                {propertiesList?.data?.map((data: any) => (
                  <HouseCard
                    tarja={data.tarja}
                    key={data.id}
                    id={data.id}
                    name={data.name}
                    value={
                      data?.promotional_amount
                        ? data.promotional_amount
                        : data.amount
                    }
                    images={data.images.map(
                      (img: any) => /*img.h_115 ||*/ img.image,
                    )}
                    favoriteId={
                      data?.favorites_data?.length
                        ? data.favorites_data[0].id
                        : undefined
                    }
                    infos={{
                      bathrooms: data.bathrooms,
                      bedrooms: data.bedrooms,
                      houseArea: data.contruct_area,
                      totalArea: data.land_area,
                      metragem: data.type_footage,
                      slug: data.slug,
                      code: data.code,
                    }}
                    withLoggedActions={!!userData.id}
                    location={{
                      latitude: Number(data.latitude),
                      longitude: Number(data.longitude),
                      name: `${data.district} - ${data?.city?.description}`,
                    }}
                    property={data}
                    catalogos={catalogos}
                  />
                ))}
              </S.ListContainer>

              {propertiesList.last_page > 1 && (
                <Box display="flex" justifyContent="center" marginTop="24px">
                  <Stack spacing={2}>
                    <Pagination
                      variant="outlined"
                      color="primary"
                      count={Math.ceil(propertiesList.total / LIST_PER_PAGE)}
                      page={propertiesList.current_page}
                      onChange={(_, page) => {
                        loadData(false, { page, limit: LIST_PER_PAGE });
                      }}
                    />
                  </Stack>
                </Box>
              )}
            </div>
          ) : isLoading ? (
            <div
              className="shimmer"
              style={{ height: width > 700 ? '68vh' : '500px', width: '100%' }}
            ></div>
          ) : (
            <>
              {mapScream}
              <LastAddedCarousel />
            </>
          )}
        </S.Container>
      </PageDefaultWrapper>
      <ModalMapSearch setOpen={setOpen} open={open}>
        {mapScream}
      </ModalMapSearch>
    </>
  );
}

const Header = ({
  properties,
  filter,
  displayMode,
  lastReqData,
  setDisplayMode,
  isLoading,
  hideFields,
}: any) => {
  const { keys } = useSearch();
  const [titleData, setTitleData] = useState({
    type: 'imóveis',
    found: 'encontrados',
    district:
      lastReqData.district?.length && !lastReqData.district?.includes('+')
        ? lastReqData.district
        : filter?.city?.label || '',
  });

  useEffect(() => {
    const isPlural = properties?.data?.length > 1;
    let type = 'imóveis';
    let found = 'encontrados';
    let district = '';
    const t = (filter?.type ?? '').toLowerCase();

    if (['área', 'casa', 'fazenda', 'chácara', 'sala comercial'].includes(t)) {
      type = isPlural ? `${t}s` : t;
      found = isPlural ? 'encontradas' : 'encontrada';
    }

    if (['apartamento', 'sobrado', 'lote'].includes(t)) {
      type = isPlural ? `${t}s` : t;
      found = isPlural ? 'encontrados' : 'encontrado';
    }

    if (['galpão'].includes(t)) {
      type = isPlural ? 'galpões' : t;
      found = isPlural ? 'encontrados' : 'encontrado';
    }
    if (!properties?.data?.length) {
      district = '';
    } else if (
      lastReqData.district?.length &&
      !lastReqData.district?.includes('+')
    ) {
      district = lastReqData.district + ', ' + filter?.city?.label;
    } else {
      district = filter?.city?.label || '';
    }

    setTitleData({ type, found, district });
  }, [filter?.type, filter?.city, properties?.data, lastReqData]);

  return (
    <S.TopWrapper>
      <div>
        <DefaultPageTitle emphasis={titleData.district}>
          {isLoading
            ? 'Carregando...'
            : properties?.data?.length > 0
            ? `${properties?.total} ${titleData.type} à venda ${
                titleData.found
              } ${filter?.city?.label ? 'em ' : ''}`
            : 'Não foram encontrados imóveis para os filtros aplicados'}
        </DefaultPageTitle>

        <Filter
          key={keys.general}
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
          hideFields={hideFields}
        />
      </div>

      <SearchFilter key={keys.general} />
    </S.TopWrapper>
  );
};

export { Search };
export default Search;
