import { Link } from 'react-router-dom';

import { ReactComponent as Check } from 'common/assets/svgs/check_correct.svg';
import { ReactComponent as Delete } from 'common/assets/svgs/delete.svg';

import { ReactComponent as Bedroom } from '../../../common/assets/svgs/bedroom.svg';
import { ReactComponent as BuildingSize } from '../../../common/assets/svgs/building_size.svg';
import { ReactComponent as Info } from '../../../common/assets/svgs/info_2.svg';
import { ReactComponent as Maximize } from '../../../common/assets/svgs/maximize.svg';
import { ReactComponent as Shower } from '../../../common/assets/svgs/shower.svg';
import { formatCurrency } from '../../../common/utils/masks';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { Button } from '../../Atoms';
import HouseCardCarousel from '../../Organisms/HouseCardCarousel';
import * as S from './style';
interface IHouseCardSimple {
  propertie: any;
  onClick: any;
  onClickView?: any;
}

function HouseCardSimple({
  propertie,
  onClick,
  onClickView,
}: IHouseCardSimple) {
  const { width } = useWindowDimensions();

  return (
    <>
      <S.ExternalContainer>
        <S.Container className="house_card_container">
          <S.CarouselContainer>
            <HouseCardCarousel
              items={propertie.images?.map((img: any, index: number) => (
                <img
                  style={{ objectFit: 'cover', objectPosition: 'center' }}
                  src={img.image}
                  key={`${img.image}-${index}`}
                  alt={`${name}-imagem-${index + 1}`}
                />
              ))}
            />
          </S.CarouselContainer>
          <S.Infos>
            <div className="basic-infos">
              <span className="dots">{propertie.name}</span>
              <div className="house-attributes">
                {propertie.bedrooms !== null && (
                  <div>
                    <Bedroom />
                    <span>{propertie.bedrooms}</span>
                  </div>
                )}
                {propertie.bathrooms !== null && (
                  <div>
                    <Shower />
                    <span>{propertie.bathrooms}</span>
                  </div>
                )}

                {propertie.houseArea !== null && propertie.houseArea !== 0 && (
                  <div>
                    <BuildingSize />
                    <span>{propertie.houseArea} m²</span>
                  </div>
                )}

                {propertie.totalArea !== null && propertie.totalArea !== 0 && (
                  <div>
                    <Maximize />
                    <span>
                      {propertie.totalArea}{' '}
                      {propertie.metragem === 'hectares' ? 'ha' : 'm²'}
                    </span>
                  </div>
                )}

                {propertie.bedrooms === null &&
                  propertie.bathrooms === null &&
                  propertie.houseArea === null &&
                  propertie.totalArea === null && (
                    <div>
                      <Info />
                      <span>Sem dados adicionais</span>
                    </div>
                  )}
              </div>
              <S.ButtonContainer>
                <span></span>
                <span className="price">
                  {formatCurrency(
                    propertie?.promotional_amount
                      ? propertie.promotional_amount
                      : propertie.amount,
                  )}
                </span>
                {onClickView ? (
                  <Link
                    target="_blank"
                    style={{ textDecoration: 'none' }}
                    to={`/imovel/${propertie.slug}-${propertie.totalArea}${propertie.metragem}-codigo:${propertie.code}/${propertie.id}`}
                  >
                    <Button
                      color="blue"
                      aria-label="Ver"
                      name="house-card-action-btn"
                    >
                      Ver
                    </Button>
                  </Link>
                ) : propertie.selected_comunicate ? (
                  <Button
                    color="transparent"
                    aria-label="Ver"
                    name="house-card-action-btn"
                    onClick={() => onClick(propertie)}
                  >
                    <Delete />
                  </Button>
                ) : (
                  <Button
                    color="alpha-blue"
                    aria-label="Ver"
                    name="house-card-action-btn"
                    onClick={() => onClick(propertie)}
                  >
                    Add
                  </Button>
                )}
              </S.ButtonContainer>
            </div>
          </S.Infos>
        </S.Container>
      </S.ExternalContainer>
    </>
  );
}

export { HouseCardSimple };
