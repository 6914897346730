import * as S from './style';

export type LightButtonProps = {
  text: string;
  icon: React.ReactElement;
  className?: string;
  responsive?: boolean;
  onClick?: (event: MouseEvent) => void;
  active?: boolean;
  style?: any;
};

export default function LightButton({ icon, text, ...rest }: LightButtonProps) {
  return (
    <S.Button {...(rest as any)}>
      <div className="icon-container">{icon}</div>
      <span>{text}</span>
    </S.Button>
  );
}
