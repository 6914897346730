import styled, { css } from 'styled-components';

import { IMapProps } from './IMap';

type IContainerProps = Pick<IMapProps, 'notDefaultZoomControlPosition'>;

export const Container = styled.div<IContainerProps>`
  width: 100%;
  height: 100%;

  .marker-cluster span {
    font-weight: 600;
    font-size: 14px;
    color: var(--primary-neutral-900);
  }

  .marker-cluster-medium,
  .marker-cluster-small,
  .marker-cluster-large {
    background: rgba(12, 74, 136, 0.75);

    div {
      background: #fff;
    }
  }

  .leaflet-bottom {
    display: none;
  }

  .leaflet-touch .leaflet-control-layers,
  .leaflet-touch .leaflet-bar {
    border: none;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.275);
  }

  .leaflet-touch .leaflet-bar a:first-child {
    background: var(--white);
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .leaflet-touch .leaflet-bar a:last-child {
    background: var(--white);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .leaflet-touch .leaflet-bar a span {
    color: var(--primary-neutral-550);
    font-size: 1.25rem;
    font-weight: 400;
  }

  .leaflet-tooltip-top {
    margin-top: -46px;
    max-width: 300px;
  }

  .leaflet-popup {
    bottom: 10px !important;
    left: -140px;
  }

  .leaflet-popup-content {
    margin: 0;
    padding: 4px;
    padding-bottom: 8px;
    width: 295px !important;
  }

  .house_card_container {
    margin: 5px;
  }

  .loading-icon {
    animation: spinner 2s infinite;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  ${props =>
    props.notDefaultZoomControlPosition &&
    css`
      .leaflet-left {
        left: 3.5rem;
      }

      .leaflet-top {
        top: 2.5rem;
      }
    `}

  @media (min-width: 1024px) {
    .leaflet-left {
      left: 1.5rem;
    }

    .leaflet-top {
      top: 1.5rem;
    }
    .leaflet-popup {
      left: -150px;
    }

    .leaflet-popup-content {
      margin: 0;
    }
  }

  @media (max-width: 1024px) {
    .leaflet-left {
      left: 1.5rem;
    }

    .leaflet-top {
      top: 1.5rem;
    }
    .leaflet-popup {
      left: -150px;
    }

    .leaflet-popup-content {
      margin: 0;
    }
  }

  @media (max-width: 884px) {
    .leaflet-left {
      left: 1.5rem;
    }

    .leaflet-top {
      top: 1.5rem;
    }
    .leaflet-popup {
      left: -150px;
    }

    .leaflet-popup-content {
      margin: 0;
    }
  }

  @media (max-width: 480px) {
    .leaflet-left {
      left: 0.5rem;
    }

    .leaflet-top {
      top: 0.5rem;
    }
  }
`;

export const PopupMessage = styled.div`
  width: 250px;

  p {
    padding: 0 10px;
    white-space: pre-line;
    margin-bottom: 5px;

    font-size: 14px;
    text-align: center;
    font-weight: bold;

    color: var(--primary-neutral-900);
  }

  .dots {
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  span {
    display: block;
    margin: 10px 0;

    font-weight: 600;
    font-size: 12px;
    text-align: center;

    color: var(--primary-neutral-700);
  }

  .price {
    padding: 5px 10px;
    margin-top: 10px;

    font-size: 18px;
    background: var(--blue-10);
    color: var(--blue-400);
    border-radius: 0.4rem;
  }
`;
export const ButtonContainerBaloon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  button {
    margin-left: 10px;
    padding: 4px 12px !important;
  }
`;
