import { useEffect, useState } from 'react';

import {
  Autocomplete,
  Box,
  FormControl,
  Paper,
  TextField,
} from '@mui/material';
import { IDropdownOption } from 'models/IDropdownOption';

import { ICustomAutocompleteProps } from './ICustomAutocomplete';
import * as S from './styles';

function CustomAutocomplete({
  title,
  icon,
  width,
  options,
  onSelectOption,
  initialValue,
  multiple,
  temporaryValues,
  renderTags,
}: ICustomAutocompleteProps) {
  const [inputValue, setInputValue] = useState(
    initialValue || ({} as IDropdownOption),
  );

  return (
    <S.Container>
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        maxWidth="100%"
        height="100%"
        maxHeight="38px"
        columnGap="10px"
        fontFamily={'Urbanist'}
      >
        {icon ? <S.IconWrapper>{icon}</S.IconWrapper> : ''}
        <FormControl
          size="small"
          sx={{ width: width ? width : '80%', fontFamily: 'Urbanist' }}
        >
          <Autocomplete
            multiple={multiple}
            disablePortal
            id="combo-box-demo"
            options={options}
            disableClearable
            noOptionsText="Informação não encontrada"
            value={!multiple ? inputValue : inputValue}
            blurOnSelect={!multiple}
            disableCloseOnSelect
            renderTags={renderTags}
            onChange={(e: any, v: any) => {
              if (v.label !== 'Selecione vários') {
                setInputValue(v);
              }
              onSelectOption(v, e);
            }}
            sx={{
              "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
                {
                  backgroundColor: '#4396e6',
                  color: 'white',
                },
              "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'].Mui-focused":
                {
                  backgroundColor: '#3878b4',
                },
            }}
            PaperComponent={({ children }) => {
              return <Paper>{children}</Paper>;
            }}
            getOptionLabel={option => option?.label || ''}
            renderInput={params => <TextField {...params} label={title} />}
            isOptionEqualToValue={(option, value) => {
              if (option.value == value.value) return true;
              if (
                temporaryValues &&
                !!temporaryValues.filter((tp: any) => tp.value == options.value)
                  ?.length
              )
                return true;
              return false;
            }}
          />
        </FormControl>
      </Box>
    </S.Container>
  );
}

export { CustomAutocomplete };
