import styled from 'styled-components';

export const MenuContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 6rem;
  max-width: var(--content-width);

  margin: 0 auto;

  .action-wrapper {
    display: flex;

    button + button {
      margin-left: 0.5rem;
    }
  }
`;

export const ResponsiveMenuContent = styled.div`
  display: none;

  align-items: center;
  justify-content: space-between;

  height: 4.25rem;
  width: 100%;

  padding: 0 1.25rem;
`;

export const Container = styled.header`
  position: relative;
  box-shadow: 0 4px 6px 0px rgba(0, 0, 0, 0.05);
  background: var(--white);
  z-index: 1;

  @media (max-width: 884px) {
    box-shadow: 0 4px 6px 0px rgba(0, 0, 0, 0.075);

    ${MenuContent} {
      display: none;
    }

    ${ResponsiveMenuContent} {
      display: flex;
    }
  }

  @media (max-width: 1200px) {
    padding: 0 1.4rem;
  }
`;
