import { useEffect, useState } from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Box,
} from '@mui/material';

import { INewDropdownProps } from './INewDropdown';
import * as S from './styles';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
    },
  },
};

function NewDropdown({
  title,
  options,
  onSelectOption,
  icon,
  withoutNoOneOpt,
  initialValue,
  width,
}: INewDropdownProps) {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (initialValue) setValue(initialValue);
  }, [initialValue]);

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setValue(value);

    if (value === '') {
      onSelectOption({ label: '', value: '' });
      return;
    }

    const selectedOpt = options.find(el => el.value === value);
    onSelectOption({ ...selectedOpt! });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      maxWidth="100%"
      height="100%"
      maxHeight="38px"
      columnGap="10px"
    >
      {icon ? <S.IconWrapper>{icon}</S.IconWrapper> : ''}
      <FormControl size="small" sx={{ width: width ? width : '80%' }}>
        <InputLabel sx={{ fontFamily: 'Urbanist' }}>{title}</InputLabel>

        <Select
          label={title}
          onChange={handleChange}
          MenuProps={MenuProps}
          value={value}
          sx={{ fontFamily: 'Urbanist' }}
        >
          {!withoutNoOneOpt && (
            <MenuItem value="" sx={{ fontFamily: 'Urbanist' }}>
              <em>Nenhum</em>
            </MenuItem>
          )}

          {options.map(el => (
            <MenuItem
              value={el.value}
              key={el.value}
              disabled={el.notSelectable}
              sx={{ fontFamily: 'Urbanist' }}
            >
              {el.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export { NewDropdown };
