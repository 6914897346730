import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 4rem;

  button {
    margin-right: 0.5rem;
    color: var(--grey-700);

    svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  p {
    font-size: 0.85rem;
    font-weight: 500;
    color: var(--grey-400);
  }

  @media (max-width: 480px) {
    p {
      padding-right: 0.75rem;
    }
  }
`;
