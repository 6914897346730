import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as Bedroom } from 'common/assets/svgs/bedroom.svg';
import { ReactComponent as Building } from 'common/assets/svgs/building.svg';
import { ReactComponent as House } from 'common/assets/svgs/house.svg';
import { ReactComponent as Location } from 'common/assets/svgs/location.svg';
import { ReactComponent as MaxPrice } from 'common/assets/svgs/max_price.svg';
import { ReactComponent as MinPrice } from 'common/assets/svgs/min_price.svg';
import { Button, Checkbox, DefaultPageTitle } from 'components/Atoms';
import { useSearch } from 'hooks/search';
import useInputMask from 'hooks/useInputMask';
import { DomUtil } from 'leaflet';
import { IDropdownOption } from 'models/IDropdownOption';
import { IPriceFormData } from 'models/IPriceFormData';

import { InputWithIcon, NewDropdown } from '..';

import CloseButton from '../../Atoms/CloseButton';
import { CustomAutocomplete } from '../CustomAutocomplete';
import NewMultipleDropdown from '../NewMultipleDropdown';
import * as S from './styles';

function SearchFilter() {
  const { onMoneyMask, removeMoneyMask, handleMoneyMask } = useInputMask();

  const {
    cities,
    districts,
    updateFilter,
    filter,
    selectedCity,
    setSelectedCity,
    selectedDistrict,
    setSelectedDistrict,
    selectedDistrictSeveral,
    resetFilters,
    keys,
    setKeys,
    allKey,
    setAllKey,
    temporarySelectedDistricts,
    setTemporarySelectedDistricts,
  } = useSearch();

  const [type, setType] = useState<string | undefined>(filter?.type);
  const [bedrooms, setBedrooms] = useState<IDropdownOption | undefined>(() => {
    if (filter?.bedrooms) {
      return { label: `${filter.bedrooms}`, value: `${filter.bedrooms}` };
    }

    return undefined;
  });

  const { register, handleSubmit, control } = useForm<IPriceFormData>();

  const [selectVarios, setSelectVarios] = useState<any>(
    selectedDistrictSeveral ? selectedDistrictSeveral : [],
  );
  const [initialValueDistrict, setValueDistrict] = useState<any>(
    filter?.district?.length
      ? {
          value: filter.district.split(',')[0],
          label: filter.district.split(',')[0],
        }
      : [],
  );

  const [openModal, setOpenModal] = useState(false);

  const onSubmit = useCallback(
    ({ min_value, max_value }: IPriceFormData) => {
      // if (!selectedCity && !type) return;
      let dataDistrict = selectedDistrict;
      let districtSlug = dataDistrict;

      if (selectedDistrict === 'varios') {
        districtSlug = '';
        if (selectedDistrictSeveral) {
          selectedDistrictSeveral?.map((dt: any) => {
            if (districtSlug) {
              districtSlug += ',' + dt.value;
            } else {
              districtSlug = dt.value;
            }
          });
        } else {
          selectVarios?.map((dt: any) => {
            if (districtSlug) {
              districtSlug += ',' + dt.value;
            } else {
              districtSlug = dt.value;
            }
          });
        }
        dataDistrict = districtSlug;
      }
      localStorage.setItem('page', '1');
      updateFilter({
        ...filter,
        type: type,
        bedrooms: Number(bedrooms?.value),
        min_value: removeMoneyMask(min_value),
        max_value: removeMoneyMask(max_value),
        district: dataDistrict,
      });
    },
    [
      selectedCity,
      type,
      bedrooms,
      selectedDistrict,
      selectedDistrictSeveral,
      selectVarios,
    ],
  );

  const getDistrictOptions = (showFirst = false) => {
    if (districts.length === 1)
      return [
        {
          label: 'Sem cidade',
          value: 'Sem cidade',
          notSelectable: true,
          notSelectableMessage:
            'Não há imóveis cadastrados em um bairro nesta cidade',
        },
      ];

    return [...districts];
  };

  const selectDisctrict = (value: any) => {
    if (value === 'varios') {
      setOpenModal(true);
    }
    setSelectedDistrict(value);
  };

  const variosSelect = (value: any) => {
    setSelectVarios(value);
    setTemporarySelectedDistricts(value);
  };

  /*const handleClickOpen = () => {
    setOpen(true);
  };*/

  function closeModal() {
    setOpenModal(false);
  }

  useEffect(() => {
    if (keys.general > 1) {
      setSelectVarios([]);
    }
  }, [keys.general]);

  return (
    <S.Container>
      <S.Wrapper>
        <S.OptionFilter className="option">
          <CustomAutocomplete
            icon={<Location />}
            title={'Cidade'}
            options={[...cities]}
            initialValue={filter?.city}
            key={keys.general}
            onSelectOption={(opt: IDropdownOption) => {
              setSelectedCity(opt);
              setKeys({ ...keys, bairro: keys.bairro + 1 });
            }}
          />
        </S.OptionFilter>
        <S.OptionFilter className="option">
          <CustomAutocomplete
            multiple
            key={keys.bairro}
            renderTags={() => ''}
            icon={<Building />}
            title={
              temporarySelectedDistricts.length
                ? `+${temporarySelectedDistricts?.length} selecionados`
                : 'Bairro'
            }
            initialValue={
              temporarySelectedDistricts
              // selectVarios.length ? [] : initialValueDistrict
              /*filter?.district?.length ? filter.district.split(',') : []*/
            }
            options={[...getDistrictOptions()]}
            onSelectOption={(opt: any, e: any) => {
              selectDisctrict(opt.value);
              if ((e.target.innerText as string).includes('Selecione vários')) {
                setOpenModal(true);
                return;
              }

              // selectDisctrict(opt.value);
              setTemporarySelectedDistricts(
                opt.filter((item: any) => item.value !== 'varios'),
              );
            }}
          />
        </S.OptionFilter>

        <S.OptionFilter className="option">
          <NewDropdown
            icon={<House />}
            title={'Tipos de imóveis'}
            options={[
              {
                label: 'Área',
                value: 'Área',
              },
              {
                label: 'Casa',
                value: 'Casa',
              },
              {
                label: 'Apartamento',
                value: 'Apartamento',
              },
              {
                label: 'Sobrado',
                value: 'Sobrado',
              },
              {
                label: 'Lote',
                value: 'Lote',
              },
              {
                label: 'Fazenda',
                value: 'Fazenda',
              },
              {
                label: 'Chácara',
                value: 'Chácara',
              },
              {
                label: 'Galpão',
                value: 'Galpão',
              },
              {
                label: 'Sala comercial',
                value: 'Sala comercial',
              },
            ]}
            initialValue={type}
            onSelectOption={opt => setType(opt.label)}
          />
        </S.OptionFilter>

        <S.OptionFilter className="option">
          <NewDropdown
            icon={<Bedroom />}
            title={'Quartos'}
            options={[
              {
                label: '1',
                value: 1,
              },
              {
                label: '2',
                value: 2,
              },
              {
                label: '3',
                value: 3,
              },
              {
                label: '4',
                value: 4,
              },
              {
                label: '5',
                value: 5,
              },
              {
                label: '6',
                value: 6,
              },
              {
                label: '7',
                value: 7,
              },
              {
                label: '8',
                value: 8,
              },
              {
                label: '9',
                value: 9,
              },
              {
                label: '10',
                value: 10,
              },
              {
                label: '11',
                value: 11,
              },
              {
                label: '12',
                value: 12,
              },
              {
                label: '13',
                value: 13,
              },
              {
                label: '14',
                value: 14,
              },
              {
                label: '15',
                value: 15,
              },
              {
                label: '16',
                value: 16,
              },
              {
                label: '17',
                value: 17,
              },
              {
                label: '18',
                value: 18,
              },
              {
                label: '19',
                value: 19,
              },
              {
                label: '20',
                value: 20,
              },
            ]}
            initialValue={bedrooms?.value.toString()}
            onSelectOption={opt => setBedrooms(opt)}
          />
        </S.OptionFilter>

        <S.OptionFilter className="option">
          <InputWithIcon
            icon={<MinPrice />}
            placeholder="Preço minimo"
            name="min_value"
            defaultValue={
              filter?.min_value
                ? handleMoneyMask(String(filter?.min_value))
                : undefined
            }
            onChange={onMoneyMask}
            ref={register}
          />
        </S.OptionFilter>

        <S.OptionFilter className="option">
          <InputWithIcon
            icon={<MaxPrice />}
            placeholder="Preço máximo"
            name="max_value"
            defaultValue={
              filter?.max_value
                ? handleMoneyMask(String(filter?.max_value))
                : undefined
            }
            onChange={onMoneyMask}
            ref={register}
          />
        </S.OptionFilter>
      </S.Wrapper>

      <Button
        color="blue"
        type="submit"
        onClick={handleSubmit(onSubmit)}
        aria-label="Pesquisar"
        name="search-btn"
      >
        Pesquisar
      </Button>

      <Dialog
        maxWidth={'md'}
        fullWidth={true}
        open={openModal}
        onClose={() => closeModal()}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h2>Filtro</h2>
            <CloseButton onClick={() => closeModal()} />
          </div>
          {/* </IconButton> */}
        </DialogTitle>
        <DialogContent style={{ overflowY: 'unset' }}>
          <DialogContentText>Filtro de bairros</DialogContentText>
          <Box noValidate component="form" sx={{ mt: 2, minHeight: 300 }}>
            <CustomAutocomplete
              multiple={true}
              key={keys.bairro}
              icon={<Building />}
              title={'Bairro'}
              initialValue={
                temporarySelectedDistricts
                // filter?.district?.length ? filter.district.split(',') : []
              }
              options={[
                ...getDistrictOptions().filter(dt => dt.value !== 'varios'),
              ]}
              onSelectOption={(opt: any) => {
                // variosSelect(opt);
                setTemporarySelectedDistricts(
                  opt.filter((item: any) => item.value !== 'varios'),
                );
                setKeys({ ...keys, bairro: keys.bairro + 1 });
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color={'blue'} onClick={() => closeModal()}>
            Selecionar
          </Button>
        </DialogActions>
      </Dialog>
    </S.Container>
  );
}

export { SearchFilter };
