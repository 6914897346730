import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Box, TextField, useMediaQuery } from '@mui/material';

import { Button, DefaultPageTitle } from '../../components/Atoms';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import * as S from './styles';

interface IChangePassword {
  password?: string;
  newPassword: string;
  repeatPassword: string;
}
function ChangePassword() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const matches = useMediaQuery('(max-width: 884px)');
  const { handleSubmit, control, errors, reset } = useForm();
  const { addToast } = useToast();
  const onSubmit = useCallback(
    ({ newPassword, repeatPassword }: IChangePassword) => {
      if (newPassword !== repeatPassword) {
        addToast({
          title: 'Senhas diferentes',
          type: 'error',
        });
        return;
      }

      const formData = new FormData();
      formData.append('password', newPassword);

      api
        .post(`/users/updatePassword`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          setSubmitDisabled(false);
          addToast({
            title: 'Usuário editado com sucesso!',
            type: 'success',
          });
          setTimeout(() => {
            logout();
          }, 2000);
        })
        .catch(err => {
          addToast({
            title: 'Ocorreu um erro ao cadastrar!',
            description: err.response.data.message,
            type: 'error',
          });
          setSubmitDisabled(false);
        });
    },
    [],
  );

  return (
    <Box
      width="100%"
      padding="24px 26px"
      border="1px solid var(--primary-neutral-300)"
      borderRadius="16px"
      sx={{ background: 'var(--white)' }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={matches ? '14px' : '30px'}
      >
        <DefaultPageTitle>Alterar senha</DefaultPageTitle>
      </Box>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" width="420px" rowGap="18px">
          <Controller
            name="newPassword"
            rules={{ required: true }}
            control={control}
            defaultValue=""
            render={props => (
              <Box>
                <TextField
                  type="password"
                  placeholder="Digite a nova senha do usuário"
                  label="Nova Senha *"
                  variant="standard"
                  sx={{ width: '100%' }}
                  {...props}
                />
                {errors.newPassword && (
                  <S.ErrorMessage>* Informe a senha</S.ErrorMessage>
                )}
              </Box>
            )}
          />

          <Controller
            name="repeatPassword"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={props => (
              <Box>
                <TextField
                  type="password"
                  placeholder="Repita a senha do usuário"
                  label="Repetir senha *"
                  variant="standard"
                  sx={{ width: '100%' }}
                  {...props}
                />
                {errors.repeatPassword && (
                  <S.ErrorMessage>* Repita a senha</S.ErrorMessage>
                )}
              </Box>
            )}
          />

          <Box
            display="flex"
            flexDirection="column"
            rowGap="12px"
            marginTop="18px"
          >
            <Button
              color="high_blue"
              type="submit"
              full_width
              disabled={submitDisabled}
            >
              Salvar
            </Button>

            <Button color="white" type="button" full_width onClick={reset}>
              Cancelar
            </Button>
          </Box>
        </Box>
      </S.Form>
    </Box>
  );
}

export { ChangePassword };
export default ChangePassword;
