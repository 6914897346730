import { Typography } from '@mui/material';

import LabelRequiredIndicator from '../LabelRequiredIndicator';

export interface LabelProps {
  children?: React.ReactNode;
  required?: boolean;
}

export default function Label({ children, required }: LabelProps) {
  return (
    <Typography
      {...{
        fontSize: '14px',
        fontWeight: '600',
        color: 'var(--primary-neutral-900)',
      }}
    >
      {children}
      {required && <LabelRequiredIndicator />}
    </Typography>
  );
}
