import styled from 'styled-components';

export const CreditBox = styled.div`
  background-color: var(--alpha-green-10);
  border: 2px solid var(--green-400);
  border-radius: 10px;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
  }

  header {
    padding: 24px 24px;
    text-align: center;

    @media (min-width: 1024px) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  main {
    padding: 20px;
    border-top: 2px solid var(--green-400);

    @media (min-width: 1024px) {
      &:first-of-type {
        border-right: 2px solid var(--green-400);
      }
    }

    h5 {
      font-weight: bold;
      font-size: 1.1rem;
    }
  }

  ul {
    list-style: none;

    li {
      color: var(--green-400);
      display: flex;
      align-items: center;
      margin-top: 20px;

      span {
        color: var(--grey-400);
        font-size: 1rem;
        margin-left: 10px;
      }
    }
  }

  a,
  button {
    text-decoration: none;
    background-color: var(--green-400);
    color: white;
    font-size: 1.2rem;
    text-align: left;
    border-radius: 10px;
    border: none;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 0 20px;
    width: calc(100% - 40px);
    transition: 0.2s;

    &:hover {
      background-color: var(--green-500);
    }

    @media (min-width: 1024px) {
      margin: 0;
      width: 100%;
    }
  }
`;
