import { Property } from '@types';
import property_docs from 'common/constants/property_docs';
import { ContextPageSize } from 'pdfmake/interfaces';

import {
  colors,
  getBase64FromUrl,
  greyText,
  infoSection,
  logo,
  text,
  title,
} from '../actions';
import footerBase64 from './footer_base64';
import pointerBase64 from './pointer_base64';

export default async function renderPropertyDocs(
  property: Property,
) /**: TDocumentDefinitions */ {
  return {
    background: function (_: number, pageSize: ContextPageSize) {
      return {
        image: footerBase64,
        margin: [0, pageSize.height - 100, 0, 0],
        height: 90,
        width: 600,
      };
    },
    footer: {
      margin: [40, 0, 40, 0],
      gap: 10,
      columnGap: 10,
      columns: [
        ...logo(),
        ...greyText(
          'A empresa tem como proposito, levar moradia de qualidade ao mundo, ajudando as pessoas e suas famílias a encontrarem o imóvel ideal, e realizarem o sonho da casa própria, trazendo qualidade de vida, alegria e satisfação.',
        ),
      ],
    },

    content: [
      {
        columnGap: 310,
        columns: [
          ...logo(),
          {
            table: {
              alignment: 'right',
              body: [
                [
                  {
                    text: `Código: ${property.code}`,
                    fontSize: text.base,
                    fillColor: colors.lightBlue,
                    fillOpacity: 0.1,
                    color: colors.lightBlue,
                  },
                ],
              ],
            },
            layout: {
              hLineColor: function () {
                return colors.lightBlue;
              },
              vLineColor: function () {
                return colors.lightBlue;
              },
            },
          },
        ],
      },
      ...infoSection(property_docs.title, property_docs.docs),
    ],
  };
}
