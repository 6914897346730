import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 25px;

  @media (min-width: 1050px) {
    flex-direction: row;
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 12px;

  @media (min-width: 481px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1440px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const OptionFilter = styled.div`
  display: flex;
  column-gap: 0.75rem;
  height: 38px;
  width: 100%;
`;

export const IconWrapper = styled.div`
  width: 0.1rem;
`;

export const ContentButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;

  button {
    width: 50%;
  }

  @media (min-width: 1050px) {
    flex-direction: column;
    width: 100%;

    button {
      width: auto;
    }
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
