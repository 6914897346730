import styled from 'styled-components';

export type ButtonParams = {
  variant?: 'green';
  invertColors?: boolean;
};

export const Button = styled.button<ButtonParams>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 20px;
  font-size: 0.9rem;
  gap: 8px;
  font-weight: 500;
  transition: 0.2s;
  border: 2px solid var(--blue-100);
  color: ${({ invertColors }) => (invertColors ? 'white' : 'var(--blue-100)')};
  background-color: ${({ invertColors }) =>
    invertColors ? 'var(--blue-200)' : 'var(--blue-10)'};

  &:hover {
    color: ${({ invertColors }) =>
      invertColors ? 'var(--blue-100)' : 'white'};
    background-color: ${({ invertColors }) =>
      invertColors ? 'var(--blue-10)' : 'var(--blue-100)'};
  }

  ${({ variant }) =>
    variant === 'green' &&
    `
    border: 2px solid var(--green-400);
    color: var(--green-400);
    background-color: var(--alpha-green-10);

    &:hover {
      background-color: var(--green-400);
    }
  `}

  @media (max-width: 1024px) {
    flex: 1;
    min-width: max-content;
    max-width: 100%;
  }
`;
