/* eslint-disable jsx-a11y/media-has-caption */
import { useState } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

import { Property } from '@types';
import Str from 'common/utils/Str';
import styled from 'styled-components';

export type UseVideoDisplayDataProps = {
  property?: Property;
  currentVideo: number;
  setCurrentVideo: (v: number) => void;
};

export function useVideoDisplay(data: { property?: Property }) {
  const [currentVideo, setCurrentVideo] = useState(0);

  return {
    props: {
      property: data.property,
      currentVideo,
      setCurrentVideo,
    } as UseVideoDisplayDataProps,
  };
}

const VideoDisplayContainer = styled.div`
  height: 100%;
  width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > video,
  > img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const VideoDisplay = ({
  property,
  currentVideo,
}: UseVideoDisplayDataProps) => {
  return (
    <VideoDisplayContainer>
      {!!property?.videos?.length &&
      Str.isYoutubeUrl(property.videos[currentVideo].src) ? (
        <iframe
          width="100%"
          height="100%"
          src={Str.handleEmbedYoutubeVideoUrl(
            property.videos[currentVideo].src,
          )}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      ) : property?.videos?.length ? (
        <video src={property.videos[currentVideo].src} controls>
          Your browser does not support HTML5 video.
        </video>
      ) : (
        <div></div>
      )}
    </VideoDisplayContainer>
  );
};

const QtdMessage = styled.div`
  color: var(--grey-400);
  text-align: center;
  margin: 14px;
  display: block;
`;

export const VideoSubMessage = ({
  property,
  currentVideo,
}: UseVideoDisplayDataProps) => {
  if (!property?.videos?.length) return <></>;

  return (
    <QtdMessage>
      {currentVideo + 1} de {property.videos.length} videos
    </QtdMessage>
  );
};

const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 100vw;
  width: 100vw;
  overflow-x: scroll;
  padding-bottom: 15px;

  @media (min-width: 1280px) {
    max-width: calc(100vw - 236px);
    width: calc(100vw - 236px);
  }

  &::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }
  &::-webkit-scrollbar-thumb {
    background-color: grey; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }

  .property-Video-container {
    height: 90px;
    width: 120px;
    min-height: 80px;
    min-width: 136px;
    max-height: 80px;
    max-width: 136px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      filter: brightness(0.9);
    }

    &:first-of-type {
      margin-left: auto;
    }

    &:last-of-type {
      margin-right: auto;
    }

    > video,
    > img {
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
      margin: auto auto;
      display: block;
    }
  }
`;

export const VideoFooter = ({
  property,
  currentVideo,
  setCurrentVideo,
}: UseVideoDisplayDataProps) => {
  if (!property?.videos?.length) return <></>;

  return (
    <Footer>
      {property.videos.map((video, i) => (
        <div
          key={video.id}
          className="property-Video-container"
          onClick={() => setCurrentVideo(i)}
          style={{
            opacity:
              !!property?.videos?.length &&
              property?.videos[currentVideo].id === video.id
                ? '0.3'
                : '1',
          }}
        >
          {Str.isYoutubeUrl(video.src) ? (
            <img
              src={Str.handleYoutubeVideoThumbnailFromUrl(video.src)}
              alt={video.src!}
            />
          ) : (
            <video src={video.src}>
              Your browser does not support HTML5 video.
            </video>
          )}
        </div>
      ))}
    </Footer>
  );
};

const SwitchBtnContainer = styled.div`
  display: none;

  @media (min-width: 1280px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  > button {
    border: none;
    border-radius: 100%;
    padding: 5px 7px 0 7px;
    color: var(--blue-400);
    font-size: 30px;
    transition: 0.2s;

    &:hover {
      background-color: var(--blue-400);
      color: white;
    }
  }
`;

export const VideoSwitchBtnRight = ({
  property,
  currentVideo,
  setCurrentVideo,
}: UseVideoDisplayDataProps) => {
  return (
    <SwitchBtnContainer>
      <button
        type="button"
        onClick={() => {
          if ((property?.videos?.length ?? Infinity) > currentVideo + 1) {
            setCurrentVideo(currentVideo + 1);
          }
        }}
      >
        <BiChevronRight />
      </button>
    </SwitchBtnContainer>
  );
};

export const VideoSwitchBtnLeft = ({
  currentVideo,
  setCurrentVideo,
}: UseVideoDisplayDataProps) => {
  return (
    <SwitchBtnContainer>
      <button
        onClick={() => {
          if (currentVideo > 0) {
            setCurrentVideo(currentVideo - 1);
          }
        }}
      >
        <BiChevronLeft />
      </button>
    </SwitchBtnContainer>
  );
};
