import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SweetAlert2 from 'react-sweetalert2';

import no_image from 'common/assets/images/no_image.jpg';
import { ReactComponent as Check } from 'common/assets/svgs/check_correct.svg';
import { ReactComponent as Edit } from 'common/assets/svgs/edit.svg';
import { ReactComponent as EyeIcon } from 'common/assets/svgs/eye-icon.svg';
import { ReactComponent as Block } from 'common/assets/svgs/icons8-block-24.svg';
import { ReactComponent as Menu } from 'common/assets/svgs/menu.svg';
import { ReactComponent as Trash } from 'common/assets/svgs/trash_blue.svg';
import { controlHideDiv } from 'common/utils/controlHideDiv';
import { getMonthName } from 'common/utils/getMonthName';
import { routesNames } from 'common/utils/routesNames';
import { getYear, getMonth } from 'date-fns';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { IUser } from 'models/IAuth';
import api from 'services/api';

import * as S from './styles';

interface IUserBoxProps extends IUser {
  onDeleteUser: () => void;
  onDisableUser?: () => void;
}

function UserBox({
  photo,
  name,
  type,
  created_at,
  id,
  status,
  onDeleteUser,
  onDisableUser,
}: IUserBoxProps) {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [swalProps, setSwalProps] = useState({});
  const { userData } = useAuth();

  const ref = useRef(null);
  const { addToast } = useToast();

  const month = getMonthName(getMonth(new Date(created_at)));
  const year = getYear(new Date(created_at));

  useEffect(() => {
    if (ref !== null) controlHideDiv(ref, () => setActionsVisible(false));
  }, [ref]);

  function onResetPassword() {
    setSwalProps({
      show: true,
      title: 'Redefinir senha?',
      text: 'Deseja realmente redefinir a senha deste usuário?',
      icon: 'warning',
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#1DAEFF',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      onConfirm: () => handleResetPassword(),
    });
  }

  function handleResetPassword() {
    api.put(`/users/${id}/reset-password-default`).then(() => {
      addToast({
        type: 'info',
        title: `Senha redefinida com sucesso.`,
      });
    });
  }

  const handleDeleteUser = () => {
    api.delete(`/users/${id}`).then(() => {
      onDeleteUser();

      addToast({
        type: 'info',
        title: `O usuário ${name} foi apagado do sistema`,
      });
    });
  };

  const onDisable = () => {
    const formData = new FormData();
    // @ts-ignore
    formData.append('disabled', !status);
    api
      .post(`/users/disable/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        addToast({
          title: 'Usuário desabilitado com sucesso!',
          type: 'success',
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(err => {
        addToast({
          title: 'Ocorreu um erro ao desabilitar!',
          description: err.response.data.message,
          type: 'error',
        });
      });
  };

  const onDelete = () => {
    setSwalProps({
      show: true,
      title: 'Ops!',
      text: 'Deseja realmente deletar esse usuário?',
      icon: 'warning',
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#1DAEFF',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      onConfirm: () => handleDeleteUser(),
    });
  };

  return (
    <>
      <S.Container
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <S.UserData photo={photo || no_image}>
          <div className="photo" />

          <span className="user-name">{name}</span>
          <span className="user-description">{type.description}</span>

          <div className="since">
            <span>
              Cadastrado no sistema em <em>{month}</em> de <em>{year}</em>
            </span>
          </div>
        </S.UserData>
        {isHovered && (
          <S.ActionsButton
            type="button"
            onClick={() => setActionsVisible(true)}
          >
            <Menu />
          </S.ActionsButton>
        )}
        {actionsVisible && (
          <S.ActionsContainer ref={ref}>
            <button
              type="button"
              onClick={() => navigate(`/detalhe-usuario/${id}`)}
            >
              <EyeIcon /> Detalhes do usuário
            </button>
            <button
              type="button"
              onClick={() => navigate(`/editar-usuario/${id}`)}
            >
              <Edit /> Editar usuário
            </button>
            <button type="button" onClick={onDelete}>
              <Trash /> Excluir Usuário
            </button>
            {userData.type_id == 4 && (
              <button type="button" onClick={onResetPassword}>
                <Edit /> Redefinir senha
              </button>
            )}
            {status ? (
              <button type="button" className="delete" onClick={onDisable}>
                <Block /> <span>Desabilitar</span>
              </button>
            ) : (
              <button type="button" className="delete" onClick={onDisable}>
                <Check /> <span>Habilitar</span>
              </button>
            )}
          </S.ActionsContainer>
        )}
      </S.Container>

      <SweetAlert2
        {...swalProps}
        didClose={() => {
          setSwalProps({
            show: false,
          });
        }}
      />
    </>
  );
}

export { UserBox };
