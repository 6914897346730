import { useState } from 'react';
import { FiCheckSquare } from 'react-icons/fi';

import { Property } from '@types';
import useProperty from 'hooks/useProperty';

import * as S from './style';

export type CharacteristicsProps = {
  property?: Property;
  denyCollapse?: boolean;
};
export default function Characteristics({
  property,
  denyCollapse,
}: CharacteristicsProps) {
  const [isOpen, setIsOpen] = useState(false);
  if (!property) {
    return <span>Carregando...</span>;
  }

  if (denyCollapse) {
    return <Chars property={property} />;
  }

  return (
    <S.Container isOpen={isOpen}>
      <Chars property={property} />

      <S.ShowButton
        type="button"
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? 'Ver menos características' : 'Ver mais características'}
      </S.ShowButton>
    </S.Container>
  );
}

function InfoItem({ label }: any) {
  return (
    <div>
      <span style={{ color: 'var(--blue-400)' }}>
        <FiCheckSquare size={22} />
      </span>
      <span>{label}</span>
    </div>
  );
}

const Chars = ({ property }: { property: Property }) => {
  const p = useProperty();

  const validPropertyDetail = (name: string): boolean => {
    if ((property as any)[name] !== 0 && (property as any)[name] !== null)
      return true;

    return false;
  };

  return (
    <S.Characteristics>
      {(validPropertyDetail('contruct_area') ||
        validPropertyDetail('land_area') ||
        validPropertyDetail('bedrooms') ||
        validPropertyDetail('bathrooms') ||
        !!p.mapKeysLabels(property as any).info.caracteristicas.data
          ?.length) && <span>Descrição e características</span>}

      <S.CharacteristicsGrid>
        {validPropertyDetail('contruct_area') && (
          <div className="constructed_area">
            <span style={{ color: 'var(--blue-400)' }}>
              <FiCheckSquare size={22} />
            </span>
            <span>{property.contruct_area} m² construídos</span>
          </div>
        )}

        {validPropertyDetail('land_area') && (
          <div className="total_area">
            <span style={{ color: 'var(--blue-400)' }}>
              <FiCheckSquare size={22} />
            </span>
            <span>
              {property.land_area}{' '}
              {property.type_footage === 'hectares' ? 'hectares' : 'm²'} total
            </span>
          </div>
        )}

        {validPropertyDetail('bathrooms') && (
          <div className="bathrooms">
            <span style={{ color: 'var(--blue-400)' }}>
              <FiCheckSquare size={22} />
            </span>
            <span>
              {property.bathrooms > 1
                ? `${property.bathrooms} banheiros`
                : `${property.bathrooms} banheiro`}{' '}
            </span>
          </div>
        )}

        {validPropertyDetail('bedrooms') && (
          <div className="bedrooms">
            <span style={{ color: 'var(--blue-400)' }}>
              <FiCheckSquare size={22} />
            </span>
            <span>
              {property.bedrooms > 1
                ? `${property.bedrooms} quartos`
                : `${property.bedrooms} quarto`}{' '}
            </span>
          </div>
        )}

        {validPropertyDetail('suites') && (
          <div className="suites">
            <span style={{ color: 'var(--blue-400)' }}>
              <FiCheckSquare size={22} />
            </span>
            <span>
              {property.suites > 1
                ? `${property.suites} Suites`
                : `${property.suites} Suite`}{' '}
            </span>
          </div>
        )}
        {!!p.mapKeysLabels(property as any).info.caracteristicas.data
          ?.length && (
          <>
            {p
              .mapKeysLabels(property as any)
              .info.caracteristicas.data.map((field: any, index: number) => (
                <InfoItem
                  key={index}
                  label={field.displayLabel || field.label}
                  icon={field.icon}
                />
              ))}
          </>
        )}
        <br />
        <br />
      </S.CharacteristicsGrid>

      {!!p.mapKeysLabels(property as any).info.lazerEConveniencia.data
        ?.length && (
        <>
          <span>Lazer e conveniência</span>
          <S.CharacteristicsGrid>
            {p
              .mapKeysLabels(property as any)
              .info.lazerEConveniencia.data.map((field: any, index: number) => (
                <InfoItem
                  key={index}
                  label={field.displayLabel || field.label}
                  icon={field.icon}
                />
              ))}
          </S.CharacteristicsGrid>
          <br />
        </>
      )}

      {!!p.mapKeysLabels(property as any).info.seguranca.data?.length && (
        <>
          <span>Segurança</span>
          <S.CharacteristicsGrid>
            {p
              .mapKeysLabels(property as any)
              .info.seguranca.data.map((field: any, index: number) => (
                <InfoItem
                  key={index}
                  label={field.displayLabel || field.label}
                  icon={field.icon}
                />
              ))}
          </S.CharacteristicsGrid>
          <br />
        </>
      )}

      {!!p.mapKeysLabels(property as any).info.loteArea.data?.length && (
        <>
          <span>Informações do terreno</span>
          <S.CharacteristicsGrid>
            {p
              .mapKeysLabels(property as any)
              .info.loteArea.data.map((field: any, index: number) => (
                <InfoItem
                  key={index}
                  label={field.displayLabel || field.label}
                  icon={field.icon}
                />
              ))}
          </S.CharacteristicsGrid>
          <br />
        </>
      )}

      {!!p.mapKeysLabels(property as any).info.rural.data?.length && (
        <>
          <span>Informações rurais</span>
          <S.CharacteristicsGrid>
            {p
              .mapKeysLabels(property as any)
              .info.rural.data.map((field: any, index: number) => (
                <InfoItem
                  key={index}
                  label={field.displayLabel || field.label}
                  icon={field.icon}
                />
              ))}
          </S.CharacteristicsGrid>
          <br />
        </>
      )}

      {!!p.mapKeysLabels(property as any).info.formasDePagamento.data
        ?.length && (
        <>
          <span>Formas de pagamento e documentação</span>
          <S.CharacteristicsGrid>
            {p
              .mapKeysLabels(property as any)
              .info.formasDePagamento.data.map((field: any, index: number) => (
                <InfoItem
                  key={index}
                  label={field.displayLabel || field.label}
                  icon={field.icon}
                />
              ))}
          </S.CharacteristicsGrid>
          <br />
        </>
      )}
    </S.Characteristics>
  );
};
