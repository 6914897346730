import api, { ApiResponse } from './api';

export default {
  async destroy(id: number): Promise<ApiResponse<null>> {
    try {
      const { status } = await api.delete(`/catalogo/${id}`);
      return {
        isError: false,
        isUnprocessableEntity: false,
        code: status,
        data: null,
      };
    } catch (error) {
      return {
        isError: true,
        isUnprocessableEntity: (error as any)?.response?.status == 422,
        code: (error as any)?.response?.status,
        data: (error as any)?.response?.data,
      };
    }
  },
};
