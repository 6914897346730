import { ReactComponent as BagTick } from 'common/assets/svgs/bag_tick.svg';
import { ReactComponent as ShieldTick } from 'common/assets/svgs/shield_tick.svg';

import * as S from './styles';

function AntecipateStage() {
  return (
    <S.Container>
      <div className="content-wrapper">
        <div className="card-header">
          <span>Antecipe essa etapa</span>
          <S.Link
            className="call-on-wpp"
            href={`https://wa.me/+556239991400?text=${encodeURI(
              'Olá, gostaria de fazer uma análise.',
            )}`}
            target="_blank"
            rel="noreferrer"
            aria-label="Fazer análise"
            name="make-analysis"
          >
            Fazer análise
          </S.Link>
          {/* <Button color="green">Fazer análise</Button> */}
        </div>

        <p>
          Faça a sua avaliação de crédito sem compromisso, com resultado em até
          48 horas!
        </p>

        <S.Link
          className="call-on-wpp"
          href={`https://wa.me/+556239991400?text=${encodeURI(
            'Olá, gostaria de fazer uma análise.',
          )}`}
          target="_blank"
          rel="noreferrer"
          aria-label="Fazer análise"
          name="make-analysis"
        >
          Fazer análise
        </S.Link>
      </div>

      <S.CardFooter>
        <div>
          <ShieldTick />
          <span>Sem nenhum custo e totalmente seguro</span>
        </div>

        <div>
          <BagTick />
          <span>Não compartilhamos essas informações.</span>
        </div>
      </S.CardFooter>
    </S.Container>
  );
}

export { AntecipateStage };
