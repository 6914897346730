/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

export const Link = styled.a<{ name?: string }>``;

export const Container = styled.div<{ open: boolean }>`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  //width: 100%;

  @media (max-width: 884px) {
    ${props =>
      props.open &&
      css`
        margin-bottom: 320px;
      `}
  }
`;

export const AgentInfo = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;

  column-gap: 0.5rem;

  height: 6.25rem;
  width: 100%;

  margin-bottom: 1.5rem;

  border-radius: 1rem;
  border: 1px dashed var(--green-400);

  cursor: pointer;

  span {
    color: var(--grey-400);
    font-weight: 500;
  }
`;

export const Info = styled.span`
  display: flex;
  align-items: center;
  font-size: 0.75rem;

  svg {
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.75rem;
  }
`;

export const InfoBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  //width: 100%;
  z-index: 9999;
  height: fit-content;

  border: 1px dashed var(--green-400);
  border-radius: 15px;

  padding: 18px;
  background: white;

  h4 {
    font-size: 16px;
    font-weight: 700;
  }

  > .marginTop40 {
    margin-top: 40px;
  }
  > .marginTop10 {
    margin-top: 10px;
  }
  > .marginTop20 {
    margin-top: 20px;
  }
`;

export const InfoBoxModal = styled.div`
  top: 0;
  left: 0;

  width: 100%;
  max-width: 640px;

  max-height: calc(100vh - 60px);
  height: fit-content;
  overflow: auto;

  background: white;

  padding: 20px;

  h4 {
    font-size: 16px;
    font-weight: 700;
  }

  > .marginTop40 {
    margin-top: 40px;
  }
  > .marginTop10 {
    margin-top: 10px;
  }
  > .marginTop20 {
    margin-top: 20px;
  }
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 18px;

  > div {
    display: flex;
    align-items: center;
    column-gap: 14px;
    width: 100%;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    background: none;
    border: none;

    width: 24px;
    height: 24px;
  }

  svg {
    transform: rotate(180deg);
  }
`;

export const ListInfos = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 18px;

  > div {
    display: flex;
    column-gap: 8px;

    width: 100%;
  }

  > div:last-child {
    padding-bottom: 36px;
    border-bottom: 1px dashed #d1d5db;
  }

  span {
    font-size: 14px;
  }

  span.title {
    color: var(--grey-400);
  }

  span.value {
    color: var(--grey-700);
    font-weight: 700;
  }

  a {
    color: var(--grey-700);
  }
`;

export const NotifyStatus = styled.section`
  margin: 12px 0 18px;

  > div {
    display: flex;
    align-items: center;
    column-gap: 12px;

    margin-top: 18px;
  }
`;
