import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --white: #fff;
    --black: #000;

    --white100: #fafafa;

    --brand-200: #B7CFFC;

    --black-700: #101114;
    --black-400: #282828;
    --black-200: #454652;

    --grey-700: #2B2C33;
    --grey-600: #535b5f;
    --grey-400: #7C7A80;
    --grey-300: #B7B7B7;
    --grey-200: #67687026;
    --grey-150: #E5E5E5;
    --grey-100: #EBEBEB;
    --grey-80: #F4F4F4;
    --grey-75: #F7F7F7;
    --grey-50: #F1F1F1;

    --blue-500: #0C4A88;
    --blue-450: #4A74EA;
    --blue-400: #0073B5;
    --blue-300: #01579b;
    --blue-200: #00A3FF;
    --blue-100: #1DAEFF;
    --blue-10: #0073B514;

    --brand-100: #DBE8FD;
    --brand-400: #769AF2;

    --yellow-500: #ffeb3b;
    --yellow-700: #fbc02d;
    --yellow: #F2C94C;

    --green-500: #226e34;
    --green-400: #2EA340;
    --green-200: #66B949;

    --primary-neutral-900: #192252;
    --primary-neutral-700: #424F7B;
    --primary-neutral-650: #848FAC;
    --primary-neutral-600: #606D93;
    --primary-neutral-550: #65708D;
    --primary-neutral-500: #C5D0E6;
    --primary-neutral-400: #A9B4CD;
    --primary-neutral-300: #DFE8F6;
    --primary-neutral-100: #EFF3FA;

    --alpha-white-20: #fff2;
    --alpha-white-50: #fff5;
    --alpha-yellow-50: #ffeb3b50;
    --alpha-green-10: #2EA34010;
    --alpha-blue-15: #1DAEFF15;
    --alpha-blue-500: #0C4A8860;

    --error-700: #B71926;

    --content-width: 1220px;
    --tablet-width: 884px;
    --mobile-xs: 480px;
    --mobile-lg: 450px;
    --mobile-md: 360px;
    --mobile-sm: 320px;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    background: var(--background);
    -webkit-font-smoothing: antialiased;
  }

  .leaflet-popup-content {
    font-size: 15px !important;
  }

  body, input, textarea, button, .leaflet-popup-content {
    font-family: 'Urbanist', sans-serif !important;
    font-weight: 400;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
    color: var(--grey-700);
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .custom-marker-cluster {
    font-weight: 600;
    background-color: white;
    border: 5px solid #014F90;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }

  .MuiMenuItem-root.Mui-selected {
    background-color: #014F90 !important;
    margin-bottom: 1px;
    opacity: 0.9;
    color: white;
  }


  .shimmer {
    border-radius: 10px;
    background: #f6f7f8;
    /* background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: inline-block;
    position: relative;
    /*
      -webkit-animation-duration: 1.5s;
      -webkit-animation-fill-mode: forwards;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-name: placeholderShimmer;
      -webkit-animation-timing-function: linear; */
  }

  @-webkit-keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }

  .MuiToggleButton-root {
    border: 1px solid #1DAEFF !important;
  }

  .MuiToggleButton-root.Mui-selected {
    background: var(--blue-100) !important;
    color: #ffffff !important;
  }

  .font-10 {
    font-size: 10px;
  }

  .react-tel-input {
    width: 100%;
  }

  .phoneInput {
    height: 60px !important;
    font-size: 16px !important;
  }

  .react-tel-input .form-control {
    width: 100%;
  }

  .flag-dropdown {
    width: 38px !important;
  }

  .conainerTel {
    width: 100%;
  }

  .labelInputPhone {
    font-weight: bold;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: start;
    align-items: center;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 10px;
      width: 45% !important;
    }
  }

  .MuiTableCell-head {
    font-weight: bold !important;
  }

  .button-action-table {
    cursor: pointer;
  }

  .mobile-only {
    @media (min-width: 1024px) {
      display: none !important;
    }
  }

  .desktop-only {
    @media (max-width: 1024px) {
      display: none !important;
    }
  }

  .ql-size-small {
    font-size: 0.75em !important;
  }

  .ql-size-large {
    font-size: 1.5em !important;
  }

  .ql-size-huge {
    font-size: 2.5em !important;
}

.swal2-html-container img {
  max-width: 100%;
  }

  .loading-icon {
    font-size: 2rem;
    animation: animate 2s infinite;
  }

  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .not-starred-star {
    path {
      stroke: grey;
    }
  }

  .starred-star {
    fill: var(--yellow-500);

    path {
      stroke: var(--yellow-700);
    }
  }

  .char-title {
    margin-top: 20px !important;
  }

  #google-reviews {
    display: flex;
    gap: 24px;
    overflow-x: auto;
    margin-top: 40px;
    padding: 0px 0 40px;

    .review-item {
      width: 400px;
      max-width: 400px;
      min-width: 400px;
      padding: 26px;
      box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 10px;

      & .review-meta {
        & .review-author {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          font-weight: 600;

          & .review-date {
            font-size: .8rem;
            color: var(--grey-400);
          }
        }
      }

      & .review-stars {
        padding: 14px;

        ul {
          display: flex;
          align-items: center;
          justify-content: center;
          list-style: none;
          gap: 10px;
        }
      }

      & .review-text {
        font-size: 1rem;
      }
    }
  }
`;

export { GlobalStyle };
