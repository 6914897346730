import styled from 'styled-components';

export type TitleParams = {
  color?: 'green-400' | 'white';
};

export const Title = styled.h2<TitleParams>`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ color }) =>
    color === 'green-400'
      ? `var(--${color})`
      : color === 'white'
      ? `${color}`
      : `var(--${color})`};
`;
