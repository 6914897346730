import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .actions-container {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }

  .filters-container {
    width: 100%;

    @media (min-width: 1145px) {
      width: max-content;
    }
  }

  @media (min-width: 481px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > button {
      width: revert;
    }
  }

  @media (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;

    .actions-container {
      justify-content: flex-end;
      margin-top: 0;
    }
  }
`;

export const CardsContainer = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: min-content;
  align-items: center;
  gap: 12px;
  justify-content: center;

  & > div {
    height: 100%;
  }

  @media (min-width: 670px) {
    grid-template-columns: min-content min-content;
  }

  @media (min-width: 885px) {
    grid-template-columns: min-content min-content;
  }

  @media (min-width: 1175px) {
    grid-template-columns: min-content min-content min-content;
  }

  @media (min-width: 1500px) {
    grid-template-columns: min-content min-content min-content min-content;
  }

  @media (min-width: 2000px) {
    grid-template-columns: min-content min-content min-content min-content min-content;
  }
`;
