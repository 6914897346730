import styled from 'styled-components';

export const Background = styled.div`
  background: white;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  @media (min-width: 1280px) {
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding: 0 40px;
  }

  .mobile-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    padding: 14px;
  }

  .liked-btn {
    background-color: var(--blue-200);
    padding: 12px 20px;
    color: white;
    border-radius: 7px;
    border: none;
    font-weight: bold;
    letter-spacing: 1.1px;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
  }
`;

export const Container = styled.div`
  flex: 1;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (min-width: 1280px) {
    margin-top: 30px;
  }

  > .actions-container {
    margin-top: 86px;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0 14px;
    flex: 1;

    @media (min-width: 1280px) {
      margin: 0;
    }

    > .btn-clone-c {
      margin-left: 200px;
    }
  }
`;

export const Main = styled.section`
  display: flex;
  margin-top: 26px;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 380px);

  @media (min-width: 1280px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    height: calc(100vh - 280px);
  }

  > .price-ticket {
    display: none;

    @media (min-width: 1280px) {
      display: block;
    }
  }

  > main {
    height: 100%;
    width: 100%;

    @media (min-width: 1280px) {
      flex: 1;
    }
  }
`;
