import { IRadioButtonProps } from './IRadioButton';
import * as S from './styles';

function RadioButton({ name, selected, clickEvent }: IRadioButtonProps) {
  return (
    <S.Container onClick={clickEvent}>
      <input type="radio" name={name} checked={selected} />
      <span className="checkmark" />
      {name}
    </S.Container>
  );
}

export { RadioButton };
