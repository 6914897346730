import { Link as ReactRouterLink } from 'react-router-dom';

import styled, { css, keyframes } from 'styled-components';

interface ICustomLinkProps {
  current?: boolean;
  name?: string;
}

const animation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.nav`
  ul {
    display: flex;

    list-style-type: none;
  }

  li + li {
    margin-left: 2rem;
  }

  @media (max-width: 884px) {
    position: absolute;
    top: 4.25rem;
    left: 0;
    width: 100%;
    padding: 1.25rem 0;

    background: var(--white);
    box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.25);

    animation: ${animation} 0.5s;

    ul {
      flex-direction: column;
      align-items: center;
      row-gap: 1.5rem;
    }

    &&& {
      li {
        margin: 0;
      }
    }
  }
`;

export const Link = styled(ReactRouterLink)<ICustomLinkProps>`
  position: relative;

  text-decoration: none;
  font-weight: 400;
  font-size: 1rem;
  color: var(--black-700);

  ${props =>
    props.current &&
    css`
      font-weight: 700;

      &::after {
        content: '';
        position: absolute;
        bottom: -0.5rem;
        left: 0;

        width: 100%;
        height: 0.15rem;
        background: var(--blue-500);
      }
    `}
`;
