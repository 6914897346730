import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

import { Property } from '@types';
import styled from 'styled-components';

import Map from '../Map';

export type UseLocationDisplayDataProps = {
  property?: Property;
};

export function useLocationDisplay(data: { property?: Property }) {
  return {
    props: {
      property: data.property,
    } as UseLocationDisplayDataProps,
  };
}

const LocationDisplayContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 14px;

  @media (min-width: 1024px) {
    padding: 0;
  }
`;

export const LocationDisplay = ({ property }: UseLocationDisplayDataProps) => {
  if (!property) return <></>;

  return (
    <LocationDisplayContainer>
      <Map
        markers={[
          {
            lat: Number(property.lat_cliente),
            lng: Number(property.long_cliente),
          },
        ]}
        centerPosition={{
          lat: Number(property.lat_cliente),
          lng: Number(property.long_cliente),
        }}
      />
    </LocationDisplayContainer>
  );
};

const Footer = styled.footer`
  padding: 20px 14px;

  @media (min-width: 1024px) {
    padding: 20px 0;
  }

  > h5 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 7px;
  }
`;

export const LocationFooter = ({ property }: UseLocationDisplayDataProps) => {
  if (!property?.description?.length) return <></>;
  return (
    <Footer>
      <h5>Benefícios do imóvel</h5>
      <p>{property.description}</p>
    </Footer>
  );
};

const SwitchBtnContainer = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    opacity: 0;
    flex-direction: column;
    justify-content: center;
  }

  > button {
    border: none;
    border-radius: 100%;
    padding: 5px 7px 0 7px;
    color: var(--blue-400);
    font-size: 30px;
    transition: 0.2s;

    &:hover {
      background-color: var(--blue-400);
      color: white;
    }
  }
`;

export const LocationSwitchBtnRight = ({
  property,
}: UseLocationDisplayDataProps) => {
  return (
    <SwitchBtnContainer>
      <button
        type="button"
        // onClick={() => {
        //   if ((property?.images?.length ?? Infinity) > currentImage + 1) {
        //     setCurrentImage(currentImage + 1);
        //   }
        // }}
      >
        <BiChevronRight />
      </button>
    </SwitchBtnContainer>
  );
};

export const LocationSwitchBtnLeft = ({
  property,
}: UseLocationDisplayDataProps) => {
  return (
    <SwitchBtnContainer>
      <button
      // onClick={() => {
      //   if (currentImage > 0) {
      //     setCurrentImage(currentImage - 1);
      //   }
      // }}
      >
        <BiChevronLeft />
      </button>
    </SwitchBtnContainer>
  );
};
