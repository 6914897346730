import styled, { css } from 'styled-components';

interface IContainerProps {
  hasCursorPointer?: boolean;
  isMarked?: boolean;
  hasTooltipMap?: boolean;
}

export const ExternalContainer = styled.div`
  width: max-content;
  margin: 0 !important;
`;

export const Container = styled.div<IContainerProps>`
  position: relative;
  //height: 100%;
  min-height: 250px;
  max-width: ${props => (props.hasTooltipMap ? '100%' : '275px')};
  min-width: 220px;
  padding: 5px;

  /* margin: 10px; */

  border: 1px solid var(--grey-50);
  border-radius: 0.625rem;

  background: var(--white);

  img {
    width: 100%;
    height: 8.25rem;
    border-radius: 0.625rem;
    object-fit: cover;
    object-position: center;
  }

  .dots {
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  ${props =>
    props.hasCursorPointer &&
    css`
      cursor: pointer;
    `};

  ${props =>
    props.isMarked &&
    css`
      border: 1px solid var(--alpha-blue-500);
      box-shadow: 0 0 5px var(--blue-300);
    `};

  @media (max-width: 884px) {
    width: 95%;
  }
`;

export const Content = styled.div`
  position: relative;

  padding: 0.625rem;
  min-height: 250px;
`;

export const CarouselContainer = styled.div``;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 4px;
  min-height: 110px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .basic-infos {
    min-height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 6px;
    /* column-gap: 5px; */
    /* margin: 0.5rem 0; */

    > span {
      text-align: center;
    }

    span {
      font-weight: 700 !important;
    }

    .price {
      padding: 0.25rem 0.5rem;
      border-radius: 0.4rem;
      background: var(--blue-10);
      color: var(--blue-400);
    }
  }

  .house-attributes {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  /*.house-attributes > div ~ div:last-of-type {
    margin-left: 5px;
  }*/

  .house-attributes {
    > div {
      margin-left: 5px;
      display: flex;
      align-items: center;
      svg {
        width: 1.2rem;
        height: 1.2rem;

        margin-right: 0.25rem;
      }

      svg path {
        stroke: var(--primary-neutral-500);
      }

      span {
        color: var(--primary-neutral-700);
        font-weight: 600 !important;
      }
    }

    div:nth-child(3) {
      svg path {
        stroke: initial;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  button {
    padding: 4px 12px !important;
  }
`;
