/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

interface IStartButtonProps {
  starred?: boolean;
}

interface ICommonProps {
  unavailable?: boolean;
}

interface IContainerProps {
  hasCursorPointer?: boolean;
  isMarked?: boolean;
  hasTooltipMap?: boolean;
}

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  button {
    padding: 4px 12px !important;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  padding: 10px 10px 0 10px;
  align-items: flex-start;
`;

export const ExternalContainer = styled.div`
  width: max-content;
  margin: 0 !important;
`;

export const Container = styled.div<IContainerProps>`
  position: relative;
  height: 100%;
  min-height: 250px;
  max-width: ${props => (props.hasTooltipMap ? '100%' : '275px')};
  min-width: 220px;

  /* margin: 10px; */

  border: 1px solid var(--grey-50);
  border-radius: 0.625rem;

  background: var(--white);

  img {
    width: 100%;
    height: 8.25rem;
    border-radius: 0.625rem;
    object-fit: cover;
    object-position: center;
  }

  .dots {
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  ${props =>
    props.hasCursorPointer &&
    css`
      cursor: pointer;
    `};

  ${props =>
    props.isMarked &&
    css`
      border: 1px solid var(--alpha-blue-500);
      box-shadow: 0 0 5px var(--blue-300);
    `};

  @media (max-width: 884px) {
    width: 95%;
  }
`;

export const CarouselContainer = styled.div``;

export const Content = styled.div<ICommonProps>`
  position: relative;

  padding: 0.625rem;
  min-height: 250px;

  ${props =>
    props.unavailable &&
    css`
      img {
        opacity: 0.7;
      }
    `}
`;


export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 4px;
  min-height: 110px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .basic-infos {
    min-height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 6px;
    /* column-gap: 5px; */
    /* margin: 0.5rem 0; */

    > span {
      text-align: center;
    }

    span {
      font-weight: 700 !important;
    }

    .price {
      padding: 0.25rem 0.5rem;
      border-radius: 0.4rem;
      background: var(--blue-10);
      color: var(--blue-400);
    }
  }

  .house-attributes {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  /*.house-attributes > div ~ div:last-of-type {
    margin-left: 5px;
  }*/

  .house-attributes {
    > div {
      margin-left: 5px;
      display: flex;
      align-items: center;
      svg {
        width: 1.2rem;
        height: 1.2rem;

        margin-right: 0.25rem;
      }

      svg path {
        stroke: var(--primary-neutral-500);
      }

      span {
        color: var(--primary-neutral-700);
        font-weight: 600 !important;
      }
    }

    div:nth-child(3) {
      svg path {
        stroke: initial;
      }
    }
  }
`;

export const Location = styled.div<ICommonProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
  height: 70px;

  padding: 1rem 0.75rem 0.75rem;
  border-top: 1px solid var(--grey-50);

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 0.35rem;
      width: 2rem;
      height: 2rem;
    }

    svg path {
      fill: var(--primary-neutral-500);
    }

    span {
      font-size: 0.75rem !important;
      color: var(--primary-neutral-700);
      font-weight: 400 !important;
    }
  }

  > button {
    padding: ${props =>
      props.unavailable ? '0.375rem 0.5rem' : '0.375rem 1.25rem'};
    border-radius: 0.625rem;
  }
`;

export const ShareContainer = styled.div`
  display: flex;
  column-gap: 12px;

  background: var(--white);
  border: 1px solid var(--primary-neutral-300);
  border-radius: 6px;

  padding: 10px 12px;

  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
  button {
  }

  svg {
    border-radius: 50%;
    width: 42px;
    height: 42px;

    transition: all 0.35s;

    &:hover {
      box-shadow: 0 0 5px 3px var(--primary-neutral-300);
    }
  }
`;

export const ShareButton = styled.button`
  border: none;
  background: transparent;
`;

export const StartButton = styled.button<IStartButtonProps>`
  background: none;
  border: none;

  svg {
    transition: all 0.2s ease-in-out;

    path {
      stroke: lightgray;
    }

    ${props =>
      props.starred &&
      css`
        fill: var(--yellow-500);

        path {
          stroke: var(--yellow-700);
        }
      `}
  }

  @media (min-width: 884px) {
    svg {
      &:hover {
        fill: ${props =>
          props.starred ? 'var(--alpha-yellow-50)' : 'var(--alpha-white-20)'};
      }
    }
  }
`;

export const SelectButton = styled.button`
  height: 16px;
  width: 16px;

  background: none;
  border: 2px solid var(--white);
  border-radius: 4px;
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 24px;
  width: 100%;
`;

export const Targe = styled.div<{ color: string; type?: any, status?: any }>`
  /*position: absolute;*/
  left: 20px;
  /*top: ${({ type }) => (type == 2 ? '44px' : '18px')};*/
  top: 18px;
  margin-bottom: 10px;

  padding: 2px 14px;

  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);

  background: ${props => props.color};

  z-index: 1;

  span {
    color: var(--white);
    font-size: 14px !important;
    font-weight: 600 !important;
  }
`;

export const TargeDisable = styled.div<{ color: string }>`
  position: absolute;
  top: 18px;
  left: 20px;

  padding: 2px 14px;

  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);

  background: ${props => props.color};

  margin-left: 8rem;

  z-index: 1;

  span {
    color: var(--white);
    font-size: 14px !important;
    font-weight: 600 !important;
  }
`;

export const RealtorName = styled.div`
  max-width: 90%;
  background: var(--white);
  margin-bottom: 8px;
  padding: 2px 10px;
  border-radius: 12px;

  span {
    display: block;
    text-align: center;
    font-weight: 700 !important;
    font-size: 14px !important;
    color: var(--primary-neutral-900);
  }
`;

export const ActionsContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 18px;

  width: 160px;
  height: fit-content;
  z-index: 200;

  padding: 10px;
  background: var(--white);
  border: 1px solid #dfe8f6;
  border-radius: 6px;

  > button {
    display: grid;
    grid-template-columns: 15% 75%;
    align-items: center;
    column-gap: 14px;

    width: 100%;

    padding: 6px 6px 6px 6px;

    background: transparent;
    border: none;
    border-radius: 6px;

    font-weight: 600 !important;
    color: var(--blue-500);
    transition: all 0.35s;

    svg {
      width: 22px;
      height: 22px;
    }

    &.delete {
      color: #b71926;
    }

    &:hover {
      background: var(--grey-80);
    }
  }
`;

export const LoggedActionsButton = styled.button`
  position: absolute;
  right: 20px;
  top: 18px;

  width: 24px;
  height: 24px;

  background: rgba(255, 255, 255, 0.75);
  border-radius: 6px;
  border: none;

  z-index: 2;
  transition: all 0.35s;

  svg {
    fill: var(--blue-500);
  }

  &:hover {
    background: var(--white);
  }
`;

export const ShareHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: -10px;
  margin-top: -12px;
`;

export const ShareHeaderButton = styled.button`
  background: transparent;
  border: none;
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
