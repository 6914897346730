import constants from 'common/constants';
import Str from 'common/utils/Str';
import { env } from 'configs';

export type WhatsAppShareParams = {
  message?: string | ((propertyUrl: string) => string);
  property?: {
    id: number;
  };
};

export const Sharer = {
  whatsApp(params: WhatsAppShareParams & { toPrado?: boolean }) {
    let url = 'https://api.whatsapp.com/send?';

    if (params.toPrado) {
      url += `phone=+${constants.defaultWppNumber}&`;
    }

    window.open(
      `${url}text=${encodeURI(Sharer.whatsAppUrl(params))}`,
      '_blank',
    );
  },

  whatsAppUrl({ message, property }: WhatsAppShareParams) {
    const propertyUrl = property
      ? Str.concatPath(env.siteUrl, `share/${property?.id}`)
      : '';

    return typeof message === 'function'
      ? message(propertyUrl)
      : message || propertyUrl;
  },
};
