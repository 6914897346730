import { useState, forwardRef } from 'react';

import { ReactComponent as See } from 'common/assets/svgs/eye.svg';

import { IInputProps } from './IInput';
import * as S from './styles';

const Input = forwardRef<HTMLInputElement, IInputProps>(
  ({ label, bordered, background_color, bolder_font, type, ...props }, ref) => {
    const [active, setActive] = useState(false);
    const [seePass, setSeePass] = useState(false);

    return (
      <S.Container fontColor={props.fontColor}>
        {!!label && <label htmlFor={props.id}>{label}</label>}

        <S.InputWrapper
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
          fontColor={props.fontColor}
          bordered={bordered}
          active={active}
          background_color={background_color}
        >
          <S.Input
            bolder_font={bolder_font}
            type={seePass ? 'text' : type}
            {...props}
            ref={ref}
          />

          {type === 'password' && (
            <S.SeePasswordButton
              type="button"
              seePass={seePass}
              onClick={() => setSeePass(!seePass)}
              aria-label="Ver senha"
            >
              <See />
            </S.SeePasswordButton>
          )}
        </S.InputWrapper>

        {props.error_message && (
          <S.ErrorMessage>{props.error_message}</S.ErrorMessage>
        )}
      </S.Container>
    );
  },
);

export { Input };
