import { Property } from '@types';
import { formatCurrency } from 'common/utils/masks';
import { ContextPageSize } from 'pdfmake/interfaces';

import {
  characteristicsItem,
  colors,
  getBase64FromUrl,
  greyText,
  infoSection,
  logo,
  text,
  title,
} from '../actions';
import footerBase64 from './footer_base64';
import pointerBase64 from './pointer_base64';

export default async function renderProperty(
  property: Property,
) /**: TDocumentDefinitions */ {
  const img1 = await getBase64FromUrl(property!.images![0].image);
  const img2 = await getBase64FromUrl(property!.images![1].image);
  const img3 = await getBase64FromUrl(property!.images![2].image);
  const img4 = await getBase64FromUrl(property!.images![3].image);
  const img5 = await getBase64FromUrl(property!.images![4].image);

  return {
    background: function (_: number, pageSize: ContextPageSize) {
      return {
        image: footerBase64,
        margin: [0, pageSize.height - 100, 0, 0],
        height: 90,
        width: 600,
      };
    },
    footer: {
      margin: [40, 0, 40, 0],
      gap: 10,
      columnGap: 10,
      columns: [
        ...logo(),
        ...greyText(
          'A empresa tem como proposito, levar moradia de qualidade ao mundo, ajudando as pessoas e suas famílias a encontrarem o imóvel ideal, e realizarem o sonho da casa própria, trazendo qualidade de vida, alegria e satisfação.',
        ),
      ],
    },

    content: [
      {
        columnGap: 310,
        columns: [
          ...logo(),
          {
            table: {
              alignment: 'right',
              body: [
                [
                  {
                    text: `Código: ${property.code}`,
                    fontSize: text.base,
                    fillColor: colors.lightBlue,
                    fillOpacity: 0.1,
                    color: colors.lightBlue,
                  },
                ],
              ],
            },
            layout: {
              hLineColor: function () {
                return colors.lightBlue;
              },
              vLineColor: function () {
                return colors.lightBlue;
              },
            },
          },
        ],
      },

      // Fotos
      {
        margin: [0, 20, 0, 20], // LTRB
        columnGap: 2,
        columns: [
          {
            image: img1,
            width: 101,
            height: 116,
          },
          {
            image: img2,
            width: 101,
            height: 116,
          },
          {
            image: img3,
            width: 101,
            height: 116,
          },
          {
            image: img4,
            width: 101,
            height: 116,
          },
          {
            image: img5,
            width: 101,
            height: 116,
          },
        ],
      },
      {
        text: [
          ...title(formatCurrency(property.amount), { color: colors.darkBlue }),
          ...greyText(
            property.contruct_area
              ? `  | Valor m² ${formatCurrency(
                  property.amount / property.contruct_area,
                )}`
              : '',
          ),
        ],
      },

      ...title(property.name),
      {
        text: ' ',
      },
      {
        columnGap: 6,
        columns: [
          {
            image: pointerBase64,
            width: 14,
            height: 14,
          },
          ...greyText(
            `${property?.district} - ${property?.city?.description}/${property?.city?.state?.uf}`,
          ),
        ],
      },
      ...characteristicsItem(property),
      ...infoSection('Outras informações do imóvel', property.description),
      ...infoSection(
        'Qual a relação de documentos para adquirir esse imóvel?',
        [
          'Confira, a seguir, os principais documentos que um comprador deve apresentar:',
          '- Comprovante de Identidade – RG (ou carteira de habilitação) e CPF;',
          '- Comprovante de renda – CTPS e contracheque. No caso de autônomos, pode ser utilizado o extrato bancário; Comprovante de estado civil – certidão de nascimento ou de casamento. Em casos de divórcio ou separação, a certidão de casamento deve possuir a averbação.',
          '- Comprovante da residência atual – contas de luz, telefone ou água;',
          '- Declaração de Imposto de Renda – apenas quem não for isento.',
        ],
      ),
      // ...infoSection('Condições de financiamento', 'XXX'),
    ],
  };
}
