/* eslint-disable jsx-a11y/media-has-caption */
import { useState } from 'react';
import { BiDownload } from 'react-icons/bi';
import { FaCheck } from 'react-icons/fa';
import { HiOutlineDownload } from 'react-icons/hi';

import { Property } from '@types';
import Str from 'common/utils/Str';
import { Button } from 'components/Atoms';
import Check from 'components/Atoms/Check';
import ContentButton from 'components/Atoms/ContentButton';
import Modal, { useModal } from 'components/Atoms/modal/Modal';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import styled from 'styled-components';

const ImagesContainer = styled.div`
  > main {
    padding: 0 14px 4px 14px;
    > small {
      color: var(--grey-400);
    }

    > div {
      margin-top: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 2px;
      max-height: 205px;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }

  .content-item {
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    position: relative;
    cursor: pointer;

    > img,
    > video {
      height: 100%;
      width: 100%;
    }

    &:hover {
      > img,
      > video {
        outline: 2px solid var(--blue-200);
        opacity: 0.2;
      }
    }

    &:active {
      > img,
      > video {
        outline: 2px solid var(--blue-200);
        opacity: 0.1;
      }
    }

    .check {
      height: 100%;
      width: 100%;
      position: absolute;
      inset: 0;
      color: white;
      display: flex;
      align-items: center;
      background-color: var(--blue-200);
      opacity: 0.5;
      justify-content: center;
      z-index: 9999;
    }
  }
`;

const Footer = styled.footer`
  border-top: 2px solid var(--grey-200);
  padding: 0 14px 14px 14px;

  .actions {
    margin: 10px 0 14px 0;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
`;

export type DownloadItemProps = {
  property?: Property;
};

export const DownloadItem = ({ property }: DownloadItemProps) => {
  const modal = useModal();
  const [selectedImagesUrls, setSelectedImagesUrls] = useState<string[]>([]);
  const [selectedCriativosUrls, setSelectedCriativosUrls] = useState<string[]>(
    [],
  );
  const [selectedVideosUrls, setSelectedVideosUrls] = useState<string[]>([]);
  const selectedAllImages =
    selectedImagesUrls.length === property?.images?.length;
  const selectedAllCriativos =
    selectedCriativosUrls.length === property?.criativos?.length;
  const selectedAllVideos =
    selectedVideosUrls.length === property?.videos?.length;

  function handleSelectImage(url: string) {
    selectedImagesUrls.includes(url)
      ? setSelectedImagesUrls(
          selectedImagesUrls.filter(imageUrl => imageUrl !== url),
        )
      : setSelectedImagesUrls([...selectedImagesUrls, url]);
  }

  function handleSelectCriativo(url: string) {
    selectedCriativosUrls.includes(url)
      ? setSelectedCriativosUrls(
          selectedCriativosUrls.filter(imageUrl => imageUrl !== url),
        )
      : setSelectedCriativosUrls([...selectedCriativosUrls, url]);
  }

  function selectAllImages() {
    selectedAllImages
      ? setSelectedImagesUrls([])
      : setSelectedImagesUrls(property!.images!.map(image => image.image));
  }

  function selectAllCriativos() {
    selectedAllCriativos
      ? setSelectedCriativosUrls([])
      : setSelectedCriativosUrls(
          property!.criativos!.map(image => image.image),
        );
  }

  // function handleSelectVideo(url: string) {
  //   selectedVideosUrls.includes(url)
  //     ? setSelectedVideosUrls(
  //         selectedVideosUrls.filter(videoUrl => videoUrl !== url),
  //       )
  //     : setSelectedVideosUrls([...selectedVideosUrls, url]);
  // }

  // function selectAllVideos() {
  //   selectedAllVideos
  //     ? setSelectedVideosUrls([])
  //     : setSelectedVideosUrls(property!.videos!.map(video => video.src));
  // }

  function getImage(url: string) {
    return new Promise((resolve, reject) => {
      JSZipUtils.getBinaryContent(url, (err: any, data: any) => {
        const fileInfo = url.split('/').slice(-2);
        resolve({
          file: data,
          folder: fileInfo[0],
          filename: fileInfo[1],
        });
      });
    });
  }

  async function handleDownload() {
    const zip = new JSZip();

    // Baixa as  fotos
    const promises: any = [];
    selectedImagesUrls.map(x => {
      promises.push(getImage(x));
    });

    // Adiciona os imagens na pasta Fotos
    await Promise.all(promises).then(files => {
      const folderZip = zip.folder('Fotos');
      files.map(fileInfo => {
        folderZip!.file(fileInfo.filename, fileInfo.file, { binary: true });
      });
    });

    // Baixa os criativos
    const promisesCriativos: any = [];
    selectedCriativosUrls.map(x => {
      promisesCriativos.push(getImage(x));
    });

    // Adiciona os criativos na pasta Criativos
    await Promise.all(promisesCriativos).then(files => {
      const criativoZip = zip.folder('Criativos');
      files.map(fileInfo => {
        criativoZip!.file(fileInfo.filename, fileInfo.file, {
          binary: true,
        });
      });
    });

    // Faz o download do arquivo
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      FileSaver.saveAs(content, 'conteudo' + '.zip');
    });

    // const promisesVideos: any = [];
    // selectedVideosUrls.map(x => {
    //   promisesVideos.push(getImage(x));
    // });

    // Promise.all(promisesVideos).then(files => {
    //   const videosZip = zip.folder('Videos');
    //   files.map(fileInfo => {
    //     videosZip!.file(fileInfo.filename, fileInfo.file, { binary: true });
    //   });
  }

  return (
    <>
      <ContentButton
        variant="green"
        right={<HiOutlineDownload size={17} />}
        onClick={() => modal.open()}
      >
        Download
      </ContentButton>

      <Modal {...modal.props} title="Baixar Conteúdo">
        <ImagesContainer>
          {!!property?.images?.length && (
            <main>
              <small>Fotos</small>
              <div>
                {property.images!.map(image => (
                  <div
                    key={image.id}
                    className="content-item"
                    onClick={() => handleSelectImage(image.image)}
                  >
                    <img src={image.image} alt="imagem" />

                    {selectedImagesUrls.includes(image.image) && (
                      <div className="check">
                        <FaCheck />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </main>
          )}

          {!!property?.criativos?.length && (
            <main>
              <small>Criativos</small>
              <div>
                {property.criativos!.map(image => (
                  <div
                    key={image.id}
                    className="content-item"
                    onClick={() => handleSelectCriativo(image.image)}
                  >
                    <img src={image.image} alt="imagem" />

                    {selectedCriativosUrls.includes(image.image) && (
                      <div className="check">
                        <FaCheck />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </main>
          )}

          {/* {!!property?.videos?.length && (
            <main>
              <small>Vídeos</small>
              <div>
                {property.videos!.map(video => (
                  <div
                    key={video.id}
                    className="content-item"
                    onClick={() => handleSelectVideo(video.src)}
                  >
                    <img
                      height={90}
                      width={120}
                      style={{ marginRight: '5px' }}
                      src={Str.handleYoutubeVideoThumbnailFromUrl(video.src)}
                      alt={video.src}
                    />

                    {selectedVideosUrls.includes(video.src) && (
                      <div className="check">
                        <FaCheck />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </main>
          )} */}

          <Footer>
            <div className="actions">
              <div>
                <Check
                  label="Fotos"
                  value={selectedAllImages}
                  setValue={selectAllImages}
                />
              </div>
              {/* {!!property?.videos?.length && (
                <div>
                  <Check
                    label="Vídeos"
                    value={selectedAllVideos}
                    setValue={selectAllVideos}
                  />
                </div>
              )} */}
              {!!property?.criativos?.length && (
                <div>
                  <Check
                    label="Criativos"
                    value={selectedAllCriativos}
                    setValue={selectAllCriativos}
                  />
                </div>
              )}
            </div>

            <Button
              color="high_blue"
              className="download-btn"
              full_width
              onClick={handleDownload}
            >
              Baixar
              <BiDownload />
            </Button>
          </Footer>
        </ImagesContainer>
      </Modal>
    </>
  );
};
