import styled from 'styled-components';

interface ImageBackgroundProps {
  image: string;
}

export const Container = styled.div`
  height: 32rem;
  position: relative;

  .carousel .thumbs-wrapper {
    position: absolute;
    bottom: 25px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 100%;
    margin: 0;

    > button {
      display: none;
    }
  }

  .carousel .thumb {
    padding: 0;
    cursor: pointer;
    border: 1px solid var(--grey-50);
  }

  .carousel .thumb.selected {
    border: 2px solid var(--grey-50);
  }

  @media (max-width: 884px) {
    .carousel.carousel-slider .control-arrow:hover {
      background: none;
    }
  }

  @media (max-width: 480px) {
    .carousel .thumbs-wrapper {
      justify-content: initial;
      padding: 0 4px;
    }
  }
`;

export const ImageContainer = styled.div<ImageBackgroundProps>`
  height: 32rem;

  div.image_background {
    height: 100%;
    width: 100%;

    background-image: url(${props => props.image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;

export const Thumb = styled.div`
  > div,
  > div div.image_backgroun {
    width: 5rem;
    height: 3.75rem;
  }
`;

export const ShareButton = styled.button`
  position: absolute;
  top: 2.5rem;
  right: 3rem;

  width: 3rem;
  height: 3rem;

  border-radius: 50%;
  border: none;
  background: var(--white);
  box-shadow: 0 0 0 6px var(--alpha-white-50);

  opacity: 0.5;

  transition: all 0.35s ease-in-out;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 884px) {
    opacity: 1;
  }
`;
