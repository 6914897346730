/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';

import {
  Drawer,
  Box,
  Button,
  useMediaQuery,
  SwipeableDrawer,
} from '@mui/material';
import { ReactComponent as House } from 'common/assets/svgs/add_house.svg';
import { ReactComponent as AddPerson } from 'common/assets/svgs/add_person.svg';
import { ReactComponent as AmmouncementIcon } from 'common/assets/svgs/announcement-icon2.svg';
import { ReactComponent as Catalog } from 'common/assets/svgs/catalog.svg';
import { ReactComponent as StateAgent } from 'common/assets/svgs/estate-agent.svg';
import { ReactComponent as Home } from 'common/assets/svgs/home_page.svg';
import { ReactComponent as HouseMenu } from 'common/assets/svgs/house-menu.svg';
import { ReactComponent as Logout } from 'common/assets/svgs/logout.svg';
import { ReactComponent as Profile } from 'common/assets/svgs/profile-circle.svg';
import { ReactComponent as MessageText } from 'common/assets/svgs/spread.svg';
import { ReactComponent as Star } from 'common/assets/svgs/star.svg';
import { ReactComponent as Users } from 'common/assets/svgs/users.svg';
import { routesNames } from 'common/utils/routesNames';
import { Logo, /* Input */ HamburgerButton } from 'components/Atoms';
import { MyProfileActs } from 'components/Molecules';
import { ICommonProps } from 'models/ICommonProps';

import { MenuButton } from './MenuButton';

const drawerWidth = 240;
const swipeableHeight = 56;

function PrivateTemplate({ children }: ICommonProps) {
  const [swipeableOpen, setSwipeableOpen] = useState(false);
  const [description, setDescription] = useState('');

  const matches = useMediaQuery('(max-width: 884px)');

  const toggleDrawer = (open: boolean) => {
    setSwipeableOpen(open);
  };

  useEffect(() => {
    const storageData = localStorage.getItem('@user:prado_imoveis');
    //console.log(JSON.parse(storageData)?.type)
    if (storageData !== null) {
      setDescription(JSON.parse(storageData)?.type?.description);
    }
  }, []);


  return (
    <>
      <Drawer
        sx={{
          width: matches ? '100%' : drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: matches ? '100%' : drawerWidth,
            height: matches ? `${swipeableHeight}px` : '100vh',
            boxSizing: 'border-box',
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05))',
          },
        }}
        variant="permanent"
        anchor={matches ? 'top' : 'left'}
      >
        {matches ? (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              height="100%"
              padding="0 20px 0 0px"
            >
              <Button type="button" onClick={() => toggleDrawer(true)}>
                <HamburgerButton
                  color="var(--primary-neutral-900)"
                  checked={swipeableOpen}
                  clickEvent={() => setSwipeableOpen(!swipeableOpen)}
                />
              </Button>

              <Logo small />

              <MyProfileActs />
            </Box>

            <SwipeableDrawer
              anchor="left"
              open={swipeableOpen}
              onClose={() => toggleDrawer(false)}
              onOpen={() => toggleDrawer(true)}
            >
              <>
                {matches && (
                  <Box display="flex" justifyContent="center" marginTop="1.5rem">
                    <Logo small />
                  </Box>
                )}

                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  marginTop={matches ? '3rem' : '2.375rem'}
                  width={matches ? `${drawerWidth}px` : '100%'}
                  padding={matches ? '0 16px 16px' : 'unset'}
                  height="80vh"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    rowGap=".5rem"
                    width="100%"
                  >
                    <MenuButton
                      label="Página inicial"
                      path={routesNames.root}
                      icon={<Home />}
                    />

                    {/* <MenuButton
                      label="Meu perfil"
                      path={routesNames.dashboard}
                      icon={<Profile />}
                    /> */}

                    <MenuButton
                      label="Imóveis Favoritos"
                      path={routesNames.favorites}
                      icon={<Star />}
                    />

                    {(description === 'Adminstrador' || description === 'Secretária' || description === 'Gestor') && (
                      <>
                        <MenuButton
                          label="Proprietários"
                          path={routesNames.Owners}
                          icon={<StateAgent />}
                        />

                        <MenuButton
                          label="Imóveis"
                          path={routesNames.list}
                          icon={<HouseMenu />}
                        />

                        {/* <MenuButton
                          label="Cadastrar imóvel"
                          path={routesNames.register}
                          icon={<House />}
                        /> */}

                        <MenuButton
                          fillPathColor
                          label="Tarjas"
                          path={routesNames.tags}
                          icon={<MessageText />}
                        />
                      </>
                    )}

                    {(description === 'Adminstrador' || description === 'Gestor') && (
                      <>
                        <MenuButton
                          label="Usuários"
                          path={routesNames.users}
                          icon={<Users />}
                        />

                        {/* <MenuButton
                          label="Adicionar usuário"
                          path={routesNames.addUser}
                          icon={<AddPerson />}
                        /> */}
                      </>
                    )}

                    {(description === 'Adminstrador' || description === 'Secretária' || description === 'Corretor' || description === 'Gestor') && (
                      <>
                        <MenuButton
                          label="Catálogo"
                          path={routesNames.catalogs}
                          icon={<Catalog />}
                        />
                      </>
                    )}

                    {(description === 'Adminstrador' || description === 'Secretária' || description === 'Gestor') && (
                      <>
                        <MenuButton
                          label="Comunicado"
                          path={routesNames.communication}
                          icon={<AmmouncementIcon />}
                        />
                      </>
                    )}
                  </Box>

                  {/* <MenuButton label="Sair" path="logout" icon={<Logout />} isLogout /> */}
                </Box>
              </>
            </SwipeableDrawer>
          </>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding="1.625rem 1.5rem 0"
          >
            <Logo small />

            {matches && (
              <Box display="flex" justifyContent="center" marginTop="1.5rem">
                <Logo small />
              </Box>
            )}

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              marginTop={matches ? '3rem' : '2.375rem'}
              width={matches ? `${drawerWidth}px` : '100%'}
              padding={matches ? '0 16px 16px' : 'unset'}
              height="80vh"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                rowGap=".5rem"
                width="100%"
              >
                <MenuButton
                  label="Página inicial"
                  path={routesNames.root}
                  icon={<Home />}
                />

                {/* <MenuButton
                  label="Meu perfil"
                  path={routesNames.dashboard}
                  icon={<Profile />}
                /> */}

                <MenuButton
                  label="Imóveis Favoritos"
                  path={routesNames.favorites}
                  icon={<Star />}
                />

                {(description === 'Adminstrador' || description === 'Secretária' || description === 'Gestor') && (
                  <>
                    <MenuButton
                      label="Proprietários"
                      path={routesNames.Owners}
                      icon={<StateAgent />}
                    />
                    <MenuButton
                      label="Imóveis"
                      path={routesNames.list}
                      icon={<HouseMenu />}
                    />

                    {/* <MenuButton
                      label="Cadastrar imóvel"
                      path={routesNames.register}
                      icon={<House />}
                    /> */}

                    <MenuButton
                      fillPathColor
                      label="Tarjas"
                      path={routesNames.tags}
                      icon={<MessageText />}
                    />
                  </>
                )}

                {(description === 'Adminstrador' || description === 'Gestor') && (
                  <>
                    <MenuButton
                      label="Usuários"
                      path={routesNames.users}
                      icon={<Users />}
                    />

                    {/* <MenuButton
                      label="Adicionar usuário"
                      path={routesNames.addUser}
                      icon={<AddPerson />}
                    /> */}
                  </>
                )}

                {(description === 'Adminstrador' || description === 'Secretária' || description === 'Corretor' || description === 'Gestor') && (
                  <>
                    <MenuButton
                      label="Catálogo"
                      path={routesNames.catalogs}
                      icon={<Catalog />}
                    />
                  </>
                )}

                {(description === 'Adminstrador' || description === 'Secretária' || description === 'Gestor') && (
                  <>
                    <MenuButton
                      label="Comunicado"
                      path={routesNames.communication}
                      icon={<AmmouncementIcon />}
                    />
                  </>
                )}
              </Box>

              {/* <MenuButton label="Sair" path="logout" icon={<Logout />} isLogout /> */}
            </Box>
          </Box>
        )}
      </Drawer>

      <Box
        sx={{
          width: matches ? '100%' : `calc(100% - ${drawerWidth}px)`,
          background: 'var(--grey-75)',
        }}
        marginLeft={matches ? 'unset' : `${drawerWidth}px`}
        minHeight="100vh"
      >
        {!matches && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            height="4rem"
            padding="0 2rem"
            sx={{ background: 'var(--white)' }}
          >
            {/* <Box width="25%">
              <Input
                placeholder="Pesquisar"
                fontColor="#A9B4CD"
                background_color="var(--grey-80)"
              />
            </Box> */}

            <Box display="flex" alignItems="center" columnGap="2.5rem">
              {/* <Button
                type="button"
                sx={{
                  width: '24px',
                  height: '24px',
                  minWidth: '24px',
                  padding: '0',
                }}
              >
                <Notication />
              </Button> */}

              <MyProfileActs />
            </Box>
          </Box>
        )}

        <Box
          padding={
            matches ? `calc(${swipeableHeight}px + 1rem) 1.2rem 2rem` : '2rem'
          }
        >
          {children}
        </Box>
      </Box>
    </>
  );
}

export { PrivateTemplate };
