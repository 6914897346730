import { useEffect, useState } from 'react';
import { BsCameraVideo, BsCardImage } from 'react-icons/bs';
import { FiMapPin } from 'react-icons/fi';
import { HiOutlinePrinter } from 'react-icons/hi';
import { ImSpinner10 } from 'react-icons/im';
import { RiArrowRightLine } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { WhatsappIcon } from 'react-share';

import { Property, PropertyImage, STATUS_CODE } from '@types';
import { ReactComponent as Star } from 'common/assets/svgs/star.svg';
import { ReactComponent as Whatsapp } from 'common/assets/svgs/whatsapp_filled.svg';
import property_docs from 'common/constants/property_docs';
import LightButton from 'components/Atoms/LightButton';
import { Title } from 'components/Atoms/Title';
import { Breadcrumb } from 'components/Molecules';
import Accordion from 'components/Molecules/Accordion';
import { ProperyCarousel } from 'components/Organisms/PropertyCarousel';
import PropertyContentModal, {
  PropertyContentModalProps,
  useModal,
} from 'components/Organisms/PropertyContentModal';
import { Alert, Pdf, Sharer } from 'helpers';
import { useAuth } from 'hooks/auth';
import { PropertyPriceTicket } from 'pages/AnnouncementDetail/components/PropertyPriceTicket';
import PropertyService from 'services/PropertyService';

import Characteristics from './components/Characteristcs';
import ContentButtons from './components/ContentButton';
import CreditBox from './components/CreditBox';
import DocumentationPrint from './components/DocumentationPrint';
import ImagesPreview from './components/ImagesPreview';
import Installment from './components/Installment';
import * as S from './style';

const service = new PropertyService();

function scrollListener() {
  const aside: any = document.querySelector(
    'aside.property-price-ticket.desktop-only',
  );
  if (!aside) return;
  const content: any = aside.querySelector('.property-price-ticket--content');
  if (!content) return;

  const limit: any = document.querySelector('#property-price-ticket--limit');
  if (!limit) return;

  const scrollTop = window.scrollY - aside.offsetTop;
  const isScrollPoint = scrollTop > 0 ? scrollTop : 0;

  if (isScrollPoint) {
    if (window.scrollY + 692 >= limit.offsetTop) {
      content.style.position = `absolute`;
      content.style.bottom = '-140px';
      content.style.left = 0;
      content.style.right = 0;
      content.style.top = 'auto';
    } else {
      content.style.position = `fixed`;
      content.style.top = `5px`;
      content.style.left = `${aside.offsetLeft}px`;
      content.style.width = `${aside.offsetWidth}px`;
      content.style.height = `max-content`;
    }
  } else {
    content.style.position = `absolute`;
    content.style.inset = 0;
  }
}

function AnnouncementDetail() {
  const { id } = useParams();
  const contentModal = useModal();
  const { isLogged, logout } = useAuth();

  const [contentModalMode, setContentModalMode] =
    useState<PropertyContentModalProps['mode']>('images');
  const [contentModalIndex, setContentModalIndex] = useState(0);
  const [property, setProperty] = useState<Property>();
  const { userData } = useAuth();
  useEffect(() => {
    if (userData) {
      load();
    }
  }, [userData]);

  function openContentModal(
    mode: PropertyContentModalProps['mode'],
    options?: { image?: PropertyImage; index?: number },
  ) {
    setContentModalMode(mode);
    setContentModalIndex(options?.index ?? 0);
    contentModal.open();
  }

  async function load() {
    const { data, isError, code } = await service.find(
      { id: id! },
      { isLogged },
    );

    if (isError) {
      code === STATUS_CODE.UNAUTHORIZED
        ? logout()
        : Alert.error({
            error: data,
            title: 'Falha ao carregar imóvel',
          });
      return;
    }

    console.log('PROPRIEDADE CARREGADA', data);
    setProperty(data);
  }

  useEffect(() => {
    setProperty(undefined);
    load();
  }, [id]);

  useEffect(() => {
    window.addEventListener('scroll', scrollListener, { passive: true });

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  return (
    <S.Container>
      <button
        id="fab"
        onClick={() =>
          Sharer.whatsApp({
            property,
            message: url =>
              `Olá, gostaria de falar com um corretor sobre o imóvel: ${url}`,
          })
        }
        aria-label="Fale com um corretor"
      >
        <div>
          <div>
            <WhatsappIcon />
          </div>
          Fale com um corretor
        </div>
      </button>

      <PropertyContentModal
        {...contentModal.props}
        property={property}
        mode={contentModalMode}
        index={contentModalIndex}
        key={contentModalIndex}
      />

      <S.Header>
        <Breadcrumb />

        <S.HeaderButtonsContainer>
          <ContentButtons property={property} />
        </S.HeaderButtonsContainer>
      </S.Header>

      <section>
        <ImagesPreview
          property={property}
          contentActions={{
            onOpenImages: options => openContentModal('images', options),
            onOpenVideos: () => openContentModal('videos'),
          }}
        />

        <S.HeaderButtonsContainerMobile>
          <ContentButtons property={property} />
        </S.HeaderButtonsContainerMobile>
      </section>

      <S.Body>
        <main>
          <div className="actions-container">
            <LightButton
              icon={<BsCardImage />}
              text="Fotos"
              onClick={() => openContentModal('images')}
            />
            {!!property?.videos?.length && (
              <LightButton
                icon={<BsCameraVideo />}
                text="Vídeos"
                onClick={() => openContentModal('videos')}
              />
            )}
            <LightButton
              responsive
              icon={<FiMapPin />}
              text="Localização"
              onClick={() => openContentModal('location')}
            />
          </div>

          <Title>{property?.name}</Title>

          <div className="location-container">
            <FiMapPin size={20} />
            <span className="address-text">
              {property?.district} - {property?.city?.description}/
              {property?.city?.state?.uf}
            </span>
            <button
              className="ver-no-mapa"
              onClick={() => openContentModal('location')}
              type="button"
            >
              Ver no mapa
            </button>
          </div>

          <Characteristics property={property} />

          <Aside className="mobile-only" property={property} />

          {!!property?.description?.length && (
            <Accordion
              title="Outras informações do imóvel"
              text={property?.description}
            />
          )}

          <Installment />

          <CreditBox property={property} />

          <Accordion title={property_docs.title} text={property_docs.docs}>
            <LightButton
              style={{
                marginLeft: 'auto',
              }}
              icon={<HiOutlinePrinter />}
              text="Imprimir"
              onClick={() => {
                if (!property) return;
                Pdf.propertyDocs(property);
              }}
            />
          </Accordion>
        </main>

        <Aside property={property} className="desktop-only" />

        <div id="property-price-ticket--limit"></div>
      </S.Body>

      {property && (
        <ProperyCarousel idProperty={property.id} property={property} />
      )}
      <div style={{ maxWidth: '900px' }}>
        <div style={{ height: '0px', overflow: 'hidden' }}>
          <div id="print-container">
            {property && <DocumentationPrint property={property} />}
          </div>
        </div>
      </div>
    </S.Container>
  );
}

const Aside = ({
  property,
  className,
}: {
  property?: Property;
  className?: string;
}) => {
  const { isLogged } = useAuth();
  const [favoritePivotId, setFavoritePivotId] = useState<number | undefined>(
    property?.favoriteData?.id,
  );
  const [loadingFavoritePivotId, setLoadingFavoritePivotId] = useState(false);

  useEffect(() => {
    setFavoritePivotId(property?.favoriteData?.id);
  }, [property]);

  async function toggleFavorite() {
    setLoadingFavoritePivotId(true);
    if (!favoritePivotId) {
      const { data } = await service.addFavorite(property!.id);
      setFavoritePivotId(data?.id);
    } else {
      await service.removeFavorite(favoritePivotId);
      setFavoritePivotId(undefined);
    }
    setLoadingFavoritePivotId(false);
  }

  return (
    <aside className={`property-price-ticket ${className}`}>
      <div className="property-price-ticket--content">
        {isLogged && (
          <S.FavoriteButton
            aria-label="Favoritar/Desfavoritar"
            onClick={() => toggleFavorite()}
          >
            <span>
              {favoritePivotId
                ? 'Remover dos favoritos'
                : 'Adicionar aos favoritos'}
            </span>
            {loadingFavoritePivotId ? (
              <ImSpinner10
                className="loading-icon"
                size={24}
                style={{
                  marginLeft: '10px',
                }}
              />
            ) : (
              <Star
                className={
                  favoritePivotId ? 'starred-star' : 'not-starred-star'
                }
                style={{
                  marginLeft: '10px',
                }}
              />
            )}
          </S.FavoriteButton>
        )}

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <PropertyPriceTicket property={property} />
        </div>
        <div className="outside">
          <small>
            * Os preços e informações sobre o imóvel estão sujeitos a alteração
            sem comunicação ou aviso prévio, consulte a disponibilidade e
            referências.
          </small>

          <S.FinanBtn
            onClick={() =>
              Sharer.whatsApp({
                property,
                toPrado: true,
                message: url =>
                  `Olá! Venho através do site solicitar uma simulação de financiamento do imóvel ${url}.`,
              })
            }
            aria-label="Simular financiamento"
          >
            <div>Simular Financiamento</div>
            <div>
              <RiArrowRightLine size={15} />
            </div>
          </S.FinanBtn>

          <S.WppQuestions
            onClick={() =>
              Sharer.whatsApp({
                property,
                toPrado: true,
                message: url =>
                  `Olá, estou com dúvidas sobre o imóvel ${url}. Pode me ajudar?.`,
              })
            }
            aria-label="Fale com um corretor"
          >
            <Whatsapp /> Dúvidas? Fale no WhatsApp!
          </S.WppQuestions>
        </div>
      </div>
    </aside>
  );
};

export { AnnouncementDetail };
export default AnnouncementDetail;
