import { useState, useEffect } from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DefaultPageTitle } from 'components/Atoms';
import { HouseCard } from 'components/Molecules';
import { getFavoritesProperties } from 'services/api';

import { useAuth } from '../../hooks/auth';
import * as S from './styles';

const Favorites = () => {
  const [properties, setProperties] = useState<any[]>();
  const [message, setMessage] = useState('Carregando imóveis favoritos...');
  const { logoutLocal } = useAuth();
  async function loadProperties() {
    try {
      const response = await getFavoritesProperties();
      if (response === false) {
        setMessage('Falha ao carregar lista de imóveis favoritos...');
        return;
      }

      if (!response?.length) {
        setMessage('Não há imóveis marcados como favoritos...');
        return;
      }

      setProperties(response as any);
    } catch (err) {
      // @ts-ignore
      if (err.response.status === 401) {
        logoutLocal();
        return;
      }
      setMessage('Falha ao carregar lista de imóveis favoritos...');
    }
  }

  useEffect(() => {
    loadProperties();
  }, []);

  if (!properties) {
    return (
      <Box marginTop="24px" borderTop="1px solid var(--alpha-blue-15)">
        <Typography
          fontWeight="600"
          fontSize="22px"
          textAlign="center"
          paddingTop="22px"
          color="var(--primary-neutral-900)"
        >
          {message}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      width="100%"
      height="fit-content"
      padding="24px 26px"
      border="1px solid var(--primary-neutral-300)"
      borderRadius="16px"
      sx={{ background: 'var(--white)' }}
    >
      <DefaultPageTitle>Imóveis Favoritos</DefaultPageTitle>

      <S.CardsContainer>
        {properties.map((data: any) => (
          <HouseCard
            key={data.id}
            id={data.id}
            name={data.name}
            value={data.amount}
            favoriteId={
              data?.favorites_data?.length
                ? data.favorites_data[0].id
                : undefined
            }
            infos={{
              bathrooms: data.bathrooms,
              bedrooms: data.bedrooms,
              houseArea: data.contruct_area,
              totalArea: data.land_area,
              metragem: data.type_footage,
              slug: data.slug ?? '',
              code: data.code ?? '',
            }}
            location={{
              latitude: Number(data.latitude),
              longitude: Number(data.longitude),
              name: `${data.district} - ${data.city?.description}`,
            }}
            withLoggedActions
            images={data?.imagens?.map(
              (img: any) => /*img.h_115 ||*/ img.image,
            )}
            property={data}
          />
        ))}
      </S.CardsContainer>
    </Box>
  );
};

export { Favorites };
export default Favorites;
