import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Typography, useMediaQuery, TextField } from '@mui/material';
import { Tarja } from 'types';

import { Button, DefaultPageTitle } from '../../../components/Atoms';
import { Dropdown } from '../../../components/Molecules';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import useInputMask from '../../../hooks/useInputMask';
import { IOwner } from '../../../models/IOwner';
import api from '../../../services/api';
import * as S from './styles';

function TagsForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { logoutLocal } = useAuth();
  const matches = useMediaQuery('(max-width: 884px)');
  const { handleSubmit, control, errors, reset } = useForm();
  const { addToast } = useToast();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [stadoCivil, setStadoCivil] = useState<any>('');
  const { onPhoneMask, removePhoneMask } = useInputMask();

  useEffect(() => {
    if (id) {
      api
        .get(`/tarjas/${id}`)
        .then(req => {
          const data = req.data;
          control.setValue('name', data.name);
        })
        .catch(error => {
          if (error.response.status === 401) {
            logoutLocal();
          }
        });
    }
  }, []);

  const onSubmit = useCallback(
    (tarja: Partial<Tarja>) => {
      console.log(tarja);
      const url = id ? `/tarjas/${id}` : '/tarjas';
      const method = id ? 'put' : 'post';
      api[method](url, tarja)
        .then(response => {
          setSubmitDisabled(false);
          addToast({ title: 'Salvo com sucesso!', type: 'success' });
          setTimeout(() => {
            navigate('/tarjas');
          }, 2000);
        })
        .catch(err => {
          addToast({
            title: 'Ocorreu um erro ao cadastrar!',
            description: err.response.data.message,
            type: 'error',
          });
          setSubmitDisabled(false);
        });
    },
    [removePhoneMask],
  );

  return (
    <Box
      width="100%"
      padding="24px 26px"
      border="1px solid var(--primary-neutral-300)"
      borderRadius="16px"
      sx={{ background: 'var(--white)' }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={matches ? '14px' : '30px'}
      >
        <DefaultPageTitle>Cadastrar tarja</DefaultPageTitle>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        rowGap="24px"
        marginBottom={matches ? '24px' : ''}
      >
        <Typography
          fontSize="14px"
          fontWeight="400"
          color="var(--primary-neutral-650)"
        >
          * Indica os campos obrigatórios
        </Typography>
      </Box>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" width="420px" rowGap="18px">
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={props => (
              <Box>
                <TextField
                  type="text"
                  label="Nome"
                  variant="standard"
                  sx={{ width: '100%' }}
                  {...props}
                />
                {errors.name && (
                  <S.ErrorMessage>* Informe o nome</S.ErrorMessage>
                )}
              </Box>
            )}
          />

          <Box
            display="grid"
            gridTemplateColumns={matches ? '100%' : '47.5% 47.5%'}
            rowGap={matches ? '24px' : ''}
            justifyContent="space-between"
            marginTop="42px"
          >
            <Button
              color="high_blue"
              type="submit"
              full_width
              disabled={submitDisabled}
            >
              Salvar
            </Button>

            <Button
              color="white"
              full_width
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      </S.Form>
    </Box>
  );
}

export { TagsForm };
export default TagsForm;
