import { ICommonProps } from 'models/ICommonProps';

import { AuthProvider } from './auth';
import { RealtorsProvider } from './realtors';
import { SearchProvider } from './search';
import { ToastProvider } from './toast';

function Hooks({ children }: ICommonProps) {
  return (
    <ToastProvider>
      <AuthProvider>
        <RealtorsProvider>
          <SearchProvider>{children}</SearchProvider>
        </RealtorsProvider>
      </AuthProvider>
    </ToastProvider>
  );
}

export { Hooks };
