import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

import { defaultRealtorName } from 'common/constants/defaultRealtorName';
import { IDropdownOption } from 'models/IDropdownOption';
import api from 'services/api';

import { useAuth } from './auth';

interface IRealtorsContextData {
  realtors: IDropdownOption[];
  getRealtorNameById: (id: number) => string;
}

const RealtorsContext = createContext<IRealtorsContextData>(
  {} as IRealtorsContextData,
);

const RealtorsProvider: React.FC = ({ children }) => {
  const [realtors, setRealtors] = useState<IDropdownOption[]>([]);

  const { userData } = useAuth();

  useEffect(() => {
    /*
     * O endpoint de corretores está publico. Posteriormente deve ser colocado
     * e retornar os corretores apenas quando o usuário estiver logado.
     * adicionei a regra abaixo apenas para impedir que faça a request quando o
     * usuário não estiver logado (paliativo)
     */

    if (Object.keys(userData).length > 0) {
      api.get('/corretores?coluna=name&order=asc').then(req => {
        const { data } = req.data;
        const realtorsData = data.corretores.map((el: any) => {
          return { label: el.name, value: el.id };
        });

        setRealtors([...realtorsData]);
      });
    }
  }, [userData]);

  const getRealtorNameById = useCallback(
    (id: number): string => {
      return (
        realtors.find(el => {
          if (el.value === id) return el.label;
        })?.label || defaultRealtorName
      );
    },
    [realtors],
  );

  return (
    <RealtorsContext.Provider
      value={{
        realtors,
        getRealtorNameById,
      }}
    >
      {children}
    </RealtorsContext.Provider>
  );
};

function useRealtors(): IRealtorsContextData {
  const context = useContext(RealtorsContext);

  if (!context)
    throw new Error('useRealtors must be used with RealtorsProvider');

  return context;
}

export { RealtorsProvider, useRealtors };
