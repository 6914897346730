import { FaPaperPlane, FaPhoneAlt } from 'react-icons/fa';
import { ImSpinner10 } from 'react-icons/im';

import { Property } from '@types';
import { formatCurrency } from 'common/utils/masks';
import { routesNames } from 'common/utils/routesNames';
import Str from 'common/utils/Str';
import { Button } from 'components/Atoms';
import Modal from 'components/Atoms/modal/Modal';
import { Title } from 'components/Atoms/Title';
import BaseInput from 'components/Molecules/BaseInput';
import AgendarVisitaModal from 'components/Organisms/AgendarVisitaModal';
import { useModal } from 'components/Organisms/PropertyContentModal';
import { Alert } from 'helpers';
import useRootForm from 'hooks/useRootForm';
import C2SService from 'services/C2SService';

import { Container } from './styles';

const c2sService = new C2SService();

export type IPropertyPriceTicketProps = {
  property?: Property;
  className?: string;
  variant?: 'price-down' | 'price-down-full' | 'price-down-full-no-border';
};

function PropertyPriceTicket({
  property,
  className,
  variant,
}: IPropertyPriceTicketProps) {
  const valorM2 = property
    ? formatCurrency(
        (property?.promotional_amount
          ? property?.promotional_amount
          : property.amount) / property.contruct_area!,
      )
    : '';
  const ramaisModal = useModal();
  const agendarVisitaModal = useModal();
  const { form, setFormErrors, register, setForm, isLoading, setIsLoading } =
    useRootForm<{
      name?: string;
      phone?: string;
      email?: string;
    }>();

  const phones = {
    main: { numero: '(62) 3999-1400', ramal: '' },
    other: [
      // { numero: '(62) 3999-1400', ramal: '' },
      { numero: '(62) 3999-1400', ramal: '' },
    ],
  };

  async function handleSubmit(e: any) {
    e.preventDefault();
    const errors: any = {};

    setFormErrors({});
    if (!form.name?.trim().length) errors['name'] = 'Informe o seu nome';
    if (!form.email?.trim().length) errors['email'] = 'Informe o seu e-mail';
    else if (!Str.validateEmail(form.email))
      errors['email'] = 'Informe um e-mail válido';
    if (!form.phone?.trim().length) errors['phone'] = 'Informe o seu telefone';

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return;
    }

    setIsLoading(true);
    const { isError } = await c2sService.createLead({
      name: form.name!,
      email: form.email!,
      phone: form.phone!,
      body: `Pedido de contato para o imóvel: ${property?.code}, Link: ${window.location.href}`,
    });
    setIsLoading(false);
    if (isError) {
      Alert.error({ text: 'Falha ao registrar informações', error: true });
      return;
    }
    Alert.fire({
      text: 'Registro realizado com sucesso, entraremos em contato em breve.',
      icon: 'success',
    });
    setForm({
      name: '',
      email: '',
      phone: '',
    });
  }

  function makeCall(phone: string) {
    phone = phone.replace(/\D/g, '');
    phone = phone.startsWith('55') ? phone : `55${phone}`;
    console.log(`tel:+${phone}`);
    window.open(`tel:+${phone}`);
  }

  if (!property) {
    return (
      <Container>
        <span>carregando...</span>
      </Container>
    );
  }

  return (
    <Container
      className={className}
      variant={variant}
      data-responsive={!!variant}
    >
      {!variant && (
        <header>
          <div className="curve-left"></div>
          <div className="curve-right"></div>

          <div className="row">
            <h5>Total*</h5>
            {property.promotional_amount ? (
              <div>
                <h3>{formatCurrency(property.amount)}</h3>
                {!!property.promotional_amount && (
                  <Title>{formatCurrency(property.promotional_amount)}</Title>
                )}
              </div>
            ) : (
              <Title>{formatCurrency(property.amount)}</Title>
            )}
          </div>
          {!!property.contruct_area && (
            <div className="row">
              <small>Valor m²</small> <small>{valorM2}</small>
            </div>
          )}
        </header>
      )}

      <main className="main-content">
        <Title>
          {variant === 'price-down'
            ? 'Gostou? Entre em contato.'
            : variant === 'price-down-full' ||
              variant === 'price-down-full-no-border'
            ? 'Quero falar com um especialista'
            : 'Quer mais informações?'}
        </Title>
        <span className="leave-contact">
          Deixe seu contato e um dos nossos profissionais entrará em contato em
          breve.
        </span>

        <form onSubmit={handleSubmit}>
          <BaseInput placeholder="Nome" {...register('name')} />
          <BaseInput placeholder="Telefone" {...register('phone')} />
          <BaseInput placeholder="E-mail" {...register('email')} />

          <button className="contato" type="submit" disabled={isLoading}>
            {!isLoading ? (
              <>
                Entrar em contato
                <FaPaperPlane />
              </>
            ) : (
              <ImSpinner10 className="loading-icon" size={20} />
            )}
          </button>
        </form>

        <small>
          Ao solicitar contato, você concorda com os{' '}
          <a target="_blank" href={routesNames.termsOfUsage} rel="noreferrer">
            termos de uso
          </a>{' '}
          e{' '}
          <a target="_blank" href={routesNames.privacyPolicy} rel="noreferrer">
            política de privacidade
          </a>
          , confirmando ter mais de 18 anos
        </small>

        <span className="call-btn">
          <span
            role="button"
            tabIndex={0}
            style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
            onClick={() => {
              makeCall(phones.main.numero);
            }}
          >
            <FaPhoneAlt />
            {phones.main.numero}
          </span>
          <span
            className="call-btn__ramais"
            role="button"
            tabIndex={0}
            onClick={() => ramaisModal.open()}
          >
            Ramais
          </span>
          <span
            className="call-btn__vernumero"
            role="button"
            tabIndex={0}
            onClick={({ target }) => {
              (target as any).style.display = 'none';
            }}
          >
            Ver número
          </span>

          <Modal title="Ramais Prado Imóveis" {...ramaisModal.props}>
            <main style={{ padding: '14px', display: 'grid', gap: '7px' }}>
              {phones.other.map(p => (
                <Button
                  key={p.numero}
                  color="light"
                  full_width
                  onClick={() => {
                    makeCall(p.numero);
                  }}
                >
                  <FaPhoneAlt />
                  {p.numero} {!!p.ramal?.length && `(Ramal ${p.ramal})`}
                </Button>
              ))}
            </main>

            <footer style={{ padding: '14px', borderTop: '1px solid #eee' }}>
              <Button
                color="high_blue"
                full_width
                onClick={() => {
                  makeCall(phones.main.numero);
                }}
              >
                <FaPhoneAlt />
                Ligar agora
              </Button>
            </footer>
          </Modal>
        </span>

        <button type="button" onClick={() => agendarVisitaModal.open()}>
          Agendar uma visita
        </button>
        <AgendarVisitaModal {...agendarVisitaModal.props} property={property} />
      </main>

      {variant === 'price-down' && (
        <header className="price-down">
          <div className="curve-left"></div>
          <div className="curve-right"></div>

          <div className="row">
            <h5>Total*</h5>
            {property.promotional_amount ? (
              <div>
                <h3>{formatCurrency(property.amount)}</h3>
                {!!property.promotional_amount && (
                  <Title>{formatCurrency(property.promotional_amount)}</Title>
                )}
              </div>
            ) : (
              <Title>{formatCurrency(property.amount)}</Title>
            )}
          </div>
          {!!property.contruct_area && (
            <div className="row">
              <small>Valor m²</small> <small>{valorM2}</small>
            </div>
          )}
        </header>
      )}

      {variant === 'price-down-full' ||
        (variant === 'price-down-full-no-border' && (
          <header className="price-down">
            <div className="row">
              <h5>Total*</h5>
              {property.promotional_amount ? (
                <div>
                  <h3>{formatCurrency(property.amount)}</h3>
                  {!!property.promotional_amount && (
                    <Title>{formatCurrency(property.promotional_amount)}</Title>
                  )}
                </div>
              ) : (
                <Title>{formatCurrency(property.amount)}</Title>
              )}
            </div>
          </header>
        ))}
    </Container>
  );
}

export { PropertyPriceTicket };
