import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowLeft } from 'common/assets/svgs/arrow-left.svg';
import { Button } from 'components/Atoms';

import * as S from './styles';

function Breadcrumb() {
  const navigate = useNavigate();

  return (
    <S.Container>
      <Button
        icon={<ArrowLeft />}
        color="transparent"
        bolder
        onClick={() => navigate(-1)}
      >
        Voltar
      </Button>
    </S.Container>
  );
}

export { Breadcrumb };
