/* eslint-disable prettier/prettier */
import styled from 'styled-components';

interface IDropdownWrapperProps {
  has_icon: boolean;
}

export const DropdownWrapper = styled.div<IDropdownWrapperProps>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: ${props => (props.has_icon ? '78%' : '100%')};
  padding: 0 0.75rem;
  border-radius: 5px;

  cursor: pointer;

  span {
    font-size: 0.875rem;
    font-weight: 500;
    
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 18%;

  border-radius: 8px;

  svg,
  path {
    width: 20px;
    height: 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 38px;

  ${DropdownWrapper}, ${IconWrapper} {
    height: 100%;
    border: 2px solid var(--primary-neutral-300);
  }

  @media (max-width: 884px) {
    ${DropdownWrapper}, ${IconWrapper} {
      border: 1px solid var(--primary-neutral-300);
    }
  }
`;

export const OptionsWrapper = styled.div`
  position: absolute;
  top: 99%;
  left: 0;

  display: flex;
  flex-direction: column;
  row-gap: 8px;

  max-height: 200px;
  overflow-y: scroll;

  width: 100%;
  padding: 8px;

  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 2px solid var(--primary-neutral-300);

  background: var(--white);

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: var(--primary-neutral-300);
  }

  z-index: 100;
`;

export const Option = styled.button`
  padding: 5px;
  border-radius: 4px;
  border: 2px solid var(--primary-neutral-300);
  background: var(--white);

  transition: all 0.25s;

  &:disabled {
    font-weight: 600;
    color: var(--grey-700);
    border-color: var(--grey-200);
    background: var(--grey-150);
  }

  &:hover {
    background: ${props => props.disabled ? 'var(--grey-150)' : 'var(--primary-neutral-300)'};
    color: ${props => props.disabled ? 'var(--grey-700)' : 'var(--primary-neutral-900)'};
    font-weight: 600;
  }
`;
