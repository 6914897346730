import box_bg from 'common/assets/svgs/box_total_background.svg';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 15.375rem;
  width: 20rem;
  padding: 1.5rem 1.75rem 4rem;

  background-image: url(${box_bg});
  background-size: contain;
  background-repeat: no-repeat;

  > span {
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--grey-400);
  }

  div {
    display: flex;
    align-items: center;

    span {
      display: inline-block;
    }

    span:first-child {
      font-size: 1.15rem;
      font-weight: 600;
      color: var(--grey-400);
      margin-right: 0.5rem;
    }

    span:last-child {
      font-size: 1.5rem;
      font-weight: 700;
      color: var(--grey-700);
    }
  }
`;
