import styled from 'styled-components';

export const ImagesPreviewContainer = styled.div`
  display: none;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
  border-radius: 10px;
  /* overflow: hidden; */
  height: 700px;
  max-height: 60vh;
  position: relative;

  img {
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    display: grid;
  }

  .first-image-container {
    height: 700px;
    max-height: 60vh;

    .first-image {
      height: 100%;
      width: 100%;
      min-height: 100%;
      min-width: 100%;
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
      background: #f6f7f8;
    }
  }
  .other-image-container {
    display: grid;
    gap: 2px;
    grid-template-columns: 1fr 1fr;
    height: 700px;
    max-height: 60vh;

    .other-image {
      height: 100%;
      width: 100%;
      min-height: 100%;
      min-width: 100%;
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
      background: #f6f7f8;
    }
  }

  .actions-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 14px 12px;
    background-color: #0073b5aa;
    width: calc(25% - 1px);
    bottom: 0;
    right: 0;
  }
`;

export const ImagesPreviewContainerMobile = styled.div`
  margin-bottom: 5px;

  @media (min-width: 1024px) {
    display: none;
  }
`;
