import styled, { css } from 'styled-components';

export interface ContainerProps {
  counter?: number;
  clearFilters?: boolean;
}

export const Wrapper = styled.div`
  display: grid;
  gap: 10px;
  width: 100%;

  @media (min-width: 300px) {
    &[data-nowrap='true'] {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 900px) {
    width: max-content;

    max-width: 540px;
    margin-left: auto;
  }
`;

export const Formulario = styled.form`
  .content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    @media (min-width: 720px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1024px) {
      /* grid-template-columns: 1fr 1fr 1fr; */
    }
  }
`;

export const Container = styled.button<ContainerProps>`
  position: relative;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  font-size: 0.85rem;
  font-weight: 600;
  color: ${props => (props.clearFilters ? '#fff' : 'var(--blue-100)')};

  padding: 5px 0.5rem;
  background: ${props => (props.clearFilters ? '#1DAEFF' : 'transparent')};
  border-radius: 0.25rem;
  border: 1px solid ${props => (props.clearFilters ? 'transparent' : '#1DAEFF')};

  ${props =>
    props.counter &&
    css`
      &::before {
        content: '${props.counter}';
        position: absolute;
        right: -0.625rem;
        top: -0.375rem;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 1.15rem;
        height: 1.15rem;
        border-radius: 50%;
        background: var(--blue-500);
        color: var(--white);

        font-size: 0.75rem;
        &::content {
          color: red;
        }
      }
    `};
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
