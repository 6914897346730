import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';

import { Modal } from '@mui/material';
import { Button, Checkbox, Input } from 'components/Atoms';
import { useAuth } from 'hooks/auth';

import * as S from './style';

export interface RegisterModalProps {
  isOpen: boolean;
  onLogin: () => void;
  onClose: () => void;
}

export default function RegisterModal({
  isOpen,
  onLogin,
  onClose,
}: RegisterModalProps) {
  const { registerUser, error, errors, loading } = useAuth();

  const { handleSubmit, register, control } = useForm();
  const onSubmit = (data: {
    name: string;
    email: string;
    password: string;
    phone: string;
  }) => {
    registerUser({ ...data, type_id: 1 }, onLogin);
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <S.ModalContainer>
        <S.ModalContent>
          <S.Header>
            <h3>Cadastrar-se!</h3>
            <p>Preencha os dados abaixo para criar sua conta.</p>
          </S.Header>

          {!!error?.length && (
            <>
              <br />
              <small style={{ color: 'red' }}>{error}</small>
            </>
          )}
          <br />
          <br />

          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <Input
              placeholder="Digite seu nome completo"
              fontColor="var( --primary-neutral-900)"
              name="name"
              ref={register()}
              error_message={errors.name?.length ? errors.name[0] : ''}
              bordered
              bolder_font
            />

            <Input
              placeholder="Digite seu e-mail"
              fontColor="var( --primary-neutral-900)"
              name="email"
              ref={register()}
              error_message={errors.email ? errors.email[0] : ''}
              bordered
              bolder_font
            />

            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={props => (
                <PhoneInput
                  placeholder="Digite seu telefone"
                  country={'br'}
                  inputClass={'phoneInput'}
                  {...props}
                />
              )}
            />

            {errors.phone && (
              <span
                style={{
                  fontWeight: 'medium',
                  display: 'block',
                  marginTop: '-14px',
                  fontSize: '14px',
                  color: 'var(--error-700)',
                }}
              >
                {errors.phone[0]}
              </span>
            )}

            <Input
              type="password"
              placeholder="Digite uma senha"
              fontColor="var( --primary-neutral-900)"
              name="password"
              ref={register()}
              error_message={errors.password ? errors.password[0] : ''}
              bordered
              bolder_font
            />

            <Input
              type="password"
              placeholder="Repita a senha"
              fontColor="var( --primary-neutral-900)"
              name="password_confirmation"
              ref={register()}
              error_message={
                errors.password_confirmation
                  ? errors.password_confirmation[0]
                  : ''
              }
              bordered
              bolder_font
            />

            <Button
              color="blue"
              type="submit"
              disabled={loading}
              loading={loading}
              aria-label="Entrar"
              name="submit-login-btn"
            >
              Criar conta
            </Button>

            <S.MessageWithLink
              onClick={onClose}
              aria-label="Voltar"
              name="give-up-login-btn"
            >
              Voltar
            </S.MessageWithLink>
          </S.Form>
        </S.ModalContent>
      </S.ModalContainer>
    </Modal>
  );
}
