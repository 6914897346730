import styled, { css } from 'styled-components';

import { IInputProps } from './IInput';

type IInputWrapperProps = Pick<
  IInputProps,
  'bordered' | 'fontColor' | 'background_color'
> & {
  active: boolean;
};

type IInputStyleProps = Pick<IInputProps, 'fontColor' | 'bolder_font'>;

type IContainerProps = Pick<IInputProps, 'fontColor'>;

type ISeePasswordButton = {
  seePass?: boolean;
};

export const Container = styled.div<IContainerProps>`
  width: 100%;

  > label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;

    color: ${props => props.fontColor};
  }
`;

export const InputWrapper = styled.div<IInputWrapperProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 0.65rem 1rem;

  background: ${props =>
    props.background_color ? props.background_color : 'var(--white)'};

  border: ${props =>
    props.bordered ? `1px solid ${props.fontColor}` : 'none'};
  border-radius: 0.325rem;

  ${props =>
    !props.active &&
    css`
      border-color: var(--primary-neutral-650);

      &:hover {
        border-color: var(--primary-neutral-900);
      }
    `};

  transition: all 0.25s;
`;

export const Input = styled.input<IInputStyleProps>`
  width: 90%;

  outline: none;
  border: none;

  font-size: 16px;
  color: ${props => props.fontColor};
  background-color: transparent;

  padding-right: 10px;

  font-weight: ${props => (props.bolder_font ? '600' : 'initial')};

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &[type='password'] {
    -webkit-text-security: '*' !important;
  }
`;

export const SeePasswordButton = styled.button<ISeePasswordButton>`
  width: 22px;
  height: 22px;
  background: none;
  border: none;

  &,
  svg,
  svg path {
    transition: all 0.25s;
  }

  ${props =>
    props.seePass &&
    css`
      svg path {
        stroke: var(--primary-neutral-900);
      }
    `}
`;

export const ErrorMessage = styled.span`
  font-weight: medium;
  display: block;
  margin-top: 6px;

  font-size: 14px;

  color: var(--error-700);
`;
