import { useCallback, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Box, TextField } from '@mui/material';
import { ReactComponent as Whatsapp } from 'common/assets/svgs/whatsapp_filled.svg';
import { formatCurrency } from 'common/utils/masks';
import { Button, Checkbox } from 'components/Atoms';
import { useToast } from 'hooks/toast';
import api from 'services/api';

import { useAuth } from '../../../../hooks/auth';
import useInputMask from '../../../../hooks/useInputMask';
import { InfosToBrokerProps } from './InfosToBroker';
import * as S from './styles';

interface IComunicateStatus {
  sold: boolean;
  rented: boolean;
  unavailable: boolean;
}

function InfosToBroker({
  avaliation_value,
  captador,
  link_enterprise,
  commission,
  keys_local,
  owner,
  owner_phone,
  captador_phone,
  property_id,
  address,
  evaluated,
  lat,
  lng,
  branch_agency,
  key_number,
  visible,
  mensageWppCaptador,
  mensageWppProprietario,
  user_include,
  data_inclusao,
  data_edit,
  broker_info,
}: InfosToBrokerProps) {
  const { convertDate } = useInputMask();
  const { userData } = useAuth();
  // const [visibleInfos, setVisibleInfos] = useState(visible ? visible : false);
  const [comunicateStatus, setComunicateStatus] = useState<IComunicateStatus>({
    rented: false,
    sold: false,
    unavailable: false,
  });
  const [showAtualizationInformation, setShowAtualizationInformation] =
    useState(false);
  const navigate = useNavigate();
  const ref = useRef(null);
  const { control } = useForm();
  const { addToast } = useToast();
  // useEffect(() => {
  //   if (ref !== null) controlHideDiv(ref, () => setVisibleInfos(false));
  // }, [ref]);

  const handleSendData = useCallback(() => {
    let status = '';
    let message = '';

    if (comunicateStatus.rented) {
      status = 'aluguel_comunicado';
      message = 'Aluguel do imóvel comunicado';
    } else if (comunicateStatus.sold) {
      status = 'venda_comunicada';
      message = 'Venda do imóvel comunicada';
    } else if (comunicateStatus.unavailable) {
      status = 'indisponivel_comunicado';
      message = 'Indisponibilidade do imóvel comunicada';
    }

    if (status !== '') {
      api
        .put(`properties/comunicarAlteracaoStatus/${property_id}`, { status })
        .then(() => {
          addToast({
            type: 'info',
            title: message,
          });
        });
    } else {
      addToast({
        type: 'error',
        title: 'Selecione o tipo de comunicado a ser enviado',
      });
    }
  }, [comunicateStatus, addToast]);

  const { phoneMask } = useInputMask();
  const validateOwnerPhone = (phone: string) => {
    let newPhone = phone;
    console.log(phone);
    if (phone && phone.substr(0, 2) === '55') {
      newPhone = phoneMask(phone.slice(2));
    }
    return newPhone;
  };

  const insertAtualizaçãoImovel = () => {
    const formData = new FormData();
    formData.append(`description`, control.getValues('AtualizationInfo'));

    if (!control.getValues('AtualizationInfo')) {
      addToast({
        title: 'Atenção!',
        description: 'Informe a descrição da informação de atualização',
        type: 'info',
      });
      return;
    }

    api
      .post(`/properties/atualization-info/${property_id}`, formData)
      .then(res => {
        addToast({
          title: 'Informação registrada!',
          type: 'success',
        });
        setShowAtualizationInformation(false);
        control.setValue('AtualizationInfo', '');
      })
      .catch(err => {
        addToast({
          title: 'Ocorreu um erro ao cadastrar!',
          description: err.response.data.message,
          type: 'error',
        });
      });
  };

  return (
    <S.InfoBoxModal ref={ref}>
      <S.ListInfos>
        {commission && (
          <div>
            <span className="title">Comissão:</span>
            <span className="value">{formatCurrency(commission)}</span>
          </div>
        )}

        <div>
          <span className="title">Captador:</span>
          <span className="value">{captador}</span>
        </div>

        {captador_phone !== null && (
          <div>
            <span className="title">Telefone do captador:</span>
            <a
              href={`tel:+55${captador_phone}`}
              className="value"
              aria-label="Ligar"
            >
              {phoneMask(captador_phone)}
            </a>
            <a
              aria-label="Entrar em contato por WhatsApp"
              href={`https://wa.me/+55${captador_phone}?text=${encodeURI(
                mensageWppCaptador as string,
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              <Whatsapp />
            </a>
          </div>
        )}

        {userData.access !== 'Bloqueado' &&
        userData.current_access_limit !== 0 ? (
          <div>
            <span className="title">Nome do proprietário(a):</span>
            <span className="value">{owner}</span>
          </div>
        ) : (
          ''
        )}

        {userData.access !== 'Bloqueado' &&
          userData.current_access_limit !== 0 &&
          owner_phone !== null && (
            <div>
              <span className="title" style={{ width: '100px' }}>
                Telefone do proprietário(a):
              </span>
              <a href={`tel:+${owner_phone}`} aria-label="Ligar">
                {validateOwnerPhone(owner_phone)}
              </a>
              <a
                aria-label="Entrar em contato por Whatsapp"
                href={`https://wa.me/+${owner_phone}?text=${encodeURI(
                  mensageWppProprietario as string,
                )}`}
                target="_blank"
                rel="noreferrer"
              >
                <Whatsapp />
              </a>
            </div>
          )}

        <div>
          <span className="title">Imóvel avaliado:</span>
          <span className="value">{evaluated ? 'Sim' : 'Não'}</span>
        </div>

        {evaluated && (
          <div>
            <span className="title">Avaliação do Imóvel:</span>
            <span className="value">{formatCurrency(avaliation_value)}</span>
          </div>
        )}

        <div>
          <span className="title">Endereço:</span>
          <a
            aria-label="Ver no google maps"
            href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
            target="_blank"
            rel="noreferrer"
          >
            {address}
          </a>
        </div>
        {link_enterprise !== null && (
          <div>
            <span className="title">Link:</span>
            <a
              aria-label="Acessar link"
              href={
                link_enterprise.indexOf('http') === -1
                  ? 'http://' + link_enterprise
                  : link_enterprise
              }
              target="_blank"
              rel="noreferrer"
            >
              {link_enterprise.substr(0, 30)}...
            </a>
          </div>
        )}

        <div>
          <span className="title">Local das Chaves:</span>
          <span className="value">{keys_local}</span>
        </div>
        {branch_agency ? (
          <div>
            <span className="title">Agência:</span>
            <span className="value">{branch_agency}</span>
          </div>
        ) : (
          ''
        )}

        {key_number ? (
          <div>
            <span className="title">Chaves da agência:</span>
            <span className="value">{key_number}</span>
          </div>
        ) : (
          ''
        )}
        {user_include && (
          <div>
            <span className="title">Adicionado por:</span>
            <span className="value">{user_include.name}</span>
          </div>
        )}
        {data_inclusao && (
          <div>
            <span className="title">Em:</span>
            <span className="value">
              {convertDate(data_inclusao.split('T')[0])}
            </span>
          </div>
        )}
        {data_edit !== data_inclusao && (
          <div>
            <span className="title">Editado em:</span>
            <span className="value">
              {convertDate(data_edit && data_edit.split('T')[0])}
            </span>
          </div>
        )}
      </S.ListInfos>

      {!!broker_info?.length && (
        <div>
          <span style={{ fontWeight: 'bold' }}>Informações ao corretor</span>
          <br />
          <span>{broker_info}</span>
        </div>
      )}

      <S.NotifyStatus>
        <h4>Notificar status do imóvel</h4>

        <div>
          <Checkbox
            data={{ name: 'vendido', selected: comunicateStatus.sold }}
            onClick={() =>
              setComunicateStatus({
                rented: false,
                sold: !comunicateStatus.sold,
                unavailable: false,
              })
            }
            description="Vendido"
          />

          <Checkbox
            data={{ name: 'alugado', selected: comunicateStatus.rented }}
            onClick={() =>
              setComunicateStatus({
                rented: !comunicateStatus.rented,
                sold: false,
                unavailable: false,
              })
            }
            description="Alugado"
          />

          <Checkbox
            data={{
              name: 'indisponivel',
              selected: comunicateStatus.unavailable,
            }}
            onClick={() =>
              setComunicateStatus({
                rented: false,
                sold: false,
                unavailable: !comunicateStatus.unavailable,
              })
            }
            description="Indisponível"
          />
        </div>
      </S.NotifyStatus>
      {showAtualizationInformation ? (
        <Box gridTemplateColumns={'79.5% 19.5%'} marginTop="10px">
          <Controller
            name={'AtualizationInfo'}
            control={control}
            defaultValue=""
            render={props => (
              <TextField
                type="text"
                multiline
                maxRows={4}
                label="Informação de atualização"
                sx={{ width: '100%' }}
                {...props}
              />
            )}
          />
          <Box display={'flex'} justifyContent="end" marginTop="10px">
            <Button
              color="high_blue"
              type="button"
              onClick={insertAtualizaçãoImovel}
            >
              Adicionar
            </Button>
          </Box>
        </Box>
      ) : (
        ''
      )}
      <Button
        type="button"
        color="green"
        full_width
        className={'marginTop20'}
        onClick={() =>
          setShowAtualizationInformation(!showAtualizationInformation)
        }
        aria-label="Enviar alteração"
        name="send-btn"
      >
        Informar atualização
      </Button>
      <Button
        type="button"
        color="high_blue"
        full_width
        className={'marginTop10'}
        onClick={handleSendData}
        aria-label="Enviar alteração"
        name="send-btn"
      >
        Enviar
      </Button>
      {userData.type_id === 2 || userData.type_id === 4 ? (
        <Button
          type="button"
          color="blue"
          full_width
          className={'marginTop10'}
          onClick={() => navigate(`/editar-imovel/${property_id}`)}
          aria-label="Editar imóvel"
          name="edit-property-btn"
        >
          Editar imóvel
        </Button>
      ) : (
        ''
      )}
    </S.InfoBoxModal>
  );
}

export { InfosToBroker };
