import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1.5fr;

  min-height: 100vh;
  width: 100%;

  @media (max-width: 884px) {
    padding: 0 24px;
    grid-template-columns: 1fr;
  }
`;

export const SlideContainer = styled.aside`
  position: relative;

  padding: 1rem 6.25rem;

  background-color: var(--white100);

  @media (max-width: 884px) {
    display: none;
  }
`;

export const MainContainer = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    padding: 1.5rem 0;
    align-items: flex-start;
  }

  @media (max-width: 884px) {
    justify-content: center;
  }
`;
