import { Header, Footer } from 'components/Organisms';
import { ICommonProps } from 'models/ICommonProps';

import * as S from './styles';

function PublicTemplate({ children }: ICommonProps) {
  return (
    <S.Container>
      <Header />
      <S.Content>{children}</S.Content>
      <Footer />
    </S.Container>
  );
}

export { PublicTemplate };
