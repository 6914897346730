import { useCallback, useEffect, useState, lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import apartment from 'common/assets/images/apartamento.webp';
import condominium from 'common/assets/images/casa.webp';
import lot from 'common/assets/images/lote.webp';
import loft from 'common/assets/images/sobrado.webp';
import { ReactComponent as ClockIcon } from 'common/assets/svgs/clock.svg';
import { ReactComponent as MessageTextIcon } from 'common/assets/svgs/message_text.svg';
import { ReactComponent as SearchIcon } from 'common/assets/svgs/search_normal.svg';
import { routesNames } from 'common/utils/routesNames';
import { PageDefaultWrapper } from 'components/Atoms';
import { Title } from 'components/Atoms/Title';
import { AntecipateStage, HasQuestion } from 'components/Molecules';
import { HomeSearch, HighlightCarousel } from 'components/Organisms';
import { useModal } from 'components/Organisms/ModalMensagemComunicado';
import { Alert } from 'helpers';
import { useSearch } from 'hooks/search';
import api, { getLandingCommunications } from 'services/api';

const GoogleReview = lazy(() => import('components/Organisms/GoogleReview'));
const ModalMensagemComunicado = lazy(
  () => import('components/Organisms/ModalMensagemComunicado'),
);

import * as S from './styles';

function LandingPage() {
  const { updateFilter, keys } = useSearch();
  const [totalHouse, setTotalHouse] = useState<number | undefined>();
  const [totalApartament, setTotalApartament] = useState<number | undefined>();
  const [totalLoft, setTotalLoft] = useState<number | undefined>();
  const [totalLot, setTotalLot] = useState<number | undefined>();
  const navigate = useNavigate();
  const [alertIndex, setAlertIndex] = useState(-1);
  const [communicates, setCommunicates] = useState([]);

  const comunicadoModal = useModal();
  const [modalCommunicateError] = useState(false);
  const [dataModal, setDataModal] = useState({
    mensage: '',
    properties: [],
  });

  useEffect(() => {
    /* resetFilters();*/
    api.get('/properties?type_property=Casa&count=true').then(req => {
      setTotalHouse(req.data.imoveis.total);
    });
    api.get('/properties?type_property=Apartamento&count=true').then(req => {
      setTotalApartament(req.data.imoveis.total);
    });
    api.get('/properties?type_property=Sobrado&count=true').then(req => {
      setTotalLoft(req.data.imoveis.total);
    });
    api.get('/properties?type_property=Lote&count=true').then(req => {
      setTotalLot(req.data.imoveis.total);
    });

    loadComunicates();
  }, []);

  async function loadComunicates() {
    const response = await getLandingCommunications();
    setCommunicates(response);
    setAlertIndex(0);
  }

  async function showCommunicate() {
    if (!communicates.length || communicates.length < alertIndex + 1) return;
    // @ts-ignore
    if (communicates[0].properties) {
      // @ts-ignore
      const text = communicates[0].description;
      // @ts-ignore
      const properties = communicates[0].properties;
      setDataModal({
        mensage: text,
        properties: properties,
      });
      comunicadoModal.open();
    } else {
      await Alert.fire({ html: (communicates[alertIndex] as any).description });
      setAlertIndex(alertIndex + 1);
    }
  }

  useEffect(() => {
    if (alertIndex === -1) return;
    showCommunicate();
  }, [alertIndex]);

  const handleClick = useCallback(
    (origin: string) => {
      updateFilter({ type: origin });
      navigate(routesNames.search);
    },
    [updateFilter],
  );

  const closeModal = () => {
    comunicadoModal.close();
  };

  return (
    <>
      <Helmet>
        <title>
          Prado Imóveis | Imóveis à Venda em Goiânia e Aparecida de Goiânia
        </title>
        <meta
          name="description"
          content={`Encontre Casas, Apartamentos e Imóveis à Venda em Goiânia e Aparecida de Goiânia na Prado Imóveis. Seu Lar Perfeito Espera por Você!`}
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <PageDefaultWrapper>
        <S.TopPage>
          <S.Wrapper>
            <h1>Encontre um lar para chamar de seu!</h1>
            <h2>
              Selecione os imóveis do seu jeito, converse conosco, tire todas as
              suas dúvidas e agende uma visita, você terá o melhor atendimento.
            </h2>

            <S.Banner aria-label="Casal com chave na mão" />

            <HomeSearch key={keys.general} />
          </S.Wrapper>

          <S.Banner aria-label="Casal com chave na mão" />
        </S.TopPage>

        <S.StepMessage>
          <div className="text_description">
            <h3>
              Veja como é simples e fácil encontrar o imóvel ideal para você!
            </h3>
            <span>São apenas 3 passos.</span>
          </div>

          <S.StepsWrapper>
            <S.Step step="1" aria-label="Primeiro passo para compra do imóvel">
              <SearchIcon />
              <span>Busque pelo imóvel utilizando nossa busca.</span>
            </S.Step>

            <S.Step step="2" aria-label="Segundo passo para compra do imóvel">
              <MessageTextIcon />
              <span>Converse com o corretor, agende o dia e o horário.</span>
            </S.Step>

            <S.Step step="3" aria-label="Terceiro passo para compra do imóvel">
              <ClockIcon />
              <span>Analise rápida de crédito para o seu financiamento.</span>
            </S.Step>
          </S.StepsWrapper>
        </S.StepMessage>

        <HighlightCarousel />

        <S.ResumeDataWrapper>
          <div>
            <AntecipateStage />
            <HasQuestion />
          </div>

          <div className="property-container">
            <S.PropertyOffer>
              <div>
                <h4>Casas</h4>
                <span>
                  Casa padrões e em condomínios: {totalHouse} unidades
                </span>
                <button
                  type="button"
                  onClick={() => handleClick('Casa')}
                  aria-label="Visualizar todas as casas"
                  name="see-all-houses-btn"
                >
                  Visualizar todos
                </button>
              </div>

              <img
                width={300}
                height={240}
                src={condominium}
                alt="Casa em condomínio com dois carros estacionados"
              />
            </S.PropertyOffer>

            <S.PropertyOffer>
              <div>
                <h4>Apartamentos</h4>
                <span>Apartamentos {totalApartament} unidades</span>
                <button
                  type="button"
                  onClick={() => handleClick('Apartamento')}
                  aria-label="Visualizar todos os apartamentos"
                  name="see-all-apartamentos-btn"
                >
                  Visualizar todos
                </button>
              </div>

              <img
                width={300}
                height={240}
                src={apartment}
                alt="Dois blocos de apartamentos em uma tarde de pôr do sol"
              />
            </S.PropertyOffer>
          </div>
          <div className="property-container">
            <S.PropertyOffer>
              <div>
                <h4>Sobrados</h4>
                <span>Sobrados {totalLoft} unidades</span>
                <button
                  type="button"
                  onClick={() => handleClick('Sobrado')}
                  aria-label="Visualizar todos os sobrados"
                  name="see-all-sobrados-btn"
                >
                  Visualizar todos
                </button>
              </div>

              <img width={300} height={240} src={loft} alt="Sobrados" />
            </S.PropertyOffer>

            <S.PropertyOffer>
              <div>
                <h4>Lotes</h4>
                <span>Lotes {totalLot} unidades</span>
                <button
                  type="button"
                  onClick={() => handleClick('Lote')}
                  aria-label="Visualizar todos os lotes"
                  name="see-all-lotes-btn"
                >
                  Visualizar todos
                </button>
              </div>

              <img width={300} height={240} src={lot} alt="Lotes" />
            </S.PropertyOffer>
          </div>
        </S.ResumeDataWrapper>

        <S.GoogleReviewsContainer>
          <Title>O que dizem sobre nós?</Title>
          <Suspense fallback={<></>}>
            <GoogleReview />
          </Suspense>
        </S.GoogleReviewsContainer>
      </PageDefaultWrapper>

      <Suspense fallback={<></>}>
        <ModalMensagemComunicado
          {...comunicadoModal.props}
          mensage={dataModal.mensage}
          properties={dataModal.properties}
          onConfirm={closeModal}
          onError={modalCommunicateError}
        />
      </Suspense>
    </>
  );
}

export { LandingPage };
