import styled from 'styled-components';

interface IImageSlide {
  image: string;
}

export const Wrapper = styled.div`
  row-gap: 24px;

  h1 {
    font-size: 2.4rem;
    font-weight: 700;
    color: var(--primary-neutral-900);
  }

  p {
    font-weight: 500;
    font-size: 1rem;
    color: var(--primary-neutral-650);
    line-height: 1.75rem;
  }
`;

export const Container = styled.section`
  &,
  ${Wrapper} {
    display: flex;
    flex-direction: column;
  }

  height: 100%;
  width: 100%;

  row-gap: 8vh;
`;

export const ImageSlide = styled.div<IImageSlide>`
  width: 100%;
  height: 45%;

  background: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: contain;
`;
