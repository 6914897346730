import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  background-color: var(--white);
`;

export const Content = styled.main`
  max-width: var(--content-width);
  margin: 0 auto;

  padding-bottom: 2.5rem;

  @media (max-width: 884px) {
    padding-bottom: 2rem;
  }
`;
