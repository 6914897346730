import { FiMapPin } from 'react-icons/fi';

import MuiModal from '@mui/material/Modal';
import { Property } from '@types';
import constants from 'common/constants';
import Str from 'common/utils/Str';
import { Button, Logo } from 'components/Atoms';
import CloseButton from 'components/Atoms/CloseButton';
import { Title } from 'components/Atoms/Title';
import BaseInput from 'components/Molecules/BaseInput';
import { Alert } from 'helpers';
import useRootForm from 'hooks/useRootForm';
import C2SService from 'services/C2SService';

import * as S from './style';

export { useModal as useModal } from 'components/Atoms/modal/Modal';

const c2sService = new C2SService();

export type AgendarVisitaModalProps = {
  property?: Property;
  isOpen: boolean;
  close: () => void;
};

export default function AgendarVisitaModal({
  close,
  isOpen,
  property,
}: AgendarVisitaModalProps) {
  const { form, setFormErrors, register, setForm, isLoading, setIsLoading } =
    useRootForm<{
      name?: string;
      phone?: string;
      email?: string;
      data?: string;
      hour?: string;
      obs?: string;
    }>();

  async function handleSubmit(e: any) {
    e.preventDefault();
    const errors: any = {};

    setFormErrors({});
    if (!form.name?.trim().length) errors['name'] = 'Informe o seu nome';
    if (!form.email?.trim().length) errors['email'] = 'Informe o seu e-mail';
    else if (!Str.validateEmail(form.email))
      errors['email'] = 'Informe um e-mail válido';
    if (!form.phone?.trim().length) errors['phone'] = 'Informe o seu telefone';

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return;
    }

    setIsLoading(true);
    const res = await c2sService.createLead({
      name: form.name!,
      email: form.email!,
      phone: form.phone!,
      body: `Agendamento de visita do. Imóvel: ${property?.code}, Link: ${window.location.href}, Data: ${form.data}, Hora: ${form.hour}, Obs: ${form.obs}`,
    });
    if (res?.isError) {
      Alert.error({ text: 'Falha ao registrar informações', error: true });
      return;
    }
    Alert.fire({
      text: 'Registro realizado com sucesso, entraremos em contato em breve.',
      icon: 'success',
    });
    setForm({
      name: '',
      email: '',
      phone: '',
      data: '',
      hour: '',
      obs: '',
    });
    close();
    setIsLoading(false);
    window.open(
      'https://wa.me/' +
        constants.defaultWppNumber +
        '?text=' +
        encodeURI(
          `Olá! Venho através do site solicitar um agendamento de visita para o imóvel ${
            property?.code
          }, Meus dados de contato são: Nome: ${form.name || ''}, Telefone: ${
            form.phone || ''
          }, Data: ${form.data || ''}, Horário: ${form.hour || ''}, Obs: ${
            form.obs || ''
          },`,
        ),
      '_blank',
    );
  }

  return (
    <MuiModal
      open={isOpen}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <S.Background>
          <S.Container>
            <header className="header">
              <Logo />
              <CloseButton onClick={close} />
            </header>

            <main>
              <form onSubmit={handleSubmit}>
                <main className="form-main">
                  <Title>Agendar uma visita</Title>
                  <br />
                  <BaseInput placeholder="Nome *" {...register('name')} />
                  <BaseInput placeholder="Telefone *" {...register('phone')} />
                  <BaseInput placeholder="E-mail *" {...register('email')} />
                  <BaseInput placeholder="Data" {...register('data')} />
                  <BaseInput placeholder="Horário" {...register('hour')} />
                  <BaseInput placeholder="Observação" {...register('obs')} />
                </main>

                <footer className="form-footer">
                  <Button
                    color="high_blue"
                    type="submit"
                    full_width
                    loading={isLoading}
                  >
                    Agendar Visita
                  </Button>
                </footer>
              </form>

              <div
                className="image-container desktop-only"
                style={{
                  backgroundImage: `url(${property?.images![0].image})`,
                }}
              >
                <div className="gradient">
                  <div className="location-container">
                    <FiMapPin size={20} />
                    <span>{property?.address}</span>
                  </div>

                  <p>{property?.name}</p>
                </div>
              </div>
            </main>
          </S.Container>
        </S.Background>
      </div>
    </MuiModal>
  );
}
