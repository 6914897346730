import { Property } from '@types';
import api, {
  addFavorite as addFav,
  removeFavorite as removeFav,
} from 'services/api';

import { Response } from './BaseService';

export type FindParams = {
  id: string | number;
};

export default class PropertyService {
  async find(
    { id }: FindParams,
    options?: { isLogged?: boolean },
  ): Promise<Response<Property>> {
    const url = options?.isLogged
      ? `/propertiesAuth/${id}?view=true`
      : `/properties/${id}`;
    console.clear();

    console.log('ENDPOINT DE BUSCA', url);
    console.log('ENDPOINT DE BUSCA', options?.isLogged);
    try {
      const data = await api.get(url);
      return {
        isError: false,
        data: data.data.data,
        code: data.status ?? 500,
      };
    } catch (err) {
      return {
        isError: true,
        data: (err as any)?.response?.data,
        code: (err as any)?.response?.status ?? 500,
      };
    }
  }

  async addFavorite(propertyId: number) {
    const stringData = localStorage.getItem('@user:prado_imoveis');
    const data = JSON.parse(stringData!);
    try {
      const res = await api.post(
        `favorites/${propertyId}`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + data.token,
          },
        },
      );
      return {
        isError: false,
        data: res.data.data as { id: number },
        code: res.status ?? 500,
      };
    } catch (err) {
      return {
        isError: true,
        data: (err as any)?.response?.data,
        code: (err as any)?.response?.status ?? 500,
      };
    }
  }

  async removeFavorite(favoritePivotId: number) {
    const stringData = localStorage.getItem('@user:prado_imoveis');
    const data = JSON.parse(stringData!);
    try {
      const res = await api.delete(`favorites/${favoritePivotId}`, {
        headers: {
          Authorization: 'Bearer ' + data.token,
        },
      });
      return {
        isError: false,
        data: res.data.data,
        code: res.status ?? 500,
      };
    } catch (err) {
      return {
        isError: true,
        data: (err as any)?.response?.data,
        code: (err as any)?.response?.status ?? 500,
      };
    }
  }
}
