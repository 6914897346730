import { Container } from './styles';

interface HamburgerButtonProps {
  color: string;
  checked: boolean;
  clickEvent: () => void;
}

function HamburgerButton({ color, checked, clickEvent }: HamburgerButtonProps) {
  return (
    <Container color={color} onClick={() => clickEvent()}>
      <input type="checkbox" checked={checked} />

      <span />
      <span />
      <span />
    </Container>
  );
}

export { HamburgerButton };
