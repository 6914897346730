import { Helmet } from 'react-helmet-async';

import { Box, Typography, useMediaQuery, TextField } from '@mui/material';

import { PageDefaultWrapper } from '../../components/Atoms';
import { HighlightCarousel } from '../../components/Organisms';
function NotFound() {
  return (
    <>
      <Helmet>
        <title>Prado imóveis | Pagina não encontrada</title>
        <meta name="description" content={`Pagina não encontrada`} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Box
        width="100%"
        height="100%"
        padding="80px 50px"
        sx={{ background: 'var(--white)' }}
      >
        <Box
          display="flex"
          flexDirection={'column'}
          alignItems="center"
          justifyContent="flex-start"
        >
          <h1
            style={{
              fontSize: '80px',
              fontWeight: 'bold',
              marginBottom: '60px',
            }}
          >
            404, página não encontrada.
          </h1>
          <div style={{ width: '100%' }}>
            <HighlightCarousel />
          </div>
        </Box>
      </Box>
    </>
  );
}
export { NotFound };
export default NotFound;
