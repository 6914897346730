import styled from 'styled-components';

export const Characteristics = styled.div`
  > span {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--grey-700);
  }

  .title {
    margin-top: 20px;
  }
`;

export const CharacteristicsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 0.6rem;

  margin-top: 5px;

  > div {
    display: flex;
    align-items: center;
    column-gap: 0.85rem;

    width: 100%;
    height: 100%;

    svg {
      width: 1.5rem;
      height: 1.5rem;

      path {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    span {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  @media (max-width: 884px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.5rem;
    row-gap: 0.75rem;

    > div {
      column-gap: 0.5rem;

      svg {
        width: 1.35rem;
        height: 1.35rem;
      }

      span {
        font-size: 0.85rem;
      }
    }
  }
`;

export type ControlProps = {
  isOpen: boolean;
};

export const Container = styled.div<ControlProps>`
  position: relative;
  overflow: hidden;
  max-height: ${({ isOpen }) => (isOpen ? 'auto' : '140px')};
  transition: 0.2s;
  min-height: 140px;
`;

export const ShowButton = styled.button<ControlProps>`
  position: ${({ isOpen }) => (isOpen ? 'initial' : 'absolute')};
  bottom: 0;
  left: 0;
  right: 0;
  top: 34px;
  background: transparent;
  color: var(--blue-400);
  transition: 0.2s;
  border: none;
  font-size: 1rem;
  background: linear-gradient(180deg, transparent, white, white);
  padding-top: ${({ isOpen }) => (isOpen ? '10px' : '40px')};
  font-weight: 600;
  width: 100%;

  &:hover {
    color: var(--blue-200);
  }
`;
