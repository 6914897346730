import { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Modal } from '@mui/material';
import { Button, Checkbox, Input } from 'components/Atoms';
import { useAuth } from 'hooks/auth';
import { ILoginData } from 'models/ILoginData';

import * as S from './style';

export interface LoginModalProps {
  isOpen: boolean;
  onLogin: () => void;
  onClose: () => void;
  openRegister?: any;
  openResetSenha?: () => void;
}

export default function LoginModal({
  isOpen,
  onLogin,
  onClose,
  openRegister,
  openResetSenha,
}: LoginModalProps) {
  const [rememberLogin, setRememberLogin] = useState(false);
  const { login, error, loading } = useAuth();

  const { register, handleSubmit, errors } = useForm<ILoginData>();
  const onSubmit = (data: ILoginData) => {
    login(data, onLogin);
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <S.ModalContainer>
        <S.ModalContent>
          <S.Header>
            <h3>Bem-vindo!</h3>
            <p>Digite o email para resetar sua senha</p>
          </S.Header>

          {!!error?.length && (
            <>
              <br />
              <small style={{ color: 'red' }}>{error}</small>
            </>
          )}
          <br />
          <br />
          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <Input
              type="email"
              placeholder="digite seu e-mail"
              fontColor="var( --primary-neutral-900)"
              name="email"
              ref={register({
                required: {
                  value: true,
                  message: 'Preencha seu e-mail para continuar',
                },
              })}
              error_message={errors.email?.message}
              bordered
              bolder_font
            />

            <Input
              type="password"
              placeholder="digite sua senha"
              fontColor="var( --primary-neutral-900)"
              name="password"
              ref={register({
                required: {
                  value: true,
                  message: 'Preencha sua senha para continuar',
                },
              })}
              error_message={errors.password?.message}
              bordered
              bolder_font
            />

            <div className="remember-container">
              <Checkbox
                data={{ name: 'remember_login', selected: rememberLogin }}
                onClick={() => setRememberLogin(!rememberLogin)}
                description="Lembrar de mim"
              />
            </div>

            <Button
              color="blue"
              type="submit"
              disabled={loading}
              loading={loading}
              aria-label="Entrar"
              name="submit-login-btn"
            >
              Entrar
            </Button>
            <S.newUser
              onClick={openRegister}
              aria-label="Cadastro"
              name="give-up-login-btn"
            >
              Não tem cadastro ainda? Clique aqui!
            </S.newUser>

            <S.resetSenha
              onClick={openResetSenha}
              aria-label="Senha"
              name="give-up-login-btn"
            >
              Esqueceu a senha? Clique aqui!
            </S.resetSenha>

            <S.MessageWithLink
              onClick={onClose}
              aria-label="Voltar"
              name="give-up-login-btn"
            >
              Voltar
            </S.MessageWithLink>
          </S.Form>
        </S.ModalContent>
      </S.ModalContainer>
    </Modal>
  );
}
