import styled, { css } from 'styled-components';

interface IContainerProps {
  image: string;
  full_height?: boolean;
}

interface IContactButtonProps {
  variant: 'email' | 'whatsapp';
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.full_height ? 'center' : 'flex-end')};
  align-items: center;

  row-gap: 0.75rem;

  height: ${props => (props.full_height ? '100%' : '18.5rem')};
  width: 100%;

  border: 1px solid var(--primary-neutral-300);
  border-radius: 0.5rem;
  padding: 1.5rem;

  .image {
    width: 3rem;
    height: 3rem;
    border-radius: 0.25rem;

    background-image: url(${props => props.image});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .name {
    font-size: 1rem;
    font-weight: 700;
    color: var(--primary-neutral-900);
  }

  .responsibility {
    font-size: 0.875rem;
    color: var(--primary-neutral-600);
    text-transform: uppercase;
  }

  .company {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;

    font-size: 0.75rem;
    color: var(--primary-neutral-600);
  }

  .properties_qtd {
    font-size: 0.875rem;
    color: var(--primary-neutral-900);
  }
`;

export const Contact = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  column-gap: 0.75rem;
`;

export const ContactButton = styled.button<IContactButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  column-gap: 0.5rem;

  width: 40%;
  height: 2.5rem;

  border: none;
  border-radius: 0.25rem;

  color: var(--white);
  font-weight: 500;

  ${props =>
    props.variant === 'email' &&
    css`
      background: var(--blue-500);
    `}

  ${props =>
    props.variant === 'whatsapp' &&
    css`
      background: var(--green-200);
    `}
`;
