import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import useCatalogo from 'hooks/useCatalogo';
import api from 'services/api';
import { Catalogo } from 'types';

import { Button, DefaultPageTitle } from '../../components/Atoms';
import { DataTable } from '../../components/Atoms/DataTable';
import { useAuth } from '../../hooks/auth';

function Catalog() {
  const navigate = useNavigate();
  const { destroy } = useCatalogo();

  const [list, setList] = useState();
  const [pagesTotal, setPagesTotal] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const handleChangePage = (_: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  const { logoutLocal } = useAuth();

  function loadData() {
    api
      .get(`/catalogo?page=${currentPage}`)
      .then(({ data }) => {
        const response = data.data;
        setPagesTotal(response.last_page);
        setCurrentPage(response.current_page);
        setList(response.data);
      })
      .catch(error => {
        if (error.response.status === 401) {
          logoutLocal();
        }
      });
  }

  useEffect(() => {
    loadData();
  }, [currentPage]);

  const columns = [
    {
      title: 'Id',
      field: 'id',
    },
    {
      title: 'Titulo',
      field: 'styled_titulo',
      type: 'html',
    },
  ];

  const edit = (data: any) => {
    navigate(`/catalogo/${data.id}`);
  };

  const view = (data: any) => {
    const title = data.titulo.replaceAll(' ', '_');
    navigate(`/catalogo/${title}/${data.id}`);
  };

  function handleDelete(data: Catalogo) {
    destroy(data, {
      onSuccess: loadData,
    });
  }

  return (
    <Box
      width="100%"
      height="fit-content"
      padding="24px 26px"
      border="1px solid var(--primary-neutral-300)"
      borderRadius="16px"
      sx={{ background: 'var(--white)' }}
    >
      <Box
        display="flex"
        gap="20px"
        flexWrap="wrap"
        marginTop="20px"
        justifyContent="space-between"
      >
        <div>
          <DefaultPageTitle>Catálogos</DefaultPageTitle>
        </div>
        <div
          style={{
            justifyContent: 'flex-end',
            width: '46%',
            display: 'flex',
          }}
        >
          <Button
            color="high_blue"
            onClick={() => navigate(`/catalogo`)}
            type="button"
          >
            Novo catálogo
          </Button>
        </div>
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        marginTop="20px"
        justifyContent="center"
        alignItems={'center'}
        width={'100%'}
        flexDirection={'column'}
      >
        {list && (
          <DataTable
            width={'100%'}
            columns={columns}
            data={list}
            validIdUserRegister={true}
            onEdit={edit}
            onDelete={handleDelete}
            onView={view}
            pagesTotal={pagesTotal}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
          />
        )}
      </Box>
    </Box>
  );
}

export { Catalog };
export default Catalog;
