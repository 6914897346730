import { CheckboxProps } from './ICheckbox';
import * as S from './styles';

export default function Check({ label, value, setValue }: CheckboxProps) {
  return (
    <S.Container checked={value}>
      {label}
      <input
        type="checkbox"
        checked={value}
        onClick={() => setValue && setValue(!value)}
      />
      <span className="checkmark" />
    </S.Container>
  );
}
