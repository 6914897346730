import styled from 'styled-components';

export const Background = styled.div`
  background: white;
  height: 100vh;
  width: 100vw;
  padding: 0;
`;

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  //max-width: 820px;

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
  }
  .footer {
    display: flex;
  }

  > main {
    display: flex;
    /*box-shadow: 0px 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 7px;*/
    margin-top: 20px;

    > form {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .form-main {
      padding: 20px;
    }

    .form-footer {
      border-top: 1px solid var(--grey-200);
      padding: 20px;
    }

    .image-container {
      flex: 1;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;

      .gradient {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        padding: 20px;
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        background-image: linear-gradient(
          180deg,
          transparent,
          transparent,
          #000000
        );

        > p {
          margin-top: 10px;
          color: white;
          font-size: 1.2rem;
          font-weight: bold;
        }
      }

      .location-container {
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--grey-100);

        > .ver-no-mapa {
          text-decoration: none;
          color: var(--blue-400);
          margin-left: 10px;
          border: none;
          background-color: transparent;
        }
      }
    }
  }
`;

export const CardsContainer = styled.div`
  margin-top: 5px;
  display: grid;
  grid-template-columns: min-content;
  align-items: center;
  gap: 12px;
  justify-content: center;
  overflow: auto;
  height: 70vh;

  & > div {
    height: 100%;
  }

  @media (min-width: 670px) {
    grid-template-columns: min-content min-content;
  }

  @media (min-width: 885px) {
    grid-template-columns: min-content min-content;
  }

  @media (min-width: 1175px) {
    grid-template-columns: min-content min-content min-content;
  }

  @media (min-width: 1500px) {
    grid-template-columns: min-content min-content min-content min-content;
  }

  @media (min-width: 2000px) {
    grid-template-columns: min-content min-content min-content min-content min-content;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 15px;

  .actions-container {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }

  .filters-container {
    & > div {
      justify-content: center;
    }
  }

  @media (min-width: 481px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > button {
      width: revert;
    }
  }

  @media (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;

    .actions-container {
      justify-content: flex-end;
      margin-top: 0;
    }
  }
`;
