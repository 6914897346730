import { useEffect, useMemo, useRef, useState } from 'react';
import { Marker as MarkerLeftlet, Popup } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';

import { Skeleton } from '@mui/material';
import { formatCurrency } from 'common/utils/masks';
import { Button } from 'components/Atoms';
import { HouseCard } from 'components/Molecules';
import { HouseCardMap } from 'components/Molecules';
import { ILocation } from 'models/ILocation';

import { ReactComponent as Star } from '../../../../../common/assets/svgs/star.svg';
import { useAuth } from '../../../../../hooks/auth';
import { IMarkerProps } from '../../IMap';
import * as S from '../../styles';
function Marker({
  centerPosition,
  icon,
  lat,
  lng,
  alt,
  onClickMarker,
  property_id,
  property_name,
  property_price,
  property_bathrooms,
  property_bedrooms,
  property_houseArea,
  property_totalArea,
  property_metragem,
  property_slug,
  code,
  images,
  favoriteId,
  onClickDragend,
  fullMarkerPopup,
  markedProperty,
  catalogos,
  tarja,
}: IMarkerProps & {
  fullMarkerPopup?: boolean;
}) {
  const navigate = useNavigate();
  const markerRef = useRef(null);
  const [iconFinal, setIconFinal] = useState(icon);
  const { userData } = useAuth();
  useEffect(() => {
    if (!markerRef.current) return;
    // @ts-ignore
    markerRef.current.closePopup();

    if (centerPosition.property_id === property_id) {
      // @ts-ignore
      markerRef.current.openPopup();
      setIconFinal(icon);
      //console.log(lat, lng);
    }
    /* Antiga validação para abrir o box - deixado caso precisem utilizar */
    // const { lat, lng } = markerRef.current._latlng;
    // if (lat === centerPosition.lat && lng === centerPosition.lng) {
    //   // @ts-ignore
    //   markerRef.current.openPopup();
    // }
  }, [centerPosition]);

  const [text, setText] = useState(null);
  const eventHandlers = useMemo(
    () => ({
      dragend(e: { target: { getLatLng: () => any } }) {
        if (onClickDragend) {
          onClickDragend(e.target.getLatLng());
        }
      },
      click(e: { target: { getLatLng: () => any } }) {
        if (onClickMarker) {
          onClickMarker({
            lat,
            lng,
            alt,
            property_id,
            property_name,
            property_price,
            images,
          });
        }
      },
    }),
    [text],
  );

  return (
    <MarkerLeftlet
      draggable={true}
      position={{ lat: lat, lng: lng, alt: alt }}
      icon={iconFinal}
      eventHandlers={eventHandlers}
      title={property_name}
      ref={markerRef}
    >
      {property_name && (
        <Popup closeButton={false}>
          {fullMarkerPopup ? (
            <HouseCard
              tarja={tarja}
              id={Number(property_id)}
              name={property_name}
              value={property_price ?? 0}
              hasTooltipMap
              favoriteId={favoriteId}
              infos={{
                bathrooms: property_bathrooms ?? 0,
                bedrooms: property_bedrooms ?? 0,
                houseArea: property_houseArea ?? 0,
                totalArea: property_totalArea ?? 0,
                metragem: String(property_metragem) || '',
                slug: property_slug ?? '',
                code: code ?? '',
              }}
              images={images}
              location={{
                latitude: lat,
                longitude: lng,
                name: property_name,
              }}
            />
          ) : (
            <HouseCardMap
              id={Number(property_id)}
              withLoggedActions={!!userData.id}
              catalogos={catalogos}
              location={{
                latitude: lat,
                longitude: lng,
                name: property_name,
              }}
            />
          )}
        </Popup>
      )}
    </MarkerLeftlet>
  );
}

export { Marker };
