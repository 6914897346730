import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Typography, useMediaQuery, TextField } from '@mui/material';
import Label from 'components/Atoms/Label';
import LabelRequiredIndicator from 'components/Atoms/LabelRequiredIndicator';
import RichText from 'components/Atoms/RichText';
import useCatalogo from 'hooks/useCatalogo';

import { Button, DefaultPageTitle } from '../../../components/Atoms';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { IAddUser } from '../../../models/IAddUser';
import api from '../../../services/api';
import { ICatalogoForm } from './ICatalogForm';
import * as S from './style';

function CatalogForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { destroy } = useCatalogo();

  const [initialized, setInitialized] = useState(false);
  const [rawTitulo, setRawTitulo] = useState('');
  const [styledTitulo, setStyledTitulo] = useState('');
  const matches = useMediaQuery('(max-width: 884px)');
  const [title, setTitle] = useState('Cadastrar Catálogo');
  const { handleSubmit, control, errors, reset } = useForm();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { addToast } = useToast();
  const { logoutLocal } = useAuth();

  useEffect(() => {
    if (id) {
      setTitle('Editar Catálogo');
      api
        .get(`/catalogo/${id}`)
        .then(req => {
          const data = req.data.data;
          setRawTitulo(data.titulo);
          setStyledTitulo(data.styled_titulo);
          control.setValue('titulo', data.titulo);
          control.setValue('descricao', data.descricao ? data.descricao : '');
        })
        .catch(error => {
          if (error.response.status === 401) {
            logoutLocal();
          }
        });
    }
  }, []);

  useEffect(() => {
    if (initialized) setStyledTitulo(rawTitulo);
    setInitialized(true);
  }, [rawTitulo]);

  const onSubmit = useCallback(
    ({ descricao }: ICatalogoForm) => {
      const formData = new FormData();
      formData.append('titulo', rawTitulo);
      formData.append('styled_titulo', styledTitulo);
      formData.append('descricao', descricao);
      const url = id ? `/catalogo/${id}` : '/catalogo';
      api
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          setSubmitDisabled(false);
          addToast({
            title: response.data.message,
            type: 'success',
          });
          setTimeout(() => {
            navigate('/catalogos');
          }, 2000);
        })
        .catch(err => {
          addToast({
            title: 'Ocorreu um erro ao cadastrar!',
            description: err.response.data.message,
            type: 'error',
          });
          setSubmitDisabled(false);
        });
    },
    [id, rawTitulo, styledTitulo],
  );

  return (
    <>
      <Box
        width="100%"
        padding="24px 26px"
        border="1px solid var(--primary-neutral-300)"
        borderRadius="16px"
        sx={{ background: 'var(--white)' }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom={matches ? '14px' : '30px'}
        >
          <DefaultPageTitle>{title}</DefaultPageTitle>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          rowGap="24px"
          marginBottom={matches ? '24px' : ''}
        >
          <Typography
            fontSize="14px"
            fontWeight="400"
            color="var(--primary-neutral-650)"
          >
            <LabelRequiredIndicator /> Indica os campos obrigatórios
          </Typography>
        </Box>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display="flex"
            flexDirection="column"
            width="420px"
            rowGap="18px"
          >
            <Label required>Título</Label>

            <Controller
              name="titulo"
              control={control}
              render={props => (
                <Box>
                  <TextField
                    type="text"
                    placeholder="Digite o titulo do catálogo"
                    variant="standard"
                    sx={{ width: '100%' }}
                    {...props}
                    value={rawTitulo}
                    onChange={(e: any) => {
                      setRawTitulo(e.target.value);
                    }}
                  />
                  {errors.titulo && (
                    <S.ErrorMessage>* Informe o titulo</S.ErrorMessage>
                  )}
                </Box>
              )}
            />

            <RichText
              required
              label="Título Estilizado"
              value={styledTitulo}
              setValue={setStyledTitulo}
            />

            <Box
              display="flex"
              flexDirection="column"
              rowGap="12px"
              marginTop="18px"
            >
              <Label>Descrição do catálogo</Label>

              <Controller
                name="descricao"
                control={control}
                defaultValue=""
                render={props => (
                  <Box>
                    <Controller
                      name="descricao"
                      control={control}
                      defaultValue=""
                      render={props => (
                        <TextField
                          type="text"
                          multiline
                          maxRows={4}
                          // label="Descrição do catálogo"
                          sx={{ width: '100%' }}
                          {...props}
                        />
                      )}
                    />
                  </Box>
                )}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              rowGap="12px"
              marginTop="18px"
            >
              <Button color="high_blue" type="submit" full_width>
                Salvar
              </Button>
              {id ? (
                <Button
                  color="alpha-red"
                  type="button"
                  full_width
                  onClick={() =>
                    destroy(Number(id), { navigateTo: '/catalogos' })
                  }
                  disabled={submitDisabled}
                >
                  Deletar
                </Button>
              ) : (
                ''
              )}
              <Button
                color="white"
                type="button"
                full_width
                onClick={() => navigate('/catalogos')}
                disabled={submitDisabled}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </S.Form>
      </Box>
    </>
  );
}

export { CatalogForm };
export default CatalogForm;
