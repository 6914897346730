import footer_bg from 'common/assets/svgs/footer_bg.svg';
import { darken } from 'polished';
import styled from 'styled-components';

export const Link = styled.a<{ name?: string }>``;

export const Container = styled.footer`
  border-top: 1px solid var(--grey-100);

  @media (min-width: 840px) and (max-width: 1200px) {
    padding: 0 1.4rem;
  }
`;

export const RightsReserved = styled.span`
  font-size: 0.75rem;
  color: var(--grey-400);
`;

export const CompanyDescription = styled.div`
  align-items: flex-start;

  p {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.75rem;
    color: var(--grey-400);
  }

  a + a {
    margin-left: 1.75rem;
  }

  svg path {
    transition: all 0.25s;
  }

  a:hover {
    svg path {
      filter: brightness(0.8);
    }
  }

  @media (max-width: 884px) {
    ${RightsReserved} {
      display: none;
    }
  }
`;

export const FooterNavigation = styled.nav`
  &,
  ul li + li {
    margin-top: 1.4rem;
  }

  ul {
    list-style: none;
  }

  ul li a {
    position: relative;
    text-decoration: none;
    color: var(--black-700);
    font-weight: 600;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background: var(--black-700);
      transition: all 0.35s ease-in-out;
    }

    &:hover::after {
      width: 100%;
    }
  }

  h4 {
    font-size: 1.25rem;
  }

  @media (max-width: 884px) {
    ul li + li {
      margin-top: 2rem;
    }
  }
`;

export const Newsletter = styled.div`
  row-gap: 1.5rem;

  > a {
    width: 8rem;
    font-size: 1rem;
    font-weight: 600;

    padding: 0.5rem 1.25rem;
    border-radius: 0.5rem;
    border: none;
    border: 2px solid var(--blue-100);

    text-align: center;
    text-decoration: none;

    background: var(--alpha-blue-15);
    color: var(--blue-100);

    transition: all 0.25s ease-in-out;

    &:hover {
      border-color: ${darken(0.075, '#1DAEFF')};
      background: ${darken(0.075, '#1DAEFF')};
      color: var(--white);
    }
  }

  h5 {
    font-size: 1.5rem;
    width: 70%;
    line-height: 2.4rem;
  }

  @media (max-width: 884px) {
    margin-top: 2rem;

    button {
      display: none;
    }

    h5 {
      width: 100%;
      font-size: 1.25rem;
      font-weight: 700;
    }
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 30%);
  column-gap: 3%;

  max-width: var(--content-width);
  margin: 0 auto;
  padding: 3.5rem 0;

  background-image: url(${footer_bg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;

  > ${RightsReserved} {
    display: none;
  }

  ${CompanyDescription}, ${FooterNavigation}, ${Newsletter} {
    display: flex;
    flex-direction: column;
  }

  ${CompanyDescription}, ${FooterNavigation} {
    row-gap: 1.25rem;
  }

  > ${FooterNavigation} {
    align-items: center;
  }

  @media (max-width: 884px) {
    display: flex;
    flex-direction: column;
    padding: 2.5rem 1.25rem;

    > ${FooterNavigation} {
      align-items: flex-start;
    }

    > ${RightsReserved} {
      display: block;
      text-align: center;
      margin-top: 2.75rem;
    }
  }
`;
