import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import { routesNames } from 'common/utils/routesNames';
import { IAuthRequest, IUser } from 'models/IAuth';
import { ILoginData } from 'models/ILoginData';
import api from 'services/api';
import { USER_TYPE } from 'types';

import { useToast } from './toast';

type IUserData = IUser & {
  token: string;
};

interface IAuthContextData {
  userData: IUserData;
  setUserData: (user: IUserData) => void;
  error: string;
  loading: boolean;
  login(data: ILoginData, onLogin?: () => void): Promise<any>;
  logout(): void;
  logoutLocal(): void;
  subViewUserCorretor(): any;
  resetPassword: (data: { password: string; hash: string }) => void;
  registerUser: (
    data: {
      name: string;
      email: string;
      password: string;
      phone: string;
      type_id: number;
    },
    onLogin?: any,
  ) => void;
  requestResetPassword: (data: { email: string }) => void;
  errors: {
    [key: string]: string[];
  };
  isLogged: boolean;
  is: (types: typeof USER_TYPE[keyof typeof USER_TYPE]) => boolean;
  isNot: (types: typeof USER_TYPE[keyof typeof USER_TYPE]) => boolean;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [error, setError] = useState('');
  const [errors, setErrors] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<IUserData>({} as IUserData);
  const isLogged = localStorage.getItem('@user:prado_imoveis') !== null;

  const [cookies, setCookie, removeCookie] = useCookies(['api_padro_session']);

  const navigate = useNavigate();
  const { addToast } = useToast();

  useEffect(() => {
    const localStorageData = localStorage.getItem('@user:prado_imoveis');

    if (localStorageData) {
      const parsedStorageData = JSON.parse(localStorageData);
      setUserData({ ...parsedStorageData });

      api.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${parsedStorageData.token}`;
    }
  }, []);

  async function registerUser(
    formData: {
      name: string;
      email: string;
      password: string;
      phone: string;
      type_id: number;
    },
    onLogin = null,
  ) {
    setLoading(true);
    setErrors({});
    try {
      const { data } = await api.post(`/register-user`, formData);
      setLoading(false);
      login({ email: formData.email, password: formData.password }, onLogin);
      return data as IUser;
    } catch (error) {
      // setError((error as any).response.data.message);
      setErrors((error as any).response.data.errors);
      setLoading(false);
    }
  }

  async function requestResetPassword(formData: { email: string }) {
    setLoading(true);
    setErrors({});
    try {
      const { data } = await api.post(`/users/reset/senha`, formData);
      setLoading(false);
      addToast({
        title: data,
        type: 'success',
      });
      return data;
    } catch (error) {
      // setError((error as any).response.data.message);
      setErrors((error as any).response.data.errors);
      setLoading(false);
      addToast({
        title: (error as any).response.data.errors,
        type: 'success',
      });
    }
  }

  const login = useCallback(
    (data: ILoginData, onLogin = null) => {
      setError('');
      setLoading(true);
      return api
        .post<IAuthRequest>('/login', { ...data })
        .then(req => {
          const { user, token } = req.data;

          api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

          const loggedUser: IUserData = { ...user, token };

          localStorage.setItem(
            '@user:prado_imoveis',
            JSON.stringify(loggedUser),
          );
          setUserData({ ...loggedUser });
          setLoading(false);

          addToast({
            title: `Bem-vindo à Prado Imóveis, ${user.name}!`,
            type: 'success',
          });

          if (onLogin) {
            onLogin();
            return true;
          }

          // navigate(routesNames.dashboard);
        })
        .catch(err => {
          setLoading(false);
          console.log(err.response);
          if (onLogin) {
            setError(err.response.data.message);
            return err.response.data.message;
          }
          addToast({
            title: 'Erro ao fazer o login',
            description: 'Verifique seus dados.',
            type: 'error',
          });
        });
    },
    [addToast, navigate],
  );

  const logout = useCallback(() => {
    api.post('/logout', { token: userData.token }).then(() => {
      localStorage.removeItem('@user:prado_imoveis');
      localStorage.removeItem('api_padro_session');
      removeCookie('api_padro_session');
      setUserData({} as IUserData);
      localStorage.removeItem('@user:prado_imoveis');
      localStorage.removeItem('api_padro_session');
      removeCookie('api_padro_session');
      delete api.defaults.headers.common['Authorization'];

      addToast({
        title: 'Sua sessão foi finalizada!',
        type: 'info',
      });
      navigate(routesNames.root);
    });
  }, [userData, addToast, navigate]);

  const logoutLocal = useCallback(() => {
    localStorage.removeItem('@user:prado_imoveis');
    localStorage.removeItem('api_padro_session');
    removeCookie('api_padro_session');
    setUserData({} as IUserData);
    navigate('/');
    delete api.defaults.headers.common['Authorization'];
  }, [userData, addToast, navigate]);

  const resetPassword = useCallback(
    (data: { password: string; hash: string }) => {
      return api
        .post(`/users/reset-alterar-senha`, data)
        .then(req => {
          addToast({
            title: `Sua senha foi alterada!`,
            type: 'success',
          });
          navigate('/');
        })
        .catch(err => {
          addToast({
            title: err.response.data.message,
            type: 'error',
          });
        });
    },
    [],
  );

  const subViewUserCorretor = useCallback(() => {
    return api
      .post(`/users/subView/${userData.id}`)
      .then(req => {
        const { user } = req.data;
        const token = api.defaults.headers.common['Authorization'];
        const loggedUser: IUserData = { ...user, token };
        localStorage.setItem('@user:prado_imoveis', JSON.stringify(loggedUser));
        setUserData({ ...loggedUser });
      })
      .catch(err => {
        console.log(err.response);
      });
  }, [userData]);

  function is(types: typeof USER_TYPE[keyof typeof USER_TYPE]) {
    if (!isLogged) return false;
    if (!userData.type_id) return false;
    if (Array.isArray(types)) {
      return types.includes(userData.type_id);
    }
    return userData.type_id === types;
  }

  function isNot(types: typeof USER_TYPE[keyof typeof USER_TYPE]) {
    if (!isLogged) return false;
    if (!userData.type_id) return false;
    if (Array.isArray(types)) {
      return !types.includes(userData.type_id);
    }
    return userData.type_id !== types;
  }

  return (
    <AuthContext.Provider
      value={{
        userData,
        setUserData,
        errors,
        login,
        logout,
        logoutLocal,
        subViewUserCorretor,
        error,
        loading,
        registerUser,
        requestResetPassword,
        resetPassword,
        isLogged,
        is,
        isNot,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuth must be used with AuthProvider');

  return context;
}

export { AuthProvider, useAuth };
