import styled from 'styled-components';

export const GridContainer = styled.form`
  display: grid;
  grid-template-columns: 45% 45%;
  column-gap: 10%;

  @media (max-width: 884px) {
    grid-template-columns: 100%;
  }
`;
