export default function useInputMask() {
  function handleMoneyMask(val: string) {
    if (typeof val !== 'string') {
      if (typeof val === 'number') {
        val = String(val);
      } else {
        return '';
      }
    }
    if (val.endsWith(',0')) {
      val = val.replace(',0', '');
      val = val = val.slice(0, -1);
    } else {
      val = val.replace(',00', '');
    }
    val = val.replace(/\D/g, '');
    if (!val) return '';
    val = Number(val).toLocaleString('pt-br', { minimumFractionDigits: 2 });
    return val;
  }

  function handlePhoneMask(val: string) {
    if (!val) return '';
    val = val.replace(/\D/g, '');

    if (val.length > 11) {
      val = val.substring(0, 11);
    }

    if (val.length <= 6) {
      val = val.replace(/(\d{2})(\d)/, '($1) $2');
    }

    if (val.length <= 10) {
      val = val.replace(/(\d{2})(\d{4})(\d)/, '($1) $2-$3');
    }

    if (val.length === 11) {
      val = val.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4');
    }

    return val;
  }

  function onMoneyMask(e: any) {
    e.target.value = handleMoneyMask(e.target.value);
  }

  function onPhoneMask(e: any) {
    e.target.value = handlePhoneMask(e.target.value);
  }

  function removePhoneMask(val: any) {
    if (!val) return '';
    return val.replace(/\D/g, '');
  }

  function removeMoneyMask(val: any) {
    if (!val) return '';
    val = String(val).replace(',00', '');
    return val.replace(/\D/g, '');
  }

  function phoneMask(value: any) {
    if (!value) return '';
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d)/, '($1) $2');
    value = value.replace(/(\d)(\d{4})$/, '$1-$2');
    return value;
  }

  function phoneMaskWithCountry(value: any) {
    if (!value) return '';
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d{2})(\d)/, '$1 ($2) $3');
    value = value.replace(/(\d)(\d{4})$/, '$1-$2');
    return value;
  }

  function formataCPF(cpf: any) {
    if (cpf) {
      //retira os caracteres indesejados...
      cpf = cpf.replace(/[^\d]/g, '');
      //realizar a formatação...
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
  }

  function convertDate(date: any) {
    if (date) {
      return new Date(date.replace(/-/g, '/')).toLocaleDateString('pt-br');
    }
  }

  return {
    handleMoneyMask,
    handlePhoneMask,
    removePhoneMask,
    onMoneyMask,
    onPhoneMask,
    removeMoneyMask,
    phoneMask,
    formataCPF,
    convertDate,
    phoneMaskWithCountry,
  };
}
