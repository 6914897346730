import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  min-height: 100vh;
`;

export const ModalContent = styled.div`
  background: white;
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  height: max-content;
  margin-top: 15vh;
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.25rem;

  > div.remember-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Header = styled.header`
  h3 {
    font-size: 26px;
    font-weight: 700;
    line-height: 2;
    color: var(--primary-neutral-900);
  }

  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--primary-neutral-650);
  }

  @media (max-width: 480px) {
    h3 {
      line-height: unset;
      margin-bottom: 1rem;
    }
  }
`;

export const MessageWithLink = styled.div<any>`
  display: flex;
  justify-content: center;

  margin-top: ${props => props.withMargin && '7.5vh'};

  font-size: 14px;

  /* span { */
  color: var(--primary-neutral-650);
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  color: var(--blue-400);
  /* } */
`;
