import { ChangeEvent, useState, useEffect } from 'react';

import { ReactComponent as Add } from 'common/assets/svgs/add-circle.svg';
import { ReactComponent as Camera } from 'common/assets/svgs/camera.svg';
import { ReactComponent as Trash } from 'common/assets/svgs/trash_white.svg';
import { v4 as uuid } from 'uuid';

import { IImageInputProps } from './IImageInput';
import * as S from './styles';

function ImageInput({
  addMoreType,
  onAddImage,
  profilePage,
  multiple,
  onAddMultiple,
  previewFile,
  disableAutoPreview,
  onRemoveImage,
  bgImage,
}: IImageInputProps) {
  const id = uuid();
  const [preview, setPreview] = useState<string | ArrayBuffer | null>('');

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const reader = new FileReader();

    if (event.target.files === null) return;
    const files = Array.from(event.target.files);
    if (onAddImage) onAddImage(files[0]);
    if (onAddMultiple) onAddMultiple(files);
    if (disableAutoPreview) return;
    if (!addMoreType && files[0] !== undefined) {
      reader.onload = () => {
        if (reader.result) {
          setPreview(reader.result);
        }
      };

      reader.readAsDataURL(files[0]);
    }
  }

  function handleRemoveImage() {
    setPreview('');
    if (onRemoveImage) onRemoveImage();
  }

  useEffect(() => {
    if (bgImage !== undefined) {
      setPreview(bgImage);
    }
    if (!addMoreType && previewFile && previewFile !== '-1') {
      const reader = new FileReader();

      reader.onload = () => {
        setPreview(reader.result);
      };

      reader.readAsDataURL(previewFile);
    }
  }, [previewFile, bgImage]);

  return (
    <S.Container preview={`${preview}`} profilePage={profilePage}>
      <label htmlFor={id}>
        {addMoreType ? <Add /> : preview === '' && <Camera />}

        <input
          multiple={multiple}
          type="file"
          id={id}
          accept="image/*"
          data-file={preview}
          onChange={handleChange}
        />
      </label>

      {preview !== '' && (
        <S.RemoveButton type="button" onClick={handleRemoveImage}>
          <Trash />
        </S.RemoveButton>
      )}
    </S.Container>
  );
}

export { ImageInput };
