import styled from 'styled-components';

import { IPropertyPriceTicketProps } from '.';

export const Container = styled.div<{
  variant?: IPropertyPriceTicketProps['variant'];
}>`
  padding: ${({ variant }) =>
    variant === 'price-down-full-no-border' ? '0 1.3rem 1rem' : '1rem 1.3rem'};
  border: ${({ variant }) =>
    variant === 'price-down-full-no-border'
      ? 'none'
      : '1px solid var(--grey-300)'};
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.2);
  background-color: white;

  &[data-responsive='true'] {
    @media (max-height: 775px) {
      .leave-contact {
        display: none;
      }
    }

    @media (max-height: 730px) {
      .main-content {
        max-height: 300px;
        overflow-y: auto;
      }
    }

    @media (max-height: 660px) {
      .main-content {
        max-height: 200px;
        overflow-y: auto;
      }
    }
  }

  header {
    border-bottom: 2px dashed var(--grey-300);
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;

    .curve-left,
    .curve-right {
      position: absolute;
      background-color: white;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      bottom: -20px;
    }

    .curve-left {
      left: calc(-20px + (-1.75rem));
      border-right: 1px solid var(--grey-300);
      /* box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.2) inset; */
      /* box-shadow: 1px 0px 4px rgba(0, 0, 0, 0) inset; */
    }

    .curve-right {
      right: calc(-20px + (-1.75rem));
      border-left: 1px solid var(--grey-300);
      /* box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.2) inset; */
    }

    .row {
      display: grid;
      grid-template-columns: 1fr 3fr;
      align-items: center;

      h2 {
        font-size: 1.8rem;
      }

      h3 {
        text-decoration: line-through;
        font-size: 1.1rem;
        color: var(--grey-400);
      }

      h5 {
        font-size: 1.5rem;
        color: var(--grey-400);
      }

      small {
        font-weight: bold;
        color: var(--grey-700);
      }

      small:first-of-type {
        color: var(--grey-400);
        font-weight: normal;
      }
    }

    &.price-down {
      border-bottom: none;
      border-top: 2px dashed var(--grey-300);
      padding: 1rem 1rem 0;
      margin-top: 30px;

      .curve-left,
      .curve-right {
        top: -20px;
      }

      > .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  > main {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;

    > span {
      font-size: 0.9rem;
    }

    a {
      color: var(--blue-200);
    }

    button,
    .call-btn {
      background-color: var(--blue-200);
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      border: none;
      border-radius: 7px;
      color: white;
      font-size: 1rem;
      transition: 0.2s;
      width: 100%;
      cursor: pointer;

      &:hover {
        background-color: var(--blue-400);
      }

      &.contato {
        background-color: var(--blue-500);
        margin-top: 0;

        &:hover {
          background-color: var(--blue-400);
        }
      }
    }

    .call-btn {
      /* display: none; */
      padding-right: 50px;
      position: relative;

      &__ramais {
        position: absolute;
        right: 10px;
      }

      &__vernumero {
        position: absolute;
        z-index: 1;
        inset: 0;
        left: 40px;
        padding-left: 20%;
        border-radius: 5px;
        color: var(--blue-500);
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(
          90deg,
          transparent,
          var(--blue-200),
          var(--blue-200)
        );
      }
    }
  }
`;
