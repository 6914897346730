import { ChangeEvent, useEffect, useState } from 'react';

import { Box, Pagination, Stack } from '@mui/material';
import MuiModal from '@mui/material/Modal';

import { Property } from '../../../@types';
import { useSearch } from '../../../hooks/search';
import api from '../../../services/api';
import CloseButton from '../../Atoms/CloseButton';
import { Filter, SearchFilter } from '../../Molecules';
import { HouseCardSimple } from '../../Molecules/HouseCardSimple';
import * as S from './style';
import { cleanObj } from '../../../common/utils/cleanObj';
export type ImoveisComunicadoModalProps = {
  isOpen: boolean;
  propertiesIds: any;
  setPropertiesIds?: any;
  close: () => void;
};
export { useModal as useModal } from 'components/Atoms/modal/Modal';
export default function ImoveisComunicadoModal({
  close,
  propertiesIds,
  setPropertiesIds,
  isOpen,
}: ImoveisComunicadoModalProps) {
  const [loading, setLoading] = useState(true);
  const [properties, setProperties] = useState<any[]>([]);
  const [pagesTotal, setPagesTotal] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {
    filter,
    changeCitiesMode,
    keys,
    addressQ,
    proprietaryQ,
    temporarySelectedDistrictsToString,
  } = useSearch();
  useEffect(() => {
    setLoading(true);
    const params = {
      city_id: filter?.city?.value,
      price_min: filter?.min_value,
      price_max: filter?.max_value,
      type_property: filter?.type === 'Galpão' ? 'Galpões' : filter?.type,
      bedrooms: filter?.bedrooms,
      district: temporarySelectedDistrictsToString(),
      code: filter?.code,
      evaluation: filter?.evaluation,
      disabled: filter?.disabled,
      tarja_id: filter?.tarja_id,
      furnished_property: filter?.furnished_property,
      gated_community: filter?.gated_community,
      address: filter?.address,
      proprietary: filter?.proprietary,
      corretor: filter?.corretor,
      phone_proprietary: filter?.phone_proprietary,
      contruct_area: filter?.contruct_area,
      land_area: filter?.land_area,
      preco_max_mq: filter?.preco_max_mq,
      preco_min_mq: filter?.preco_min_mq,
      bathrooms: filter?.bathrooms,
      garage: filter?.garage,
      suites: filter?.suites,
      user_capture: true,
      street: addressQ?.value,
      proprietario: proprietaryQ?.value,
    };
    api
      .get(
        `/propertiesAuth?page=${currentPage}&limit=12&coluna=id&order=desc&resume=true`,
        {
          params: { ...cleanObj(params) },
        },
      )
      .then(req => {
        const dataProperties = req.data.imoveis;
        setProperties(dataProperties.data);
        setPagesTotal(dataProperties.last_page);
        setLoading(false);
      });
  }, [currentPage, filter]);

  const onClickAddPropertie = (data: any) => {
    const propertiesData = [...properties];
    const index = properties.findIndex(prop => prop.id === data.id);
    if (propertiesData[index].selected_comunicate) {
      propertiesData[index].selected_comunicate = false;
      setPropertiesIds(data.id, 'remove');
    } else {
      propertiesData[index].selected_comunicate = true;
      setPropertiesIds(data.id, 'add');
    }

    setProperties(propertiesData);
  };

  const handleChangePage = (_: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  return (
    <MuiModal
      open={isOpen}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        <S.Background>
          <S.Container>
            <header className="header">
              <CloseButton onClick={close} />
            </header>
            <S.HeaderContainer>
              <div></div>
              <div className="filters-container">
                <Filter key={keys.general} />
              </div>
            </S.HeaderContainer>
            <Box
              paddingRight={'15px'}
              paddingLeft={'15px'}
              marginTop="10px"
              marginBottom={'10px'}
            >
              <SearchFilter key={keys.general} />
            </Box>
            {loading ? (
              <S.CardsContainer>
                <h1>Carregando</h1>
              </S.CardsContainer>
            ) : (
              <S.CardsContainer>
                {properties.length
                  ? properties.map((propertie, index) => {
                      const validateProps = propertiesIds.find(
                        (propId: number) => propId === propertie.id,
                      );
                      if (validateProps) {
                        propertie.selected_comunicate = true;
                      }
                      return (
                        <HouseCardSimple
                          key={index}
                          propertie={propertie}
                          onClick={(e: any) => onClickAddPropertie(e)}
                        />
                      );
                    })
                  : ''}
              </S.CardsContainer>
            )}
            {!!pagesTotal && pagesTotal > 1 && (
              <Box display="flex" justifyContent="center" marginTop="24px">
                <Stack spacing={2}>
                  <Pagination
                    count={pagesTotal}
                    page={currentPage}
                    onChange={handleChangePage}
                    variant="outlined"
                    color="primary"
                  />
                </Stack>
              </Box>
            )}
          </S.Container>
        </S.Background>
      </div>
    </MuiModal>
  );
}
