import Swal, { SweetAlertOptions } from 'sweetalert2';

export default {
  async confirm(title: string, message: string) {
    return (
      await Swal.fire({
        title,
        text: message,
        icon: 'warning',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#1DAEFF',
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#d33',
        showCancelButton: true,
      })
    ).isConfirmed;
  },

  error(params: SweetAlertOptions & { error: any }) {
    console.log('ERROR:', params.error);
    return this.fire({
      icon: 'error',
      confirmButtonText: params.confirmButtonText || 'Ok',
      ...params,
    });
  },

  async fire(params: SweetAlertOptions) {
    return Swal.fire({
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#1DAEFF',
      cancelButtonColor: '#d33',
      ...params,
    });
  },
};
