import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Pagination,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Button, DefaultPageTitle } from 'components/Atoms';
import { saveAs } from 'file-saver';
import { useAuth } from 'hooks/auth';
import { IUser } from 'models/IAuth';
import api from 'services/api';

import { ReactComponent as MaxPrice } from '../../common/assets/svgs/max_price.svg';
import { InputWithIcon } from '../../components/Molecules';
import { UserBox } from './components/UserBox';

const Clients = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<IUser[]>([]);
  const [pagesTotal, setPagesTotal] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { logoutLocal } = useAuth();
  useEffect(() => {
    getUsers();
  }, [currentPage]);

  const getUsers = (consult?: string, page?: number) => {
    let url = `/users?type_id=1&page=${page ? page : currentPage}`;
    // @ts-ignore
    if (consult) {
      url += `&name=${consult}`;
    }
    api
      .get(url)
      .then(({ data }) => {
        console.clear();
        const res = data.data;
        setPagesTotal(res.last_page);
        //setCurrentPage(res.current_page);
        setUsers(res.data);
      })
      .catch(error => {
        if (error.response.status === 401) {
          logoutLocal();
        }
      });
  };

  const searchUsers = (consult: string) => {
    if (consult.length > 3) {
      getUsers(consult, 1);
      return;
    }
    if (consult.length === 0) {
      getUsers();
    }
  };

  const handleChangePage = (_: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const excelDataExport = () => {
    api
      .get('users/export?userType=1', { responseType: 'blob' })
      .then(({ data }) => {
        const filename = 'clientes.xlsx';
        const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
        saveAs(blob, filename);
      })
      .catch(error => {
        if (error.response.status === 401) {
          logoutLocal();
        }
      });
  };

  return (
    <Box
      width="100%"
      height="fit-content"
      padding="24px 26px"
      border="1px solid var(--primary-neutral-300)"
      borderRadius="16px"
      sx={{ background: 'var(--white)' }}
    >
      <>
        <Box
          display="flex"
          gap="20px"
          marginTop="20px"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <div
            style={{
              justifyContent: 'flex-start',
              display: 'flex',
            }}
          >
            <DefaultPageTitle>Clientes</DefaultPageTitle>
          </div>

          <div style={{ width: '50%' }}>
            <InputWithIcon
              placeholder="Busca usuário"
              name="name"
              onChange={e => searchUsers(e.target.value)}
              defaultValue={''}
            />
          </div>

          <Button color="green" onClick={() => excelDataExport()}>
            Baixar Excel
          </Button>

          <Button color="alpha-blue" onClick={() => navigate('/usuarios')}>
            Listar usuários
          </Button>
        </Box>
      </>

      <Box
        display="flex"
        gap="20px"
        flexWrap="wrap"
        marginTop="20px"
        justifyContent="center"
      >
        {users && users.map(user => <UserBox {...user} key={user.id} />)}
      </Box>
      {!!pagesTotal && pagesTotal > 1 && (
        <Box display="flex" justifyContent="center" marginTop="24px">
          <Stack spacing={2}>
            <Pagination
              count={pagesTotal}
              page={currentPage}
              onChange={handleChangePage}
              variant="outlined"
              color="primary"
            />
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export { Clients };
export default Clients;
