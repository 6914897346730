import { useCallback, useEffect, useState } from 'react';
import { Settings } from 'react-slick';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as Casa } from 'common/assets/svgs/house.svg';
import { RealtyDetailsOption } from 'components/Molecules';
import { ReactComponent as Sobrado } from 'common/assets/svgs/building.svg';
import { ReactComponent as Lote } from 'common/assets/svgs/maximize_black.svg';
import { ReactComponent as Apartment } from 'common/assets/svgs/apartment.svg';
import { ReactComponent as Galpao } from 'common/assets/svgs/galpao.svg';
import { ReactComponent as Loft } from 'common/assets/svgs/loft.svg';
import { ReactComponent as DefaultOpt } from 'common/assets/svgs/default_house_opt.svg';
import { v4 as uuid } from 'uuid';

import { IRealtyTypeProps } from './IRealtyType';

import { Carousel } from '..';

const TYPES_INDEX = {
  Casa: 0,
  Apartamento: 1,
  Sobrado: 2,
  Lote: 3,
  Área: 4,
  Chácara: 5,
  Fazenda: 6,
  Galpão: 7,
  'Sala comercial': 8,
};

function RealtyType({ onSelectOption, value }: IRealtyTypeProps) {
  const [selectedOpt, setSelectedOpt] = useState<string | undefined>();
  const matches = useMediaQuery('(max-width: 884px)');

  useEffect(() => {
    setSelectedOpt(value);
  }, [value]);

  const handleSelectOption = useCallback(
    (v: string) => {
      setSelectedOpt(v);
      onSelectOption(v);
    },
    [onSelectOption],
  );

  return (
    <Box width="100%" maxWidth="100%">
      <Typography
        fontSize="14px"
        fontWeight="600"
        color="var(--primary-neutral-900)"
        marginBottom="24px"
      >
        Selecione o tipo de propriedade *
      </Typography>

      <Carousel
        noDots
        customSettings={{
          dots: false,
          autoplay: false,
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: (TYPES_INDEX as any)[value] ?? 0,
          arrows: true,
          responsive: [
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 520,
              settings: {
                dots: true,
                arrows: false,
                slidesToShow: 3,
                slidesToScroll: 2,
              },
            },
          ],
        }}
        items={[
          <RealtyDetailsOption
            icon={<Casa />}
            subLabel="Casa"
            key={uuid()}
            width={matches ? '90%' : '85%'}
            onClick={() => handleSelectOption('Casa')}
            selected={selectedOpt === 'Casa'}
          />,
          <RealtyDetailsOption
            icon={<Apartment />}
            subLabel="Apartamento"
            key={uuid()}
            width={matches ? '90%' : '85%'}
            onClick={() => handleSelectOption('Apartamento')}
            selected={selectedOpt === 'Apartamento'}
          />,
          <RealtyDetailsOption
            icon={<Sobrado />}
            subLabel="Sobrado"
            key={uuid()}
            width={matches ? '90%' : '85%'}
            onClick={() => handleSelectOption('Sobrado')}
            selected={selectedOpt === 'Sobrado'}
          />,
          <RealtyDetailsOption
            icon={<Lote />}
            subLabel="Lote"
            key={uuid()}
            width={matches ? '90%' : '85%'}
            onClick={() => handleSelectOption('Lote')}
            selected={selectedOpt === 'Lote'}
          />,
          <RealtyDetailsOption
            icon={<Lote />}
            subLabel="Área"
            key={uuid()}
            width={matches ? '90%' : '85%'}
            onClick={() => handleSelectOption('Área')}
            selected={selectedOpt === 'Área'}
          />,
          <RealtyDetailsOption
            icon={<DefaultOpt />}
            subLabel="Chácara"
            key={uuid()}
            width={matches ? '90%' : '85%'}
            onClick={() => handleSelectOption('Chácara')}
            selected={selectedOpt === 'Chácara'}
          />,
          <RealtyDetailsOption
            icon={<DefaultOpt />}
            subLabel="Fazenda"
            key={uuid()}
            width={matches ? '90%' : '85%'}
            onClick={() => handleSelectOption('Fazenda')}
            selected={selectedOpt === 'Fazenda'}
          />,
          <RealtyDetailsOption
            icon={<Galpao />}
            subLabel="Galpão"
            key={uuid()}
            width={matches ? '90%' : '85%'}
            onClick={() => handleSelectOption('Galpão')}
            selected={selectedOpt === 'Galpão'}
          />,
          <RealtyDetailsOption
            icon={<Sobrado />}
            subLabel="Sala comercial"
            key={uuid()}
            width={matches ? '90%' : '85%'}
            onClick={() => handleSelectOption('Sala comercial')}
            selected={selectedOpt === 'Sala comercial'}
          />,
        ]}
      />
    </Box>
  );
}

export { RealtyType };
