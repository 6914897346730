import { Property } from '@types';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';

import renderProperty from './models/property';
import renderPropertyDocs from './models/property_documents';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

export default {
  async property(property: Property) {
    console.log('GERANDO PDF...');
    const data = (await renderProperty(property)) as any;
    this.open(data);
  },

  async propertyDocs(property: Property) {
    console.log('GERANDO PDF...');
    const data = (await renderPropertyDocs(property)) as any;
    this.open(data);
  },

  open(definition: TDocumentDefinitions) {
    const pdf = pdfMake.createPdf(definition);
    console.log('PDF GERADO: ', pdf);
    pdf.open();
  },
};
