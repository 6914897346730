import { useCallback, useMemo, useRef, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { Checkbox, DefaultPageTitle } from '../../../components/Atoms';
import CloseButton from '../../../components/Atoms/CloseButton';
import * as S from '../../../components/Molecules/Filter/styles';

const styles = {
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
};

function ModalMapSearch(props: any) {
  const handleClose = () => {
    props.setOpen(!props.open);
  };
  /*const [draggable, setDraggable] = useState(false);
  const [position, setPosition] = useState(center);
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
        }
      },
    }),
    [],
  );
  const toggleDraggable = useCallback(() => {
    setDraggable(d => !d);
  }, []);*/

  return (
    <>
      <Dialog
        fullScreen={true}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: '100%',
            padding: '0',
            height: '100%',
          },
        }}
      >
        {/* <DialogTitle> */}
        {/* <S.ModalHeader style={{ justifyContent: 'flex-end' }}> */}
        <div
          style={{
            position: 'absolute',
            top: '15px',
            right: '20px',
            zIndex: '9999',
          }}
        >
          <CloseButton onClick={handleClose} />
        </div>
        {/* </S.ModalHeader> */}
        {/* </DialogTitle> */}
        {props.children}
      </Dialog>
    </>
  );
}

export { ModalMapSearch };
