import { Property } from '@types';
import PropertyChar from 'helpers/PropertyChar';

import check_base64 from './models/check_base64';
import logo_base64 from './models/logo_base64';

export const colors = {
  lightBlue: '#1DAEFF',
  darkBlue: '#0073B5',
  grey: '#7C7A80',
};

export const text = {
  xs: 8,
  sm: 10,
  base: 12,
  xl: 16,
  xxl: 20,
  xxxl: 20,
};

export const getBase64FromUrl = async (url: string) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;

      const img = new Image() as any;
      img.src = base64data;

      img.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        const outputImg = new Image();
        outputImg.src = canvas.toDataURL('image/png');
        resolve(canvas.toDataURL('image/png'));
      };
    };
  });
};

export function logo() {
  return [
    {
      image: logo_base64,
      height: 30,
      width: 100,
    },
  ];
}

const validPropertyDetail = (property: any, name: string): boolean => {
  if ((property as any)[name] !== 0 && (property as any)[name] !== null)
    return true;

  return false;
};

export function characteristicsItem(property: Property) {
  const info = PropertyChar.mapKeysLabels(property).info;

  const charData = [
    ...characteristicItem(
      property,
      'Descrição e características',
      ['contruct_area', 'land_area', 'bedrooms', 'bathrooms'],
      info.caracteristicas.data,
    ),
    ...characteristicItem(
      property,
      'Lazer e conveniência',
      [],
      info.lazerEConveniencia.data,
    ),
    ...characteristicItem(property, 'Segurança', [], info.seguranca.data),
    ...characteristicItem(
      property,
      'Informações do terreno',
      [],
      info.loteArea.data,
    ),
    ...characteristicItem(property, 'Informações rurais', [], info.rural.data),
    ...characteristicItem(
      property,
      'Formas de pagamento e documentação',
      [],
      info.formasDePagamento.data,
    ),
  ];

  console.log(charData);

  return charData;
}

export function characteristicItem(
  property: Property,
  title: string,
  fields: string[],
  pData: any,
) {
  console.log('pData', pData);
  const data = [];
  const validatedFields: { [key: string]: boolean } = {};
  let hasValidField = false;

  fields.forEach(field => {
    const value = validPropertyDetail(property, field);
    if (value) hasValidField = true;
    validatedFields[field] = value;
  });

  if (hasValidField || pData?.length) {
    data.push({
      text: title,
      bold: true,
      margin: [0, 20, 0, 5],
      fontSize: text.xl,
    });
  }

  let columnIndex = 0;
  let column: any = {
    columns: [],
  };

  fields.forEach(field => {
    if (validatedFields[field]) {
      column.columns.push({
        columnGap: 5,
        columns: [
          {
            image: check_base64,
            height: 12,
            width: 12,
            margin: [0, 0, 5, 0],
          },
          {
            text: PropertyChar.renderField(property, field),
          },
        ],
        margin: [0, 0, 0, 5],
      });
      columnIndex++;
      if (columnIndex === 4) {
        data.push(column);
        column = {
          columns: [],
        };
        columnIndex = 0;
      }
    }
  });

  pData.forEach((field: any) => {
    column.columns.push({
      columnGap: 5,
      columns: [
        {
          image: check_base64,
          height: 12,
          width: 12,
          margin: [0, 0, 5, 0],
        },
        {
          text: field.displayLabel || field.label,
        },
      ],
      margin: [0, 0, 0, 5],
    });
    columnIndex++;
    if (columnIndex === 4) {
      data.push(column);
      column = {
        columns: [],
      };
      columnIndex = 0;
    }
  });

  if (column.columns.length) {
    data.push(column);
  }

  return data;
}

export function infoSection(title: string, txt?: string | string[]) {
  if (!txt?.length) return [];
  return [
    {
      text: title,
      margin: [0, 20, 0, 5],
      fontSize: text.xl,
      bold: true,
    },
    (Array.isArray(txt) ? txt : [txt]).map(function (t) {
      return { text: t };
    }),
  ];
}

export function greyText(txt: string) {
  return [
    {
      text: txt,
      color: colors.grey,
      fontSize: text.sm,
    },
  ];
}

export function title(txt: string, options?: { color: string }) {
  return [
    {
      text: txt,
      fontSize: text.xxxl,
      bold: true,
      color: options?.color,
      margin: [0, 20, 0, 0],
    },
  ];
}
