import { Box, Typography, useMediaQuery } from '@mui/material';

import { IProfileDataOnDashboardProps } from './IProfileDataOnDashboard';

function ProfileDataOnDashboard({
  icon,
  label,
  subLabel,
}: IProfileDataOnDashboardProps) {
  const matches = useMediaQuery('(max-width: 884px)');

  return (
    <Box display="flex" columnGap="1.25rem">
      {icon}

      <Box
        display="flex"
        flexDirection="column"
        maxWidth={matches ? 'calc(100% - 40px)' : 'unset'}
      >
        <Typography
          fontSize="1rem"
          width="fit-content"
          fontWeight="600"
          color="var(--primary-neutral-900)"
        >
          {label}
        </Typography>

        <Typography
          fontSize=".85rem"
          fontWeight="500"
          color="var(--primary-neutral-650)"
        >
          {subLabel}
        </Typography>
      </Box>
    </Box>
  );
}

export { ProfileDataOnDashboard };
