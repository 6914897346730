import { useNavigate } from 'react-router-dom';

import { Alert } from 'helpers';
import catalogoApi from 'services/catalogoApi';
import { Catalogo } from 'types';

import { useToast } from './toast';

export default function useCatalogo() {
  const toast = useToast();
  const navigate = useNavigate();

  async function destroy(
    idOrCatalogo: number | Catalogo,
    options?: { onSuccess?: () => void; navigateTo?: string },
  ) {
    const isInstance = typeof idOrCatalogo === 'object';
    const confirm = await Alert.confirm(
      'Deletar catálogo?',
      isInstance
        ? `Tem certeza que deseja deletar o catálogo: "${idOrCatalogo.titulo}"`
        : 'Tem certeza que deseja deletar este catálogo?',
    );

    if (!confirm) return;

    const { isError, data } = await catalogoApi.destroy(
      isInstance ? idOrCatalogo.id : Number(idOrCatalogo),
    );

    if (isError) {
      toast.error(
        data?.message || data?.error || 'Falha ao deletar este catálogo',
      );
      return;
    }

    toast.success('Catálogo deletado com sucesso!');
    options?.onSuccess && options?.onSuccess();
    if (options?.navigateTo?.trim().length) {
      setTimeout(() => {
        navigate(options?.navigateTo!.trim());
      }, 2000);
    }
  }

  return {
    destroy,
  };
}
