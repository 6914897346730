import * as S from './style';

export type ContentButtonProps = {
  children: React.ReactNode;
  right?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'submit' | 'button';
} & S.ButtonParams;

export default function ContentButton({
  children,
  right,
  onClick,
  type = 'button',
  ...rest
}: ContentButtonProps) {
  return (
    <S.Button {...rest} onClick={onClick} type={type}>
      {children}
      {right}
    </S.Button>
  );
}
