import styled from 'styled-components';

interface IUserDataProps {
  photo: string;
}

export const Container = styled.div`
  position: relative;
  width: 210px;

  padding: 12px 14px;

  border: 1px solid var(--primary-neutral-300);
  border-radius: 16px;

  transition: all 0.35s;

  &:hover {
    box-shadow: 0 0 5px 2px var(--primary-neutral-300);
  }
`;

export const UserData = styled.div<IUserDataProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 8px;

  .photo {
    width: 120px;
    height: 120px;

    border-radius: 50%;
    margin-bottom: 5px;

    background-image: url(${props => props.photo});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  span {
    display: inline-block;
    font-size: 14px;
  }

  .user-name {
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    height: 52px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;

    padding-bottom: 10px;

    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .since {
    span {
      text-align: center;
      font-weight: 500;
    }

    em {
      font-style: normal;
      font-weight: 700;
    }
  }
`;

export const ActionsContainer = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;

  width: 160px;
  height: fit-content;
  z-index: 1;

  padding: 10px;
  background: var(--white);
  border: 1px solid #dfe8f6;
  border-radius: 6px;

  > button {
    display: flex;
    align-items: center;
    column-gap: 14px;

    width: 100%;

    padding: 6px;

    background: transparent;
    border: none;
    border-radius: 6px;

    font-weight: 600;
    color: var(--blue-500);
    transition: all 0.35s;

    &:hover {
      background: var(--grey-80);
    }
  }
`;

export const ActionsButton = styled.button`
  position: absolute;
  right: 6px;
  top: 12px;

  width: 24px;
  height: 24px;

  background: rgba(255, 255, 255, 0.75);
  border-radius: 6px;
  border: none;

  transition: all 0.35s;

  svg {
    fill: var(--blue-500);
  }

  &:hover {
    background: var(--white);
  }
`;
