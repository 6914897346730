/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

import { Property } from '@types';
import FsLightbox from 'fslightbox-react';
import styled from 'styled-components';

export type UseImageDisplayDataProps = {
  property?: Property;
  currentImage: number;
  setCurrentImage: (v: number) => void;
};

export function useImageDisplay(data: { property?: Property; index?: number }) {
  const [currentImage, setCurrentImage] = useState(data.index ?? 0);

  return {
    props: {
      property: data.property,
      currentImage,
      setCurrentImage,
    } as UseImageDisplayDataProps,
  };
}

const ImageDisplayContainer = styled.div`
  height: 100%;
  width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const ImageDisplay = ({
  property,
  currentImage,
  onCarouselClose,
  onCarouselOpen,
}: UseImageDisplayDataProps & {
  onCarouselOpen?: () => void;
  onCarouselClose?: () => void;
}) => {
  const [toggler, setToggler] = useState(false);

  return (
    <>
      <ImageDisplayContainer>
        {!!property?.images?.length && (
          <img
            onClick={() => setToggler(!toggler)}
            src={property.images[currentImage].image}
            alt={property.images[currentImage].image}
          />
        )}
      </ImageDisplayContainer>
      {!!property && (
        <FsLightbox
          sourceIndex={currentImage}
          toggler={toggler}
          onClose={() => {
            if (onCarouselClose) {
              onCarouselClose();
            }
          }}
          onOpen={() => {
            if (onCarouselOpen) {
              onCarouselOpen();
            }
          }}
          sources={property.images!.map(image => image.image)}
        />
      )}
    </>
  );
};

const QtdMessage = styled.div`
  color: var(--grey-400);
  text-align: center;
  margin: 14px;
  display: block;
`;

export const ImageSubMessage = ({
  property,
  currentImage,
}: UseImageDisplayDataProps) => {
  if (!property?.images?.length) return <></>;

  return (
    <QtdMessage>
      {currentImage + 1} de {property.images.length} fotos
    </QtdMessage>
  );
};

const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 100vw;
  width: 100vw;
  overflow-x: scroll;
  padding-bottom: 15px;

  @media (min-width: 1280px) {
    max-width: calc(100vw - 236px);
    width: calc(100vw - 236px);
  }

  &::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }
  &::-webkit-scrollbar-thumb {
    background-color: grey; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }

  .property-image-container {
    height: 80px;
    width: 136px;
    min-height: 80px;
    min-width: 136px;
    max-height: 80px;
    max-width: 136px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      filter: brightness(0.9);
    }

    &:first-of-type {
      margin-left: auto;
    }

    &:last-of-type {
      margin-right: auto;
    }

    > img {
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
      margin: auto auto;
      display: block;
    }
  }
`;

export const ImageFooter = ({
  property,
  currentImage,
  setCurrentImage,
}: UseImageDisplayDataProps) => {
  if (!property?.images?.length) return <></>;

  return (
    <Footer>
      {property.images.map((image, i) => (
        <div
          key={image.id}
          className="property-image-container"
          onClick={() => setCurrentImage(i)}
          style={{
            opacity:
              !!property?.images?.length &&
              property?.images[currentImage].id === image.id
                ? '0.3'
                : '1',
          }}
        >
          <img alt={`imagem-${image.id}`} src={image.image} />
        </div>
      ))}
    </Footer>
  );
};

const SwitchBtnContainer = styled.div`
  display: none;

  @media (min-width: 1280px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  > button {
    border: none;
    border-radius: 100%;
    padding: 5px 7px 0 7px;
    color: var(--blue-400);
    font-size: 30px;
    transition: 0.2s;

    &:hover {
      background-color: var(--blue-400);
      color: white;
    }
  }
`;

export const ImageSwitchBtnRight = ({
  property,
  currentImage,
  setCurrentImage,
}: UseImageDisplayDataProps) => {
  return (
    <SwitchBtnContainer>
      <button
        type="button"
        onClick={() => {
          if ((property?.images?.length ?? Infinity) > currentImage + 1) {
            setCurrentImage(currentImage + 1);
          }
        }}
      >
        <BiChevronRight />
      </button>
    </SwitchBtnContainer>
  );
};

export const ImageSwitchBtnLeft = ({
  currentImage,
  setCurrentImage,
}: UseImageDisplayDataProps) => {
  return (
    <SwitchBtnContainer>
      <button
        onClick={() => {
          if (currentImage > 0) {
            setCurrentImage(currentImage - 1);
          }
        }}
      >
        <BiChevronLeft />
      </button>
    </SwitchBtnContainer>
  );
};
