import * as S from './styles';

function HasQuestion() {
  return (
    <S.Container className="question_wrapper">
      <div>
        <p>Ficou com dúvidas ?</p>
        <span>
          Fale com um dos nossos profissionais e tire todas as suas dúvidas.
        </span>
      </div>

      <S.TalkWithUsButton
        aria-label="Fale com um corretor"
        name="talk-to-us-btn"
      >
        <div
          className="whatsapp-image"
          role="img"
          aria-label="Ícone do WhastApp"
        ></div>
        <S.Link
          href={`https://wa.me/+556239991400?text=${encodeURI(
            'Olá, estou com dúvidas. Pode me ajudar?.',
          )}`}
          target="_blank"
          rel="noreferrer"
          aria-label="Fale com um corretor"
          name="talk-to-us-btn"
        >
          {window.innerWidth > 768 ? 'Fale conosco' : 'Falar com um corretor'}
        </S.Link>
      </S.TalkWithUsButton>
    </S.Container>
  );
}

export { HasQuestion };
