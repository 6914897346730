/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prettier/prettier */
import { useCallback, useEffect, useRef, useState } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Link, useNavigate} from 'react-router-dom';
import {
  WhatsappIcon,
  TwitterShareButton,
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
} from 'react-share';
import SweetAlert2 from 'react-sweetalert2';

import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import no_image from 'common/assets/images/no_image.jpg';
import { ReactComponent as Bedroom } from 'common/assets/svgs/bedroom.svg';
import { ReactComponent as BuildingSize } from 'common/assets/svgs/building_size.svg';
import { ReactComponent as Catalog } from 'common/assets/svgs/catalog_blue.svg';
import { ReactComponent as CopyPaste} from 'common/assets/svgs/clipboard.svg';
import { ReactComponent as Close } from 'common/assets/svgs/close.svg';
import { ReactComponent as Edit } from 'common/assets/svgs/edit.svg';
import { ReactComponent as Info } from 'common/assets/svgs/info_2.svg';
import { ReactComponent as Maximize } from 'common/assets/svgs/maximize.svg';
import { ReactComponent as Menu } from 'common/assets/svgs/menu.svg';
import { ReactComponent as People } from 'common/assets/svgs/people-min.svg';
import { ReactComponent as SharedCircle } from 'common/assets/svgs/share_circles.svg';
import { ReactComponent as Shower } from 'common/assets/svgs/shower.svg';
import { ReactComponent as Star } from 'common/assets/svgs/star.svg';
import { ReactComponent as Trash } from 'common/assets/svgs/trash_blue.svg';
import { controlHideDiv } from 'common/utils/controlHideDiv';
import { formatCurrency } from 'common/utils/masks';
import {Button} from 'components/Atoms';
import HouseCardCarousel from 'components/Organisms/HouseCardCarousel';
import { env } from 'configs';
import { useAuth } from 'hooks/auth';
import { useRealtors } from 'hooks/realtors';
import { useToast } from 'hooks/toast';
import useWindowDimensions from 'hooks/useWindowDimensions';
import api, { addFavorite, removeFavorite } from 'services/api';

import {defaultRealtorName} from "../../../common/constants/defaultRealtorName";
import {InfosToBroker} from "../../../pages/AnnouncementDetail/components/InfosToBroker";
import CloseButton from "../../Atoms/CloseButton";
import {CustomAutocomplete} from "../CustomAutocomplete";
import LoginModal from "../LoginModal";
import RegisterModal from "../RegisterModal";
import { IHouseCardProps } from './IHouseCard';
import * as S from './styles';

function HouseCard({
  id,
  name,
  value,
  infos,
  location,
  image,
  images,
  onClickCard,
  isMarked,
  favoriteId,
  withLoggedActions,
  status,
  onChangeStatus,
  comunicator_id,
  hasTooltipMap,
  disabled,
  property,
  catalogos,
  tarja,
  isCatalog,
  catalogRemove
}: IHouseCardProps) {
  const { width } = useWindowDimensions();
  const starRef = useRef(null);
  const { userData, subViewUserCorretor } = useAuth();
  const [isHovered, setIsHovered] = useState(false);
  const [favId, setFavId] = useState(favoriteId);
  const [togglingFavorite, setTogglingFavorite] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [shareContainerOpen, setShareContainerOpen] = useState(false);
  const [modalCorretoresOpen, setModalCorrertoresOpen] = useState(false);
  const [copyText, setCopyText] = useState(property.code);
  const [copied, setCopied] = useState(false);
  const [catalogoId, setCatalogoId] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);

  const navigate = useNavigate();
  const { addToast } = useToast();
  const { getRealtorNameById } = useRealtors();

  const textImovelWpp = `${property.type_property} com ${(property.bedrooms === null || property.bedrooms === 1) ? '1 quarto' : property.bathrooms+'quartos'} à venda, ${property.district}, ${property.city?.description}, ${property.city?.state?.description}, Código: ${property.code}, cadastrado para venda na Prado Imóveis`;
  const [mensageWppCaptador, setMensageWppCaptador] = useState(`Olá, gostaria  de falar sobre a sua captação do imóvel, ${textImovelWpp}`);
  const [mensageWppProprietario, setMensageWppProprietario] = useState(`Olá, gostaria de falar sobre o imóvel de sua responsabilidade, ${textImovelWpp}`);

  const [swalProps, setSwalProps] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const ref = useRef(null);
  const shareRef = useRef(null);

  useEffect(() => {
    if (shareRef !== null) controlHideDiv(shareRef, () => setShareContainerOpen(false));
  }, [shareRef]);

  useEffect(() => {
    if (ref !== null) controlHideDiv(ref, () => setActionsVisible(false));
  }, [ref]);

  const handleClick = (e: any) => {
    if (e.target) {
      if ((starRef.current as any)?.contains(e.target) || (starRef.current as any) === (e.target as any)) {
        return;
      }
    }
    if (onClickCard) return onClickCard({ ...location, property_id: id });

    return undefined;
  };

  const handleDeleteProperty = () => {
    api.delete(`/properties/${id}`).then(() => {
      if (onChangeStatus) onChangeStatus();

      addToast({
        type: 'info',
        title: 'Imóvel excluído do sistema',
      });
    }).catch((error) => {
      console.log(error);

      addToast({
        type: 'error',
        title: 'Erro ao remover o imóvel.',
        description: 'Tente novamente mais tarde ou contate o suporte',
      });
    });
  }

  const getTargeLabel = (status: string) => {
    const allStatus = {
      venda_comunicada: 'Venda comunicada',
      aluguel_comunicado: 'Aluguel comunicado',
      indisponivel_comunicado: 'Indisponibilidade comunicada',
      Novo: 'Novo',
      Alugado: 'Alugado',
      Vendido: 'Vendido',
      'Indisponível': 'Indisponível',
    };

    // @ts-ignore
    return allStatus[status];
  };

  const getTargeColor = (status: string) => {
    if (status.includes('comunicada') || status.includes('comunicado'))
      return '#fbc02d';

    if (status === 'Novo') return '#2EA340';
    if (status === 'Alugado' || status === 'Indisponível') return '#B7B7B7';

    return '#B71926';
  };

  const isUnavailable = (status?: string) => {
    if (
      status === 'Vendido' ||
      status === 'Indisponível' ||
      status === 'Alugado'
    ) {
      return true;
    }

    return false;
  };

  /* Regra deve ser alterada assim que a edição de imoveis estiver pronta */
  const canBeOnlyRemoved = (status?: string) => {
    if (
      status === 'Vendido' ||
      status === 'Indisponível' ||
      status === 'Novo' ||
      status === 'Alugado'
    ) {
      return false;
    }

    return true;
  };

  const wppShare = () => {
    const property = infos;
    const description = `${name} ${
      property ? '- código: ' + property.code : ''
    } - Prado Imóveis - ${env.siteUrl}`;
    const url = `https://api.whatsapp.com/send?text=${description}share/${id}`;
    window.open(url, '_blank');
  }

  const getHouseButtonLabel = (status?: string): string => {
    if (!status) return 'Ver';

    switch (status) {
      case 'Vendido':
        return 'Vendido';
      case 'Alugado':
        return 'Alugado';
      case 'Indisponível':
        return 'Indisponível';
      default:
        return 'Ver';
    }
  };

  async function toggleFavorite() {
    setTogglingFavorite(true);
    if (!favId) {
      try {
        const response = await addFavorite(id);
        setFavId(response?.id);
      } catch (err) {
        console.log(err);
      }
    } else {
      const response = await removeFavorite(favId);
      if (response) setFavId(undefined);
    }
    setTogglingFavorite(false);
  }

  const mobileShare = () => {
   /* const property = infos;
    const idProperty = property ? property.id : '';
    console.log(infos)
    const description = `${name} ${
      property ? '- código: ' + property.code : ''
    } - Prado Imóveis - ${env.siteUrl}`;
    const url = `https://api.whatsapp.com/send?text=${description}share/${idProperty}`;
    window.open(url, '_blank');*/
    const property = infos;
    const description = `${name} ${
      property ? '- código: ' + property.code : ''
    } - Prado Imóveis - ${env.siteUrl}`;
    const url = `https://api.whatsapp.com/send?text=${description}share/${id}`;
    window.open(url, '_blank');
  };

  const selectCatalog = (dt: any) => {
    setCatalogoId(dt.value);
  }

  const bindProperty = () => {
    const formData = new FormData();
    formData.append('id_imovel', property.id);
    api
      .post(`/catalogo/${catalogoId}/vincular`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        addToast({
          title: res.data.message,
          type: 'success',
        });
        setOpenModal(false);
      })
      .catch(err => {
        addToast({
          title: `Ocorreu um erro ao vincular!`,
          description: err.response.data.message,
          type: 'error',
        });
      });
  };

  useEffect(() => {
    Array.from(document.querySelectorAll('.slick-arrow.slick-next')).forEach(item => item.setAttribute('aria-label', 'Próximo'));
    Array.from(document.querySelectorAll('.slick-arrow.slick-prev')).forEach(item => item.setAttribute('aria-label', 'Anterior'));
  }, []);

  const openInfCorretor = () => {
    setModalCorrertoresOpen(true);
    if (userData.type.description === 'Corretor') {
      if (!modalCorretoresOpen) {
        subViewUserCorretor();
      }
    }
  };

  const openLogin = () => {
    setIsOpen(true);
  };

  const openRegister = () => {
    setIsOpen(false)
    setRegisterModalOpen(true);
  };

  return (
    <>
      <S.ExternalContainer>
        <S.Container
          className="house_card_container"
          hasCursorPointer={!!onClickCard}
          isMarked={isMarked}
          hasTooltipMap={hasTooltipMap}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <S.Content
            unavailable={isUnavailable(status)}
          >
            <S.TagContainer>
              {property.atualization ? (
                <S.Targe color='#1DAEFF'>
                  <span>Atualização Comunicada</span>
                </S.Targe>
              ) : status && status != 'disponivel' && (
                <S.Targe color={getTargeColor(status)}>
                  <span>{getTargeLabel(status)}</span>
                </S.Targe>
              )}

              {tarja && (
                <S.Targe color="#2EA340" status={status} type={status ? 2 : undefined}>
                  <span>{tarja.name}</span>
                </S.Targe>
              )}

              {disabled && (
                <S.TargeDisable color={'#e01a1a'}>
                  <span>Desabilitado</span>
                </S.TargeDisable>
              )}
            </S.TagContainer>

            <S.CarouselContainer>
              <HouseCardCarousel
                items={image
                  ? [image] : images?.map((img, index) => (
                  <img
                    style={{ objectFit: 'cover', objectPosition: 'center' }}
                    src={img}
                    key={`${img}-${index}`}
                    alt={`${name}-imagem-${index+1}`}
                    onClick={handleClick}
                  />
                )) ?? [<img style={{ objectFit: 'cover', objectPosition: 'center'  }} key={1} alt={`${name}-imagem`} src={no_image} onClick={handleClick} />]}
              />
            </S.CarouselContainer>

            <div className="image" onClick={handleClick}>
              {canBeOnlyRemoved(status) && comunicator_id && (
                <S.RealtorName>
                  <span>Notificador: {getRealtorNameById(comunicator_id)}</span>
                </S.RealtorName>
              )}
            </div>

            <S.Infos onClick={handleClick}>
              <div className="basic-infos">

                <span className="dots">{name}</span>

                <div className="house-attributes">
                  {infos.bedrooms !== null && (
                    <div>
                      <Bedroom />
                      <span>{infos.bedrooms}</span>
                    </div>
                  )}

                  {infos.bathrooms !== null && (
                    <div>
                      <Shower />
                      <span>{infos.bathrooms}</span>
                    </div>
                  )}

                  {infos.houseArea !== null && infos.houseArea !== 0 && (
                    <div>
                      <BuildingSize />
                      <span>{infos.houseArea} m²</span>
                    </div>
                  )}

                  {infos.totalArea !== null && infos.totalArea !== 0 && (
                    <div>
                      <Maximize />
                      <span>{infos.totalArea} {infos.metragem === "hectares" ? "ha" :"m²"}</span>
                    </div>
                  )}

                  {(infos.bedrooms === null && infos.bathrooms === null && infos.houseArea === null && infos.totalArea === null) && (
                    <div>
                      <Info />
                      <span>Sem dados adicionais</span>
                    </div>
                  )}
                </div>

                <S.ButtonContainer>
                  {userData.id ? <>
                    {togglingFavorite ? (
                      <Skeleton width={30}  />
                    ) : (
                      <S.StartButton starred={!!favId} onClick={toggleFavorite} ref={starRef} aria-label="Favoritar/Desfavoritar" name="house-card-start-btn">
                        <Star />
                      </S.StartButton>
                    )}
                  </> : (
                    <S.StartButton onClick={openLogin} aria-label="Favoritar/Desfavoritar" name="house-card-start-btn">
                      <Star />
                    </S.StartButton>
                  )}
                  <span className="price">{formatCurrency(value)}</span>
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={`/imovel/${infos.slug}-${infos.totalArea ? infos.totalArea + infos.metragem + '-' : ''}codigo:${infos.code}/${id}`}
                  >
                    <Button
                      color='blue'
                      disabled={isUnavailable(status)}
                      aria-label="Ver"
                      name="house-card-action-btn"
                    >
                      {getHouseButtonLabel(status)}
                    </Button>
                  </Link>

                </S.ButtonContainer>
              </div>
            </S.Infos>
          </S.Content>

          {(withLoggedActions || userData.id) && userData.type_id != 1 && isHovered && (
            <S.LoggedActionsButton
              type="button"
              onClick={() => setActionsVisible(true)}
              aria-label="Menu"
            >
              <Menu />
            </S.LoggedActionsButton>
          )}

          {withLoggedActions && actionsVisible && (
            <S.ActionsContainer ref={shareRef}>
              <S.ShareHeader>
                <S.ShareHeaderButton type='button' aria-label="Fechar" name="close-house-card-btn" onClick={() => {
                  setCopied(false);
                  setActionsVisible(false)}
                }>
                  <Close />
                </S.ShareHeaderButton>
              </S.ShareHeader>

              <CopyToClipboard text={copyText}
                onCopy={() => setCopied(true)} aria-label="Copiar código">
                <S.ShareButton >
                  <CopyPaste/>Cód: {property.code}
                </S.ShareButton>
              </CopyToClipboard>
              {copied && <p style={{ fontSize: 10, width: '100%', textAlign: 'center', color: '#0C4A88'}}>Códígo do imóvel copiado</p>}

              <S.ShareButton type='button' onClick={() => width < 800 ? mobileShare() : setShareContainerOpen(true)} aria-label="Compartilhar" name="share-btn">
                <SharedCircle /> Compartilhar
              </S.ShareButton>

              <button type='button' onClick={() => openInfCorretor()} aria-label="Informações ao corretor" name="info-broker-btn">
                <People /> <span>Inf. corretor</span>
              </button>

              {catalogos && (userData.type_id === 2 || userData.type_id === 4) && (
                <button type='button' onClick={() => setOpenModal(true)} aria-label="Adicionar ao catálogo" name="add-catalog-btn">
                  <Catalog /> <span>Add. catálogo</span>
                </button>
              )}

              {shareContainerOpen && (
                <S.ShareContainer ref={ref} aria-label="Compartilhar no WhatsApp">
                  <div onClick={wppShare}>
                    <WhatsappIcon type="button" name="house-card-share-wpp-btn" />
                  </div>

                  <FacebookShareButton
                    url={`${window.location.href}`}
                    title={`${name || `Olha esse imóvel incrível`
                      } - Prado Imóveis:`}
                    onClick={() => setShareContainerOpen(false)}
                    aria-label="Compartilhar no Facebook"
                    name="house-card-share-facebook-btn"
                  >
                    <FacebookIcon />
                  </FacebookShareButton>

                  <TwitterShareButton
                    url={`${window.location.href}`}
                    title={`${name || `Olha esse imóvel incrível`
                      } - Prado Imóveis:`}
                    onClick={() => setShareContainerOpen(false)}
                    aria-label="Compartilhar no Twitter"
                    name="house-card-share-twiter-btn"
                  >
                    <TwitterIcon />
                  </TwitterShareButton>
                </S.ShareContainer>
              )}
              {
                (userData.type_id === 2 || userData.type_id === 4) &&
                <>
                  <button type='button' onClick={() => navigate(`/editar-imovel/${id}`)} aria-label="Editar" name="house-card-edit-btn">
                    <Edit /> <span>Editar</span>
                  </button>
                  {isCatalog && (
                    <button type="button" className="delete" onClick={() => catalogRemove(id)} aria-label="Excluir" name="house-card-delete-btn">
                      <Trash style={{ filter: 'invert(1%) sepia(400%) saturate(5000%) hue-rotate(1deg) brightness(50%) contrast(119%)' }} /> <span>Remover do catálogo</span>
                    </button>
                  )}
                  {/*{canBeOnlyRemoved(status) && (
                    <button type="button" className="delete" onClick={onChangeStatusClick} aria-label="Excluir" name="house-card-delete-btn">
                      <Trash style={{ filter: 'invert(1%) sepia(400%) saturate(5000%) hue-rotate(1deg) brightness(50%) contrast(119%)' }} /> <span>Vendido</span>
                    </button>
                  )}*/}
                </>
              }

              {/*{(userData.type_id === 2 || userData.type_id === 4) && (
                <>
                  {
                    !property.disabled ?
                    (<button type="button" className="delete" onClick={onDisable} aria-label="Desabilitar" name="house-card-disable-btn">
                      <Block /> <span>Desabilitar</span>
                    </button>)
                      :
                      (<button type="button" onClick={onDisable} aria-label="Habilitar" name="house-card-enable-btn">
                        <Check /> <span>Habilitar</span>
                      </button>)
                  }
                  <button type="button" className="delete" onClick={onDelete} aria-label="Excluir" name="house-card-delete2-btn">
                    <Delete /> <span>Excluir</span>
                  </button>
                </>
              )}*/}

            </S.ActionsContainer>
          )}

          {userData.id && !withLoggedActions && actionsVisible && (
            <S.ActionsContainer ref={shareRef}>
              <S.ShareHeader>
                <S.ShareHeaderButton type='button' onClick={() => setActionsVisible(false)} name="house-card-close-btn">
                  <Close />
                </S.ShareHeaderButton>
              </S.ShareHeader>
              <S.ShareButton type='button' onClick={() => width < 800 ? mobileShare() : setShareContainerOpen(true)} name="house-card-share-btn">
                <SharedCircle /> Compartilhar
              </S.ShareButton>

              {shareContainerOpen && (
                <S.ShareContainer ref={ref}>
                  <div onClick={wppShare}>
                    <WhatsappIcon type="button" />
                  </div>

                  <FacebookShareButton
                    url={`${window.location.href}`}
                    title={`${name || `Olha esse imóvel incrível`
                      } - Prado Imóveis:`}
                    onClick={() => setShareContainerOpen(false)}
                    name="house-card-share-facebook-btn"
                  >
                    <FacebookIcon />
                  </FacebookShareButton>

                  <TwitterShareButton
                    url={`${window.location.href}`}
                    title={`${name || `Olha esse imóvel incrível`
                      } - Prado Imóveis:`}
                    onClick={() => setShareContainerOpen(false)}
                    name="house-card-share-twitter-btn"
                  >
                    <TwitterIcon />
                  </TwitterShareButton>
                </S.ShareContainer>
              )}
            </S.ActionsContainer>
          )}
        </S.Container>
      </S.ExternalContainer>
      <SweetAlert2 {...swalProps}
        didClose={() => {
          setSwalProps({
            show: false
          })
        }}
      />
      <Dialog
        open={modalCorretoresOpen}
        onClose={() => setModalCorrertoresOpen(!modalCorretoresOpen)}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: '25%' },
          },
        }}
      >
        <DialogContent >
          {
            property &&
            <InfosToBroker
              mensageWppCaptador={mensageWppCaptador}
              mensageWppProprietario={mensageWppProprietario}
              address={`${property.address}, ${property.district} - ${property.city?.description}`}
              keys_local={property.keys}
              captador={property.user_capture === null ? defaultRealtorName : property.user_capture?.name}
              link_enterprise={property.user_capture === null ? '' : property.url}
              avaliation_value={property.amount_evaluation}
              captador_phone={property.user_capture === null ? '62982902290' : property.user_capture?.phone}
              commission={property.comissao}
              owner={property.proprietary}
              owner_phone={property.phone_proprietary}
              property_id={property.id}
              evaluated={!!property.evaluation}
              amount_evaluation={property.amount_evaluation}
              broker_info={property.broker_info}
              lat={property.latitude}
              lng={property.longitude}
              name={property.name}
              key_number={property.key_number}
              branch_agency={property.branch_agency}
              visible={true}
              setCloseModal={setModalCorrertoresOpen}
              closeModal={modalCorretoresOpen}
              user_include={property.user_register}
              data_inclusao={property.created_at}
              data_edit={property.updated_at}
            />
          }
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth={'md'}
        fullWidth={true}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h2>Adicionar imóvel em um catálogo</h2>
            <CloseButton onClick={() => setOpenModal(false)} />
          </div>
        </DialogTitle>
        <DialogContent >
          <DialogContentText>{catalogos ? 'Selecione o catálogo' : 'Nenhum catálogo cadastrado'}</DialogContentText>
          <Box noValidate component="form" sx={{ mt: 2, minHeight: 300 }}>
            <CustomAutocomplete
              multiple={false}
              width={'100%'}
              title={'Catálogos'}
              options={catalogos}
              onSelectOption={(opt: any) => {
                selectCatalog(opt);
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color={'blue'} onClick={() => bindProperty()}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <RegisterModal
        isOpen={registerModalOpen ?? false}
        onLogin={() =>
          setRegisterModalOpen && setRegisterModalOpen(false)
        }
        onClose={() =>
          setRegisterModalOpen && setRegisterModalOpen(false)
        }
      />
      <LoginModal
        isOpen={isOpen ?? false}
        openRegister={openRegister}
        onLogin={() => setIsOpen && setIsOpen(false)}
        onClose={() => setIsOpen && setIsOpen(false)}
      />
    </>
  );
}

export { HouseCard };
