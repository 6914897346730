import { useEffect, useState } from 'react';

import no_image from 'common/assets/images/no_image.jpg';
import { HouseCard, RealtyDetailsOption } from 'components/Molecules';
import { Carousel } from 'components/Organisms';
import api from 'services/api';
import { v4 as uuid } from 'uuid';

import { useAuth } from '../../../hooks/auth';
import * as S from './styles';

function HighlightCarousel() {
  // Temporario pois não havia documentação do back-end
  const [highlightedProperties, setHighlightedProperties] = useState<any[]>([]);
  const { userData, logoutLocal } = useAuth();
  const [catalogos, setCatalogos] = useState<any[]>([]);
  useEffect(() => {
    const stringData = localStorage.getItem('@user:prado_imoveis');
    let axiosOptions = undefined;
    if (stringData) {
      const data = JSON.parse(stringData);
      axiosOptions = {
        headers: {
          Authorization: 'Bearer ' + data.token,
        },
      };
      api
        .post(
          '/properties/list?featured_property=true&order=desc',
          { user_id: data.id },
          axiosOptions,
        )
        .then(req => {
          setHighlightedProperties([...req.data.imoveis.data]);
        })
        .catch(error => {
          if (error.response.status === 401) {
            logoutLocal();
          }
        });
    } else {
      api.get('/properties?featured_property=true&order=desc').then(req => {
        setHighlightedProperties([...req.data.imoveis.data]);
      });
    }
  }, []);

  useEffect(() => {
    if (userData.id) {
      api
        .get(`/catalogo?user_register=true`)
        .then(({ data }) => {
          const resp = data.data;
          if (resp) {
            const list = resp.map(function (dt: { id: any; titulo: any }) {
              return { value: dt.id, label: dt.titulo };
            });
            setCatalogos(list);
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            logoutLocal();
          }
        });
    }
  }, [userData]);

  return (
    <>
      {highlightedProperties.length > 3 && (
        <S.Container>
          <h3>Imóveis em destaque</h3>

          <Carousel
            noDots
            classItemCaroucel={'itemCarousel'}
            slidesToShow={
              highlightedProperties.length > 4
                ? 4
                : highlightedProperties.length
            }
            customSettings={{
              dots: false,
              autoplay: false,
              infinite: true,
              slidesToShow: 4,
              slidesToScroll: 4,
              arrows: true,
              responsive: [
                {
                  breakpoint: 1600,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                  },
                },
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 2,
                  },
                },
                {
                  breakpoint: 520,
                  settings: {
                    dots: true,
                    // arrows: false,
                    slidesToShow: 1.3,
                    slidesToScroll: 1,
                  },
                },
              ],
            }}
            items={highlightedProperties.map(data => (
              <S.CardContainer key={uuid()}>
                <HouseCard
                  tarja={data.tarja}
                  id={data.id}
                  name={data.name}
                  value={
                    data?.promotional_amount
                      ? data.promotional_amount
                      : data.amount
                  }
                  favoriteId={
                    data?.favorites_data?.length
                      ? data.favorites_data[0].id
                      : undefined
                  }
                  infos={{
                    bathrooms: data.bathrooms,
                    bedrooms: data.bedrooms,
                    houseArea: data.contruct_area,
                    totalArea: data.land_area,
                    metragem: data.type_footage,
                    slug: data.slug,
                    code: data.code,
                  }}
                  location={{
                    latitude: Number(data.latitude),
                    longitude: Number(data.longitude),
                    name: `${data.district} - ${data.city?.description}`,
                  }}
                  withLoggedActions={!!userData.id}
                  images={data.images.map(
                    (img: any) => /*img.h_115 ||*/ img.image,
                  )}
                  property={data}
                  catalogos={catalogos}
                />
              </S.CardContainer>
            ))}
          />
        </S.Container>
      )}
    </>
  );
}

export { HighlightCarousel };
