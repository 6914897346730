/* eslint-disable prettier/prettier */
import 'leaflet/dist/leaflet.css';
import {useCallback, useEffect, useRef} from 'react';
import {MapContainer, TileLayer, useMapEvent} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';


import { ReactComponent as Expand } from 'common/assets/svgs/expand.svg';
import greenPin from 'common/assets/svgs/map/icon_map_green.svg';
import pin from 'common/assets/svgs/map/icon_map.svg';
import { Button } from 'components/Atoms';
import L from "leaflet";

// import pinIcon from 'common/assets/svgs/pin.svg';
import Leaflet from 'leaflet';

import { Marker } from './components/Marker';
import { IMapProps } from './IMap';
import * as S from './styles';

function  Map({
  markers,
  centerPosition,
  noZoomControl,
  notDefaultZoomControlPosition,
  currentPropertyId,
  onClickMarker,
  onClickDragend,
  zoom,
  fullMarkerPopup,
  markedProperty,
  catalogos
}: IMapProps) {

  const mapPinIcon = Leaflet.icon({
    iconUrl: pin,
    iconSize: [37, 53],
  });

  const greeMapPinIcon = Leaflet.icon({
    iconUrl: greenPin,
    iconSize: [37, 53],

  });

  const mapRef = useRef(null);

  useEffect(() => {
    if (!mapRef.current) return;
    // @ts-ignore
    mapRef.current.flyTo(centerPosition, zoom);
  }, [centerPosition]);


  /*
  Testes dos cluster (mudar cor do pino selecionado)
  useEffect(() => {
    if (!clusterRef.current) return;
    // @ts-ignore
  }, [centerPosition])
  */

  const createClusterCustomIcon = function (cluster: any) {
    const clusterTitles: string[] = cluster.getAllChildMarkers().map((m: any) => m.options.title);
    const clusterTitle  = markers.filter(m => m.property_id == currentPropertyId)[0]?.property_name ??  '';
    const lPoint = cluster._childCount > 999 ? L.point(50, 50, true) : L.point(33, 33, true);

    if (clusterTitles.includes(clusterTitle)) {
      return L.divIcon({
        html: `${cluster.getChildCount()}`,
        className: 'custom-marker-cluster custom-marker-cluster--selected',
        iconSize: lPoint,
      })
    } else {
      return L.divIcon({
        html: `${cluster.getChildCount()}`,
        className: 'custom-marker-cluster',
        iconSize: lPoint,
      })
    }
  }

  return (
    <S.Container notDefaultZoomControlPosition={notDefaultZoomControlPosition}>
      <MapContainer
        center={{ lat: centerPosition.lat, lng: centerPosition.lng }}
        zoom={zoom ? zoom : 11}
        scrollWheelZoom={false}
        style={{ height: '100%', width: '100%' }}
        zoomControl={!noZoomControl}
        ref={mapRef}
      >
        <TileLayer
          attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
          url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png"
        />
        <MarkerClusterGroup chunkedLoading iconCreateFunction={createClusterCustomIcon}>
          {markers.map((marker,) => (
            <Marker
              fullMarkerPopup={fullMarkerPopup}
              onClickDragend={onClickDragend}
              centerPosition={centerPosition}
              onClickMarker={onClickMarker}
              icon={mapPinIcon}
              {...marker}
              key={marker.property_id}
              images={marker.images}
              markedProperty={markedProperty}
              catalogos={catalogos}
            />
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    </S.Container>
  );
}

export default Map;

export { Map };
