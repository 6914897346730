export default {
  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  validateEmail(email: string) {
    const re = /(.*)@(.*).(.*)/;
    return re.test(email);
  },

  handleYoutubeVideoThumbnailFromUrl(url: string) {
    const videoId = this.extractVideoId(url);
    return `https://img.youtube.com/vi/${videoId}/default.jpg`;
  },

  extractVideoId(url: string) {
    return url.split('v=')[1].split('&')[0];
  },

  handleEmbedYoutubeVideoUrl(url: string) {
    const videoId = this.extractVideoId(url);
    return `https://www.youtube.com/embed/${videoId}`;

    // return url
    //   .replace('watch?v=', 'embed/')
    //   .replace('youtu.be/', 'youtube.com/embed/')
    //   .split('');
  },

  isYoutubeUrl(url: string) {
    return (
      url.startsWith('https://youtu') || url.startsWith('https://www.youtu')
    );
  },

  concatPath(
    firstPath?: string | number,
    secondPath?: string | number,
  ): string {
    firstPath = String(firstPath);
    secondPath = String(secondPath);
    if (!firstPath?.length && !secondPath?.length) return '';
    if (!firstPath?.length) return secondPath as string;
    if (!secondPath?.length) return firstPath;
    const f = firstPath.endsWith('/') ? firstPath.slice(0, -1) : firstPath;
    const s = secondPath.startsWith('/') ? secondPath.slice(1) : secondPath;
    return `${f}/${s}`;
  },
};
