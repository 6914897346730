import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Typography, useMediaQuery } from '@mui/material';
import Check from 'components/Atoms/Check';
import DateTimePicker from 'components/Atoms/DateTimePicker';
import LabelRequiredIndicator from 'components/Atoms/LabelRequiredIndicator';
import RichText from 'components/Atoms/RichText';
import ImoveisComunicadoModal, {
  useModal,
} from 'components/Organisms/ImoveisComunicadoModal';
import useRootForm from 'hooks/useRootForm';

import { Button, DefaultPageTitle } from '../../../components/Atoms';
import { DataTable } from '../../../components/Atoms/DataTable';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import * as S from './styles';

function CommunicationForm() {
  const { form, setForm, registerField } = useRootForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const { addToast } = useToast();
  const matches = useMediaQuery('(max-width: 884px)');
  const { handleSubmit, control, errors, reset } = useForm();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [listUser, setListUser] = useState();
  const [load, setLoad] = useState(!!id);
  const { logoutLocal } = useAuth();
  const [propertiesId, setPropertiesId]: any = useState([]);

  const imovelModal = useModal();

  const onSubmit = useCallback(() => {
    setSubmitDisabled(true);
    const data = {
      ...form,
      properties_ids: propertiesId,
    };
    api
      .post('/communication', data)
      .then(response => {
        setSubmitDisabled(false);
        addToast({ title: 'Salvo com sucesso!', type: 'success' });
        setTimeout(() => {
          navigate('/comunicados');
        }, 2000);
      })
      .catch(err => {
        if (err.response.status === 401) {
          logoutLocal();
          return;
        }
        addToast({
          title: 'Ocorreu um erro ao cadastrar!',
          description: err.response.data.message,
          type: 'error',
        });
        setSubmitDisabled(false);
      });
  }, [setSubmitDisabled, form, propertiesId]);

  useEffect(() => {
    if (id) {
      api.get(`/communication/${id}`).then(req => {
        const data = req.data.data;
        const idsProp: any[] = [];
        data?.properties.map((property: any) => {
          idsProp.push(property.id);
        });
        setPropertiesId(idsProp);
        setListUser(data.users);
        setLoad(false);
        setForm(data);
      });
    }
  }, []);

  const columns = [
    {
      title: 'Id',
      field: 'id',
    },
    {
      title: 'Usuário',
      field: 'name',
    },
    {
      title: 'Data',
      field: 'pivot:created_at',
      type: 'dateHouer',
    },
  ];

  const addProperties = (propertie_id: any, type: string) => {
    if (propertie_id) {
      let newIdsProperties = [...propertiesId];
      if (type === 'add') {
        newIdsProperties.push(propertie_id);
        setPropertiesId(newIdsProperties);
      }
      if (type === 'remove') {
        newIdsProperties = newIdsProperties.filter(
          prop => prop !== propertie_id,
        );
        setPropertiesId(newIdsProperties);
      }
    }
  };

  if (load) return <h1>Carregando</h1>;

  return (
    <Box
      width="100%"
      padding="24px 26px"
      border="1px solid var(--primary-neutral-300)"
      borderRadius="16px"
      sx={{ background: 'var(--white)' }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={matches ? '14px' : '30px'}
      >
        <DefaultPageTitle>Cadastro de comunicado</DefaultPageTitle>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        rowGap="24px"
        marginBottom={matches ? '24px' : ''}
      >
        <Typography
          fontSize="14px"
          fontWeight="400"
          color="var(--primary-neutral-650)"
        >
          <LabelRequiredIndicator /> Indica os campos obrigatórios
        </Typography>
      </Box>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" width="420px" rowGap="18px">
          <DateTimePicker
            {...registerField('data_inicio')}
            label="Data Início"
          />

          <DateTimePicker
            {...registerField('hora_inicio')}
            label="Hora Início"
            type="time"
          />

          <DateTimePicker {...registerField('data_fim')} label="Data Fim" />

          <DateTimePicker
            {...registerField('hora_fim')}
            label="Hora Fim"
            type="time"
          />

          <RichText {...registerField('description')} label="Descrição" />

          <Check {...registerField('publico')} label="Comunicado cliente" />

          <Check {...registerField('landing')} label="Comunicado público" />
          <Box
            display="grid"
            gridTemplateColumns={matches ? '100%' : '47.5% 47.5%'}
            rowGap={matches ? '24px' : ''}
            justifyContent="space-between"
            marginTop="20px"
          >
            <Button
              color="green"
              onClick={() => imovelModal.open()}
              type="button"
              full_width
            >
              {id ? 'Visualizar imoveis' : 'Adicionar imóvel'}
            </Button>
          </Box>

          <Box
            display="grid"
            gridTemplateColumns={matches ? '100%' : '47.5% 47.5%'}
            rowGap={matches ? '24px' : ''}
            justifyContent="space-between"
            marginTop="20px"
          >
            {!id ? (
              <Button
                color="high_blue"
                type="submit"
                full_width
                disabled={submitDisabled}
              >
                Salvar
              </Button>
            ) : (
              ''
            )}
            <Button
              color="white"
              full_width
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      </S.Form>

      {id && listUser ? (
        <>
          <Box
            display="flex"
            flexDirection="column"
            rowGap="24px"
            marginBottom={matches ? '24px' : ''}
            marginTop={'10px'}
          >
            <Typography fontSize="20px" fontWeight="bold">
              Usuários que visualizaram os comunicados
            </Typography>
          </Box>
          <Box
            display="flex"
            flexWrap="wrap"
            marginTop="20px"
            justifyContent="center"
            alignItems={'center'}
            width={'100%'}
            flexDirection={'row'}
          >
            {listUser && (
              <DataTable
                width={'100%'}
                columns={columns}
                data={listUser}
                validIdUserRegister={true}
              />
            )}
          </Box>
        </>
      ) : (
        ''
      )}

      <ImoveisComunicadoModal
        {...imovelModal.props}
        propertiesIds={propertiesId}
        setPropertiesIds={addProperties}
      />
    </Box>
  );
}
export { CommunicationForm };
export default CommunicationForm;
