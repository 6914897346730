import styled from 'styled-components';

import { IDefaultPageTitle } from './IDefaultPageTitle';

export const Container = styled.h1<Pick<IDefaultPageTitle, 'color' | 'align'>>`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${props => (props.color ? props.color : 'var(--grey-700)')};

  em {
    color: var(--blue-450);
    font-style: normal;
  }

  @media (max-width: 480px) {
    width: 100%;
    text-align: ${props => (props.align ? props.align : 'center')};
  }
`;
