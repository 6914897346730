import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Urbanist';
  width: 100%;

  .MuiFormControl-root,
  .MuiAutocomplete-root,
  .MuiOutlinedInput-notchedOutline,
  .MuiAutocomplete-inputRoot,
  .MuiAutocomplete-endAdornment,
  .MuiInputBase-inputAdornedEnd,
  .MuiInputBase-inputMuiInputBase-input {
    font-family: 'Urbanist';
    font-size: 14px;
  }
  .MuiAutocomplete-input {
    padding: 4px 1px 4px 6px !important;
  }
  .MuiButtonBase-root {
    height: auto !important;
    margin-top: -6px !important;
  }
  .MuiAutocomplete-endAdornment {
    top: 10px !important;
  }
  .MuiInputBase-root {
    max-height: 90px !important;
  }

  .MuiAutocomplete-popper {
    font-family: 'Urbanist';
    font-size: 16px;
  }

  .MuiInputBase-input {
    height: auto;
  }
  .MuiFormControl-root {
    > label[data-shrink='false'] {
      margin-top: -5px;
      @media (min-width: 1050px) {
        margin-top: -8px;
      }
    }
  }
  label[data-shrink='false'] + .MuiFormLabel-root {
    color: red;
  }

  .MuiInputBase-input {
    height: auto;
    margin-top: -6px;
  }

  .MuiFormControl-root {
    label {
      font-family: 'Urbanist';
      font-size: 14px;
      margin-top: -2px;
      @media (min-width: 1050px) {
        font-size: 16px;
      }
    }
  }
`;

export const IconWrapper = styled.div`
  height: 38px;
  max-height: 38px;
  width: 20%;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  border: 2px solid var(--primary-neutral-300);

  svg,
  path {
    width: 20px;
    height: 20px;
  }
`;
