import { useState } from 'react';

import MuiModal from '@mui/material/Modal';
import CloseButton from 'components/Atoms/CloseButton';

export function useModal() {
  const [isOpen, setIsOpen] = useState(false);

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  return {
    open,
    close,
    isOpen,
    props: {
      open,
      close,
      isOpen,
    },
  };
}

export type ModalProps = {
  isOpen: boolean;
  close: () => void;
  children: React.ReactNode;
  title?: string;
};

export default function Modal({ children, isOpen, close, title }: ModalProps) {
  return (
    <MuiModal
      open={isOpen}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <div
          style={{
            background: 'white',
            borderRadius: '10px',
            minWidth: '180px',
            width: 'max-content',
            maxWidth: '94vw',
            margin: 'auto',
          }}
        >
          <header
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '10px',
              gap: '10px',
            }}
          >
            <h3 style={{ marginTop: '-4px' }}>{title}</h3>
            <CloseButton onClick={close} />
          </header>
          {children}
        </div>
      </div>
    </MuiModal>
  );
}
