import { Link } from 'react-router-dom';

import { ReactComponent as Facebook } from 'common/assets/svgs/facebook.svg';
import { ReactComponent as Instagram } from 'common/assets/svgs/instagram.svg';
import { ReactComponent as Linkedin } from 'common/assets/svgs/linkedin.svg';
// import { ReactComponent as Twitter } from 'common/assets/svgs/twitter.svg';
import { routesNames } from 'common/utils/routesNames';
import { Logo } from 'components/Atoms';

import * as S from './styles';

function Footer() {
  return (
    <S.Container>
      <S.Content>
        <S.CompanyDescription>
          <Logo />

          <p>
            A empresa tem como proposito, levar moradia de qualidade ao mundo,
            ajudando as pessoas e suas famílias a encontrarem o imóvel ideal, e
            realizarem o sonho da casa própria, trazendo qualidade de vida,
            alegria e satisfação.
          </p>

          <div className="social-networks">
            <S.Link
              href="https://www.facebook.com/pradoimoveisoficial"
              target="_blank"
              rel="noreferrer"
              aria-label="Facebook"
              name="facebook-footer-link"
            >
              <Facebook />
            </S.Link>

            {/* <a href="https://twitter.com" target="_blank" rel="noreferrer">
              <Twitter />
            </a> */}

            <S.Link
              href="https://www.instagram.com/prado.imoveis/"
              target="_blank"
              rel="noreferrer"
              aria-label="Instagram"
              name="instagram-footer-link"
            >
              <Instagram />
            </S.Link>

            <S.Link
              href="https://www.linkedin.com/company/prado-im%C3%B3veis-oficial/"
              target="_blank"
              rel="noreferrer"
              aria-label="Linkedin"
              name="linkedin-footer-link"
            >
              <Linkedin />
            </S.Link>
          </div>

          <S.RightsReserved>
            © 2023. Todos os direitos reservados <br />
            Deus habita neste lugar
          </S.RightsReserved>
        </S.CompanyDescription>

        <S.FooterNavigation>
          <h4>Navegação</h4>
          <ul>
            <li>
              <S.Link
                href={routesNames.root}
                aria-label="Início"
                name="landing-page-footer-link"
              >
                Início
              </S.Link>
            </li>

            <li>
              <S.Link
                href={routesNames.search}
                aria-label="Buscar imóvel"
                name="search-properties-footer-link"
              >
                Buscar Imóvel
              </S.Link>
            </li>
          </ul>
        </S.FooterNavigation>

        <S.Newsletter>
          <S.Link
            href={`https://wa.me/+556239991400?text=${encodeURI(
              'Olá, gostaria de receber novidades.',
            )}`}
            target="_blank"
            rel="noreferrer"
            aria-label="Novidades"
            name="news-footer-link"
          >
            Novidades
          </S.Link>
          <h5>Receba as melhores oportunidades no seu whatsapp e no e-mail.</h5>
        </S.Newsletter>

        <S.RightsReserved>
          © 2023. Todos os direitos reservados <br />
          Deus habita neste lugar
        </S.RightsReserved>
      </S.Content>
    </S.Container>
  );
}

export { Footer };
