import styled from 'styled-components';

export const ActionsContainer = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media (min-width: 1255px) {
    justify-content: flex-end;
  }
`;
