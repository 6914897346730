import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Pagination,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { USER_TYPE } from '@types';
import { routesNames } from 'common/utils/routesNames';
import { Button, DefaultPageTitle } from 'components/Atoms';
import { saveAs } from 'file-saver';
import { useAuth } from 'hooks/auth';
import { IUser } from 'models/IAuth';
import api from 'services/api';

import { InputWithIcon } from '../../components/Molecules';
import { UserBox } from './components/UserBox';
import * as S from './styles';

function Users() {
  const navigate = useNavigate();
  const [trigger, setTrigger] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [tipoConsulta, setTipoConsulta] = useState('ativos');
  const [pagesTotal, setPagesTotal] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { logoutLocal, is } = useAuth();

  const [userTotal, setUserTotal] = useState({
    todos: 0,
    ativos: 0,
    inativos: 0,
  });

  const { userData } = useAuth();

  useEffect(() => {
    getUsers();
    /*const tipo = tipoConsulta ? tipoConsulta : 'ativos';
    setTipoConsulta(tipo);
    api
      .get('/users?not_type_id=1&status=' + tipo + '&page=' + currentPage)
      .then(({ data }) => {
        const res = data.data;
        setPagesTotal(res.last_page);
        setCurrentPage(res.current_page);
        setUsers(res.data);
      })
      .catch(error => {
        if (error.response.status === 401) {
          logoutLocal();
        }
      });*/
  }, [userData, trigger, tipoConsulta, currentPage]);

  const getUsers = (consult?: string, page?: number) => {
    const tipo = tipoConsulta ? tipoConsulta : 'ativos';
    setTipoConsulta(tipo);
    console.log(page ? page : currentPage);
    let url = `/users?not_type_id=1&status=${tipo}&page=${
      page ? page : currentPage
    }`;
    if (consult) {
      url += `&name=${consult}`;
    }

    api
      .get(url)
      .then(({ data }) => {
        const res = data.data;
        setPagesTotal(res.last_page);
        //setCurrentPage(res.current_page);
        setUsers(res.data);
      })
      .catch(error => {
        if (error.response.status === 401) {
          logoutLocal();
        }
      });
  };

  const searchUsers = (consult: string) => {
    if (consult.length > 3) {
      getUsers(consult, 1);
      return;
    }
    if (consult.length === 0) {
      getUsers();
    }
  };

  useEffect(() => {
    api.get('/users/totais?not_type_id=1').then(({ data }) => {
      setUserTotal(data);
    });
  }, []);

  const handleChangePage = (_: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const excelDataExport = () => {
    api
      .get('users/export', { responseType: 'blob' })
      .then(({ data }) => {
        const filename = 'usuarios.xlsx';
        const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
        saveAs(blob, filename);
      })
      .catch(error => {
        if (error.response.status === 401) {
          logoutLocal();
        }
      });
  };

  return (
    <Box
      width="100%"
      height="fit-content"
      padding="24px 26px"
      border="1px solid var(--primary-neutral-300)"
      borderRadius="16px"
      sx={{ background: 'var(--white)' }}
    >
      <>
        <Box
          display="flex"
          gap="20px"
          marginTop="20px"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <div
            style={{
              justifyContent: 'flex-start',
              display: 'flex',
            }}
          >
            <DefaultPageTitle>Usuários</DefaultPageTitle>
          </div>

          <div style={{ width: '25%' }}>
            <InputWithIcon
              placeholder="Busca usuário"
              name="name"
              onChange={e => searchUsers(e.target.value)}
              defaultValue={''}
            />
          </div>

          <div
            style={{
              justifyContent: 'flex-end',
              display: 'flex',
            }}
          >
            <ToggleButtonGroup
              value={tipoConsulta}
              exclusive
              color="primary"
              style={{ height: '40px' }}
              onChange={(_, v) => {
                setTipoConsulta(v);
              }}
            >
              <ToggleButton value="todos">
                <div>
                  <div>Todos</div>
                  <div className={'font-10'}>{userTotal.todos}</div>
                </div>
              </ToggleButton>
              <ToggleButton value="ativos">
                <div>
                  <div>Ativos</div>
                  <div className={'font-10'}>{userTotal.ativos}</div>
                </div>
              </ToggleButton>
              <ToggleButton value="desabilitados">
                <div>
                  <div>Inativos</div>
                  <div className={'font-10'}>{userTotal.inativos}</div>
                </div>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <S.ActionsContainer>
            <Button color="green" onClick={() => excelDataExport()}>
              Baixar Excel
            </Button>

            <Button color="alpha-blue" onClick={() => navigate('/clientes')}>
              Listar clientes
            </Button>

            {is(USER_TYPE.gestor) && (
              <Button
                color="high_blue"
                onClick={() => navigate(routesNames.addUser)}
              >
                Novo usuário
              </Button>
            )}
          </S.ActionsContainer>
        </Box>
      </>

      <Box
        display="flex"
        gap="20px"
        flexWrap="wrap"
        marginTop="20px"
        justifyContent="center"
      >
        {users &&
          users.map(user => (
            <UserBox
              {...user}
              onDeleteUser={() => setTrigger(!trigger)}
              key={user.id}
            />
          ))}
      </Box>
      {!!pagesTotal && pagesTotal > 1 && (
        <Box display="flex" justifyContent="center" marginTop="24px">
          <Stack spacing={2}>
            <Pagination
              count={pagesTotal}
              page={currentPage}
              onChange={handleChangePage}
              variant="outlined"
              color="primary"
            />
          </Stack>
        </Box>
      )}
    </Box>
  );
}

export { Users };
export default Users;
