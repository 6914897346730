import { Box } from '@mui/material';

import { DefaultPageTitle } from '../../components/Atoms';

function PrivacyPolicy() {
  return (
    <Box
      width="100%"
      height="fit-content"
      padding="24px 26px"
      border="1px solid var(--primary-neutral-300)"
      borderRadius="16px"
      sx={{ background: 'var(--white)' }}
    >
      <Box
        display="flex"
        gap="20px"
        flexWrap="wrap"
        marginTop="20px"
        justifyContent="space-between"
      >
        <div>
          <DefaultPageTitle>Política de privacidade</DefaultPageTitle>
        </div>
      </Box>
    </Box>
  );
}

export { PrivacyPolicy };
export default PrivacyPolicy;
