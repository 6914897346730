import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useSearch } from 'hooks/search';

import { Button, DefaultPageTitle } from '../../../components/Atoms';
import { HouseCard } from '../../../components/Molecules';
import { CustomAutocomplete } from '../../../components/Molecules/CustomAutocomplete';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api, { getTarjas } from '../../../services/api';
import * as S from './styles';

function CatalogView() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { userData } = useAuth();
  const [catalogData, setCatalogData] = useState({
    id: null,
    titulo: '',
    styled_titulo: '',
  });
  const { register } = useForm();
  const [tarjasOpt, setTarjasOpt] = useState<any[]>([]);
  const [tarjas, setTarjas] = useState<any[]>([]);
  const [imoveis, setImoveis] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const { addToast } = useToast();
  const { logoutLocal } = useAuth();
  const [tarjasSelected, setTarjasSelected] = useState<any>('');
  const { filter, keys } = useSearch();
  const { control } = useForm();
  useEffect(() => {
    loadTarjas();
    if (id) {
      api
        .get(`/catalogo/${id}`)
        .then(req => {
          const data = req.data.data;
          setCatalogData({
            id: data.id,
            titulo: data.titulo,
            styled_titulo: data.styled_titulo,
          });
          setImoveis(data.imoveis);
          setLoading(false);
        })
        .catch(error => {
          if (error.response.status === 401) {
            logoutLocal();
          }
        });
    }
  }, [id]);

  async function loadTarjas() {
    const data = await getTarjas();
    if (!data) return;
    const opt = data.map(t => ({
      label: t.name,
      value: t.id,
    }));
    // @ts-ignore
    opt.unshift({ label: 'Todos', value: '' });
    setTarjasOpt(opt);
    setTarjas(data);
  }

  const removeCaralog = (idImovel: any) => {
    const formData = new FormData();
    formData.append('id_imovel', idImovel);
    api
      .post(`/catalogo/${id}/desvincular`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => {
        setImoveis(imoveis.filter((im: any) => im.id !== idImovel));
        addToast({
          title: res.data.message,
          type: 'success',
        });
      })
      .catch(err => {
        addToast({
          title: `Ocorreu um erro ao desvincular!`,
          description: err.response.data.message,
          type: 'error',
        });
      });
  };

  const consultCatalogos = () => {
    setLoading(true);
    api
      .get(`/catalogo/${id}?tarja_id=${tarjasSelected}`)
      .then(req => {
        const data = req.data.data;
        setCatalogData({
          id: data.id,
          titulo: data.titulo,
          styled_titulo: data.styled_titulo,
        });
        setImoveis(data.imoveis);
        setLoading(false);
      })
      .catch(error => {
        if (error.response.status === 401) {
          logoutLocal();
        }
        setLoading(false);
      });
  };

  return (
    <Box
      width="100%"
      height="fit-content"
      padding="24px 26px"
      sx={{ background: 'var(--white)' }}
    >
      {loading ? (
        <Box marginTop="24px" borderTop="1px solid var(--alpha-blue-15)">
          <Typography
            fontWeight="600"
            fontSize="22px"
            textAlign="center"
            paddingTop="22px"
            color="var(--primary-neutral-900)"
          >
            Carregando
          </Typography>
        </Box>
      ) : (
        <>
          {userData.id && (
            <Box
              display="flex"
              flexWrap="wrap"
              marginTop="5px"
              justifyContent="flex-end"
            >
              <div
                style={{
                  justifyContent: 'flex-end',
                  width: '100%',
                  display: 'flex',
                }}
              >
                <Button
                  color="transparent"
                  onClick={() => navigate(`/catalogos`)}
                  type="button"
                >
                  Voltar
                </Button>
              </div>
            </Box>
          )}

          <Box
            display="flex"
            flexWrap="wrap"
            marginTop="5px"
            alignItems="flex-end"
          >
            <div
              style={{
                width: '30%',
              }}
            >
              <Typography
                style={{
                  fontSize: '14px',
                  fontWeight: '600',
                  color: 'var(--primary-neutral-900)',
                }}
              >
                Tarja
              </Typography>

              <Controller
                name="tarja_id"
                control={control}
                render={({ onChange }) => (
                  <Box height="100%" width="100%">
                    <CustomAutocomplete
                      key={keys.general}
                      width={'100%'}
                      title={''}
                      //initialValue={{ label: 'Todos', value: '' }}
                      initialValue={tarjasOpt.find(
                        (tar: any) => tar.value === tarjasSelected,
                      )}
                      options={tarjasOpt}
                      onSelectOption={(opt: any) => {
                        setTarjasSelected(opt.value);
                        onChange(opt);
                      }}
                    />
                  </Box>
                )}
              />
            </div>
            <div
              style={{
                marginLeft: '15px',
                height: '40px',
                width: '30%',
                display: 'flex',
              }}
            >
              <Button
                color="blue"
                onClick={() => consultCatalogos()}
                type="button"
              >
                Consultar
              </Button>
            </div>
          </Box>

          <Box
            display="flex"
            flexWrap="wrap"
            marginTop="10px"
            justifyContent="center"
          >
            <div
              style={{
                justifyContent: 'center',
                width: '100%',
                display: 'flex',
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: catalogData.styled_titulo,
                }}
              ></div>
            </div>
          </Box>
          <S.CardsContainer>
            {imoveis.map((data: any) => (
              <HouseCard
                tarja={data.tarja}
                key={data.id}
                id={data.id}
                name={data.name}
                value={
                  data?.promotional_amount
                    ? data.promotional_amount
                    : data.amount
                }
                /*disabled={data.disabled}*/
                infos={{
                  bathrooms: data.bathrooms,
                  bedrooms: data.bedrooms,
                  houseArea: data.contruct_area,
                  totalArea: data.land_area,
                  metragem: data.type_footage,
                  slug: data.slug ?? '',
                  code: data.code ?? '',
                }}
                location={{
                  latitude: Number(data.latitude),
                  longitude: Number(data.longitude),
                  name: `${data.district} - ${data.city?.description}`,
                }}
                withLoggedActions
                images={data.images.map(
                  (img: any) => /*img.h_115 ||*/ img.image,
                )}
                status={data.status || ''}
                property={data}
                isCatalog={true}
                catalogRemove={removeCaralog}
              />
            ))}
          </S.CardsContainer>
        </>
      )}
    </Box>
  );
}

export { CatalogView };
export default CatalogView;
