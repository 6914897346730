import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as Bedroom } from 'common/assets/svgs/bedroom.svg';
import { ReactComponent as Building } from 'common/assets/svgs/building.svg';
// import { ReactComponent as Car } from 'common/assets/svgs/car.svg';
import { ReactComponent as FilterRemoved } from 'common/assets/svgs/filter-remove.svg';
import { ReactComponent as House } from 'common/assets/svgs/house.svg';
import { ReactComponent as Location } from 'common/assets/svgs/location.svg';
import { ReactComponent as MaxPrice } from 'common/assets/svgs/max_price.svg';
import { ReactComponent as MinPrice } from 'common/assets/svgs/min_price.svg';
import { ReactComponent as SearchNormal } from 'common/assets/svgs/search_normal.svg';
// import { ReactComponent as Shower } from 'common/assets/svgs/shower.svg';
import { routesNames } from 'common/utils/routesNames';
import { Button, Checkbox } from 'components/Atoms';
import CloseButton from 'components/Atoms/CloseButton';
import {
  CustomAutocomplete,
  InputWithIcon,
  NewDropdown,
} from 'components/Molecules';
import NewMultipleDropdown from 'components/Molecules/NewMultipleDropdown';
import { useSearch } from 'hooks/search';
import useInputMask from 'hooks/useInputMask';
import { IDropdownOption } from 'models/IDropdownOption';
import { IPriceFormData } from 'models/IPriceFormData';

import * as S from './styles';

function HomeSearch() {
  const [onlyAvaliados, setOnlyAvaliados] = useState(false);
  const { onMoneyMask } = useInputMask();
  const [openModal, setOpenModal] = useState(false);
  const {
    cities,
    updateFilter,
    selectedCity,
    setSelectedCity,
    selectedDistrict,
    setSelectedDistrict,
    selectedDistrictSeveral,
    setSelectedDistrictSeveral,
    districts,
    changeCitiesMode,
    filter,
    temporarySelectedDistricts,
    setTemporarySelectedDistricts,
    keys,
    setKeys,
  } = useSearch();

  const [type, setType] = useState<string | undefined>(filter?.type);
  const [bedrooms, setBedrooms] = useState<IDropdownOption | undefined>(() => {
    if (filter?.bedrooms) {
      return { label: `${filter.bedrooms}`, value: `${filter.bedrooms}` };
    }

    return undefined;
  });

  const [initialValueDistrict, setValueDistrict] = useState<any>(
    filter?.district?.length
      ? {
          value: filter.district.split(',')[0],
          label: filter.district.split(',')[0],
        }
      : [],
  );

  const [selectDistrictSeveral, setSelectDistrictSeveral] = useState<any>(
    selectedDistrictSeveral ? selectedDistrictSeveral : [],
  );

  const { handleSubmit, register, watch } = useForm<IPriceFormData>();
  const navigate = useNavigate();

  useEffect(() => {
    changeCitiesMode('default');
  }, []);
  // const buttonDisabled = useCallback(() => {
  //   if (!selectedCity) return true;

  //   return false;
  // }, [selectedCity, type, bedrooms, watch('max_value')]);

  const onSubmit = useCallback(
    ({ min_value, max_value, code }: IPriceFormData) => {
      // if (!selectedCity) return;
      let dataDistrict = selectedDistrict;
      let districtSlug = dataDistrict;

      if (selectedDistrict === 'varios') {
        districtSlug = '';
        if (selectedDistrictSeveral) {
          selectedDistrictSeveral?.map((dt: any) => {
            if (districtSlug) {
              districtSlug += ',' + dt.value;
            } else {
              districtSlug = dt.value;
            }
          });
        } else {
          selectDistrictSeveral?.map((dt: any) => {
            if (districtSlug) {
              districtSlug += ',' + dt.value;
            } else {
              districtSlug = dt.value;
            }
          });
        }
        dataDistrict = districtSlug;
      }
      updateFilter({
        type: type,
        bedrooms: Number(bedrooms?.value),
        min_value,
        max_value,
        district: dataDistrict,
        code,
        evaluation: onlyAvaliados,
      });
      navigate(routesNames.search);
    },
    [
      selectedCity,
      type,
      bedrooms,
      selectedDistrict,
      onlyAvaliados,
      selectedDistrictSeveral,
    ],
  );

  const getDistrictOptions = () => {
    if (districts.length === 0)
      return [
        {
          label: 'Sem cidade',
          value: 'Sem cidade',
          notSelectable: true,
          notSelectableMessage:
            'Não há imóveis cadastrados em um bairro nesta cidade',
        },
      ];

    return [...districts];
  };

  const variosSelect = (value: any) => {
    setSelectedDistrictSeveral(value);
    setSelectDistrictSeveral(value);
  };

  const selectDisctrict = (value: any) => {
    if (value === 'varios') {
      setOpenModal(true);
    }
    setSelectedDistrict(value);
  };

  function closeModal() {
    setOpenModal(false);
  }

  function clearFilter() {
    window.location.reload();
  }

  return (
    <S.Container onSubmit={handleSubmit(onSubmit)}>
      <S.SelectionWrapper className="selection-wrapper city">
        <CustomAutocomplete
          icon={<Location />}
          title={'Cidade'}
          options={[...cities]}
          initialValue={filter?.city}
          onSelectOption={(opt: IDropdownOption) => setSelectedCity(opt)}
        />
      </S.SelectionWrapper>

      <S.SelectionWrapper className="selection-wrapper district">
        <CustomAutocomplete
          multiple
          key={keys.bairro}
          icon={<Building />}
          renderTags={() => ''}
          // title={
          //   selectDistrictSeveral.length
          //     ? `+${selectDistrictSeveral?.length} selecionados`
          //     : 'Bairro'
          // }
          title={
            temporarySelectedDistricts.length
              ? `+${temporarySelectedDistricts?.length} selecionados`
              : 'Bairro'
          }
          initialValue={temporarySelectedDistricts} //initialValueDistrict}
          options={[...getDistrictOptions()]}
          onSelectOption={(opt: any, e: any) => {
            console.log('optevent:', e.target.innerText, e.target, e);

            if ((e.target.innerText as string).includes('Selecione vários')) {
              setOpenModal(true);
              return;
            }

            // selectDisctrict(opt.value);
            setTemporarySelectedDistricts(
              opt.filter((item: any) => item.value !== 'varios'),
            );
          }}
        />
      </S.SelectionWrapper>

      <S.SelectionWrapper className="selection-wrapper immobile">
        <NewDropdown
          icon={<House />}
          title={'Tipos de imóveis'}
          options={[
            {
              label: 'Área',
              value: 'Área',
            },
            {
              label: 'Casa',
              value: 'Casa',
            },
            {
              label: 'Apartamento',
              value: 'Apartamento',
            },
            {
              label: 'Sobrado',
              value: 'Sobrado',
            },
            {
              label: 'Lote',
              value: 'Lote',
            },
            {
              label: 'Fazenda',
              value: 'Fazenda',
            },
            {
              label: 'Chácara',
              value: 'Chácara',
            },
            {
              label: 'Galpão',
              value: 'Galpão',
            },
            {
              label: 'Sala comercial',
              value: 'Sala comercial',
            },
          ]}
          initialValue={type}
          onSelectOption={opt => setType(opt.label)}
        />
      </S.SelectionWrapper>
      <S.SelectionWrapper className="selection-wrapper rooms">
        <NewDropdown
          icon={<Bedroom />}
          title={'Quartos'}
          options={[
            {
              label: '1',
              value: 1,
            },
            {
              label: '2',
              value: 2,
            },
            {
              label: '3',
              value: 3,
            },
            {
              label: '4',
              value: 4,
            },
            {
              label: '5',
              value: 5,
            },
            {
              label: '6',
              value: 6,
            },
            {
              label: '7',
              value: 7,
            },
            {
              label: '8',
              value: 8,
            },
            {
              label: '9',
              value: 9,
            },
            {
              label: '10',
              value: 10,
            },
            {
              label: '11',
              value: 11,
            },
            {
              label: '12',
              value: 12,
            },
            {
              label: '13',
              value: 13,
            },
            {
              label: '14',
              value: 14,
            },
            {
              label: '15',
              value: 15,
            },
            {
              label: '16',
              value: 16,
            },
            {
              label: '17',
              value: 17,
            },
            {
              label: '18',
              value: 18,
            },
            {
              label: '19',
              value: 19,
            },
            {
              label: '20',
              value: 20,
            },
          ]}
          initialValue={bedrooms?.value.toString()}
          onSelectOption={opt => setBedrooms(opt)}
        />
      </S.SelectionWrapper>
      <S.SelectionWrapper className="selection-wrapper min_price">
        <InputWithIcon
          icon={<MinPrice />}
          placeholder="Preço mínimo"
          name="min_value"
          onChange={onMoneyMask}
          ref={register}
          defaultValue={filter?.min_value}
        />
      </S.SelectionWrapper>

      <S.SelectionWrapper className="selection-wrapper max_price">
        <InputWithIcon
          icon={<MaxPrice />}
          placeholder="Preço máximo"
          name="max_value"
          onChange={onMoneyMask}
          ref={register}
          defaultValue={filter?.max_value}
        />
      </S.SelectionWrapper>

      <S.SelectionWrapper className="searchClear">
        <S.ContainerBtn
          type={'button'}
          onClick={clearFilter}
          aria-label="Remover filtros"
          name="remove-filters-btn"
        >
          <FilterRemoved />
          Remover filtros
        </S.ContainerBtn>
      </S.SelectionWrapper>
      <S.SelectionWrapper className="search">
        <Button
          color="blue"
          type="submit"
          icon={<SearchNormal />}
          aria-label="Pesquisar"
          name="search-btn"
          // disabled={buttonDisabled()}
        >
          Pesquisar
        </Button>
      </S.SelectionWrapper>
      <Dialog
        maxWidth={'md'}
        fullWidth={true}
        open={openModal}
        onClose={() => closeModal()}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h2>Filtro</h2>
            <CloseButton onClick={() => closeModal()} />
          </div>
        </DialogTitle>
        <DialogContent style={{ overflowY: 'unset' }}>
          <DialogContentText>Filtro de bairros</DialogContentText>

          <Box noValidate component="form" sx={{ mt: 2, minHeight: 300 }}>
            <CustomAutocomplete
              multiple
              icon={<Building />}
              title={'Bairro'}
              initialValue={
                temporarySelectedDistricts
                // selectDistrictSeveral
                // filter?.district?.length ? filter.district.split(',') : []
              }
              options={[
                ...getDistrictOptions().filter(dt => dt.value !== 'varios'),
              ]}
              onSelectOption={(opt: any) => {
                // variosSelect(opt);
                console.log('opt:', opt);

                // selectDisctrict(opt.value);
                setTemporarySelectedDistricts(
                  opt.filter((item: any) => item.value !== 'varios'),
                );
                setKeys({ ...keys, bairro: keys.bairro + 1 });
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color={'blue'}
            onClick={() => {
              setKeys({ ...keys, bairro: keys.bairro + 1 });
              closeModal();
            }}
          >
            Selecionar
          </Button>
        </DialogActions>
      </Dialog>
    </S.Container>
  );
}

export { HomeSearch };
