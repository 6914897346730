import ReactQuill, { Quill } from 'react-quill';

import { Box } from '@mui/material';

import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';

import Label from '../Label';

export interface RichTextProps {
  label?: string | number;
  value?: any;
  setValue?: (value: string) => void;
  required?: boolean;
}

export default function RichText({
  label,
  value,
  required,
  setValue,
}: RichTextProps) {
  function handleValue(val: string) {
    setValue && setValue(val);
  }

  Quill.register('modules/imageResize', ImageResize);

  return (
    <Box>
      <Label required>{label}</Label>

      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        modules={{
          toolbar: [
            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
            ['bold', 'italic', 'underline'], // toggled buttons
            // [{ list: 'ordered' }, { list: 'bullet' }],
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            ['image'],
            // [{ align: [] }],
          ],
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
          },
          imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize'],
          },
        }}
      />
    </Box>
  );
}
