import { useCallback, useEffect, useRef, useState } from 'react';
import { ImSpinner } from 'react-icons/im';
import { Link, useNavigate } from 'react-router-dom';
import {
  WhatsappIcon,
  TwitterShareButton,
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
} from 'react-share';
import SweetAlert2 from 'react-sweetalert2';

import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import no_image from 'common/assets/images/no_image.jpg';
import { ReactComponent as Bedroom } from 'common/assets/svgs/bedroom.svg';
import { ReactComponent as BuildingSize } from 'common/assets/svgs/building_size.svg';
import { ReactComponent as Catalog } from 'common/assets/svgs/catalog_blue.svg';
import { ReactComponent as Close } from 'common/assets/svgs/close.svg';
import { ReactComponent as Edit } from 'common/assets/svgs/edit.svg';
import { ReactComponent as Info } from 'common/assets/svgs/info_2.svg';
import { ReactComponent as Maximize } from 'common/assets/svgs/maximize.svg';
import { ReactComponent as Menu } from 'common/assets/svgs/menu.svg';
import { ReactComponent as People } from 'common/assets/svgs/people-min.svg';
import { ReactComponent as SharedCircle } from 'common/assets/svgs/share_circles.svg';
import { ReactComponent as Shower } from 'common/assets/svgs/shower.svg';
import { ReactComponent as Star } from 'common/assets/svgs/star.svg';
import { controlHideDiv } from 'common/utils/controlHideDiv';
import { formatCurrency } from 'common/utils/masks';
import { Button } from 'components/Atoms';
import HouseCardCarousel from 'components/Organisms/HouseCardCarousel';
import { env } from 'configs';
import { useAuth } from 'hooks/auth';
import { useRealtors } from 'hooks/realtors';
import { useToast } from 'hooks/toast';
import useWindowDimensions from 'hooks/useWindowDimensions';
import api, { addFavorite, getProperty, removeFavorite } from 'services/api';
import { Tarja } from 'types';

import { defaultRealtorName } from '../../../common/constants/defaultRealtorName';
import { InfosToBroker } from '../../../pages/AnnouncementDetail/components/InfosToBroker';
import CloseButton from '../../Atoms/CloseButton';
import { CustomAutocomplete } from '../CustomAutocomplete';
import * as S from '../HouseCard/styles';
import LoginModal from '../LoginModal';

interface IHouseCardProps {
  id: number;
  location: ILocation;
  onChangeStatus?: () => void;
  favoriteId?: number;
  onClickCard?: (value: any) => void;
  isMarked?: boolean;
  hasTooltipMap?: boolean;
  status?: string;
  withLoggedActions?: boolean;
  comunicator_id?: number;
  images?: any;
  tarja?: Tarja;
  catalogos?: any;
}

interface ILocation {
  latitude: number;
  longitude: number;
  name?: string;
}

function HouseCardMap({
  id,
  location,
  onChangeStatus,
  favoriteId,
  onClickCard,
  isMarked,
  hasTooltipMap,
  status,
  withLoggedActions,
  comunicator_id,
  catalogos,
}: IHouseCardProps) {
  const { width } = useWindowDimensions();
  const starRef = useRef(null);
  const { userData, subViewUserCorretor } = useAuth();
  const [isHovered, setIsHovered] = useState(false);
  const [favId, setFavId] = useState(favoriteId);
  const [togglingFavorite, setTogglingFavorite] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [shareContainerOpen, setShareContainerOpen] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { getRealtorNameById } = useRealtors();

  const [loading, setLoading] = useState(true);

  const [swalProps, setSwalProps] = useState({});

  const [openModal, setOpenModal] = useState(false);

  const ref = useRef(null);
  const shareRef = useRef(null);
  const [modalCorretoresOpen, setModalCorrertoresOpen] = useState(false);
  const [catalogoId, setCatalogoId] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const initialinfos = {
    id: 0,
    slug: '',
    totalArea: '',
    name: '',
    bedrooms: '',
    bathrooms: '',
    houseArea: '',
    metragem: '',
    code: '',
    value: 0,
    images: [],
    address: '',
    district: '',
    city: { description: '' },
    keys: '',
    user_capture: { name: '', phone: '' },
    url: '',
    amount_evaluation: 0,
    comissao: 0,
    proprietary: '',
    broker_info: '',
    phone_proprietary: '',
    evaluation: '',
    latitude: 0,
    longitude: 0,
    key_number: '',
    branch_agency: '',
    disabled: false,
    user_register: undefined,
    created_at: undefined,
    updated_at: undefined,
    tarja: undefined,
  };
  const [infos, setInfos] = useState(initialinfos);
  const [mensageWppCaptador, setMensageWppCaptador] = useState('');
  const [mensageWppProprietario, setMensageWppProprietario] = useState('');
  useEffect(() => {
    if (shareRef !== null)
      controlHideDiv(shareRef, () => setShareContainerOpen(false));
  }, [shareRef]);

  useEffect(() => {
    if (ref !== null) controlHideDiv(ref, () => setActionsVisible(false));
  }, [ref]);

  function loadData() {
    api.get(`/properties/showToMap/${id}`).then(res => {
      const response = res.data.data;
      const images = response.images?.map((img: any) => img.image);
      const textImovelWpp = `${response.type_property} com ${
        response.bedrooms === null || response.bedrooms === 1
          ? '1 quarto'
          : response.bathrooms + 'quartos'
      } à venda, ${response.district}, ${response.city.description}, ${
        response.city.state.description
      }, Código: ${response.code}, cadastrado para venda na Prado Imóveis`;
      setMensageWppCaptador(
        `Olá, gostaria de falar sobre a sua captação do imóvel, ${textImovelWpp}`,
      );
      setMensageWppProprietario(
        `Olá, gostaria de falar sobre o imóvel de sua responsabilidade, ${textImovelWpp}`,
      );
      setInfos({
        id: response.id,
        slug: response.slug,
        totalArea: response.land_area,
        name: response.name,
        bedrooms: response.bedrooms,
        bathrooms: response.bathrooms,
        houseArea: response.contruct_area,
        metragem: response.type_footage,
        code: response.code,
        value: response.amount,
        images: images,
        address: response.address,
        amount_evaluation: response.amount_evaluation,
        branch_agency: response.branch_agency,
        city: response.city,
        comissao: response.comissao,
        district: response.district,
        evaluation: response.evaluation,
        key_number: response.key_number,
        keys: response.keys,
        latitude: response.latitude,
        longitude: response.longitude,
        phone_proprietary: response.phone_proprietary,
        proprietary: response.proprietary,
        url: response.url,
        user_capture: response.user_capture,
        disabled: response.disabled,
        user_register: response.user_register,
        created_at: response.created_at,
        updated_at: response.updated_at,
        tarja: response.tarja,
        broker_info: response.broker_info,
      });
      setLoading(false);
    });
  }

  useEffect(() => {
    loadData();
  }, []);

  const handleClick = (e: any) => {
    if (e.target) {
      if (
        (starRef.current as any)?.contains(e.target) ||
        (starRef.current as any) === (e.target as any)
      ) {
        return;
      }
    }
    if (onClickCard) return onClickCard({ ...location, property_id: id });

    return undefined;
  };

  const handleDeleteProperty = () => {
    api
      .delete(`/properties/${id}`)
      .then(() => {
        if (onChangeStatus) onChangeStatus();

        addToast({
          type: 'info',
          title: 'Imóvel excluído do sistema',
        });
      })
      .catch(error => {
        console.log(error);

        addToast({
          type: 'error',
          title: 'Erro ao remover o imóvel.',
          description: 'Tente novamente mais tarde ou contate o suporte',
        });
      });
  };

  const getTargeLabel = (status: string) => {
    const allStatus = {
      venda_comunicada: 'Venda comunicada',
      aluguel_comunicado: 'Aluguel comunicado',
      indisponivel_comunicado: 'Indisponibilidade comunicada',
      Novo: 'Novo',
      Alugado: 'Alugado',
      Vendido: 'Vendido',
      Indisponível: 'Indisponível',
    };

    // @ts-ignore
    return allStatus[status];
  };

  const getTargeColor = (status: string) => {
    if (status.includes('comunicada') || status.includes('comunicado'))
      return '#fbc02d';

    if (status === 'Novo') return '#2EA340';
    if (status === 'Alugado' || status === 'Indisponível') return '#B7B7B7';

    return '#B71926';
  };

  const isUnavailable = (status?: string) => {
    if (
      status === 'Vendido' ||
      status === 'Indisponível' ||
      status === 'Alugado'
    ) {
      return true;
    }

    return false;
  };

  /* Regra deve ser alterada assim que a edição de imoveis estiver pronta */
  const canBeOnlyRemoved = (status?: string) => {
    if (
      status === 'Vendido' ||
      status === 'Indisponível' ||
      status === 'Novo' ||
      status === 'Alugado'
    ) {
      return false;
    }

    return true;
  };

  const wppShare = () => {
    // @ts-ignore
    const property = infos;
    const description = `${property && property.name} ${
      property ? '- código: ' + property.code : ''
    } - Prado Imóveis - ${env.siteUrl}`;
    const url = `https://api.whatsapp.com/send?text=${description}share/${id}`;

    window.open(url, '_blank');
  };

  const getHouseButtonLabel = (status?: string): string => {
    if (!status) return 'Ver';

    switch (status) {
      case 'Vendido':
        return 'Vendido';
      case 'Alugado':
        return 'Alugado';
      case 'Indisponível':
        return 'Indisponível';
      default:
        return 'Ver';
    }
  };

  async function toggleFavorite() {
    setTogglingFavorite(true);
    if (!favId) {
      try {
        const response = await addFavorite(id);
        setFavId(response?.id);
      } catch (err) {
        console.log(err);
      }
    } else {
      const response = await removeFavorite(favId);
      if (response) setFavId(undefined);
    }
    setTogglingFavorite(false);
  }

  const mobileShare = () => {
    const idProperty = infos ? infos.id : '';
    const property = infos;
    const description = `${property && property.name} ${
      property ? '- código: ' + property.code : ''
    } - Prado Imóveis - `;
    navigator.share({
      text: description,
      url: `${env.siteUrl}share/${idProperty}`,
    });
  };

  const selectCatalog = (dt: any) => {
    setCatalogoId(dt.value);
  };

  const bindProperty = () => {
    const formData = new FormData();
    formData.append('id_imovel', infos.id.toString());
    api
      .post(`/catalogo/${catalogoId}/vincular`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => {
        addToast({
          title: res.data.message,
          type: 'success',
        });
        setOpenModal(false);
      })
      .catch(err => {
        addToast({
          title: `Ocorreu um erro ao vincular!`,
          description: err.response.data.message,
          type: 'error',
        });
      });
  };

  const openInfCorretor = () => {
    setModalCorrertoresOpen(true);
    if (userData.type.description === 'Corretor') {
      if (!modalCorretoresOpen) {
        subViewUserCorretor();
      }
    }
  };

  const openLogin = () => {
    setIsOpen(true);
  };

  return (
    <>
      <S.ExternalContainer>
        <S.Container
          className="house_card_container"
          hasCursorPointer={!!onClickCard}
          isMarked={isMarked}
          hasTooltipMap={hasTooltipMap}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {loading ? (
            <S.Content unavailable={isUnavailable(status)}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '245px',
                }}
              >
                <ImSpinner
                  style={{
                    width: '60%',
                    margin: 'auto',
                    display: 'block',
                  }}
                  className="loading-icon"
                />
              </div>
            </S.Content>
          ) : (
            <S.Content unavailable={isUnavailable(status)}>
              <S.TagContainer>
                {status && status != 'disponivel' && (
                  <S.Targe color={getTargeColor(status)}>
                    <span>{getTargeLabel(status)}</span>
                  </S.Targe>
                )}

                {infos.tarja && (
                  <S.Targe color="#2EA340">
                    <span>{(infos.tarja as any)?.name}</span>
                  </S.Targe>
                )}
              </S.TagContainer>

              <S.CarouselContainer>
                <HouseCardCarousel
                  items={
                    infos.images?.map((img, index) => (
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                      <img
                        style={{ objectFit: 'cover', objectPosition: 'center' }}
                        src={img}
                        key={`${img}-${index}`}
                        alt=""
                        onClick={handleClick}
                      />
                    )) ?? [
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                      <img
                        style={{ objectFit: 'cover', objectPosition: 'center' }}
                        key={1}
                        alt=""
                        src={no_image}
                        onClick={handleClick}
                      />,
                    ]
                  }
                />
              </S.CarouselContainer>

              <div className="image" onClick={handleClick}>
                {canBeOnlyRemoved(status) && comunicator_id && (
                  <S.RealtorName>
                    <span>
                      Notificador: {getRealtorNameById(comunicator_id)}
                    </span>
                  </S.RealtorName>
                )}
              </div>
              <S.Infos onClick={handleClick}>
                <div className="basic-infos">
                  <span className="dots">{infos.name}</span>

                  <div className="house-attributes">
                    {infos.bedrooms !== null && (
                      <div>
                        <Bedroom />
                        <span>{infos.bedrooms}</span>
                      </div>
                    )}

                    {infos.bathrooms !== null && (
                      <div>
                        <Shower />
                        <span>{infos.bathrooms}</span>
                      </div>
                    )}

                    {infos.houseArea !== null && infos.houseArea !== '0' && (
                      <div>
                        <BuildingSize />
                        <span>{infos.houseArea} m²</span>
                      </div>
                    )}

                    {infos.totalArea !== null && (
                      <div>
                        <Maximize />
                        <span>
                          {infos.totalArea}{' '}
                          {infos.metragem === 'hectares' ? 'ha' : 'm²'}
                        </span>
                      </div>
                    )}

                    {infos.bedrooms === null &&
                      infos.bathrooms === null &&
                      infos.houseArea === null &&
                      infos.totalArea === null && (
                        <div>
                          <Info />
                          <span>Sem dados adicionais</span>
                        </div>
                      )}
                  </div>

                  <S.ButtonContainer>
                    {userData.id ? (
                      <>
                        {togglingFavorite ? (
                          <Skeleton width={30} />
                        ) : (
                          <S.StartButton
                            starred={!!favId}
                            onClick={toggleFavorite}
                            ref={starRef}
                          >
                            <Star />
                          </S.StartButton>
                        )}
                      </>
                    ) : (
                      <S.StartButton onClick={openLogin}>
                        <Star />
                      </S.StartButton>
                    )}
                    <span className="price">{formatCurrency(infos.value)}</span>
                    <Link
                      style={{ textDecoration: 'none' }}
                      to={`/imovel/${infos.slug}-${infos.totalArea}${infos.metragem}-codigo:${infos.code}/${id}`}
                    >
                      <Button color="blue" disabled={isUnavailable(status)}>
                        {getHouseButtonLabel(status)}
                      </Button>
                    </Link>
                  </S.ButtonContainer>
                </div>
              </S.Infos>
            </S.Content>
          )}

          {(withLoggedActions || userData.id) && isHovered && (
            <S.LoggedActionsButton
              type="button"
              onClick={() => setActionsVisible(true)}
            >
              <Menu />
            </S.LoggedActionsButton>
          )}

          {withLoggedActions && actionsVisible && (
            <S.ActionsContainer ref={shareRef}>
              <S.ShareHeader>
                <S.ShareHeaderButton
                  type="button"
                  onClick={() => setActionsVisible(false)}
                >
                  <Close />
                </S.ShareHeaderButton>
              </S.ShareHeader>
              <S.ShareButton
                type="button"
                onClick={() =>
                  width < 800 ? mobileShare() : setShareContainerOpen(true)
                }
              >
                <SharedCircle /> Compartilhar
              </S.ShareButton>

              {shareContainerOpen && (
                <S.ShareContainer ref={ref}>
                  <div onClick={wppShare}>
                    <WhatsappIcon type="button" />
                  </div>

                  <FacebookShareButton
                    url={`${window.location.href}`}
                    title={`${
                      infos.name || `Olha esse imóvel incrivel`
                    } - Prado Imóveis:`}
                    onClick={() => setShareContainerOpen(false)}
                  >
                    <FacebookIcon />
                  </FacebookShareButton>

                  <TwitterShareButton
                    url={`${window.location.href}`}
                    title={`${
                      infos.name || `Olha esse imóvel incrivel`
                    } - Prado Imóveis:`}
                    onClick={() => setShareContainerOpen(false)}
                  >
                    <TwitterIcon />
                  </TwitterShareButton>
                </S.ShareContainer>
              )}

              {(userData.type_id === 2 || userData.type_id === 4) && (
                <>
                  <button
                    type="button"
                    onClick={() => navigate(`/editar-imovel/${id}`)}
                  >
                    <Edit /> <span>Editar</span>
                  </button>
                </>
              )}

              <button type="button" onClick={() => openInfCorretor()}>
                <People /> <span>Inf. corretor</span>
              </button>

              {catalogos && (
                <button
                  type="button"
                  onClick={() => setOpenModal(true)}
                  aria-label="Add. catálogo"
                >
                  <Catalog /> <span>Add. catálogo</span>
                </button>
              )}
            </S.ActionsContainer>
          )}

          {userData.id && !withLoggedActions && actionsVisible && (
            <S.ActionsContainer ref={shareRef}>
              <S.ShareHeader>
                <S.ShareHeaderButton
                  type="button"
                  onClick={() => setActionsVisible(false)}
                >
                  <Close />
                </S.ShareHeaderButton>
              </S.ShareHeader>
              <S.ShareButton
                type="button"
                onClick={() =>
                  width < 800 ? mobileShare() : setShareContainerOpen(true)
                }
              >
                <SharedCircle /> Compartilhar
              </S.ShareButton>

              {shareContainerOpen && (
                <S.ShareContainer ref={ref}>
                  <div onClick={wppShare}>
                    <WhatsappIcon type="button" />
                  </div>

                  <FacebookShareButton
                    url={`${window.location.href}`}
                    title={`${
                      infos.name || `Olha esse imóvel incrivel`
                    } - Prado Imóveis:`}
                    onClick={() => setShareContainerOpen(false)}
                  >
                    <FacebookIcon />
                  </FacebookShareButton>

                  <TwitterShareButton
                    url={`${window.location.href}`}
                    title={`${
                      infos.name || `Olha esse imóvel incrivel`
                    } - Prado Imóveis:`}
                    onClick={() => setShareContainerOpen(false)}
                  >
                    <TwitterIcon />
                  </TwitterShareButton>
                </S.ShareContainer>
              )}
            </S.ActionsContainer>
          )}
        </S.Container>
      </S.ExternalContainer>
      <SweetAlert2
        {...swalProps}
        onConfirm={() => handleDeleteProperty()}
        didClose={() => {
          setSwalProps({
            show: false,
          });
        }}
      />

      <Dialog
        open={modalCorretoresOpen}
        onClose={() => setModalCorrertoresOpen(!modalCorretoresOpen)}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: '25%' },
          },
        }}
      >
        <DialogContent>
          {infos && (
            <InfosToBroker
              mensageWppCaptador={mensageWppCaptador}
              mensageWppProprietario={mensageWppProprietario}
              broker_info={infos.broker_info}
              address={`${infos.address}, ${infos.district} - ${infos.city?.description}`}
              keys_local={infos.keys}
              captador={
                infos.user_capture === null
                  ? defaultRealtorName
                  : infos.user_capture?.name
              }
              link_enterprise={infos.user_capture === null ? '' : infos.url}
              avaliation_value={infos.amount_evaluation}
              captador_phone={
                infos.user_capture === null
                  ? '62982902290'
                  : infos.user_capture?.phone
              }
              commission={infos.comissao}
              owner={infos.proprietary}
              owner_phone={infos.phone_proprietary}
              property_id={infos.id}
              evaluated={!!infos.evaluation}
              amount_evaluation={infos.amount_evaluation}
              lat={infos.latitude}
              lng={infos.longitude}
              name={infos.name}
              key_number={infos.key_number}
              branch_agency={infos.branch_agency}
              visible={true}
              setCloseModal={setModalCorrertoresOpen}
              closeModal={modalCorretoresOpen}
              user_include={infos.user_register}
              data_inclusao={infos.created_at}
              data_edit={infos.updated_at}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth={'md'}
        fullWidth={true}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h2>Adicionar imóvel em um catálogo</h2>
            <CloseButton onClick={() => setOpenModal(false)} />
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {catalogos ? 'Selecione o catálogo' : 'Nenhum catálogo cadastrado'}
          </DialogContentText>
          <Box noValidate component="form" sx={{ mt: 2, minHeight: 300 }}>
            <CustomAutocomplete
              multiple={false}
              width={'100%'}
              title={'Catálogos'}
              options={catalogos}
              onSelectOption={(opt: any) => {
                selectCatalog(opt);
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color={'blue'} onClick={() => bindProperty()}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <LoginModal
        isOpen={isOpen ?? false}
        onLogin={() => setIsOpen && setIsOpen(false)}
        onClose={() => setIsOpen && setIsOpen(false)}
      />
    </>
  );
}

export { HouseCardMap };
