/* eslint-disable prettier/prettier */
import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { IRealtyDetailsOptionProps } from './IRealtyDetailsOption';

type IContainerProps = Pick<IRealtyDetailsOptionProps, 'width' | 'selected'>;

export const Container = styled.button<IContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  width: 60px;

  padding: 12px;
  background: var(--white);
  border-radius: 4px;
  border: 1px solid var(--primary-neutral-300);

  transition: all 0.25s ease-in-out;

  ${props =>
    !!props.width &&
    css`
      width: ${props.width};
    `}

  ${props =>
    !!props.selected &&
    css`
      background: var(--alpha-blue-15);
      border-color: var(--alpha-blue-500);
    `}

  svg {
    width: 24px;
    height: 24px;
  }

  span.principal-label {
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-neutral-900);
  }

  span.sub-label {
    font-size: 12px;
    font-weight: 400;
    color: var(--primary-neutral-650);
  }

  input {
    width: 100%;
    font-weight: 700;
    text-align: center;
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &:hover {
    ${props =>
    props.selected
      ? css`
      background: ${darken('.0125', '#1DAEFF15')};
      border-color: ${darken('.15', '#0C4A8860')};
    `
      : css`
      background: ${darken('.0125', '#fff')};
      border-color: ${darken('.15', '#DFE8F6')};
    `}
  }
`;
