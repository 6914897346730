import { lazy } from 'react';

import { ILatLng } from '../Map/IMap';

const Map = lazy(() => import('components/Organisms/Map'));

export type PropertiesMapProps = {
  markers?: ILatLng[];
  initialPosition?: ILatLng;
};

export function PropertiesMap({
  initialPosition = {
    lat: 32.7502,
    lng: 114.7655,
  },
  markers = [],
}: PropertiesMapProps) {
  return <Map zoom={5} centerPosition={initialPosition} markers={markers} />;
}
