import styled, { css } from 'styled-components';

import { ContainerProps } from '../../Molecules/Filter/styles';

export const Container = styled.form`
  /* .city {
    grid-area: city;
  }

  .district {
    grid-area: district;
  }

  .immobile {
    grid-area: immobile;
  }

  .rooms {
    grid-area: rooms;
  }

  .bathrooms {
    grid-area: bathrooms;
  }

  .garages {
    grid-area: garages;
  }

  .search {
    grid-area: search;
  }

  .min_price {
    grid-area: min_price;
  }

  .max_price {
    grid-area: max_price;
  }

  .searchClear {
    grid-area: searchClear;
  } */

  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-template-columns: repeat(2, 45.5%); */
  /* grid-template-rows: repeat(6, 38px); */
  column-gap: 1.2rem;
  row-gap: 0.75rem;

  /* grid-template-areas:
    'city district'
    'immobile rooms'
    'min_price max_price'
    'searchClear search'; */

  width: 100%;
  height: 13.5rem;

  padding: 0.75rem;
  border: 1px solid var(--grey-200);
  border-radius: 8px;
  box-shadow: 4px 16px 32px rgba(70, 87, 147, 0.12);

  .search,
  .searchClear {
    height: 36px;
  }

  @media (max-width: 884px) {
    width: 100%;
    height: fit-content;

    grid-template-columns: 1fr;
    /* grid-template-areas:
      'city'
      'district'
      'immobile'
      'rooms'
      'min_price'
      'max_price'
      'searchClear search'; */

    .bathrooms,
    .garages {
      display: none;
    }
  }
`;

export const SelectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > button {
    width: 100%;
    height: 100%;

    font-weight: 600;

    border-radius: 4px;
    padding: 0;
  }
`;

export const ContainerBtn = styled.button<ContainerProps>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.625rem;
  width: auto;

  font-size: 0.85rem;
  font-weight: 600;
  color: ${props => (props.clearFilters ? '#fff' : 'var(--blue-100)')};

  padding: 0.5rem 0.5rem;
  background: ${props => (props.clearFilters ? '#1DAEFF' : 'transparent')};
  border-radius: 0.25rem;
  border: 1px solid ${props => (props.clearFilters ? 'transparent' : '#1DAEFF')};

  ${props =>
    props.counter &&
    css`
      &::before {
        content: '${props.counter}';
        position: absolute;
        right: -0.625rem;
        top: -0.375rem;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 1.15rem;
        height: 1.15rem;
        border-radius: 50%;
        background: var(--blue-500);
        color: var(--white);

        font-size: 0.75rem;
        &::content {
          color: red;
        }
      }
    `};
`;
