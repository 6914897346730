import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { Alert } from 'helpers';
import api from 'services/api';
import { Tarja } from 'types';

import { Button, DefaultPageTitle } from '../../components/Atoms';
import { DataTable } from '../../components/Atoms/DataTable';
import { useAuth } from '../../hooks/auth';

function Tags() {
  const navigate = useNavigate();
  const [list, setList] = useState();
  const { logoutLocal } = useAuth();
  const [pagesTotal, setPagesTotal] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  async function load() {
    api
      .get(`/tarjas?page=${currentPage}`)
      .then(({ data }) => {
        setPagesTotal(data.last_page);
        setCurrentPage(data.current_page);
        setList(data.data);
      })
      .catch(error => {
        if (error.response.status === 401) {
          logoutLocal();
        }
      });
  }

  useEffect(() => {
    load();
  }, [currentPage]);

  const columns = [
    {
      title: 'Id',
      field: 'id',
    },
    {
      title: 'Nome',
      field: 'name',
    },
  ];

  const handleChangePage = (_: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const edit = (data: any) => {
    navigate(`/tarjas/${data.id}`);
  };

  async function handleDelete(tarja: Tarja) {
    const confirm = await Alert.confirm(
      'Excluir Tarja?',
      'Tem certeza que deseja excluir esta tarja? Esta operação não pode ser desfeita.',
    );
    if (!confirm) return;
    try {
      await api.delete(`/tarjas/${tarja.id}`);
      load();
      Alert.fire({
        icon: 'success',
        title: 'Tarja excluída com sucesso!',
      });
    } catch (error: any) {
      Alert.error(error.response.data.message || 'Falha ao excluir tarja.');
    }
  }

  return (
    <Box
      width="100%"
      height="fit-content"
      padding="24px 26px"
      border="1px solid var(--primary-neutral-300)"
      borderRadius="16px"
      sx={{ background: 'var(--white)' }}
    >
      <Box
        display="flex"
        gap="20px"
        flexWrap="wrap"
        marginTop="20px"
        justifyContent="space-between"
      >
        <div>
          <DefaultPageTitle>Tarjas</DefaultPageTitle>
        </div>
        <div
          style={{
            justifyContent: 'flex-end',
            width: '46%',
            display: 'flex',
          }}
        >
          <Button
            color="high_blue"
            onClick={() => navigate(`/tarja`)}
            type="button"
          >
            Nova tarja
          </Button>
        </div>
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        marginTop="20px"
        justifyContent="center"
        alignItems={'center'}
        width={'100%'}
        flexDirection={'column'}
      >
        {list && (
          <DataTable
            width={'100%'}
            columns={columns}
            data={list}
            validIdUserRegister={true}
            onEdit={edit}
            onDelete={handleDelete}
            pagesTotal={pagesTotal}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
          />
        )}
      </Box>
    </Box>
  );
}

export { Tags };
export default Tags;
