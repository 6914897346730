import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 26%);
  width: 100%;

  margin-top: 36px;

  row-gap: 32px;

  background: var(--white);

  @media (min-width: 1900px) {
    grid-template-columns: repeat(5, 20.5%);
  }

  @media (max-width: 1280px) {
    grid-template-columns: repeat(3, 34.5%);
  }

  @media (max-width: 480px) {
    grid-template-columns: max-content;
    justify-content: center;

    > div {
      margin: 0 auto;
    }
  }
`;
