import { CSSProperties } from 'react';

export default {
  where: (querySelector: string, handler?: (htmlElement: any) => void) => {
    const el = document.querySelector(querySelector);
    if (!el) return null;
    if (handler) handler(el);
    return el;
  },

  allWhere(querySelector: string, handler?: (htmlElement: any[]) => void) {
    const list = Array.from(document.querySelectorAll(querySelector));
    if (handler) handler(list);
    return list;
  },

  scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },

  focus(querySelector: string) {
    return this.where(querySelector, el => el.focus());
  },

  focusWhereName(name: string) {
    return this.focus(`[name=${name}]`);
  },

  setStyleWhere(
    querySelector: string,
    style: CSSProperties | ((el: HTMLElement) => CSSProperties),
  ) {
    return this.where(querySelector, el => {
      if (!el) return;
      const finalStyle = typeof style !== 'function' ? style : style(el);
      Object.keys(finalStyle).forEach(key => {
        el.style[key] = finalStyle[key as keyof CSSProperties];
      });
    });
  },

  setStyleWhereId(
    id: string,
    style: CSSProperties | ((el: HTMLElement) => CSSProperties),
  ) {
    return this.setStyleWhere(`#${id}`, style);
  },

  toClipboard(value: string) {
    const copyText = document.createElement('input');
    copyText.value = value;
    if (!copyText) return false;
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    navigator.clipboard.writeText(copyText.value);
    return true;
  },
};
