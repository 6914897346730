import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from 'common/styles/global';
import { Hooks } from 'hooks';
import { Routes } from 'routes';

function Bootstrap() {
  const helmetContext = {};

  useEffect(() => {
    document.addEventListener('click', () => {
      setTimeout(() => {
        const el: any = document.querySelector('li.MuiAutocomplete-option');
        if (!el) return;
        if (el.innerText === 'Selecione vários') {
          el.style.backgroundColor = 'var(--blue-200)';
          el.style.color = 'white';
          el.style.borderRadius = '5px';
          el.style.margin = '5px 10px 10px 10px';
          el.style.fontWeight = 'medium';
        }
      }, 10);
    });

    document.addEventListener('keydown', () => {
      setTimeout(() => {
        const el: any = document.querySelector('li.MuiAutocomplete-option');
        if (!el) return;
        if (el.innerText === 'Selecione vários') {
          el.style.backgroundColor = 'var(--blue-200)';
          el.style.color = 'white';
          el.style.borderRadius = '5px';
          el.style.margin = '5px 10px 10px 10px';
          el.style.fontWeight = 'medium';
        }
      }, 10);
    });
  }, []);

  return (
    <>
      <BrowserRouter>
        <Hooks>
          <HelmetProvider context={helmetContext}>
            <Routes />
          </HelmetProvider>
        </Hooks>
      </BrowserRouter>

      <GlobalStyle />
    </>
  );
}

export default Bootstrap;
