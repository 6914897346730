import { useEffect, useRef } from 'react';
import Slider, { Settings } from 'react-slick';

import { ICarouselProps } from './ICarousel';
import * as S from './styles';

function Carousel({
  items,
  defaultCenterMode,
  customSettings,
  defaultArrowPositionStyle,
  noArrowLeft,
  noArrowRight,
  noDots,
  slidesToShow,
  slideIndex,
  classItemCaroucel,
}: ICarouselProps) {
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current && slideIndex) {
      //@ts-ignore
      (sliderRef.current as any).slickGoTo(slideIndex);
    }
  }, [slideIndex]);

  const getSlideToShowRule = (max_total: number): number | undefined => {
    if (!slidesToShow) return max_total;

    return slidesToShow < max_total ? slidesToShow : max_total;
  };

  const settings: Settings = {
    dots: !noDots,
    autoplay: false,
    infinite: false,
    slidesToShow: getSlideToShowRule(5),
    slidesToScroll: getSlideToShowRule(5),
    arrows: true,
    speed: 1000,
    focusOnSelect: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: getSlideToShowRule(4),
          slidesToScroll: getSlideToShowRule(4),
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: getSlideToShowRule(3),
          slidesToScroll: getSlideToShowRule(3),
        },
      },
      {
        breakpoint: 520,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const appliedSettings: Settings = customSettings ?? settings;
  return (
    <S.Container
      defaultArrowPositionStyle={defaultArrowPositionStyle}
      noArrowLeft={noArrowLeft}
      noArrowRight={noArrowRight}
    >
      <Slider
        {...appliedSettings}
        centerMode={!!defaultCenterMode}
        ref={sliderRef}
      >
        {items.map((item, index) => (
          <div key={index} className={classItemCaroucel}>
            {item}
          </div>
        ))}
      </Slider>
    </S.Container>
  );
}

export { Carousel };
