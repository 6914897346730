import { Box, Typography } from '@mui/material';

import { IScheduleProps } from './ISchedule';

function Schedule({ date, description, name }: IScheduleProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="95%"
      padding="12px"
      border="1px solid var(--primary-neutral-100)"
      borderRadius="8px"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom="12px"
        height="60px"
        marginBottom="12px"
        borderBottom="1px solid var(--primary-neutral-100)"
      >
        <Typography
          fontSize="16px"
          fontWeight="600"
          color="var(--primary-neutral-900)"
        >
          {name}
        </Typography>

        <Box
          display="flex"
          padding="2px 8px"
          borderRadius="4px"
          sx={{ background: 'var(--brand-100)' }}
        >
          <Typography fontWeight="600" color="var(--blue-400)" fontSize="14px">
            {date.split('/')[0]}/
          </Typography>
          <Typography fontSize="14px" color="var(--brand-400)">
            {date.split('/')[1]}
          </Typography>
        </Box>
      </Box>

      <Typography fontSize="14px" color="var(--primary-neutral-650)">
        {description}
      </Typography>
    </Box>
  );
}

export { Schedule };
