import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import house_mock from 'common/assets/images/house_mock.png';
import house_mock2 from 'common/assets/images/house_mock2.png';
import house_mock3 from 'common/assets/images/house_mock3.png';
import house_mock4 from 'common/assets/images/house_mock4.png';
import house_mock5 from 'common/assets/images/house_mock5.png';
import { ReactComponent as Share } from 'common/assets/svgs/share.svg';

import * as S from './styles';

const items = [
  { src: house_mock },
  { src: house_mock2 },
  { src: house_mock3 },
  { src: house_mock4 },
  { src: house_mock5 },
];

function OldGallery() {
  return (
    <S.Container>
      <Carousel
        showStatus={false}
        showIndicators={false}
        showArrows
        transitionTime={200}
        renderThumbs={data =>
          data.map((item, index) => (
            <S.Thumb key={`thumb-${index}`}>{item}</S.Thumb>
          ))
        }
      >
        {items.map((image, index) => (
          <S.ImageContainer key={`image-container-${index}`} image={image.src}>
            <div className="image_background" aria-label="House image" />
          </S.ImageContainer>
        ))}
      </Carousel>

      <S.ShareButton>
        <Share />
      </S.ShareButton>
    </S.Container>
  );
}

export { OldGallery };
