import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import login_slide_1 from 'common/assets/images/login_slide_1.png';
import login_slide_2 from 'common/assets/images/login_slide_2.png';
import login_slide_3 from 'common/assets/images/login_slide_3.png';
import { routesNames } from 'common/utils/routesNames';
import { Logo } from 'components/Atoms';
import { Steps } from 'components/Molecules';

import * as S from './styles';

function LoginSlider() {
  const [image, setImage] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [subtitle, setSubTitle] = useState<string>('');

  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case routesNames.login: {
        setImage(login_slide_1);
        setTitle('Painel administrativo');
        setSubTitle(
          'Sistema de gestão imobiliária, controle permissões, visualize os anuncios de seus empreendimentos...',
        );
        break;
      }
      case routesNames.signUp: {
        setImage(login_slide_2);
        setTitle('Avaliação Gratuita');
        setSubTitle(
          'Sistema de gestão imobiliária, controle permissões, visualize os anuncios de seus empreendimentos...',
        );
        break;
      }
    }
  }, [location.pathname]);

  return (
    <S.Container>
      <Logo small />

      <S.ImageSlide image={image} />

      <S.Wrapper>
        <h1>{title}</h1>
        <p>{subtitle}</p>

        <Steps />
      </S.Wrapper>
    </S.Container>
  );
}

export { LoginSlider };
