import { ReactComponent as FilledWhatsapp } from 'common/assets/svgs/filled_whatsapp.svg';
import { ReactComponent as LocationBold } from 'common/assets/svgs/location_bold.svg';
import { ReactComponent as MessageEdit } from 'common/assets/svgs/message-edit.svg';

import { IAgentBox } from './IAgentBox';
import * as S from './styles';

function AgentBox({
  company,
  name,
  properties_qtd,
  responsibility,
  person_image,
  full_height,
}: IAgentBox) {
  return (
    <S.Container
      className="agent-box"
      image={person_image}
      full_height={full_height}
    >
      <div className="image" aria-label={`${name} image`} />

      <span className="name">{name}</span>
      <span className="responsibility">{responsibility}</span>
      <span className="company">
        <LocationBold />
        {company}
      </span>
      <span className="properties_qtd">{properties_qtd} imóveis</span>

      <S.Contact>
        <S.ContactButton variant="email">
          <MessageEdit />
          Email
        </S.ContactButton>

        <S.ContactButton variant="whatsapp">
          <FilledWhatsapp />
          Whatsapp
        </S.ContactButton>
      </S.Contact>
    </S.Container>
  );
}

export { AgentBox };
