import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';
import { routesNames } from 'common/utils/routesNames';
import { useAuth } from 'hooks/auth';

import LoginModal from '../LoginModal';
import PasswordResetModal from '../PasswordResetModal';
import RegisterModal from '../RegisterModal';
import * as S from './styles';

export interface MenuListProps {
  isOpen?: boolean;
  setIsOpen?(boolean: boolean): void;
  isRegisterOpen?: boolean;
  setRegisterModalOpen?(boolean: boolean): void;
}

function MenuList(props: MenuListProps) {
  const location = useLocation();
  const { userData } = useAuth();

  const matches = useMediaQuery('(max-width: 884px)');
  const userLogged = Object.keys(userData).length > 0 ?? false;

  const [openRessetPassord, setOpenRessetPassord] = useState(false);

  /* ref não utilizada momentaneamente */

  const test = () => {
    console.log('aki');
  };

  return (
    <>
      <S.Container>
        <ul>
          <li>
            <S.Link
              to={routesNames.root}
              current={
                location.pathname === routesNames.root ||
                location.pathname.includes('detail')
              }
              aria-label="Início"
              name="lading-page-link"
            >
              Início
            </S.Link>
          </li>

          <li>
            <S.Link
              to={routesNames.search}
              current={location.pathname === routesNames.search}
              aria-label="Buscar imóvel"
              name="search-properties-link"
            >
              Buscar imóvel
            </S.Link>
          </li>

          {userLogged && (
            <li>
              <S.Link
                to={routesNames.dashboard}
                current={location.pathname === routesNames.dashboard}
                aria-label="Painel administrativo"
                name="adm-panel-link"
              >
                Painel Administrativo
              </S.Link>
            </li>
          )}

          {matches && !userLogged && (
            <>
              <li>
                <span
                  onClick={() =>
                    props.setRegisterModalOpen &&
                    props.setRegisterModalOpen(true)
                  }
                  aria-label="Cadastrar-se"
                >
                  Cadastrar-se
                </span>
              </li>

              <li>
                <span
                  onClick={() => props.setIsOpen && props.setIsOpen(true)}
                  aria-label="Login"
                >
                  Login
                </span>
              </li>
            </>
          )}

          {/*<li>
          <S.Link to="/">Trabalhe conosco</S.Link>
        </li> */}
        </ul>
      </S.Container>

      <RegisterModal
        isOpen={props.isRegisterOpen ?? false}
        onLogin={() =>
          props.setRegisterModalOpen && props.setRegisterModalOpen(false)
        }
        onClose={() =>
          props.setRegisterModalOpen && props.setRegisterModalOpen(false)
        }
      />

      <LoginModal
        isOpen={props.isOpen ?? false}
        onLogin={() => props.setIsOpen && props.setIsOpen(false)}
        onClose={() => props.setIsOpen && props.setIsOpen(false)}
      />

      <PasswordResetModal
        isOpen={openRessetPassord}
        onClose={() => setOpenRessetPassord(false)}
      />
    </>
  );
}

export { MenuList };
