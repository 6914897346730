import styled from 'styled-components';

interface IWrapperProps {
  has_icon: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: ${props => (props.has_icon ? '78%' : '100%')};
  padding: 0 0.75rem;
  border-radius: 5px;

  cursor: pointer;

  input {
    font-size: 0.875rem;
    font-weight: 500;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 18%;

  border-radius: 8px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 38px;
  min-height: 38px;

  ${Wrapper}, ${IconWrapper} {
    height: 100%;
    border: 2px solid var(--primary-neutral-300);
  }

  @media (max-width: 884px) {
    ${Wrapper}, ${IconWrapper} {
      border: 1px solid var(--primary-neutral-300);
    }
  }
`;
