import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { controlHideDiv } from 'common/utils/controlHideDiv';
import { routesNames } from 'common/utils/routesNames';
import { Button, Logo, HamburgerButton } from 'components/Atoms';
import { MenuList, MyProfileActs } from 'components/Molecules';
import LoginModal from 'components/Molecules/LoginModal';
import RegisterModal from 'components/Molecules/RegisterModal';
import { useAuth } from 'hooks/auth';

import PasswordResetModal from '../../Molecules/PasswordResetModal';
import * as S from './styles';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const [responsiveMenuOpen, setResponsiveMenuOpen] = useState(false);
  const [openRessetPassord, setOpenRessetPassord] = useState(false);
  const { userData } = useAuth();

  const ref = useRef(null);

  function setLoginModalOpen(open: boolean) {
    if (open) {
      setResponsiveMenuOpen(false);
    }
    setIsOpen(open);
  }

  function setRegisterModalOpen(open: boolean) {
    if (open) {
      setResponsiveMenuOpen(false);
    }
    setIsRegisterOpen(open);
  }

  useEffect(() => {
    if (ref !== null) controlHideDiv(ref, () => setResponsiveMenuOpen(false));
  }, [ref]);

  const logged = Object.keys(userData).length > 0;

  const openRegister = () => {
    setIsOpen(false);
    setRegisterModalOpen(true);
  };

  const openModalResetPassword = () => {
    setLoginModalOpen(false);
    setOpenRessetPassord(true);
  };

  return (
    <S.Container ref={ref}>
      <S.MenuContent>
        <Logo />

        <MenuList
          isOpen={isOpen}
          setIsOpen={setLoginModalOpen}
          isRegisterOpen={isRegisterOpen}
          setRegisterModalOpen={setRegisterModalOpen}
        />

        {logged ? (
          <MyProfileActs />
        ) : (
          <div className="action-wrapper">
            <Button
              color="white"
              onClick={() => setRegisterModalOpen(true)}
              aria-label="Cadastrar-se"
              name="sign-in-btn"
            >
              Cadastrar-se
            </Button>

            <RegisterModal
              isOpen={isRegisterOpen}
              onLogin={() => setRegisterModalOpen(false)}
              onClose={() => setRegisterModalOpen(false)}
            />

            <Button
              color="white"
              onClick={() => setLoginModalOpen(true)}
              aria-label="Login"
              name="login-btn"
            >
              Login
            </Button>
            <LoginModal
              openRegister={openRegister}
              isOpen={isOpen}
              onLogin={() => setLoginModalOpen(false)}
              onClose={() => setLoginModalOpen(false)}
              openResetSenha={() => openModalResetPassword()}
            />
            <PasswordResetModal
              isOpen={openRessetPassord}
              onClose={() => {
                setOpenRessetPassord(false);
                setLoginModalOpen(true);
              }}
            />
          </div>
        )}
      </S.MenuContent>

      <S.ResponsiveMenuContent>
        <HamburgerButton
          checked={responsiveMenuOpen}
          color="var(--black-400)"
          clickEvent={() => setResponsiveMenuOpen(!responsiveMenuOpen)}
          aria-label="Masdsad"
        />

        {logged ? <MyProfileActs /> : <Logo />}
      </S.ResponsiveMenuContent>
      {responsiveMenuOpen && (
        <MenuList isOpen={isOpen} setIsOpen={setLoginModalOpen} />
      )}
    </S.Container>
  );
}

export { Header };
