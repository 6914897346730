import { FiMapPin } from 'react-icons/fi';
import { ImSpinner10 } from 'react-icons/im';

import { Property } from '@types';
import { formatCurrency } from 'common/utils/masks';
import { Logo } from 'components/Atoms';
import ContentButton from 'components/Atoms/ContentButton';
import { Title } from 'components/Atoms/Title';

import Characteristics from '../Characteristcs';

export type DocumentationPrintProps = {
  property: Property;
};

export default function DocumentationPrint({
  property,
}: DocumentationPrintProps) {
  return (
    <div>
      <header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Logo />

        <ContentButton>
          Código:{' '}
          {property?.code || <ImSpinner10 className="loading-icon" size={17} />}
        </ContentButton>
      </header>

      <br />

      <section style={{ display: 'flex', gap: '2px' }}>
        {property
          .images!.filter((_, i) => i <= 4)
          .map(image => (
            <div
              key={image.image}
              style={{
                background: 'grey',
                flex: 1,
                height: '180px',
              }}
            >
              <img
                src={image.image}
                alt={image.image}
                style={{
                  objectFit: 'cover',
                  height: '100%',
                  width: '100%',
                }}
              />
            </div>
          ))}
      </section>

      <br />

      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <h2
          style={{
            fontSize: '1.5rem',
            color: 'var(--blue-400)',
            fontWeight: 'bold',
          }}
        >
          {formatCurrency(property.amount)}
        </h2>
        <small style={{ color: 'var(--grey-400)' }}>
          | Valor m²
          {` ${formatCurrency(property.amount / property.contruct_area!)}`}
        </small>
      </div>

      <br />

      <Title>{property.name}</Title>

      <br />

      <div className="location-container">
        <FiMapPin size={20} />
        <span>{property?.address}</span>
      </div>

      <br />

      <Characteristics property={property} denyCollapse />

      {!!property?.description?.length && (
        <>
          <Title>Outras informações do imóvel</Title>
          <span style={{ lineHeight: '1.3', fontSize: '15px' }}>
            {property?.description}
          </span>
        </>
      )}

      <br />
      <br />

      <Title>Qual a relação de documentos para adquirir esse imóvel?</Title>
      <ul>
        <li style={{ lineHeight: '1.3', fontSize: '15px' }}>
          Confira, a seguir, os principais documentos que um comprador deve
          apresentar:
        </li>
        <li style={{ lineHeight: '1.3', fontSize: '15px' }}>
          - Comprovante de Identidade – RG (ou carteira de habilitação) e CPF;
        </li>
        <li style={{ lineHeight: '1.3', fontSize: '15px' }}>
          - Comprovante de renda – CTPS e contracheque. No caso de autônomos,
          pode ser utilizado o extrato bancário; Comprovante de estado civil –
          certidão de nascimento ou de casamento. Em casos de divórcio ou
          separação, a certidão de casamento deve possuir a averbação.
        </li>
        <li style={{ lineHeight: '1.3', fontSize: '15px' }}>
          - Comprovante da residência atual – contas de luz, telefone ou água;
        </li>
        <li style={{ lineHeight: '1.3', fontSize: '15px' }}>
          - Declaração de Imposto de Renda – apenas quem não for isento.
        </li>
      </ul>
    </div>
  );
}
