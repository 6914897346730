import { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Title } from 'components/Atoms/Title';

import * as S from './style';

export type AccordionProps = {
  title: string;
  text?: string | string[];
  children?: React.ReactNode;
};

export default function Accordion({ title, text, children }: AccordionProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <MuiAccordion
        expanded={isOpen}
        onChange={() => setIsOpen(!isOpen)}
        sx={{
          borderTop: '1px solid lightgray',
          borderBottom: '1px solid lightgray',
          borderRadius: '0px',
          boxShadow: '0px 0px 0px transparent',
          transition: '0.2s',
          ':hover': {
            background: 'var(--blue-10)',
            borderBottom: '5px solid var(--blue-100)',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="large" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <S.Header>
            <Title>{title}</Title>
            {!isOpen && !!text && (
              <S.PreviewText>
                {Array.isArray(text) ? text[0] : text}
              </S.PreviewText>
            )}
          </S.Header>
        </AccordionSummary>
        <AccordionDetails>
          {Array.isArray(text) ? (
            text.map((t, i) =>
              t.trim().length ? <S.Text key={i}>{t}</S.Text> : <br key={i} />,
            )
          ) : (
            <S.Text>{text}</S.Text>
          )}
          {children}
        </AccordionDetails>
      </MuiAccordion>
    </div>
  );
}
