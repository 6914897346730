import no_image from 'common/assets/images/no_image.jpg';
import { getMonthName } from 'common/utils/getMonthName';
import { getYear, getMonth } from 'date-fns';
import useInputMask from 'hooks/useInputMask';
import { IUser } from 'models/IAuth';

import * as S from './styles';

type IUserBoxProps = IUser;

function UserBox({ photo, name, email, created_at, phone }: IUserBoxProps) {
  const { phoneMask, phoneMaskWithCountry } = useInputMask();

  const month = getMonthName(getMonth(new Date(created_at)));
  const year = getYear(new Date(created_at));

  return (
    <>
      <S.Container>
        <S.UserData photo={photo || no_image}>
          <div className="photo" />

          <span className="user-name">{name}</span>
          <span className="user-email">{email}</span>
          <span className="user-phone">
            {phone?.length && phone?.length > 11
              ? phoneMaskWithCountry(phone)
              : phoneMask(phone)}
          </span>

          <div className="since">
            <span>
              Cadastrado no sistema em <em>{month}</em> de <em>{year}</em>
            </span>
          </div>
        </S.UserData>
      </S.Container>
    </>
  );
}

export { UserBox };
