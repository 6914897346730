import styled, { keyframes } from 'styled-components';

interface IMessageWithLinkProps {
  withMargin?: boolean;
}

const appearAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 30px;
  margin-left: auto;
  margin-right: auto;

  margin-top: 5rem;
  margin-bottom: 5rem;

  max-width: 420px;
  min-width: 360px;
  animation: ${appearAnimation} 1s;

  .logo-prado {
    display: none;
  }

  @media (max-width: 480px) {
    width: 100%;
    min-width: 100%;

    .logo-prado {
      display: block;
    }
  }
`;

export const Header = styled.header`
  h3 {
    font-size: 36px;
    font-weight: 700;
    line-height: 2;
    color: var(--primary-neutral-900);
  }

  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--primary-neutral-650);
  }

  @media (max-width: 480px) {
    h3 {
      line-height: unset;
      margin-bottom: 1rem;
    }
  }
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.25rem;

  > div.remember-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ForgetPassword = styled.div`
  > a {
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-neutral-650);
    text-decoration: none;
  }
`;

export const MessageWithLink = styled.div<IMessageWithLinkProps>`
  display: flex;
  justify-content: center;

  margin-top: ${props => props.withMargin && '7.5vh'};

  font-size: 14px;

  span {
    color: var(--primary-neutral-650);
  }

  a {
    font-weight: 600;
    text-decoration: none;
    color: var(--blue-400);
  }
`;
