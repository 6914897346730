import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SweetAlert2 from 'react-sweetalert2';

import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import api from 'services/api';
import { Communication as CommunicationType } from 'types';

import { Button, DefaultPageTitle } from '../../components/Atoms';
import { DataTable } from '../../components/Atoms/DataTable';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
function Communication() {
  const navigate = useNavigate();
  const [list, setList] = useState();
  const [pagesTotal, setPagesTotal] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [deleteId, setDeleteId] = useState();

  const [swalProps, setSwalProps] = useState({});
  const { addToast } = useToast();
  const handleChangePage = (_: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  const { logoutLocal } = useAuth();
  useEffect(() => {
    getCommunication();
  }, [currentPage]);

  const getCommunication = () => {
    api
      .get(`/communication?page=${currentPage}`)
      .then(({ data }) => {
        const response = data.data;
        setPagesTotal(response.last_page);
        setCurrentPage(response.current_page);
        setList(response.data);
      })
      .catch(error => {
        if (error.response.status === 401) {
          logoutLocal();
        }
      });
  };

  const handleDelete = () => {
    api.delete(`/communication/${deleteId}`).then(() => {
      addToast({
        type: 'info',
        title: `Comunicado deletado`,
      });
      getCommunication();
    });
  };

  const onDelete = (data: any) => {
    setDeleteId(data.id);
    setSwalProps({
      show: true,
      title: 'Ops!',
      text: 'Deseja realmente deletar esse comunicado?',
      icon: 'warning',
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#1DAEFF',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#d33',
      showCancelButton: true,
    });
  };
  const onView = (data: any) => {
    navigate(`/comunicado/${data.id}`);
  };

  const columns = [
    {
      title: 'Id',
      field: 'id',
    },
    {
      title: 'Data da criação',
      field: 'created_at',
      type: 'dateHouer',
    },
    {
      title: 'Tipo',
      render: ({ landing, publico }: CommunicationType) => {
        if (landing) return 'Público';
        if (publico) return 'Cliente';
        return 'Privado';
      },
    },
  ];

  return (
    <>
      <Box
        width="100%"
        height="fit-content"
        padding="24px 26px"
        border="1px solid var(--primary-neutral-300)"
        borderRadius="16px"
        sx={{ background: 'var(--white)' }}
      >
        <Box
          display="flex"
          gap="20px"
          flexWrap="wrap"
          marginTop="20px"
          justifyContent="space-between"
        >
          <div>
            <DefaultPageTitle>Comunicados</DefaultPageTitle>
          </div>
          <div
            style={{
              justifyContent: 'flex-end',
              width: '46%',
              display: 'flex',
            }}
          >
            <Button
              color="high_blue"
              onClick={() => navigate(`/comunicado`)}
              type="button"
            >
              Novo comunicado
            </Button>
          </div>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          marginTop="20px"
          justifyContent="center"
          alignItems={'center'}
          width={'100%'}
          flexDirection={'column'}
        >
          {list && (
            <DataTable
              width={'100%'}
              columns={columns}
              data={list}
              validIdUserRegister={true}
              pagesTotal={pagesTotal}
              currentPage={currentPage}
              handleChangePage={handleChangePage}
              onDelete={onDelete}
              onView={onView}
            />
          )}
        </Box>
      </Box>
      <SweetAlert2
        {...swalProps}
        onConfirm={() => handleDelete()}
        didClose={() => {
          setSwalProps({
            show: false,
          });
        }}
      />
    </>
  );
}
export { Communication };
export default Communication;
