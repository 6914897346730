import axios from 'axios';
import { cleanObj } from 'common/utils/cleanObj';
import { env } from 'configs';
import { IUser } from 'models/IAuth';
import { PropertyLog, Tarja, UserLog } from 'types';

export type ApiSuccessResponse<T = any> = {
  isError: false;
  code: number;
  isUnprocessableEntity: false;
  data: T;
};

export type ApiErrorResponse<T = any> = {
  isError: true;
  code: number;
  isUnprocessableEntity: boolean;
  data: T;
};

export type ApiResponse<T = any, E = any> =
  | ApiSuccessResponse<T>
  | ApiErrorResponse<E>;

const api = axios.create({
  baseURL: env.apiUrl,
  headers: {
    // @ts-ignore
    'X-CSRF-TOKEN': document.head
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content'),
  },
});

export async function getPublicProperties(
  params: any,
  pagination?: { page?: number; limit?: number },
) {
  const stringData = localStorage.getItem('@user:prado_imoveis');
  const userData = stringData ? JSON.parse(stringData) : null;
  const headers = { Authorization: 'Bearer ' + userData?.token };
  try {
    const { data } = await api.get(
      `/properties?coluna=amount&order=asc&page=${
        pagination?.page ?? 1
      }&limit=${pagination?.limit ?? 3000}`,
      {
        headers,
        params: {
          ...cleanObj({
            user_id: userData?.id,
            ...params,
          }),
        },
      },
    );

    return data.imoveis;
  } catch (error) {
    console.log(error);
    localStorage.removeItem('@user:prado_imoveis');
  }
}

export async function getProperty(id: number) {
  return (await api.get(`/propertiesAuth/${id}`)).data.data;
}

export async function showProperty(id: number) {
  return (await api.get(`/properties/showToMap/${id}`)).data.data;
}

export async function getFavoritesProperties() {
  const stringData = localStorage.getItem('@user:prado_imoveis');
  if (!stringData) return false;
  const data = JSON.parse(stringData);
  return (
    await api.get(`users/propertiesFavoritas/${data.id}`, {
      headers: {
        Authorization: 'Bearer ' + data.token,
      },
    })
  ).data.data.favoritos;
}

export async function addFavorite(propertyId: number) {
  const stringData = localStorage.getItem('@user:prado_imoveis');
  if (!stringData) return false;
  const data = JSON.parse(stringData);
  return (
    await api.post(
      `favorites/${propertyId}`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + data.token,
        },
      },
    )
  ).data.data;
}

export async function removeFavorite(favoritePivotId: number) {
  const stringData = localStorage.getItem('@user:prado_imoveis');
  if (!stringData) return false;
  const data = JSON.parse(stringData);
  try {
    await api.delete(`favorites/${favoritePivotId}`, {
      headers: {
        Authorization: 'Bearer ' + data.token,
      },
    });
    return true;
  } catch (err) {
    return false;
  }
}

export async function getAuthProperties(
  params: any,
  pagination?: { page?: number; limit?: number },
) {
  const stringData = localStorage.getItem('@user:prado_imoveis');
  const userData = stringData ? JSON.parse(stringData) : null;
  const headers = { Authorization: 'Bearer ' + userData?.token };
  try {
    const { data } = await api.get(
      `/propertiesAuth?page=${
        pagination?.page ?? 1
      }&limit=12&coluna=id&order=desc`,
      {
        headers,
        params: {
          ...cleanObj({ ...params }),
        },
      },
    );

    return data.imoveis;
  } catch (error) {
    console.log(error);
    localStorage.removeItem('@user:prado_imoveis');
  }
}

export async function apiSearch(
  searchType: 'streets' | 'proprietaries',
  q?: string,
): Promise<
  | {
      label: string;
      value: string;
    }[]
  | false
> {
  try {
    const { data } = await api.get(`search/${searchType}?q=${q}`);
    return data;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getPropertyTotal() {
  return await api.get(`/properties/totais`);
}

export async function getPropertyLogs(id: number) {
  try {
    const { data } = await api.get(`properties/logs/${id}`);
    return data as PropertyLog[];
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getUserLogs(id: number) {
  try {
    const { data } = await api.get(`users/logs/${id}`);
    return data as UserLog[];
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getTarjas() {
  try {
    const { data } = await api.get('/tarjas');
    return data as Tarja[];
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getLandingCommunications() {
  try {
    const { data } = await api.get(`/communication/landing`);
    return data;
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      return 401;
    }
    return false;
  }
}

export async function getUser(id: number) {
  const response = await getUserData(id);
  if (response === 401 || !response) return response;
  return response.user;
}

export async function getUserData(id: number) {
  try {
    const { data } = await api.get(`/users/${id}`);
    return data.data as {
      user: IUser;
      communications: any;
    };
  } catch (error) {
    if ((error as any)?.response?.status == 401) {
      return 401;
    }
    return false;
  }
}

export default api;
