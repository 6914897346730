import { ReactElement, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router';

import { useLoginTemplate } from 'common/utils/useLoginTemplate';
import {
  PublicTemplate,
  LoginTemplate,
  PrivateTemplate,
} from 'components/Templates';
import { Dom } from 'helpers';

interface RouteProps {
  children: ReactElement;
}

export function PrivateRoute({ children }: RouteProps) {
  const logged = localStorage.getItem('@user:prado_imoveis') !== null;

  return logged ? (
    <PrivateTemplate>{children}</PrivateTemplate>
  ) : (
    <Navigate to="/" />
  );
}

export function PublicRoute({ children }: RouteProps) {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    console.log('ROUTE CHANGED');
    Dom.scrollTop();
  }, [location]);

  if (useLoginTemplate(pathname))
    return <LoginTemplate>{children}</LoginTemplate>;

  return <PublicTemplate>{children}</PublicTemplate>;
}
