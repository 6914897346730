import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const PreviewText = styled.p`
  font-size: 1rem;
  color: var(--grey-400);
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;

  @media (min-width: 1024px) {
    width: 500px;
  }
`;

export const Text = styled.p`
  font-size: 1rem;
  color: var(--grey-400);
  font-size: 1rem;
  line-height: 2;
`;
