import { useEffect, useRef } from 'react';
import Slider, { Settings } from 'react-slick';

import { IHouseCardCarouselProps } from './IHouseCardCarousel';
import * as S from './styles';

function HouseCardCarousel({
  items,
  defaultCenterMode,
  noArrowLeft,
  slideIndex,
}: IHouseCardCarouselProps) {
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current && slideIndex) {
      //@ts-ignore
      sliderRef.current.slickGoTo(slideIndex);
    }
  }, [slideIndex]);

  const settings: Settings = {
    dots: false,
    infinite: false,
    arrows: true,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <S.Container defaultArrowPositionStyle={false} noArrowLeft={noArrowLeft}>
      <Slider {...settings} centerMode={!!defaultCenterMode} ref={sliderRef}>
        {items.map((item, i) => (
          <span key={i}>{item}</span>
        ))}
      </Slider>
    </S.Container>
  );
}

export default HouseCardCarousel;
