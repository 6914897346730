import { Schedule } from 'components/Molecules';
import { IScheduleProps } from 'components/Molecules/Schedule/ISchedule';

import { Carousel } from '..';

const mockData: IScheduleProps[] = [
  {
    date: '30/11',
    name: 'Cláudio Jr.',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing pharetra faucibus sed.',
  },
  {
    date: '01/12',
    name: 'Dione Moreira',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing pharetra faucibus sed.',
  },
  {
    date: '04/12',
    name: 'Leonardo Prado',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing pharetra faucibus sed.',
  },
  {
    date: '05/12',
    name: 'Samuel',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing pharetra faucibus sed.',
  },
  {
    date: '30/11',
    name: 'Cláudio Jr.',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing pharetra faucibus sed.',
  },
  {
    date: '01/12',
    name: 'Dione Moreira',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing pharetra faucibus sed.',
  },
];

function ScheduleCarousel() {
  return (
    <Carousel
      items={mockData.map((schedule, index) => (
        <Schedule {...schedule} key={`schedule-${index}`} />
      ))}
    ></Carousel>
  );
}

export { ScheduleCarousel };
