import { CgTwitter } from 'react-icons/cg';
import { FaFacebookF, FaWhatsapp } from 'react-icons/fa';
import { FiPrinter } from 'react-icons/fi';
import { HiOutlineUpload } from 'react-icons/hi';
import { MdContentCopy } from 'react-icons/md';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import { Property } from '@types';
import ContentButton from 'components/Atoms/ContentButton';
import Modal, { useModal } from 'components/Atoms/modal/Modal';
import { Dom, Pdf, Sharer } from 'helpers';
import { useToast } from 'hooks/toast';
import styled from 'styled-components';

export type ShareItemProps = {
  property?: Property;
};

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0 14px;

  .share-icon-btn,
  .react-share__ShareButton {
    border: none !important;
    border-radius: 7px !important;
    color: var(--blue-400) !important;
    height: 66px !important;
    width: 66px !important;
    transition: 0.2s !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: var(--grey-100) !important;

    &:hover {
      background-color: var(--grey-300) !important;
    }

    &:active {
      background-color: var(--grey-400) !important;
    }

    > .icon-container {
      color: var(--blue-400);
    }
  }
`;

const Main = styled.main`
  padding: 14px;

  .copy-link-btn {
    border: none;
    border-radius: 7px;
    color: var(--blue-400);
    transition: 0.2s;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px;

    > span {
      color: initial;
    }

    &:hover {
      background-color: var(--grey-200);
    }

    &:active {
      background-color: var(--grey-300);
    }
  }
`;

const Footer = styled.footer`
  padding: 14px;
  border-top: 1px solid var(--grey-300);

  > .print-btn {
    border: none;
    border-radius: 7px;
    background-color: var(--blue-400);
    color: white;
    padding: 15px 20px;
    width: 100%;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 1rem;

    &:hover {
      background-color: var(--blue-200);
    }
  }
`;

export default function ShareItem({ property }: ShareItemProps) {
  const { addToast } = useToast();
  const modal = useModal();

  function copyPropertyLinkToClipboard() {
    Dom.toClipboard(Sharer.whatsAppUrl({ property }));
    addToast({ title: 'Link copiado para área de transferência.' });
  }

  return (
    <>
      <ContentButton
        right={<HiOutlineUpload size={17} />}
        type="button"
        onClick={() => modal.open()}
      >
        Compartilhar imóvel
      </ContentButton>

      <Modal {...modal.props} title="Compartilhar">
        <Header>
          <button
            type="button"
            className="share-icon-btn"
            onClick={() =>
              Sharer.whatsApp({
                message: url =>
                  `Olha esse imóvel incrível - Prado Imóveis: ${url}`,
                property,
              })
            }
          >
            <FaWhatsapp size={38} />
          </button>

          <FacebookShareButton
            url={`${window.location.href}`}
            title={`${
              property?.description || `Olha esse imóvel incrivel`
            } - Prado Imóveis:`}
            name="share-facebook-btn"
          >
            <span className="icon-container">
              <FaFacebookF size={30} />
            </span>
          </FacebookShareButton>

          <TwitterShareButton
            url={`${window.location.href}`}
            title={`${
              property?.description || `Olha esse imóvel incrivel`
            } - Prado Imóveis:`}
            name="share-twitter-btn"
          >
            <CgTwitter size={38} />
          </TwitterShareButton>
        </Header>

        <Main>
          <button
            className="copy-link-btn"
            type="button"
            onClick={copyPropertyLinkToClipboard}
          >
            <span>Copiar link</span>
            <MdContentCopy size={18} />
          </button>
        </Main>

        <Footer>
          <button
            className="print-btn"
            type="button"
            onClick={() => {
              if (!property) return;
              Pdf.property(property);
            }}
          >
            <FiPrinter size={18} />
            Gerar Impressão
          </button>
        </Footer>
      </Modal>
    </>
  );
}
