import { useState, useRef, useEffect } from 'react';

import { ReactComponent as Arrow } from 'common/assets/svgs/arrow_down.svg';
import { controlHideDiv } from 'common/utils/controlHideDiv';

import { IDropdownProps } from './IDropdown';
import * as S from './styles';

function Dropdown({
  icon,
  title,
  noArrow,
  options,
  onSelectOption,
}: IDropdownProps) {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref !== null) controlHideDiv(ref, () => setOpen(false));
  }, [ref]);

  return (
    <S.Container onClick={() => setOpen(!open)} role="button" ref={ref}>
      {icon && <S.IconWrapper>{icon}</S.IconWrapper>}

      <S.DropdownWrapper has_icon={!!icon}>
        <span>{title}</span>
        {!noArrow && <Arrow />}

        {open && (
          <S.OptionsWrapper>
            {options.map((opt, index) => (
              <S.Option
                key={`option-${index}`}
                onClick={() => onSelectOption(opt)}
                disabled={opt.notSelectable}
              >
                {opt.notSelectable ? opt.notSelectableMessage : opt.label}
              </S.Option>
            ))}
          </S.OptionsWrapper>
        )}
      </S.DropdownWrapper>
    </S.Container>
  );
}

export { Dropdown };
