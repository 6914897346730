import { forwardRef } from 'react';

import { IInputWithIconProps } from './InputWithIcon';
import * as S from './styles';

const InputWithIcon = forwardRef<HTMLInputElement, IInputWithIconProps>(
  (props, ref) => {
    return (
      <S.Container>
        {props.icon && <S.IconWrapper>{props.icon}</S.IconWrapper>}

        <S.Wrapper has_icon={!!props.icon}>
          <input {...props} ref={ref} />
        </S.Wrapper>
      </S.Container>
    );
  },
);

export { InputWithIcon };
