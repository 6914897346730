import * as S from './style';

export type TitleProps = {
  children: React.ReactNode;
} & S.TitleParams;

function Title({ children, ...rest }: TitleProps) {
  return <S.Title {...rest}>{children}</S.Title>;
}

export { Title };
