import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ReactComponent as FilterAdd } from 'common/assets/svgs/filter-add.svg';
import { ReactComponent as FilterRemoved } from 'common/assets/svgs/filter-remove.svg';
import { Button, Checkbox } from 'components/Atoms';
import { DefaultPageTitle } from 'components/Atoms';
import CloseButton from 'components/Atoms/CloseButton';
import { useAuth } from 'hooks/auth';
import { useRealtors } from 'hooks/realtors';
import { useSearch } from 'hooks/search';
import { getPropertyTotal, apiSearch, getTarjas } from 'services/api';
import { Tarja } from 'types';

import AsyncAutoComplete from '../AsyncAutoComplete';
import { CustomAutocomplete } from '../CustomAutocomplete';
import { InputWithIcon } from '../InputWithIcon';
import * as S from './styles';

export interface FilterProps {
  displayMode?: 'MAP' | 'LIST';
  setDisplayMode?:
    | ((displayMode?: 'MAP' | 'LIST') => void)
    | Dispatch<SetStateAction<'MAP' | 'LIST'>>;
  hideFields?: string[];
}

function Filter({ displayMode, setDisplayMode, hideFields }: FilterProps) {
  const { userData } = useAuth();

  const { realtors } = useRealtors();

  const { register, control, handleSubmit } = useForm();
  const [open, setOpen] = useState(false);
  const [filtersCounter, setFiltersCounter] = useState(0);

  const isLogged = Object.keys(userData).length > 0;

  const [tipoConsulta, setTipoConsulta] = useState('ativos');
  const [tarjas, setTarjas] = useState<Tarja[]>([]);

  const {
    updateFilter,
    filter,
    keys,
    setKeys,
    resetFilters,
    addressQ,
    setAddressQ,
    proprietaryQ,
    setProprietaryQ,
    allKey,
    setAllKey,
  } = useSearch();
  const [onlyAvaliados, setOnlyAvaliados] = useState<boolean>(
    filter?.evaluation ?? false,
  );
  const [onlyMobiliados, setOnlyMobiliados] = useState<boolean>(
    filter?.furnished_property ?? false,
  );
  const [onlyGated, setOnlyGated] = useState<boolean>(
    filter?.gated_community ?? false,
  );

  const [propertyTotal, setPropertyTotal] = useState({
    vendidos: 1,
    ativos: 0,
    inativos: 0,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = ({
    code,
    contruct_area,
    land_area,
    preco_max_mq,
    preco_min_mq,
    bathrooms,
    suites,
    garage,
    address,
    phone_proprietary,
    proprietary,
    corretor,
    tarja_id,
  }: any) => {
    localStorage.setItem('page', '1');
    updateFilter({
      ...filter,
      code,
      contruct_area,
      land_area,
      preco_max_mq,
      preco_min_mq,
      bathrooms,
      suites,
      garage,
      address,
      proprietary,
      phone_proprietary,
      corretor: corretor?.value ?? filter?.corretor,
      tarja_id: tarja_id?.value ?? filter?.tarja_id,
      evaluation: onlyAvaliados,
      furnished_property: onlyMobiliados,
      gated_community: onlyGated,
    });
    setOpen(false);
  };

  function clearFilters() {
    setOnlyAvaliados(false);
    setOnlyMobiliados(false);
    setOnlyGated(false);
    setOpen(false);
    setAddressQ(null);
    setProprietaryQ(null);
    setAllKey(allKey + 1);
    updateFilter({
      ...filter,
      code: undefined,
      contruct_area: undefined,
      land_area: undefined,
      preco_max_mq: undefined,
      preco_min_mq: undefined,
      bathrooms: undefined,
      suites: undefined,
      garage: undefined,
      evaluation: undefined,
      furnished_property: undefined,
      gated_community: undefined,
      address: undefined,
      proprietary: undefined,
      corretor: undefined,
      tarja_id: undefined,
      phone_proprietary: undefined,
    });
  }

  function clearFilter() {
    setOnlyAvaliados(false);
    setOnlyMobiliados(false);
    setOnlyGated(false);
    setOpen(false);
    resetFilters();
    setKeys({
      ...keys,
      general: keys.general + 1,
      cidade: keys.cidade + 1,
      bairro: keys.cidade + 1,
    });
  }

  function handleFiltersCounter() {
    let counter = 0;
    if (filter?.evaluation) counter++;
    if (filter?.furnished_property) counter++;
    if (filter?.gated_community) counter++;
    if (filter?.code?.length) counter++;
    if (filter?.contruct_area) counter++;
    if (filter?.land_area) counter++;
    if (filter?.preco_max_mq) counter++;
    if (filter?.preco_min_mq) counter++;
    if (filter?.bathrooms) counter++;
    if (filter?.suites) counter++;
    if (filter?.garage) counter++;
    if (filter?.address?.length) counter++;
    if (filter?.proprietary?.length) counter++;
    if (filter?.corretor) counter++;
    if (filter?.tarja_id) counter++;
    if (filter?.phone_proprietary?.length) counter++;
    if (addressQ) counter++;
    if (proprietaryQ) counter++;
    setFiltersCounter(counter);
  }

  useEffect(handleFiltersCounter, [
    filter?.evaluation,
    filter?.furnished_property,
    filter?.gated_community,
    filter?.code,
    filter?.proprietary,
    filter?.phone_proprietary,
    filter?.corretor,
    filter?.tarja_id,
    filter?.disabled,
    filter?.contruct_area,
    filter?.land_area,
    filter?.preco_max_mq,
    filter?.preco_min_mq,
    filter?.bathrooms,
    filter?.suites,
    filter?.garage,
  ]);

  async function totalProprety() {
    const totalImoveis = await getPropertyTotal();
    if (totalImoveis.data) {
      setPropertyTotal(totalImoveis.data);
    }
  }

  async function loadTarjas() {
    const data = await getTarjas();
    if (!data) return;
    setTarjas(data);
  }

  useEffect(() => {
    loadTarjas();
    if (displayMode === undefined || hideFields?.includes('filterStatus')) {
      totalProprety();
    }
  }, []);

  const consultaTipo = (data: any) => {
    const consul = data ? data : 'ativos';
    setTipoConsulta(consul);
    updateFilter({ ...filter, disabled: consul });
  };

  return (
    <>
      <S.Wrapper
        data-nowrap={
          displayMode != undefined && !hideFields?.includes('displayMode')
        }
      >
        {displayMode != undefined && !hideFields?.includes('displayMode') && (
          <ToggleButtonGroup
            value={displayMode}
            exclusive
            onChange={(_, v) =>
              setDisplayMode &&
              setDisplayMode(displayMode === 'MAP' ? 'LIST' : 'MAP')
            }
            color="primary"
            style={{ width: '100%', height: '40px' }}
          >
            <ToggleButton
              value="MAP"
              aria-label="Ver como mapa"
              name="toggle-map-btn"
              style={{ width: '100%' }}
            >
              Mapa
            </ToggleButton>
            <ToggleButton
              value="LIST"
              aria-label="Ver como lista"
              name="toggle-list-btn"
              style={{ width: '100%' }}
            >
              Lista
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        {displayMode === undefined || hideFields?.includes('filterStatus') ? (
          <ToggleButtonGroup
            value={tipoConsulta}
            exclusive
            onChange={(_, v) => consultaTipo(v)}
            color="primary"
            style={{ height: '40px', width: '100%' }}
          >
            <ToggleButton
              value="ativos"
              style={{ width: '100%' }}
              aria-label="Ver ativos"
              name="see-actives-btn"
            >
              <div>
                <div>Ativos</div>
                <div className={'font-10'}>{propertyTotal.ativos}</div>
              </div>
            </ToggleButton>
            <ToggleButton
              style={{ width: '100%' }}
              value="vendidos"
              aria-label="Ver vendidos"
              name="see-sold-btn"
            >
              <div>
                <div>Vendidos</div>
                <div className={'font-10'}>{propertyTotal.vendidos}</div>
              </div>
            </ToggleButton>
            <ToggleButton
              style={{ width: '100%' }}
              value="desabilitados"
              aria-label="Ver inativos"
              name="see-inactives-btn"
            >
              <div>
                <div>Inativos</div>
                <div className={'font-10'}>{propertyTotal.inativos}</div>
              </div>
            </ToggleButton>
          </ToggleButtonGroup>
        ) : (
          ''
        )}

        <S.Container
          onClick={handleClickOpen}
          counter={filtersCounter}
          aria-label="Filtros"
          name="filters-btn"
        >
          <FilterAdd />
          Filtros
        </S.Container>

        {!hideFields?.includes('removeFilters') && (
          <S.Container
            onClick={clearFilter}
            aria-label="Remover filtros"
            name="remove-filters"
          >
            <FilterRemoved />
            Remover filtros
          </S.Container>
        )}
      </S.Wrapper>

      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogTitle>
          <S.ModalHeader>
            <DefaultPageTitle>Filtros</DefaultPageTitle>
            <CloseButton onClick={handleClose} />
          </S.ModalHeader>
        </DialogTitle>
        <DialogContent>
          <S.Formulario onSubmit={handleSubmit(onSubmit)}>
            <div className="content">
              <div>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'var(--primary-neutral-900)',
                  }}
                >
                  Metragem do imóvel{' '}
                  <span style={{ color: '#aaa' }}>(Até)</span>
                </Typography>
                <InputWithIcon
                  type="number"
                  name="contruct_area"
                  defaultValue={filter?.contruct_area}
                  ref={register}
                />
              </div>

              <div>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'var(--primary-neutral-900)',
                  }}
                >
                  Metragem do terreno{' '}
                  <span style={{ color: '#aaa' }}>(Até)</span>
                </Typography>
                <InputWithIcon
                  type="number"
                  name="land_area"
                  defaultValue={filter?.land_area}
                  ref={register}
                />
              </div>

              <div>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'var(--primary-neutral-900)',
                  }}
                >
                  Preço máximo <span style={{ color: '#aaa' }}>(m²)</span>
                </Typography>
                <InputWithIcon
                  type="number"
                  name="preco_max_mq"
                  defaultValue={filter?.preco_max_mq}
                  ref={register}
                />
              </div>

              <div>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'var(--primary-neutral-900)',
                  }}
                >
                  Preço mínimo <span style={{ color: '#aaa' }}>(m²)</span>
                </Typography>
                <InputWithIcon
                  type="number"
                  name="preco_min_mq"
                  defaultValue={filter?.preco_min_mq}
                  ref={register}
                />
              </div>

              <div>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'var(--primary-neutral-900)',
                  }}
                >
                  Código do imóvel
                </Typography>
                <InputWithIcon
                  placeholder="Cod. |"
                  name="code"
                  defaultValue={filter?.code}
                  ref={register}
                />
              </div>

              <div>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'var(--primary-neutral-900)',
                  }}
                >
                  Banheiros
                </Typography>
                <InputWithIcon
                  type="number"
                  name="bathrooms"
                  defaultValue={filter?.bathrooms}
                  ref={register}
                />
              </div>

              <div>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'var(--primary-neutral-900)',
                  }}
                >
                  Suítes
                </Typography>
                <InputWithIcon
                  type="number"
                  name="suites"
                  defaultValue={filter?.suites}
                  ref={register}
                />
              </div>

              <div>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'var(--primary-neutral-900)',
                  }}
                >
                  Garagens
                </Typography>
                <InputWithIcon
                  type="number"
                  name="garage"
                  defaultValue={filter?.garage}
                  ref={register}
                />
              </div>

              {isLogged && userData.type_id != 1 ? (
                <>
                  <div>
                    <Typography
                      style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        color: 'var(--primary-neutral-900)',
                      }}
                    >
                      Logradouro
                    </Typography>
                    <AsyncAutoComplete
                      defaultValue={addressQ}
                      onSelectOption={(dat: any) => {
                        setAddressQ(dat);
                      }}
                      fetcher={q => apiSearch('streets', q)}
                    />
                  </div>

                  <div>
                    <Typography
                      style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        color: 'var(--primary-neutral-900)',
                      }}
                    >
                      Proprietário
                    </Typography>
                    <AsyncAutoComplete
                      defaultValue={proprietaryQ}
                      onSelectOption={(dat: any) => {
                        setProprietaryQ(dat);
                      }}
                      fetcher={q => apiSearch('proprietaries', q)}
                    />
                  </div>

                  <div>
                    <Typography
                      style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        color: 'var(--primary-neutral-900)',
                      }}
                    >
                      Corretor
                    </Typography>

                    <Controller
                      name="corretor"
                      control={control}
                      render={({ onChange }) => (
                        <Box height="100%" width="100%">
                          <CustomAutocomplete
                            key={keys.general}
                            width={'100%'}
                            title={''}
                            initialValue={
                              filter?.corretor &&
                              realtors?.length &&
                              realtors.filter(
                                item => item.value == filter?.corretor,
                              ).length
                                ? realtors.filter(
                                    item => item.value == filter?.corretor,
                                  )[0]
                                : undefined
                            }
                            options={[...realtors]}
                            onSelectOption={(opt: any) => onChange(opt)}
                          />
                        </Box>
                      )}
                    />
                  </div>

                  <div>
                    <Typography
                      style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        color: 'var(--primary-neutral-900)',
                      }}
                    >
                      Tarja
                    </Typography>

                    <Controller
                      name="tarja_id"
                      control={control}
                      render={({ onChange }) => (
                        <Box height="100%" width="100%">
                          <CustomAutocomplete
                            key={keys.general}
                            width={'100%'}
                            title={''}
                            initialValue={
                              filter?.tarja_id &&
                              tarjas?.length &&
                              tarjas.filter(item => item.id == filter?.tarja_id)
                                .length
                                ? {
                                    label: tarjas.filter(
                                      item => item.id == filter?.tarja_id,
                                    )[0].name,
                                    value: tarjas.filter(
                                      item => item.id == filter?.tarja_id,
                                    )[0].id,
                                  }
                                : undefined
                            }
                            options={tarjas.map(t => ({
                              label: t.name,
                              value: t.id,
                            }))}
                            onSelectOption={(opt: any) => onChange(opt)}
                          />
                        </Box>
                      )}
                    />
                  </div>

                  <Checkbox
                    data={{
                      name: 'evaluation',
                      selected: onlyAvaliados,
                    }}
                    onClick={() => setOnlyAvaliados(!onlyAvaliados)}
                    description="Apenas imóveis avaliados"
                  />
                </>
              ) : (
                <>
                  <div>
                    <Typography
                      style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        color: 'var(--primary-neutral-900)',
                      }}
                    >
                      Tarja
                    </Typography>

                    <Controller
                      name="tarja_id"
                      control={control}
                      render={({ onChange }) => (
                        <Box height="100%" width="100%">
                          <CustomAutocomplete
                            key={keys.general}
                            width={'100%'}
                            title={''}
                            initialValue={
                              filter?.tarja_id &&
                              tarjas?.length &&
                              tarjas.filter(item => item.id == filter?.tarja_id)
                                .length
                                ? {
                                    label: tarjas.filter(
                                      item => item.id == filter?.tarja_id,
                                    )[0].name,
                                    value: tarjas.filter(
                                      item => item.id == filter?.tarja_id,
                                    )[0].id,
                                  }
                                : undefined
                            }
                            options={tarjas.map(t => ({
                              label: t.name,
                              value: t.id,
                            }))}
                            onSelectOption={(opt: any) => onChange(opt)}
                          />
                        </Box>
                      )}
                    />
                  </div>
                  <span></span>
                </>
              )}

              <Checkbox
                data={{
                  name: 'furnished_property',
                  selected: onlyMobiliados,
                }}
                onClick={() => setOnlyMobiliados(!onlyMobiliados)}
                description="Mobiliado"
              />

              <Checkbox
                data={{
                  name: 'gated_community',
                  selected: onlyGated,
                }}
                onClick={() => setOnlyGated(!onlyGated)}
                description="Em condomínio fechado"
              />
            </div>
            <Button
              color="high_blue"
              type="submit"
              style={{ width: '100%', marginTop: '30px' }}
              aria-label="Aplicar filtros"
              name="apply-btn"
            >
              Aplicar
            </Button>

            <Button
              color="alpha-blue"
              type="button"
              style={{ width: '100%', marginTop: '10px' }}
              onClick={clearFilters}
              aria-label="Limpar filtros"
              name="clear-filters-btn"
            >
              Limpar Filtros
            </Button>
          </S.Formulario>
        </DialogContent>
      </Dialog>
    </>
  );
}

export { Filter };
