import { useCallback, useEffect, useState } from 'react';

import { routesNames } from 'common/utils/routesNames';

import { IStep } from './ISteps';
import * as S from './styles';

function Steps() {
  const [steps, setSteps] = useState<IStep[]>([
    {
      index: 0,
      enabled: false,
    },
    {
      index: 1,
      enabled: false,
    },
    {
      index: 2,
      enabled: false,
    },
  ]);

  const alterCurrentStep = useCallback(
    (step: number) => {
      const aux: IStep[] = steps.map(item => {
        let enabled = false;

        if (item.index === step) enabled = true;

        return { index: item.index, enabled };
      });

      setSteps([...aux]);
    },
    [steps],
  );

  useEffect(() => {
    switch (location.pathname) {
      case routesNames.login: {
        alterCurrentStep(0);
        break;
      }
      case routesNames.signUp: {
        alterCurrentStep(1);
        break;
      }
    }
  }, [location.pathname]);

  return (
    <S.StepSlider>
      {steps.map(item => (
        <S.Step key={`step-${item.index}`} enabled={item.enabled} />
      ))}
    </S.StepSlider>
  );
}

export { Steps };
