import CloseSvg from 'common/assets/svgs/close.svg';

import * as S from './styles';

export interface CloseButtonProps {
  onClick?: () => void;
}

export default function CloseButton(props: CloseButtonProps) {
  return (
    <S.Button {...props} aria-label="Fechar" name="close-btn">
      <img src={CloseSvg} alt="fechar" />
    </S.Button>
  );
}
