import MuiModal from '@mui/material/Modal';

import { Button, Checkbox } from '../../Atoms';
import CloseButton from '../../Atoms/CloseButton';
import { HouseCardSimple } from '../../Molecules/HouseCardSimple';
import * as S from './style';
export type ModalMensagemComunicadoProps = {
  isOpen: boolean;
  mensage: string;
  properties: any;
  close: () => void;
  onConfirm?: any;
  confirmationModal?: any;
  setConfirmationModal?: any;
  onError?: boolean;
};
export { useModal as useModal } from 'components/Atoms/modal/Modal';
export default function ModalMensagemComunicado({
  close,
  mensage,
  properties,
  isOpen,
  onConfirm,
  confirmationModal,
  setConfirmationModal,
  onError,
}: ModalMensagemComunicadoProps) {
  return (
    <MuiModal
      open={isOpen}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <S.ContainerModal>
        <S.Background>
          <S.Container>
            <S.Mensage>
              <div
                className={'text-data'}
                dangerouslySetInnerHTML={{
                  __html: `${mensage}`,
                }}
              ></div>
            </S.Mensage>
            {onError ? (
              <S.Mensage>
                <div className={'mensage-error'}>
                  Você precisa marcar como lido
                </div>
              </S.Mensage>
            ) : (
              ''
            )}

            <S.CardsContainer>
              {properties.map((propertie: any, index: number) => {
                return (
                  <HouseCardSimple
                    key={index}
                    propertie={propertie}
                    onClick={(e: any) => console.log(e)}
                    onClickView={(e: any) => console.log(e)}
                  />
                );
              })}
            </S.CardsContainer>
            <S.footerBoton>
              {confirmationModal !== undefined ? (
                <S.Check>
                  <Checkbox
                    description="Eu declaro que lí o comunicado."
                    onClick={() => setConfirmationModal(!confirmationModal)}
                    data={{
                      name: 'automation',
                      selected: confirmationModal,
                    }}
                  />
                </S.Check>
              ) : (
                ''
              )}
              <Button color={'high_blue'} onClick={() => onConfirm()}>
                Confirmar
              </Button>
            </S.footerBoton>
          </S.Container>
        </S.Background>
      </S.ContainerModal>
    </MuiModal>
  );
}
