import { darken } from 'polished';
import styled from 'styled-components';

export const Link = styled.a<{ name?: string }>``;

export const CardFooter = styled.div`
  padding: 0.625rem 1.75rem;
  border-top: 1px solid var(--green-400);

  > div {
    display: flex;
    align-items: center;
    column-gap: 0.4rem;

    span {
      font-size: 0.875rem;
      color: var(--grey-600);
    }
  }

  @media (max-width: 884px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
  }
`;

export const Container = styled.div`
  width: 46.625rem;

  background: var(--alpha-green-10);
  border: 1px solid var(--green-400);
  border-radius: 6px;

  .call-on-wpp {
    width: fit-content;

    font-size: 1rem;

    padding: 0.5rem 1.25rem;
    border-radius: 0.5rem;
    border: none;

    transition: all 0.25s ease-in-out;
    background: var(--green-400);
    color: var(--white);
    border: 2px solid var(--green-400);

    text-decoration: none;

    &:hover {
      border-color: ${darken(0.075, '#2EA340')};
      background: ${darken(0.075, '#2EA340')};
    }
  }

  .content-wrapper {
    padding: 1.25rem 1.75rem 1rem;
  }

  .content-wrapper > a {
    display: none;
  }

  .card-header,
  ${CardFooter} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card-header {
    span {
      font-weight: 600;
      font-size: 1.15rem;
      color: var(--green-500);
    }

    > button {
      border-radius: 6px;
      font-size: 0.85rem;
    }
  }

  p {
    margin-top: 0.6rem;
    font-size: 1rem;
    color: var(--grey-600);
  }

  @media (max-width: 1200px) {
    width: 54vw;
  }

  @media (max-width: 884px) {
    width: 100%;

    .card-header a {
      display: none;
    }

    .content-wrapper > a {
      display: block;
      margin: 1.25rem auto 0.5rem;
    }
  }
`;
