import { IPageDefaultWrapperProps } from './IPageDefaultWrapper';
import * as S from './styles';

function PageDefaultWrapper({
  children,
  verticalPad,
}: IPageDefaultWrapperProps) {
  return <S.Container verticalPad={verticalPad}>{children}</S.Container>;
}

export { PageDefaultWrapper };
