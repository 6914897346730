import { CheckboxProps } from './ICheckbox';
import * as S from './styles';

function Checkbox({ description, data, disabled, ...rest }: CheckboxProps) {
  return (
    <S.Container
      disabled={disabled}
      checked={data.selected}
      labelCollor={data.labelCollor}
    >
      {description}
      <input
        type="checkbox"
        name={data.name}
        checked={data.selected}
        disabled={disabled}
        {...rest}
      />
      <span className="checkmark" />
    </S.Container>
  );
}

export { Checkbox };
