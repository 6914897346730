/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

interface IProps {
  disabled?: boolean;
  checked?: boolean;
  labelCollor?: string;
}

export const Container = styled.label<IProps>`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 24px;

  padding-left: 28px;

  font-size: 16px;
  //color: var(--primary-neutral-900);
  color: ${props => (props.labelCollor ? props.labelCollor : 'var(--primary-neutral-900)')};

  user-select: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  input {
    position: absolute;
    height: 0;
    width: 0;

    opacity: 0;
  }

  span.checkmark {
    position: absolute;
    top: 2px;
    left: 0;

    height: 20px;
    width: 20px;

    border-radius: 4px;
    border: 1px solid var(--blue-400);
    background: ${props => (props.disabled ? '#f0f0f0' : 'var(--white)')};

    ${props =>
    props.checked &&
    css`
        background: var(--blue-400);
      `}
  }

  input:checked ~ .checkmark {
    border: 1px solid ${props => (props.disabled ? '#ccc' : 'var(--white)')};
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 50%;
    top: 50%;
    margin-left: -2px;
    margin-top: -8px;

    width: 3px;
    height: 10px;

    border: solid ${props => (props.disabled ? '#ccc' : 'var(--white)')};
    border-width: 0 2px 2px 0;

    transform: rotate(45deg);
  }
`;
