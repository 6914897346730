import { ImInfo, ImSpinner10 } from 'react-icons/im';

import { Property, USER_TYPE } from '@types';
import ContentButton from 'components/Atoms/ContentButton';
import { useModal } from 'components/Atoms/modal/Modal';
import { Dom } from 'helpers';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';

import InfoToBrokerModal from '../InfoToBrokerModal';
import { DownloadItem } from './DownloadItem';
import ShareItem from './ShareItem';

export type ContentButtonsProps = {
  property?: Property;
};

export default function ContentButtons({ property }: ContentButtonsProps) {
  const { addToast } = useToast();
  const { isNot } = useAuth();

  const infoToBrokerModal = useModal();

  function copyPropertyCodeToClipboard() {
    if (!property?.code) return;
    Dom.toClipboard(property.code);
    addToast({ title: 'Código copiado para área de transferência.' });
  }

  return (
    <>
      {isNot(USER_TYPE.cliente) && (
        <>
          <InfoToBrokerModal property={property} {...infoToBrokerModal.props} />
          <ContentButton
            variant="green"
            right={<ImInfo size={15} />}
            onClick={infoToBrokerModal.open}
          >
            Informações sigilosas do corretor
          </ContentButton>

          <DownloadItem property={property} />
        </>
      )}

      <ContentButton onClick={copyPropertyCodeToClipboard}>
        Código:{' '}
        {property?.code || <ImSpinner10 className="loading-icon" size={17} />}
      </ContentButton>

      <ShareItem property={property} />
    </>
  );
}
