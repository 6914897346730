import { Box, Typography, Divider } from '@mui/material';
import leo from 'common/assets/images/leo.png';
import { ReactComponent as ArrowLeft } from 'common/assets/svgs/arrow_left.svg';
import { Button } from 'components/Atoms';
import { VerticalInputContainer } from 'components/Molecules';

function Profile() {
  return (
    <Box
      height="100%"
      width="100%"
      padding="24px"
      borderRadius="16px"
      sx={{ background: 'var(--white)' }}
    >
      <Box
        display="flex"
        alignItems="center"
        columnGap="24px"
        marginBottom="40px"
        sx={{ cursor: 'pointer' }}
      >
        <ArrowLeft />
        <Typography
          color="var(--primary-neutral-900)"
          fontWeight="600"
          fontSize="16px"
        >
          Editar Perfil
        </Typography>
      </Box>

      <Box
        height="100px"
        width="100px"
        borderRadius="50%"
        marginBottom="30px"
        sx={{
          backgroundImage: `url(${leo})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      />

      <Box>
        <VerticalInputContainer
          title="Seu Nome"
          subtitle="Seu nome completo"
          placeholder="Insira o seu nome"
        />

        <Divider sx={{ margin: '24px 0' }} />

        <VerticalInputContainer
          title="Endereço"
          subtitle="Seu endereço"
          placeholder="Digite seu endereço"
        />

        <Divider sx={{ margin: '24px 0' }} />

        <VerticalInputContainer
          title="Email"
          subtitle="Seu email"
          placeholder="Digite seu email"
        />

        <Divider sx={{ margin: '24px 0' }} />

        <VerticalInputContainer
          title="Telefone"
          subtitle="Seu Whatsapp"
          placeholder="Digite seu telefone"
        />
      </Box>

      <Box width="220px" marginTop="40px">
        <Button color="high_blue" full_width>
          Salvar
        </Button>
      </Box>
    </Box>
  );
}

export { Profile };
export default Profile;
