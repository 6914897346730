/* eslint-disable prettier/prettier */
import bannerHome from 'common/assets/images/banner_home.webp';
import styled, { css } from 'styled-components';

interface IStepProps {
  step: string;
}

export const Banner = styled.div`
  background-image: url(${bannerHome});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const TopPage = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  height: fit-content;

  border-bottom: 1px solid var(--grey-100);
  padding-bottom: 1rem;

  @media (max-width: 884px) {
    grid-template-columns: 1fr;
    height: fit-content;

    > ${Banner} {
      display: none;
    }
  }
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  padding: 2.4rem 2rem 0 0;

  h1 {
    font-size: 3rem;
    font-weight: 700;
  }

  h2 {
    width: 75%;
    margin: 1.25rem 0 1.75rem 0;
    line-height: 1.8rem;

    color: var(--grey-400);
    font-weight: 400;
    font-size: 1.25rem;
  }

  @media (max-width: 884px) {
    padding: 1.125rem 1.25rem 2rem;

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 0.95rem;
      line-height: 1.2rem;
      width: 100%;
      margin: 1rem 0;
    }

    ${Banner} {
      width: 100%;
      height: 38rem;

      margin-bottom: 2rem;
    }
  }

  @media (max-width: 450px) {
    ${Banner} {
      height: 20rem;
    }
  }
`;

export const StepMessage = styled.div`
  display: flex;
  align-items: center;

  margin: 4.2rem 0 4.8rem 0;

  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  span {
    font-size: 1rem;
    color: var(--grey-400);
  }

  .text_description {
    width: 18rem;
    margin-right: 6rem;
  }

  @media (max-width: 1200px) {
    width: 100%;

    flex-direction: column;

    padding: 0 1.25rem;
    margin: 2rem 0;
    padding-bottom: 3.5rem;
    border-bottom: 1px solid var(--grey-100);

    .text_description {
      width: 100%;
      margin: 0;
      margin-bottom: 1.25rem;
    }
  }
`;

export const Step = styled.div<IStepProps>`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 6rem;
  width: 12rem;

  svg {
    width: 1.75rem;
    height: 1.75rem;

    ${props =>
      props.step === '2' &&
      css`
        margin-left: 1.5rem;
      `}

    ${props =>
      props.step === '3' &&
      css`
        margin-left: 0.8rem;
      `}

    path {
      stroke: var(--blue-500);
      stroke-width: 1px;
    }
  }

  span {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--blue-500);
  }

  &::before {
    content: '${props => props.step}';
    position: absolute;
    top: -1.25rem;
    left: -2.7rem;

    font-size: 9rem;
    line-height: 8rem;
    color: var(--blue-500);
    opacity: 0.2;

    font-family: 'Lexend', sans-serif;
  }

  @media (max-width: 884px) {
    height: 5.2rem;

    &::before {
      left: -3.5rem;
      color: var(--blue-200);
    }

    &&& {
      svg {
        margin: 0;
      }
    }
  }
`;

export const StepsWrapper = styled.div`
  display: flex;
  column-gap: 5rem;

  @media (max-width: 884px) {
    flex-direction: column;
    column-gap: 0;
    row-gap: 4rem;

    margin-top: 3.5rem;
  }

  @media (max-width: 1200px) {
    row-gap: 6.5rem;
  }
`;

export const HighlightsWrapper = styled.div`
  margin-bottom: 3.6rem;

  h3 {
    font-weight: 700;
    font-size: 1.4rem;
    margin-bottom: 5rem;
  }

  @media (max-width: 884px) {
    padding: 0 1.25rem;
  }
`;

export const PropertyOffer = styled.div`
  display: flex;
  width: 48%;

  border: 1px solid var(--grey-100);
  border-radius: 10px;
  overflow: hidden;
  height: 15rem;

  img {
    width: 18.75rem;
    object-fit: cover;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 284px;
    padding: 2rem 1.75rem;
  }

  div h4 {
    font-size: 1.75rem;
    font-weight: 600;
  }

  div span {
    font-size: 1rem;
    line-height: 1.75rem;
    color: var(--grey-400);
  }

  div button {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--blue-500);
    text-decoration: none;
    background-color: transparent;
    border: none;
  }

  @media (max-width: 1200px) {
    width: 100%;
    justify-content: space-between;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 450px) {
    img {
      width: 11.5rem;
    }

    > div {
      padding: 2rem 1.2rem;
      min-width: unset;
    }

    div span {
      font-size: 0.8rem;
      font-weight: 500;
      line-height: 1.25rem;
    }

    div h4 {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }

    div a {
      margin-top: 2rem;
    }
  }
`;

export const ResumeDataWrapper = styled.div`
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  > div + div {
    margin-top: 2.625rem;
  }

  @media (max-width: 1200px) {
    > div {
      justify-content: center;
      column-gap: 1.25rem;
    }

    .property-container {
      justify-content: center;
    }
  }

  @media (max-width: 992px) {
    padding: 0 1.25rem;

    > div {
      flex-direction: column;
      row-gap: 1.5rem;
      width: 100%;
    }
  }
`;

export const GoogleReviewsContainer = styled.div`
  padding: 40px 24px 0;
`;
