export default {
  mapKeysLabels: (property?: { [key: string]: any }) => ({
    info: {
      caracteristicas: {
        label: 'Descrição e características',
        data: getData(property, [
          {
            label: 'Garagem',
            value: property?.garage,
            key: 'garage',
            displayLabel: `${property?.garage} ${
              property?.garage > 1 ? 'Garagens' : 'Garagem'
            }`,
          },
          {
            label: 'Salas',
            displayLabel: `${property?.hall} ${
              property?.hall > 1 ? 'Salas' : 'Sala'
            }`,
            value: property?.hall,
            key: 'hall',
          },
          {
            label: 'Suítes',
            displayLabel: `${property?.suite} ${
              property?.suite > 1 ? 'Suítes' : 'Suíte'
            }`,
            value: property?.suite,
            key: 'suite',
          },
          {
            label: 'Acesso para deficientes',
            value: property?.disabled_access,
            key: 'disabled_access',
          },
          {
            label: 'Automação',
            value: property?.automation,
            key: 'automation',
          },
          {
            label: 'Área de serviços',
            value: property?.service_area,
            key: 'service_area',
          },
          {
            label: 'Sala de TV',
            value: property?.tv_room,
            key: 'tv_room',
          },
          {
            label: 'Sala de estar',
            value: property?.living_room,
            key: 'living_room',
          },
          {
            label: 'Sala de jantar',
            value: property?.dining_room,
            key: 'dining_room',
          },
          {
            label: 'Bancada de ilha',
            value: property?.island_bench,
            key: 'island_bench',
          },
          {
            label: 'Acabamentos em gesso',
            value: property?.plaster_finishes,
            key: 'plaster_finishes',
          },
          {
            label: 'Closet',
            value: property?.closet,
            key: 'closet',
          },
          {
            label: 'Condomínio fechado',
            value: property?.gated_community,
            key: 'gated_community',
          },
          {
            label: 'Cozinha',
            value: property?.kitchen,
            key: 'kitchen',
          },
          {
            label: 'Depósito',
            value: property?.deposit,
            key: 'deposit',
          },
          {
            label: 'Despensa',
            value: property?.pantry,
            key: 'pantry',
          },
          {
            label: 'Elevador',
            value: property?.elevator,
            key: 'elevator',
          },
          {
            label: 'Energia solar',
            value: property?.solar_energy,
            key: 'solar_energy',
          },
          {
            label: 'Entrada e saída de cargas',
            value: property?.Incoming_outgoing_cargo,
            key: 'Incoming_outgoing_cargo',
          },
          {
            label: 'Escaninho',
            value: property?.pigeonhole,
            key: 'pigeonhole',
          },
          {
            label: 'Escritório',
            value: property?.desk,
            key: 'desk',
          },
          {
            label: 'Escritório',
            value: property?.parking,
            key: 'parking',
          },
          {
            label: 'Estacionamento',
            value: property?.parking,
            key: 'parking',
          },

          {
            label: 'Imóvel mobiliado',
            value: property?.furnished_property,
            key: 'furnished_property',
          },
          {
            label: 'Jardim de inverno',
            value: property?.winter_garden,
            key: 'winter_garden',
          },
          {
            label: 'Mezanino',
            value: property?.mezzanine,
            key: 'mezzanine',
          },
          {
            label: 'Poço artesiano',
            value: property?.artesian_well,
            key: 'artesian_well',
          },
          {
            label: 'Projeto de iluminação',
            value: property?.lighting_project,
            key: 'lighting_project',
          },

          {
            label: 'Rampa',
            value: property?.ramp,
            key: 'ramp',
          },
          {
            label: 'Recepção',
            value: property?.reception,
            key: 'reception',
          },
          {
            label: 'Refeitório',
            value: property?.refectory,
            key: 'refectory',
          },
          {
            label: 'Sacada',
            value: property?.balcony,
            key: 'balcony',
          },
          {
            label: 'Pé direito duplo',
            value: property?.double_right_foot,
            key: 'double_right_foot',
          },
          {
            label: 'Lavabo',
            value: property?.toilet,
            key: 'toilet',
          },
          {
            label: 'Copa',
            value: property?.copa,
            key: 'copa',
          },
          {
            label: 'Cobertura',
            value: property?.roof,
            key: 'roof',
          },
          {
            label: 'Quintal',
            value: property?.yard,
            key: 'yard',
          },
        ]),
      },
      lazerEConveniencia: {
        label: 'Lazer e Conveniência',
        data: getData(property, [
          {
            label: 'Academia',
            value: property?.gym,
            key: 'gym',
          },
          {
            label: 'Áreas verdes',
            value: property?.green_areas,
            key: 'green_areas',
          },
          {
            label: 'Áreas gourmet',
            value: property?.gourmet_area,
            key: 'gourmet_area',
          },
          {
            label: 'Banheira de hidromassagem',
            value: property?.hot_tub,
            key: 'hot_tub',
          },
          {
            label: 'Bicicletário',
            value: property?.bike_rack,
            key: 'bike_rack',
          },
          {
            label: 'Brinquedoteca',
            value: property?.toy_library,
            key: 'toy_library',
          },
          {
            label: 'Campo de Futebol',
            value: property?.football_field,
            key: 'football_field',
          },
          {
            label: 'Churrasqueira',
            value: property?.grill,
            key: 'grill',
          },
          {
            label: 'Coworking',
            value: property?.coworking,
            key: 'coworking',
          },
          {
            label: 'Espaço de massagem',
            value: property?.massage_space,
            key: 'massage_space',
          },
          {
            label: 'Espaço de massagem',
            value: property?.massage_space,
            key: 'massage_space',
          },
          {
            label: 'Espaço pilates',
            value: property?.pilates_space,
            key: 'pilates_space',
          },
          {
            label: 'Estrutura de clube',
            value: property?.club_structure,
            key: 'club_structure',
          },
          {
            label: 'Hall social',
            value: property?.social_hall,
            key: 'social_hall',
          },
          {
            label: 'Lava-jato',
            value: property?.lava_jato,
            key: 'lava_jato',
          },
          {
            label: 'Mercado',
            value: property?.mart,
            key: 'mart',
          },
          {
            label: 'Lavanderia',
            value: property?.laundry,
            key: 'laundry',
          },
          {
            label: 'Ofurô',
            value: property?.ofuro,
            key: 'ofuro',
          },
          {
            label: 'Pet place',
            value: property?.pet_place,
            key: 'pet_place',
          },
          {
            label: 'Piscina',
            value: property?.pool,
            key: 'pool',
          },
          {
            label: 'Playground',
            value: property?.playground,
            key: 'playground',
          },
          {
            label: 'Praça de convivência',
            value: property?.coexistence_square,
            key: 'coexistence_square',
          },
          {
            label: 'Quadra de Areia',
            value: property?.sand_court,
            key: 'sand_court',
          },
          {
            label: 'Quadra de Tênis',
            value: property?.tennis_court,
            key: 'tennis_court',
          },
          {
            label: 'Quadra de esportes',
            value: property?.sports_court,
            key: 'sports_court',
          },
          {
            label: 'Sala de cinema',
            value: property?.movie_theater,
            key: 'movie_theater',
          },
          {
            label: 'Sala de reunião',
            value: property?.meeting_room,
            key: 'meeting_room',
          },
          {
            label: 'Salão de festas',
            value: property?.party_room,
            key: 'party_room',
          },
          {
            label: 'Salão de jogos',
            value: property?.games_room,
            key: 'games_room',
          },
          {
            label: 'Sauna',
            value: property?.sauna,
            key: 'sauna',
          },
          {
            label: 'Spa',
            value: property?.span,
            key: 'span',
          },
          {
            label: 'Adega',
            value: property?.wine_house,
            key: 'wine_house',
          },
          {
            label: 'Lareira',
            value: property?.fireplace,
            key: 'fireplace',
          },
          {
            label: 'Pista de caminhada',
            value: property?.hiking_trail,
            key: 'hiking_trail',
          },
          {
            label: 'Cliclovia',
            value: property?.bike_lane,
            key: 'bike_lane',
          },
          {
            label: 'Quiosque',
            value: property?.kiosk,
            key: 'kiosk',
          },
        ]),
      },
      seguranca: {
        label: 'Segurança',
        data: getData(property, [
          {
            label: 'Alarme',
            value: property?.alarm,
            key: 'alarm',
          },
          {
            label: 'Cerca elétrica',
            value: property?.electric_fence,
            key: 'electric_fence',
          },
          {
            label: 'Cercado',
            value: property?.surrounded,
            key: 'surrounded',
          },
          {
            label: 'Concertina',
            value: property?.concertina,
            key: 'concertina',
          },
          {
            label: 'Câmeras',
            value: property?.cameras,
            key: 'cameras',
          },
          {
            label: 'Fechadura eletrônica',
            value: property?.eletronic_lock,
            key: 'eletronic_lock',
          },
          {
            label: 'Monitoramento 24 horas',
            value: property ? property['24_hour_monitoring'] : null,
            key: '24_hour_monitoring',
          },
          {
            label: 'Portaria 24 horas',
            value: property ? property['24_hour_concierge'] : null,
            key: '24_hour_concierge',
          },
          {
            label: 'Portão eletrônica',
            value: property?.electronic_gate,
            key: 'electronic_gate',
          },
          {
            label: 'Sensores de presença',
            value: property?.presence_sensors,
            key: 'presence_sensors',
          },
          {
            label: 'Interfone',
            value: property?.intercom,
            key: 'intercom',
          },
        ]),
      },
      loteArea: {
        label: 'Informações do terreno',
        data: getData(property, [
          {
            label: 'Metragem frente',
            displayLabel: `${property?.front_footage}m na frente`,
            value: property?.front_footage,
            key: 'front_footage',
          },
          {
            label: 'Metragem fundo',
            displayLabel: `${property?.background_footage}m no fundo`,
            value: property?.background_footage,
            key: 'background_footage',
          },
          {
            label: 'Metragem lateral esquerda',
            displayLabel: `${property?.left_side_footage}m na esquerda`,
            value: property?.left_side_footage,
            key: 'left_side_footage',
          },
          {
            label: 'Metragem lateral direita',
            displayLabel: `${property?.right_side_footage}m na direita`,
            value: property?.right_side_footage,
            key: 'right_side_footage',
          },
          {
            label: 'Água',
            value: property?.water,
            key: 'water',
          },
          {
            label: 'Bairro planejado',
            value: property?.planned_neighborhood,
            key: 'planned_neighborhood',
          },
          {
            label: 'Energia',
            value: property?.energy,
            key: 'energy',
          },
          {
            label: 'Escorrido para a frente',
            value: property?.drained_forward,
            key: 'drained_forward',
          },
          {
            label: 'Escorrido para o fundo',
            value: property?.drained_to_the_bottom,
            key: 'drained_to_the_bottom',
          },
          {
            label: 'Esgoto',
            value: property?.sewage,
            key: 'sewage',
          },
          {
            label: 'Loteada',
            value: property?.allotted,
            key: 'allotted',
          },
          {
            label: 'Murado a frente',
            value: property?.walled_in_front,
            key: 'walled_in_front',
          },
          {
            label: 'Murado completo',
            value: property?.full_walled,
            key: 'full_walled',
          },
          {
            label: 'Murado lateral direita',
            value: property?.right_side_wall,
            key: 'right_side_wall',
          },
          {
            label: 'Murado lateral esquerda',
            value: property?.left_side_wall,
            key: 'left_side_wall',
          },
          {
            label: 'Murado os fundos',
            value: property?.walled_the_back,
            key: 'walled_the_back',
          },
          {
            label: 'Plano',
            value: property?.plan,
            key: 'plan',
          },
          {
            label: 'Área de preservação permanente',
            value: property?.permanent_preservation_area,
            key: 'permanent_preservation_area',
          },
        ]),
      },
      rural: {
        label: 'Informações rurais',
        data: getData(property, [
          {
            label: 'Agricultura',
            value: property?.agriculture,
            key: 'agriculture',
          },
          {
            label: 'Árvores frutíferas',
            value: property?.fruit_trees,
            key: 'fruit_trees',
          },
          {
            label: 'Área para plantio',
            value: property?.planting_area,
            key: 'planting_area',
          },
          {
            label: 'Baias',
            value: property?.stalls,
            key: 'stalls',
          },
          {
            label: 'Casa de caseiro',
            value: property?.caretakers_house,
            key: 'caretakers_house',
          },
          {
            label: 'Casa sede',
            value: property?.head_office,
            key: 'head_office',
          },
          {
            label: 'Chiqueiro',
            value: property?.sty,
            key: 'sty',
          },
          {
            label: 'Criação de gado',
            value: property?.livestock,
            key: 'livestock',
          },
          {
            label: 'Curral',
            value: property?.corral,
            key: 'corral',
          },
          {
            label: 'Córrego',
            value: property?.brook,
            key: 'brook',
          },
          {
            label: 'Depósito',
            value: property?.deposit_rural,
            key: 'deposit_rural',
          },
          {
            label: 'Floresta virgem',
            value: property?.virgin_forest,
            key: 'virgin_forest',
          },
          {
            label: 'Fonte de água',
            value: property?.water_source,
            key: 'water_source',
          },
          {
            label: 'Galpão de armazenamento',
            value: property?.storage_shed,
            key: 'storage_shed',
          },
          {
            label: 'Galpão de maquinário',
            value: property?.machinery_shed,
            key: 'machinery_shed',
          },
          {
            label: 'Galinheiro',
            value: property?.hen_house,
            key: 'hen_house',
          },
          {
            label: 'Horta',
            value: property?.vegetable_garden,
            key: 'vegetable_garden',
          },
          {
            label: 'Lago',
            value: property?.lake,
            key: 'lake',
          },
          {
            label: 'Matas',
            value: property?.woods,
            key: 'woods',
          },
          {
            label: 'Pasto formado',
            value: property?.formed_pasture,
            key: 'formed_pasture',
          },
          {
            label: 'Piscicultura',
            value: property?.pisciculture,
            key: 'pisciculture',
          },
          {
            label: 'Pomar',
            value: property?.orchard,
            key: 'orchard',
          },
          {
            label: 'Represa',
            value: property?.dam,
            key: 'dam',
          },
          {
            label: 'Rio',
            value: property?.river,
            key: 'river',
          },
          {
            label: 'Silvicultura',
            value: property?.silviculture,
            key: 'silviculture',
          },
          {
            label: 'Vegetação',
            value: property?.vegetation,
            key: 'vegetation',
          },
          {
            label: 'Pecuária',
            value: property?.cattle_raising,
            key: 'cattle_raising',
          },
        ]),
      },
      formasDePagamento: {
        label: 'Formas de pagamento e documentação',
        data: getData(property, [
          {
            label: 'Aceita troca',
            value: property?.accept_exchange,
            key: 'accept_exchange',
          },
          {
            label: 'Aceita financiamento',
            value: property?.accept_financing,
            key: 'accept_financing',
          },
          {
            label: 'Apenas pagamento à vista',
            value: property?.cash_payment_only,
            key: 'cash_payment_only',
          },
          {
            label: 'Ágio',
            value: property?.Agio,
            key: 'Agio',
          },
          {
            label: 'AVTO de Saneamento básico',
            value: property?.basic_sanitation_AVTO,
            key: 'basic_sanitation_AVTO',
          },
          {
            label: 'AVTO de energia elétrica',
            value: property?.electricity_AVTO,
            key: 'electricity_AVTO',
          },
          {
            label: 'Contrato de compra e venda',
            value: property?.purchase_sale_contract,
            key: 'purchase_sale_contract',
          },
          {
            label: 'Contrato loteadora',
            value: property?.lottery_contract,
            key: 'lottery_contract',
          },
          {
            label: 'Direito de posse',
            value: property?.right_of_ownership,
            key: 'right_of_ownership',
          },
          {
            label: 'Documentos a regularizar',
            value: property?.documents_to_regularize,
            key: 'documents_to_regularize',
          },
          {
            label: 'Documentos prontos para financiamento',
            value: property?.financing_ready_documents,
            key: 'financing_ready_documents',
          },
          {
            label: 'Escriturado',
            value: property?.recorded,
            key: 'recorded',
          },
          {
            label: 'Inventário',
            value: property?.inventory,
            key: 'inventory',
          },
          {
            label: 'Pagamento parcelado',
            value: property?.payment_in_installments,
            key: 'payment_in_installments',
          },
          {
            label: 'Projeto aprovado',
            value: property?.project_approved,
            key: 'project_approved',
          },
          {
            label: 'Parcela parte da entrada',
            value: property?.portion_part_entrance,
            key: 'portion_part_entrance',
          },
          {
            label: 'Projeto arquitetônico',
            value: property?.architectural_project,
            key: 'architectural_project',
          },
          {
            label: 'Projeto elétrico',
            value: property?.electrical_project,
            key: 'electrical_project',
          },
          {
            label: 'Projeto estrutural',
            value: property?.structural_design,
            key: 'structural_design',
          },
          {
            label: 'Projeto hidráulico',
            value: property?.hydraulic_design,
            key: 'hydraulic_design',
          },
          {
            label: 'Parcelado',
            value: property?.installments,
            key: 'installments',
          },
          {
            label: 'Quitado',
            value: property?.settled,
            key: 'settled',
          },
          {
            label: 'Registrado',
            value: property?.registered,
            key: 'registered',
          },
          {
            label: 'Uso do solo',
            value: property?.use_soil,
            key: 'use_soil',
          },
          {
            label: 'Não exige transferir financiamento',
            value: property?.requires_transferring_funding,
            key: 'requires_transferring_funding',
          },
        ]),
      },
    },
  }),

  renderField(property: any, field: any) {
    if (!property || !field) return null;

    const v = property[field];

    switch (field) {
      case 'contruct_area':
        return `${v} m² construídos`;
      case 'land_area':
        return `${v} ${
          property.type_footage === 'hectares' ? 'hectares' : 'm²'
        } total`;
      case 'bathrooms':
        return v > 1 ? `${v} banheiros` : `${v} banheiro`;
      case 'bedrooms':
        return v > 1 ? `${v} quartos` : `${v} quarto`;
      case 'suites':
        return v > 1 ? `${v} suites` : `${v} suite`;
      default:
        return v;
    }
  },
};

function getData(property: any, fields: any) {
  if (!property) return [];
  const data: any = [];

  fields.forEach((field: any) => {
    if (field.value) {
      data.push(field);
    }
  });

  return data;
}
