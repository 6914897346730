import { TextField } from '@mui/material';

import Label from '../Label';

export type DateTimePickerProps = {
  label?: string;
  value?: any;
  setValue?: (value: string) => void;
  required?: boolean;
  type?: 'date' | 'time';
};

export default function DateTimePicker({
  label,
  required,
  value,
  setValue,
  type = 'date',
}: DateTimePickerProps) {
  return (
    <div>
      <Label required={required}>{label}</Label>
      <TextField
        type={type}
        defaultValue=""
        variant="standard"
        sx={{ width: '100%' }}
        value={value}
        onChange={e => setValue && setValue(e.target.value)}
      />
    </div>
  );
}
