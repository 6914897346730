import { IDefaultPageTitle } from './IDefaultPageTitle';
import * as S from './styles';

function DefaultPageTitle({
  children,
  emphasis,
  color,
  align,
}: IDefaultPageTitle) {
  return (
    <S.Container color={color} align={align}>
      {children}
      {!!emphasis && <em> {emphasis}</em>}
    </S.Container>
  );
}

export { DefaultPageTitle };
