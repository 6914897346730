import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button, Input, Logo } from '../../components/Atoms';
import { useAuth } from '../../hooks/auth';
import * as S from './styles';

const ChangeResetPassword = () => {
  const params = useParams();
  const hash = params.hash;
  const { register, handleSubmit, errors, setError } = useForm();
  const { resetPassword } = useAuth();
  const onSubmit = useCallback(
    (data: any) => {
      if (data['password'] !== data['passwordRepete']) {
        setError('passwordRepete', {
          type: 'custom',
          message: 'Senhas não conferem',
        });
        return;
      }
      if (hash) {
        const newDt = {
          password: data.password,
          hash: hash,
        };
        resetPassword(newDt);
      }
    },
    [hash, errors],
  );
  return (
    <S.Container>
      <Logo />

      <S.Header>
        <h3>Bem-vindo!</h3>
        <p>Digite sua nova senha</p>
      </S.Header>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="password"
          placeholder="digite sua senha"
          fontColor="var( --primary-neutral-900)"
          label="Senha"
          id="password"
          name="password"
          ref={register({
            required: {
              value: true,
              message: 'Preencha sua senha para continuar',
            },
          })}
          error_message={errors.password?.message}
          bordered
          bolder_font
        />
        <Input
          type="password"
          placeholder="digite sua senha"
          fontColor="var( --primary-neutral-900)"
          label="Repita a senha"
          id="passwordRepete"
          name="passwordRepete"
          ref={register({
            required: {
              value: true,
              message: 'Preencha sua senha para continuar',
            },
          })}
          error_message={errors.passwordRepete?.message}
          bordered
          bolder_font
        />
        <Button color="blue" type="submit">
          Alterar
        </Button>
      </S.Form>
    </S.Container>
  );
};

export { ChangeResetPassword };
export default ChangeResetPassword;
